/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';

/**
 * ActionButtonGroup component renders a button that triggers a dropdown menu with
 * actions such as "Copy Selection", "Archive Selection", and "Delete Selection".
 *
 * @param {number} rowIndex - Index of the row for which the actions are being displayed.
 * @param {string} _id - Unique identifier for the item being acted upon.
 * @param {Function} handleOpenCopySelectionModal - Function to open the "Copy Selection" modal.
 * @param {Function} handleOpenArchiveSelectionModal - Function to open the "Archive Selection" modal.
 * @param {Function} handleOpenDeleteSelectionModal - Function to open the "Delete Selection" modal.
 *
 * @returns {JSX.Element} A dropdown action button group component.
 */
const ActionButtonGroup = ({
  rowIndex,
  _id,
  handleOpenCopySelectionModal,
  handleOpenArchiveSelectionModal,
  handleOpenDeleteSelectionModal,
  isArchived,
  featureSelectionFoldersEnabled,
  restrictedVisibility,
  isWaterfall,
}) => {
  // State to track dropdown visibility
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  /**
   * Handle button click based on the label of the clicked action.
   * @param {string} label - The label of the clicked action.
   * @returns {void}
   */
  const handleButtonClick = (label) => {
    switch (label) {
      case 'Delete Selection':
        handleOpenDeleteSelectionModal(_id);
        break;
      case 'Archive Selection' || 'Restore Selection':
        handleOpenArchiveSelectionModal(_id);
        break;
      case 'Copy Selection':
        handleOpenCopySelectionModal(_id);
        break;
      default:
        break;
    }
    setIsOpen(false); // Close dropdown after selection
  };

  // Effect to handle clicks outside of the dropdown
  useEffect(() => {
    /**
     * Handle click events outside the dropdown to close it.
     * @param {MouseEvent} event - The mouse event.
     * @returns {void}
     */
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    // Attach the event listener for clicks outside the dropdown
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      // Clean up the event listener on component unmount
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <div className="slds-btn-actions" key={rowIndex}>
      <div role="group">
        <div
          className={`slds-dropdown-trigger slds-dropdown-trigger_click slds-button_last ${isOpen ?
            'slds-is-open' :
            ''}`}
          ref={dropdownRef}
        >
          <button
          type="button"
            className="slds-button slds-button_icon slds-button_icon-border-filled"
            aria-haspopup="true"
            aria-expanded={isOpen}
            title="Show More"
            onClick={() => setIsOpen(!isOpen)}
          >
            <svg className="slds-button__icon" aria-hidden="true">
              <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#threedots_vertical" />
            </svg>
            <span className="slds-assistive-text">Show Actions</span>
          </button>

          {/* Conditionally render dropdown based on isOpen */}
          {isOpen && (
            <div className="slds-dropdown slds-dropdown_right slds-dropdown_actions">
              <ul className="slds-dropdown__list" role="menu">
                {isArchived ?
                  (
                     <li
                     className="slds-dropdown__item"
                     role="presentation"
                     onClick={() => handleButtonClick('Restore Selection')}
                   >
                     <a href="#" role="menuitem" tabIndex="0">
                       <span className="slds-truncate des-lib-datatable-actions-v2" title="Archive Selection">
                         {isWaterfall ? 'Restore Waterfall Selection' : 'Restore Selection'}
                       </span>
                     </a>
                     </li>
                  ) :
                  (
                    <>
                    <li
                        className="slds-dropdown__item"
                        role="presentation"
                        onClick={() => handleButtonClick('Copy Selection')}
                    >
                    <a href="#" role="menuitem" tabIndex="0">
                      <span className="slds-truncate des-lib-datatable-actions-v2" title="Copy Selection">
                      {isWaterfall ? 'Copy Waterfall Selection' : 'Copy Selection'}
                      </span>
                    </a>
                    </li>
                    {featureSelectionFoldersEnabled && !restrictedVisibility && (
                    <li
                      className="slds-dropdown__item"
                      role="presentation"
                      onClick={() => handleButtonClick('Archive Selection')}
                    >
                      <a href="#" role="menuitem" tabIndex="0">
                        <span className="slds-truncate des-lib-datatable-actions-v2" title="Archive Selection">
                          {isWaterfall ? 'Archive Waterfall Selection' : 'Archive Selection'}
                        </span>
                      </a>
                    </li>
                    )}
                    </>
                  )}

                <li
                  className="slds-dropdown__item slds-text-color_error des-lib-datatable-delete-action"
                  role="presentation"
                  onClick={() => handleButtonClick('Delete Selection')}
                >
                  <a href="#" role="menuitem" tabIndex="0">
                    <span className="slds-truncate delete-section-btn" title="Delete Selection">
                      {isWaterfall ? 'Delete Waterfall Selection' : 'Delete Selection'}
                    </span>
                  </a>
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ActionButtonGroup;
