import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {
  InputIcon, Input, ButtonGroup,
} from '@salesforce/design-system-react';
import './styles.scss';
import { connect } from 'react-redux';

import mapStateToProps from '../../../../mapStateToProps';
import Constants from '../../../../constants/constants';
import FilterCard from '../FilterCard/FilterCard';
import FilterSetCard from '../FilterSetCard/FilterSetCard';
import filtersUtil from '../../../../utils/filters/filtersUtil';
import Features from '../../../../features';
import Button from '../../../shared/Button/Button';
import UpgradeBadge from '../../../shared_v2/UpgradeBadge/UpgradeBadge';
import Tooltip from '../../../shared/Tooltip/Tooltip';
import { featureAdvertExists } from '../../../shared_v2/Feature/featureUtil';
import GuidanceTip from '../../../shared_v2/GuidanceTip/GuidanceTip';

const customButtonStyles = {
  width: '10.95rem',
};

const DataSetCustomButtonStyles = {
  width: '9rem',
};

/*
 * we tried PureComponent and the issue we got is
 * after changing an alias of a selectedExtension
 * on the header of avaliableFields, it wasn't changed
 */
class AvailableFields extends Component {
  constructor(props) {
    super(props);
    const {
      selectedDataExtensions, filterSets, dataSets,
    } = this.props;

    // If there is only one selected DE, expand the fields
    if (selectedDataExtensions?.length === 1) {
      selectedDataExtensions[0].showFields = true;
    }

    this.state = {
      filterSearchField: '',
      isFilterFieldsTabSelected: true,
      expandFields: false,
      currentSelectedDataExtensions: selectedDataExtensions,
      expandClicked: false,
      collapseClicked: false,
      toggleSingleSelection: false,
      toggledSelection: false,
      showAvailableFields: true,
      showDataSets: false,
      groupedFilterSets: filterSets ?
        filtersUtil.getGroupedFilterSetsToDisplay(
          filterSets,
          selectedDataExtensions,
          dataSets,
        ) :
        [],
    };
  }

  /**
   * update component when a new Extension is added
   * @param {*} prevProps - Previous props
   */

  async componentDidUpdate(prevProps) {
    const {
      selectedDataExtensions, createNewAutoTargetDE, filterSets, dataSets,
    } = this.props;

    const { groupedFilterSets } = this.state;

    // If there is only one selected DE, expand the fields
    if (
      prevProps.selectedDataExtensions !== selectedDataExtensions && selectedDataExtensions?.length === 1 &&
      !selectedDataExtensions[0].showFields
    ) {
      selectedDataExtensions[0].showFields = true;
    }

    // When going from 1 to multiple selected DEs, collapse all
    if (prevProps?.selectedDataExtensions?.length === 1 && selectedDataExtensions?.length > 1) {
      selectedDataExtensions.forEach((DE) => {
        DE.showFields = false;
      });
    }

    if (selectedDataExtensions && selectedDataExtensions.length !== prevProps.selectedDataExtensions.length ||
      dataSets?.length !== prevProps.dataSets?.length) {
      this.setState({
        currentSelectedDataExtensions: selectedDataExtensions,
        groupedFilterSets: filtersUtil.getGroupedFilterSetsToDisplay(
          filterSets,
          selectedDataExtensions,
          dataSets,
          groupedFilterSets,
        ),
      });
    }
    if (prevProps?.createNewAutoTargetDE !== createNewAutoTargetDE && createNewAutoTargetDE) {
      this.handleExpand(false);
    }
  }

  /**
   * Set state filterSearchField
   * @param {object} e - event
   * @returns {void}
   */
  handleFilterSearchField = (e) => {
    const {
      expandClicked, toggleSingleSelection, collapseClicked, currentSelectedDataExtensions, toggledSelection,
    } = this.state;

    this.setState({ filterSearchField: e.target.value });
    if (currentSelectedDataExtensions.length > 1) {
      if (e.target.value) {
        this.handleExpand(true);
        this.handleExpandFilterSets(true);
      } else {
        if (expandClicked) {
          this.handleExpand(true);
          this.handleExpandFilterSets(true);
        } else if (collapseClicked) {
          this.handleExpand(false);
          this.handleExpandFilterSets(false);
        } else {
          this.handleExpand(false);
          this.handleExpandFilterSets(false);
        }
      }
    } else {
      if (e.target.value) {
        this.handleExpand(true);
        this.handleExpandFilterSets(true);
      } else {
        if (toggleSingleSelection && toggledSelection) {
          this.handleExpand(true);
          this.handleExpandFilterSets(true);
        } else if (!toggleSingleSelection && toggledSelection) {
          this.handleExpand(false);
          this.handleExpandFilterSets(false);
        } else {
          this.handleExpand(false);
          this.handleExpandFilterSets(false);
        }
      }
    }
  };
  /**
   * Toggle dataExtensions
   * @param {*} dataExtensionId - this is dataExtension deAlias
   * @param {*} index - this is the position of the DataExtension
   */

  toggleFieldsVisibility = (dataExtensionId, index) => {
    const { currentSelectedDataExtensions } = this.state;

    this.setState(state => ({
      ...state,
      currentSelectedDataExtensions: state?.currentSelectedDataExtensions?.map((data, i) => {
        if (data.deAlias === dataExtensionId && index === i && data.showFields) {
          return {
            ...data,
            showFields: false,
          };
        }
        if (data.deAlias === dataExtensionId && index === i && !data.showFields) {
          return {
            ...data,
            showFields: true,
          };
        }

        return data;
      }) || [],
    }));

    if (currentSelectedDataExtensions?.length === 1) {
      if (currentSelectedDataExtensions[0].showFields) {
        this.setState({ toggleSingleSelection: false, toggledSelection: true });
      } else {
        this.setState({ toggleSingleSelection: true, toggledSelection: true });
      }
    }
  };

  /**
   * Set state expandFields and visibleFieldsDEId
   * @param {*} data - if expand data will be true if collapse data will be false
   */

  handleExpand = (data) => {
    if (data) {
      this.setState(state => ({
        ...state,
        currentSelectedDataExtensions: state.currentSelectedDataExtensions.map((data) => {
          return {
            ...data,
            showFields: true,
          };
        }),
      }));
    } else {
      this.setState(state => ({
        ...state,
        currentSelectedDataExtensions: state.currentSelectedDataExtensions.map((data) => {
          return {
            ...data,
            showFields: false,
          };
        }),
      }));
    }
  };

  /**
   * Expand and collapse filter sets fields
   * @param {boolean} status - if expand status will be true if collapse status will be false
   */

  handleExpandFilterSets = (status) => {
    if (status) {
      this.setState(state => ({
        ...state,
        groupedFilterSets: state.groupedFilterSets.map((data) => {
          return {
            ...data,
            showFilterSetFields: true,
          };
        }),
      }));
    } else {
      this.setState(state => ({
        ...state,
        groupedFilterSets: state.groupedFilterSets.map((data) => {
          return {
            ...data,
            showFilterSetFields: false,
          };
        }),
      }));
    }
  };
  /**
   * Toggle filter sets visibility
   * @param {string} name - name of category
   * @param {boolean} status - visibility status
   * @param {number} index - position of category in array
   */

  toggleFilterSetsVisibility = (name, status, index) => {
    this.setState(state => ({
      ...state,
      groupedFilterSets: state?.groupedFilterSets?.map((data, i) => {
        if (data.category.name === name && index === i) {
          return {
            ...data,
            showFilterSetFields: !status,
          };
        }

        return data;
      }) || [],
    }));
  };

  render() {
    const {
      selectedDataExtensions,
      handleAddFieldToTargetDE,
      editNewAutoTargetDE,
      newTargetDataExtensionFields,
      handleSetSelectionState,
      matchedFields,
      dropToTargetDataExtensionField,
      manageSubscriberRelationship,
      customValues,
      editTargetDataExtension,
      targetDataExtensionFields,
      DEBorderMouseOver,
      filterBorderMouseOver,
      hideCollectionAlias,
      inTargetDefinition,
      filterSets,
      showFilterSets,
      isBasicMode,
      globalCustomValues,
      showEssentialsUpgradeModal,
      featuresInfo,
      loadingAllAvailableDataExtensions,
    } = this.props;

    const featureCustomValues = Features.isFeatureEnabled(
      featuresInfo,
      Constants.FEATURE__CUSTOM_VALUES,
    );

    const featureFilterSets = Features.isFeatureEnabled(
      featuresInfo,
      Constants.FEATURE__FILTER_SETS,
    );

    const { showAvailableFields, showDataSets } = this.state;

    const availableFieldsTitle = showFilterSets || !inTargetDefinition ? 'Available Filters' : 'Available Fields';

    const fieldsHeaderTitle = customValues === undefined ? availableFieldsTitle : 'Custom Values';

    const
      {
        filterSearchField, isFilterFieldsTabSelected, currentSelectedDataExtensions, expandFields, groupedFilterSets,
      } = this.state;

    const customValuesFeatureDisabled = !featureCustomValues && customValues !== undefined;

    let filterCard = null;

    if (currentSelectedDataExtensions && currentSelectedDataExtensions.length > 0) {
      filterCard = (
        <div>
          {currentSelectedDataExtensions.map((dataExtension, index) => (
            <FilterCard
              hideCollectionAlias={!!hideCollectionAlias}
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              positionIndex={index}
              dataExtension={dataExtension}
              filterSearchField={filterSearchField}
              handleAddFieldToTargetDE={handleAddFieldToTargetDE}
              editNewAutoTargetDE={editNewAutoTargetDE}
              newTargetDataExtensionFields={newTargetDataExtensionFields}
              handleSetSelectionState={handleSetSelectionState}
              selectedDataExtensions={selectedDataExtensions}
              matchedFields={matchedFields}
              dropToTargetDataExtensionField={dropToTargetDataExtensionField}
              manageSubscriberRelationship={manageSubscriberRelationship}
              editTargetDataExtension={editTargetDataExtension}
              targetDataExtensionFields={targetDataExtensionFields}
              DEBorderMouseOver={DEBorderMouseOver}
              filterBorderMouseOver={filterBorderMouseOver}
              inTargetDefinition={inTargetDefinition}
              isBasicMode={isBasicMode}
              showFields={currentSelectedDataExtensions[index].showFields}
              toggleFieldsVisibility={this.toggleFieldsVisibility}
              showEssentialsUpgradeModal={showEssentialsUpgradeModal}
              expandFields={expandFields}
            />
          ))}
        </div>
      );
    } else if (customValues === undefined) {
      filterCard = null;
    } else {
      filterCard = (
        <FilterCard
          hideCollectionAlias={!!hideCollectionAlias}
          customValues={customValues}
          filterSearchField={filterSearchField}
          handleAddFieldToTargetDE={handleAddFieldToTargetDE}
          editNewAutoTargetDE={editNewAutoTargetDE}
          dropToTargetDataExtensionField={dropToTargetDataExtensionField}
          matchedFields={matchedFields}
          handleSetSelectionState={handleSetSelectionState}
          DEBorderMouseOver={DEBorderMouseOver}
          toggleFieldsVisibility={this.toggleFieldsVisibility}
          globalCustomValues={globalCustomValues}
          showEssentialsUpgradeModal={showEssentialsUpgradeModal}
          expandFields={expandFields}
        />
      );
    }

    let filterSetsCard = null;

    if (groupedFilterSets?.length > 0) {
      filterSetsCard = (
        groupedFilterSets.map((groupedSet, index) => (
          <FilterSetCard
            key={index}
            positionIndex={index}
            hideCollectionAlias={!!hideCollectionAlias}
            filterSetsToDisplay={groupedSet}
            filterSets={filterSets}
            filterSearchField={filterSearchField}
            handleAddFieldToTargetDE={handleAddFieldToTargetDE}
            editNewAutoTargetDE={editNewAutoTargetDE}
            newTargetDataExtensionFields={newTargetDataExtensionFields}
            handleSetSelectionState={handleSetSelectionState}
            selectedDataExtensions={selectedDataExtensions}
            matchedFields={matchedFields}
            dropToTargetDataExtensionField={dropToTargetDataExtensionField}
            manageSubscriberRelationship={manageSubscriberRelationship}
            editTargetDataExtension={editTargetDataExtension}
            toggleFilterSetsVisibility={this.toggleFilterSetsVisibility}
            targetDataExtensionFields={targetDataExtensionFields}
            DEBorderMouseOver={DEBorderMouseOver}
            filterBorderMouseOver={filterBorderMouseOver}
            inTargetDefinition={inTargetDefinition}
            loadingAllAvailableDataExtensions={loadingAllAvailableDataExtensions}
          />
        ))
      );
    }

    return (
      <div className={`available-fields-v2 ${!!inTargetDefinition}`}>
        {(showFilterSets === true || showFilterSets === false) && (
            <ButtonGroup id="button-group-more-icon" className="filter-fields-and-sets-button-group">
              <Button
                style={customButtonStyles}
                onClick={() => {
                  this.setState({ isFilterFieldsTabSelected: true, showDataSets: false, showAvailableFields: true });
                }}
                className="button-group-tde-text"
                variant={showAvailableFields ? 'brand' : 'neutral'}
                label="Filter Fields" />

              <Button
                style={DataSetCustomButtonStyles}
                onClick={() => {
                  this.setState({ isFilterFieldsTabSelected: false, showDataSets: true, showAvailableFields: false });
                }}
                className="button-group-tde-text filter-set-btn"
                variant={showDataSets ? 'brand' : 'neutral'}
                label="Filter Sets"
                disabled={!featureFilterSets}
                tooltip={featureFilterSets ?
                  null :
                  {
                    type: Constants.TOOLTIP_TYPE__UNAVAILABLE_FEATURE,
                    align: Constants.SLDS_TOOLTIP_POSITION__BOTTOM,
                    id: 'filtersets-tab-tooltip',
                  }}
                />
            </ButtonGroup>
        )}
        <div className="available-extensions-header">
          <h4 className="section-title-v2">
            {fieldsHeaderTitle}
            {
              !customValues &&
              <GuidanceTip
                tipId={showFilterSets || !inTargetDefinition ? 'available-filters-tip' : 'available-fields-tip'}
                toolTipPosition="right"
              />
            }
          </h4>
          {
              customValuesFeatureDisabled &&
              (featureAdvertExists(Constants.FEATURE__CUSTOM_VALUES) ?
                <div onClick={() => showEssentialsUpgradeModal(Constants.FEATURE__CUSTOM_VALUES)}>
                  <UpgradeBadge />
                </div> :
                <div className="unavailable-feature-tooltip">
                  <Tooltip
                    align={Constants.SLDS_TOOLTIP_POSITION__TOP}
                    type={Constants.TOOLTIP_TYPE__UNAVAILABLE_FEATURE}
                  />
                </div>)
          }
        </div>
        {
          customValuesFeatureDisabled &&
          <div
            onClick={() => showEssentialsUpgradeModal(Constants.FEATURE__CUSTOM_VALUES)}
            className="disabled-overlay-cv"
          />
        }
        <div>
          <Input
            iconLeft={
              <InputIcon
                assistiveText={{
                  icon: 'Search',
                }}
                name="search"
                category="utility"
              />
            }
            className="available-extension-v2_search des-search-position-v2 filter-search-v2"
            placeholder={customValues === undefined ? 'Search fields' : 'Search values'}
            onChange={e => this.handleFilterSearchField(e)}
            value={filterSearchField}
            id="unique-id-1"
          />
        </div>
        <div
          className={classNames('make-space-for-scroll', {
            'disabled-cv': customValuesFeatureDisabled,
          })}
        >
          <div
            id="fields"
            className="available-fields_available_collections-v2 available-filters-container-v2"
          >
            {isFilterFieldsTabSelected ? filterCard : filterSetsCard}
          </div>
        </div>
      </div>
    );
  }
}

AvailableFields.propTypes = {
  /**
   * It keeps the selected data extensions for Selection.js
   * selected data extensions are stored as collections in database
   * It will be passed from Selection.js
   */
  selectedDataExtensions: PropTypes.instanceOf(Array),
  /**
   * It helps to a new field to a DE while creating one
   */
  handleAddFieldToTargetDE: PropTypes.func,
  /**
   * It keeps the value of AC/DE creation status
   * It will be passed from Selection.js
   */
  editNewAutoTargetDE: PropTypes.bool,
  /**
   * It keeps the fields of a new target data extension while creating one
   */
  newTargetDataExtensionFields: PropTypes.instanceOf(Array),
  /**
   * It helps to set the Selection`s state
   * It will be passed from Selection.js
   */
  handleSetSelectionState: PropTypes.func.isRequired,
  /**
   * It keeps the matchedFields for a target data extension of the Selection
   * It will be passed from Selection.js
   */
  matchedFields: PropTypes.instanceOf(Array),
  /**
   * It helps to match a field between available fields and target DE fields
   */
  dropToTargetDataExtensionField: PropTypes.func,
  /**
   * It helps to manage subscriber relationship while creating a data extension
   * It will be passed from Selection.js
   */
  manageSubscriberRelationship: PropTypes.func,
  /**
   * boolean state from Selection for going in or out the edit target de mode
   */
  editTargetDataExtension: PropTypes.bool,
  /**
   * It keeps the fields of an existing target data extension
   * It will be passed from Selection.js
   */
  targetDataExtensionFields: PropTypes.instanceOf(Array),
  /*
   * Keeps track whether Available DE are dragged
   */
  DEBorderMouseOver: PropTypes.bool.isRequired,
  /**
   * Keeps track whether Available Fields are dragged
   */
  filterBorderMouseOver: PropTypes.bool.isRequired,
  /**
   * It keeps custom values data
   * It will be passed from Selection.js
   */
  customValues: PropTypes.instanceOf(Array),
  /**
   * It determines if the collection alias should be hidden after the name in the filter line
   */
  hideCollectionAlias: PropTypes.bool.isRequired,
  /**
   * Defines whether the component is used in Target Definition view
   */
  inTargetDefinition: PropTypes.bool,
  /**
   * An array containing filter sets
   */
  filterSets: PropTypes.instanceOf(Array),
  /**
   * Define whether filter sets should be shown or not
   */
  showFilterSets: PropTypes.bool,
  /**
   * Indicates whether a selection is in basic mode
   */
  isBasicMode: PropTypes.bool,
  /**
   * Shared Custom Values defined for this BU
   */
  globalCustomValues: PropTypes.instanceOf(Array),
  /**
   * It toggles a feature advert modal on with specific feature
   */
  showEssentialsUpgradeModal: PropTypes.func,
  /**
   * Determines if a new DE is being created
   */
  createNewAutoTargetDE: PropTypes.bool,
  /**
   * Features info from cookie
   */
  featuresInfo: PropTypes.object,
};

export default connect(mapStateToProps(['featuresInfo']), null, null, { pure: false })(AvailableFields);
