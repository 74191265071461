import React, { useEffect, useState } from 'react';
import {
  connect, shallowEqual, useDispatch, useSelector,
} from 'react-redux';
import axios from 'axios';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';
import { Dropdown } from 'semantic-ui-react';

import mapStateToProps from '../../../../mapStateToProps';
import {
  setDataSetName,
  setDataExtensions,
  setPredefinedRelations,
  setPredefinedRelationsMap,
  setSelectedDEsTree,
  setRelations,
  setModalDataExtensions,
  clearDataSetState,
} from '../../../../redux/actions/dataSets/globalActions';
import DataExtensionsAPI from '../../../../api/data-extensions';
import DataViews from '../../../../constants/dataViews';
import DataViewsAPI from '../../../../api/data-views';
import RelationsAPI from '../../../../api/relations';
import Util from '../../../../util';
import Constants from '../../../../constants/constants';
import LoadingModal from '../../../shared/LoadingModal/LoadingModal';
import Features from '../../../../features';
import Tooltip from '../../../shared/Tooltip/Tooltip';
import SelectedExtensions from '../../../../components/Selection/DataExtensions/SelectedExtensions/SelectedExtensions';
// eslint-disable-next-line
import FolderDataExtensions from '../../../Selection/DataExtensions/AvailableExtensions/FolderDataExtensions/FolderDataExtensions';
import RelationModal from '../../../Selection/DataExtensions/RelationalModal/RelationModal';
import DataSetsAPI from '../../../../api/data-sets';

import './styles.scss';

const DataSetsPanel = ({
  dataSetId,
  openPanel,
  selectedDataSet,
  activePanel,
  featuresInfo,
}) => {
  const dispatch = useDispatch();
  const axiosCancelToken = axios.CancelToken.source();
  const featureDataViews = Features.isFeatureEnabled(featuresInfo, Constants.FEATURE__DATA_VIEWS);
  const selectedDERef = React.createRef();

  const [loading, setLoading] = useState(true);
  const [parentDE, setParentDE] = useState({});
  const [loaderSelectedDE, setLoaderSelectedDE] = useState(false);
  const [DEBorderMouseOver, setDEBorderMouseOver] = useState(false);
  const [filterBorderMouseOver, setFilterBorderMouseOver] = useState(false);
  const [showRelatedDEsModal, setShowRelatedDEsModal] = useState(false);
  const [relatedDEs, setRelatedDEs] = useState([]);
  const [associateDE, setAssociateDE] = useState([]);
  const [showRelationalModal, setShowRelationalModal] = useState(false);
  const [savingDataSet, setSavingDataSet] = useState(false);
  const [selectedDataExtensions, setSelectedDataExtensions] = useState([]);

  // get state of properties from reducers
  const {
    dataSetName,
    dataExtensions,
    selectedDEsTree,
    relations,
    predefinedRelations,
    predefinedRelationsMap,
    modalDataExtensions,
  } =
  useSelector(({
    dataSetsGlobalReducer,
  }) => ({
    dataSetName: dataSetsGlobalReducer.dataSetName,
    dataExtensions: dataSetsGlobalReducer.dataExtensions,
    predefinedRelations: dataSetsGlobalReducer.predefinedRelations,
    predefinedRelationsMap: dataSetsGlobalReducer.predefinedRelationsMap,
    selectedDEsTree: dataSetsGlobalReducer.selectedDEsTree,
    relations: dataSetsGlobalReducer.relations,
    modalDataExtensions: dataSetsGlobalReducer.modalDataExtensions,
  }), shallowEqual);

  /**
   * @param {string} typeOfSwal - type of swal
   * @param {string} titleOfSwal - title of swal
   * @param {string} message - message of swal
   * @returns {object} - return swal
   */
  const throwSwal = async (typeOfSwal, titleOfSwal, message) => {
    return Swal.fire({
      type: typeOfSwal,
      title: `<div class="error-title">${titleOfSwal}</div>`,
      html: `<p class="width_swal">${message}</p>`,
      showCancelButton: false,
      confirmButtonText: 'OK',
      footer: '<div></div>',
      buttonsStyling: false,
      allowOutsideClick: false,
    });
  };

  /**
   * Gets predefined relations
   * @returns {Promise<array>} - predefined relations
   */
  const getRelations = async () => {
    try {
      return await RelationsAPI.getRelations(axiosCancelToken.token);
    } catch (error) {
      if (!axios.isCancel(error)) {
        if (!String(error?.response?.data?.actualError).includes(
          Constants.ERROR_SFMC_PERMISSION_FAILED,
        )) {
          throwSwal('error', 'Error', error);
        }
      }
    }
  };

  /**
   * Push dataViews in dataExtensions if feature 'dataViews' is enabled and then sort it
   * @param {object} dataExtensions - List of data extensions
   * @returns {void}
   */
  const addDataViewsInDEs = (dataExtensions) => {
    dataExtensions.push(...DataViews);

    Util.sortArrayOfObjects(dataExtensions, 'Name');
  };

  /**
   * Show a swal to the user indicating the feature is not enabled, and bring user back to the overview screen
   * @param {string} feature - Name of feature
   * @returns {void}
   */
  const handleFeatureMissing = async (feature) => {
    axiosCancelToken.cancel(`Missing feature: ${feature}.`);

    await throwSwal(
      'error',
      'Oops...',
      `You do not have the feature for <b class="bold_swal">${feature}</b> enabled to open this Data Set.`,
    );

    await openPanel(Constants.ADMIN_PANEL__MENU__DATA_SETS);
  };

  /**
   * Function for adjusting data view fields for further usage
   * @param {object[]} selectedCollection - Selected collection
   * @returns {void}
   */
  const prepareDataViewsFields = (selectedCollection) => {
    const dataExtension = {
      CustomerKey: selectedCollection.CustomerKey,
    };

    selectedCollection.fields.forEach((field) => {
      /* eslint-disable no-param-reassign */
      field.CustomerKey = `[${selectedCollection.CustomerKey}].[${field.Name.toString()}]`;
      field.DataExtension = dataExtension;
      /* eslint-enable no-param-reassign */
    });
  };

  /**
   * Helps to retrieve fields of a data extension or data view from SFMC
   * @param {object[]} selectedCollection - Selected collection
   * @param {boolean} isComparedField - Determined if we are calling this function for compared fields
   * @returns {void}
   */
  const getDataExtensionOrDataViewFields = async (selectedCollection) => {
    /**
     * Check if selected DE has CategoryID
     * If it has then move on to find DE
     * If it doesn't have then move on to find Data Views
     */
    if (selectedCollection && !selectedCollection.CategoryID) {
      /**
       * If feature 'dataViews' is 'true' get data views fields
       * Else show error message
       */
      if (featureDataViews) {
        try {
          // eslint-disable-next-line no-param-reassign, require-atomic-updates
          selectedCollection.fields = await DataViewsAPI.getDataViewFields(
            selectedCollection.CustomerKey.toString(),
            axiosCancelToken.token,
          );
        } catch (error) {
          if (!axios.isCancel(error)) {
            // show swal error if request failed with error
            throwSwal('error', 'Error', error);
          }
        }
      } else {
        await handleFeatureMissing(Constants.FEATURE__DATA_VIEWS_LABEL);

        /**
         * I am returning null so we can differentiate data views feature is disabled and
         * Data view is deleted
         */
        return null;
      }

      // Prepare fields so you can use it properly
      prepareDataViewsFields(selectedCollection);
      // Sort data view fields by Name
      Util.sortArrayOfObjects(selectedCollection.fields, 'Name');
    } else {
      // Get data extension fields
      let getCustomerKey;

      if (dataExtensions?.length) {
        // find customerKey from collection in dataExtensions
        getCustomerKey = dataExtensions.filter(de => de.CustomerKey &&
         de.CustomerKey === selectedCollection.CustomerKey);
      }

      // prevent from errors, check if fields have data
      if (getCustomerKey?.length && getCustomerKey[0].fields) {
        // make a delay to avoid errors
        const timeout = n => new Promise(cb => setTimeout(cb, n));

        await timeout(100);

        // assign fields from the fetched fields
        // eslint-disable-next-line require-atomic-updates, no-param-reassign
        selectedCollection.fields = getCustomerKey[0].fields;
      } else {
        try {
          // if it doesn't find the fields, get new ones
          const fields = await DataExtensionsAPI.getDataExtensionFields(
            selectedCollection.CustomerKey,
            axiosCancelToken.token,
          );

          if (fields?.data) {
            // eslint-disable-next-line require-atomic-updates, no-param-reassign
            selectedCollection.fields = fields.data;
          } else {
            // eslint-disable-next-line require-atomic-updates, no-param-reassign
            selectedCollection.fields = [];
          }
        } catch (error) {
          if (!axios.isCancel(error)) {
            // show swal error if request failed with error
            throwSwal('error', 'Error', error);
          }
        }
      }
    }

    return selectedCollection.fields;
  };

  /**
   * Adds all needed properties into DE
   * @param {object} extension - DE
   * @param {string} alias - Alias of DE
   * @param {object[]} subCollections - Sub collections
   * @param {string} toFromField - Indicates previous field
   * @param {number} toFromIndex - Indicates previous index
   * @param {number} relationsIndex - Indicates index in relations array
   * @param {string} toFromFieldType - Indicates type of previous field
   * @param {string} toFromFieldObjectID - Indicates ObjectId of the field
   * @returns {void}
   */
  const setDataExtensionProperties = (
    extension,
    alias,
    subCollections,
    toFromField,
    toFromIndex,
    relationsIndex,
    toFromFieldType,
    toFromFieldObjectID,
  ) => {
    /* eslint-disable no-param-reassign */
    extension.dragged = true;
    extension.id = alias + new Date().getTime();
    extension.deAlias = alias;
    // Push all subCollections, filter(remove) them if there is/are subCollections' subCollections
    extension.subCollections = (extension.subCollections ?
      [...extension.subCollections, subCollections] :
      subCollections
    ).filter(collection => !(collection && collection.length >= 0));
    // 0 -> From (parent extension) properties
    if (toFromIndex === 0) {
      // 1 -> To (child extension) properties
    } else if (toFromIndex === 1) {
      extension.edit = true;
      extension.toField = toFromField;
      extension.toFieldType = toFromFieldType;
      extension.toFieldObjectID = toFromFieldObjectID;
      /**
       * Create unique Id for relationalModal using getTime() and relationsIndex (adding to ensure
       * RelationalModalIds built in same millisecond are still unique)
       */
      extension.relationalModalId = Number.parseInt(
        new Date().getTime() + '' + (typeof relationsIndex === 'number' ? relationsIndex : 0),
      );
    }
    /* eslint-enable no-param-reassign */
  };

  /**
   * Pushes related data extensions (if there is no duplication) into selectedDataExtension state
   * @param {object[]} selectedDataExtensions - Selected DE
   * @param {object} toCollection - To which collection
   * @param {object} fromCollection - From which collection
   * @returns {object[]} The selected data extensions with new related data extension items
   */
  const addToSelectedDataExtensions = (selectedDataExtensions, toCollection, fromCollection) => {
    // If both toCollection and fromCollection don't exist; push both of them.
    if (selectedDataExtensions.filter(ex => ex.deAlias === toCollection.deAlias)?.length === 0 &&
      selectedDataExtensions.filter(ex => ex.deAlias === fromCollection.deAlias)?.length === 0) {
      // Add both from and to collection
      // eslint-disable-next-line no-param-reassign
      selectedDataExtensions = [...selectedDataExtensions, fromCollection, toCollection];
      // Push fromCollection if toCollection exists in selectedDataExtensions and fromCollection does not.
    } else if (selectedDataExtensions.filter(ex => ex.deAlias === fromCollection.deAlias)?.length === 0) {
      // Add only from collection
      // eslint-disable-next-line no-param-reassign
      selectedDataExtensions = [...selectedDataExtensions, fromCollection];
      // Push toCollection if fromCollection exists in selectedDataExtensions and toCollection does not.
    } else if (selectedDataExtensions.filter(ex => ex.deAlias === toCollection.deAlias)?.length === 0) {
      // Add only to collection
      // eslint-disable-next-line no-param-reassign
      selectedDataExtensions = [...selectedDataExtensions, toCollection];
    }

    return selectedDataExtensions;
  };

  /**
   * Show a swal to the user indicating the Data Extension cannot be found, and bring user back to the overview screen
   * @param {string} dataExtensionName - Name of DE
   * @returns {void}
   */
  const handleDataExtensionMissing = async (dataExtensionName) => {
    // Only throw alert when the component is mounted
    axiosCancelToken.cancel(`Missing data extension: ${dataExtensionName}.`);

    // Throw error indicating the selection cannot be opened because of the missing data extension
    await throwSwal(
      'error',
      'Oops...',
      // eslint-disable-next-line max-len
      `Selected Data Extension <b class="bold_swal">${dataExtensionName}</b> cannot be found. Therefore, this data set cannot be opened.`,
    ).then((result) => {
      if (result.isConfirmed) {
        // Navigate back to overview
        openPanel(Constants.ADMIN_PANEL__MENU__DATA_SETS);
      }
    });
  };

  /**
   * Convert the stored value of a data set into the required states
   * @param {object[]} relations - DE relations
   * @param {object[]} collections - selected DEs
   * @param {boolean} loading - parameter indicating that loading is finished
   * @returns {Promise<array>} fetchedDataSet - formatted data set
   */
  const loadStatesForDataSet = async (relations, collections, loading) => {
    const dataExtensionsCopy = JSON.parse(JSON.stringify(dataExtensions));

    const fetchedDataSet = await Util.formatRelationsAndSelectedDEs(
      relations,
      dataExtensionsCopy,
      collections,
      true,
      setDataExtensionProperties,
      handleDataExtensionMissing,
      null,
      null,
      null,
      null,
      addToSelectedDataExtensions,
      null,
      null,
      axios,
      null,
      null,
    );

    if(fetchedDataSet) {
      // get fields for Selected DEs and update relations and additional joins
      for (let j = 0; j < fetchedDataSet.selectedDataExtensions.length; j += 1) {
        // eslint-disable-next-line
        const fields = await getDataExtensionOrDataViewFields(fetchedDataSet.selectedDataExtensions[j]);

        if (fields) {
          fetchedDataSet.relations?.forEach((relation) => {
            // Assign fields and update additional joins for the fromDE part of relation
            if (relation.fromCollection.ObjectID === fetchedDataSet.selectedDataExtensions[j].ObjectID) {
              relation.fromCollection.fields = fields;

              fields.forEach((field) => {
                relation.additionalJoins.forEach((join) => {
                  if (field.ObjectID === join.fromFieldObjectID) {
                    join.fromFieldObjectID = field.ObjectID;
                  } else if (field.ObjectID === join.toFieldObjectID) {
                    join.toFieldObjectID = join.fromFieldObjectID;
                    join.fromFieldObjectID = field.ObjectID;
                  }

                  join.rowID = Util.uuid();
                });
              });
            }

            // Assign fields and update additional joins for the toDE part of relation
            if ((relation.toCollection.ObjectID === fetchedDataSet.selectedDataExtensions[j].ObjectID) ||
            (relation.toCollection.CustomerKey === fetchedDataSet.selectedDataExtensions[j].CustomerKey)) {
              relation.toCollection.fields = fields;

              fields.forEach((field) => {
                relation.additionalJoins.forEach((join) => {
                  if ((field.ObjectID === join.fromFieldObjectID) ||
                    (field.ObjectID === join.toFieldObjectID)) {
                    join.toFieldObjectID = field.ObjectID;
                  }

                  join.rowID = Util.uuid();
                });
              });
            }
          });
        }
      }

      fetchedDataSet.loading = !loading;

      return fetchedDataSet;
    }
  };

  /**
   * Fetch the DEs and predef relations
   * @returns {void}
   */
  const fetchDataExtensionAndRelations = async () => {
    let dataExtensions = [];

    let relations;

    try {
      // get data extensions and predefined relations
      const DEsAndPredefinedRelations = await Promise.all([
        DataExtensionsAPI.getDataExtensions(
          axiosCancelToken.token,
          Constants.DATAEXTENSION__FILTER_MODE__AVAILABLE,
        ),
        getRelations()]);

      // Get data extensions
      dataExtensions = [...(DEsAndPredefinedRelations?.[0] || [])];

      // Get predefined relations
      relations = [...(DEsAndPredefinedRelations?.[1] || [])];

      // add data views in data extensions array
      addDataViewsInDEs(dataExtensions);

      const predefinedRelationsMap = {};

      let predefinedDEs = [];

      relations?.forEach((relation) => {
        /*
         * for each relation create an object with the key: relation ID and
         * value consisting of the array fromFieldObjectId and toFieldObjectId
         */
        predefinedRelationsMap[relation._id] = [relation?.fromFieldObjectId?.toString(),
          relation?.toFieldObjectId?.toString()];

        // get only DEs that are part of predef relations
        const predefinedFromDE = dataExtensions.find(de => (de.ObjectID === relation.fromDEObjectId) ||
        (de.CustomerKey === relation.fromDECustomerKey));
        const predefinedToDE = dataExtensions.find(de => (de.ObjectID === relation.toDEObjectId) ||
        (de.CustomerKey === relation.toDECustomerKey));

        if (predefinedFromDE) {
          predefinedDEs.push(predefinedFromDE);
        }

        if (predefinedToDE) {
          predefinedDEs.push(predefinedToDE);
        }
      });

      predefinedDEs = Util.removeDuplicatesFromArray(predefinedDEs);
      predefinedDEs = Util.sortArrayOfObjects(predefinedDEs, 'Name');

      dispatch(setDataExtensions(predefinedDEs));
      dispatch(setPredefinedRelations(relations));
      dispatch(setPredefinedRelationsMap(predefinedRelationsMap));

      setLoading(!!dataSetId);
    } catch (error) {
      if (!axios.isCancel(error)) throwSwal('error', 'Error', error);
    }
  };

  /**
   * Formats the states for the data set
   * @returns {void}
   */
  const setDataSetStates = async () => {
    if (dataSetId) {
      // In case that predef relations have been change update relations for the data set
      selectedDataSet?.relations?.forEach((dataSetRelation) => {
        predefinedRelations.forEach((relation) => {
          // if relation has the same fromDE and toDE
          if (dataSetRelation.fromCollectionCustomerKey === relation.fromDECustomerKey &&
            dataSetRelation.toCollectionCustomerKey === relation.toDECustomerKey) {
            dataSetRelation.additionalJoins = relation.additionalJoins;
            dataSetRelation.fromField = relation.fromFieldName;
            dataSetRelation.fromFieldObjectID = relation.fromFieldObjectId;
            dataSetRelation.fromFieldType = relation.fromFieldType;
            dataSetRelation.toField = relation.toFieldName;
            dataSetRelation.toFieldObjectID = relation.toFieldObjectId;
            dataSetRelation.toFieldType = relation.toFieldType;
            // if relation has fromDE and toDE in the reverse order
          } else if (dataSetRelation.fromCollectionCustomerKey === relation.toDECustomerKey &&
            dataSetRelation.toCollectionCustomerKey === relation.fromDECustomerKey) {
            dataSetRelation.additionalJoins = relation.additionalJoins;
            dataSetRelation.fromField = relation.toFieldName;
            dataSetRelation.fromFieldObjectID = relation.toFieldObjectId;
            dataSetRelation.fromFieldType = relation.toFieldType;
            dataSetRelation.toField = relation.fromFieldName;
            dataSetRelation.toFieldObjectID = relation.fromFieldObjectId;
            dataSetRelation.toFieldType = relation.fromFieldType;
          }
        });
      });

      // get relations between selected DEs and format selectedDEs
      const stateForDataSet = await loadStatesForDataSet(
        selectedDataSet.relations,
        selectedDataSet.collections,
        loading,
      );

      if (stateForDataSet) {
        dispatch(setRelations(stateForDataSet.relations));
        dispatch(setDataSetName(selectedDataSet.name));
        setSelectedDataExtensions(stateForDataSet.selectedDataExtensions);
        dispatch(setSelectedDEsTree(stateForDataSet.selectedDEsTree));

        setLoading(stateForDataSet.loading);
      } else {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchDataExtensionAndRelations();

    // when component is unmounted
    return () => {
      // cancel axios token
      axiosCancelToken.cancel();

      // clear the data set state
      dispatch(clearDataSetState());
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (dataExtensions.length && predefinedRelations.length) {
      setDataSetStates();
    }
    // eslint-disable-next-line
  }, [dataExtensions, predefinedRelations]);

  /**
   * Sets the parent selected DE in the dropdown
   * @param {object} e - event
   * @returns {void}
   */
  const handleSetParentDE = (e) => {
    // get specific data extension determined by ObjectID
    const dataExtension = dataExtensions.find(d => String(d.CustomerKey) === e.value);

    setParentDE(dataExtension);
  };

  /**
   * Add the selected parent DE to the selected DEs
   * @returns {void}
   */
  const addParentDE = () => {
    if (parentDE.CustomerKey) {
      // this line calls the handleDropToSelectedDE function from SelectedExtensions.js
      selectedDERef.current(parentDE.CustomerKey, parentDE.Name);
    }
  };

  /**
   * Delete a selected DE
   * @param {string} dataExtensionIdToRemove - Id of DE to be removed
   * @returns {void}
   */
  const handleDeleteSelectedDE = async (dataExtensionIdToRemove) => {
    // Confirm modal to remove data
    const confirmRemove = () => Swal.fire({
      title: 'Remove Data Extension',
      html: '<p class="width_swal">Are you sure you want to remove this data extension?</p>',
      showCancelButton: true,
      confirmButtonColor: 'green',
      confirmButtonText: 'Remove',
      cancelButtonColor: 'red',
      cancelButtonText: 'Cancel',
      type: 'question',
      footer: '<div></div>',
      buttonsStyling: false,
    });

    // Variable to keep track of how many data extensions were removed
    const removedDataExtension = [];

    /**
     * Removes data extension for selected data extension if that is possible
     * @param {String} DEIdToRemove - id of data extension we want to remove from selected data extensions
     * @param {Object} dataExtension - data extension we want to remove
     * @param {Array} subCollectionParent - subCollection of the parent de
     * @param {Number} i - index of the DE we want to remove
     * @param {Boolean} isSubCollection - check if a DE we want to remove is a subCollection or not
     * @returns {object} The removed data extension
     */
    const handleRemovalOfOneDataExtension = async (
      DEIdToRemove,
      dataExtension,
      subCollectionParent,
      i,
      isSubCollection,
    ) => {
      if (isSubCollection) {
        // This line is the problem, should not be on subCollections but the parent of dataExtension
        removedDataExtension.push(subCollectionParent.splice(i, 1));

        // Remove dataExtension with the id we want to remove
        const updatedSelectedDataExtensions = selectedDataExtensions.filter(
          selectedDataExtension => selectedDataExtension.id !== DEIdToRemove,
        );

        // Remove relation with dataExtension we want to remove
        const updatedRelations = relations.filter(
          relation => relation.relationalModalId !== dataExtension.relationalModalId,
        );

        dispatch(setRelations(updatedRelations));
        setSelectedDataExtensions(updatedSelectedDataExtensions);

        return dataExtension;
      }

      // It pushes some value just to indicate that one DE has been removed
      removedDataExtension.push(1);
      setSelectedDataExtensions([]);

      return null;
    };

    /**
     * Loop through all selected DE and remove one if id matches
     * @param {object} dataExtension - all available data extensions
     * @returns {void}
     */
    const traverseDEs = async (dataExtension) => {
      // Remove from subCollection if they exists
      if (dataExtension && dataExtension.subCollections.length > 0) {
        for (let i = 0; i < dataExtension.subCollections.length; i += 1) {
          // DataExtension.subCollections.forEach(async (subCollection, i) => {

          // Recursively call fn once again if there are still subCollections
          if (dataExtension.subCollections[i]?.subCollections?.length > 0) {
            // eslint-disable-next-line no-await-in-loop
            await traverseDEs(dataExtension.subCollections[i]);
          } else {
            // If ids are matched splice dataExtension and update relations
            if (dataExtension.subCollections[i].id === dataExtensionIdToRemove) {
              // eslint-disable-next-line no-await-in-loop
              await handleRemovalOfOneDataExtension(
                dataExtensionIdToRemove,
                dataExtension.subCollections[i],
                dataExtension.subCollections,
                i,
                true,
              );
            }
          }
        }
      } else {
        // Remove from current level
        if (dataExtension && dataExtension.id === dataExtensionIdToRemove) {
          await handleRemovalOfOneDataExtension(dataExtensionIdToRemove, dataExtension, null, null, false);
        }
      }
    };

    let res;

    if (dataExtensionIdToRemove !== 'undefined') {
      res = await confirmRemove();

      if (res.value) {
        await traverseDEs(selectedDataExtensions[0]);
      }
    }
  };

  /**
   * Shows only DEs that are related via predef relations with the currentDE
   * @param {object} currentDE - data extension to which we are adding a new one
   * @returns {void}
   */
  const showRelatedDEs = (currentDE) => {
    let relatedDEs = [];

    // get only DEs that are related via the predef relations with the current DE
    predefinedRelations.forEach((relation) => {
      if ((relation.fromDEObjectId === currentDE.ObjectID) || (relation.fromDECustomerKey === currentDE.CustomerKey)) {
        const de = dataExtensions.find(de => (de.ObjectID === relation.toDEObjectId) ||
         (de.CustomerKey === relation.toDECustomerKey));

        if (de) relatedDEs.push(de);
      }

      if ((relation.toDEObjectId === currentDE.ObjectID) || (relation.toDECustomerKey === currentDE.CustomerKey)) {
        const de = dataExtensions.find(de => (de.ObjectID === relation.fromDEObjectId) ||
        (de.CustomerKey === relation.fromDECustomerKey));

        if (de) relatedDEs.push(de);
      }
    });

    relatedDEs = Util.removeDuplicatesFromArray(relatedDEs);
    relatedDEs = Util.sortArrayOfObjects(relatedDEs, 'Name');

    setShowRelatedDEsModal(true);
    setRelatedDEs(relatedDEs);
    setAssociateDE(currentDE);
  };

  /**
   * Show only DEs that are related via predef relations with the currentDE
   * @param {object} selectedDE - data extension to add
   * @returns {void}
   */
  const addRelatedDE = (selectedDE) => {
    selectedDERef.current(
      selectedDE.CustomerKey,
      selectedDE.Name,
      selectedDE.id,
      associateDE.id,
      false,
      associateDE.deAlias,
      selectedDE.deAlias,
    );

    setShowRelatedDEsModal(false);
  };

  /**
   * Cancels a data set and redirects a user back to the overview page of the data sets
   * @returns {void}
   */
  const handleCancel = async () => {
    await openPanel(Constants.ADMIN_PANEL__MENU__DATA_SETS);
  };

  /**
   * Updates relations state to DESelect data format.
   * @param {object} relation - relation between DE
   * @returns {object} A relation
   */
  const formatRelations = relation => ({
    type: relation.joinType,
    toField: relation.toCollection.toField,
    toFieldType: relation.toCollection.toFieldType,
    toFieldObjectID: relation.toCollection.toFieldObjectID,
    toCollectionAlias: relation.toCollection.deAlias,
    toCollection: relation.toCollection.Name.toString(),
    toCollectionCustomerKey: relation.toCollection.CustomerKey.toString(),
    toCollectionObjectID: relation.toCollection.ObjectID,
    fromField: relation.fromCollection.fromField,
    fromFieldType: relation.fromCollection.fromFieldType,
    fromFieldObjectID: relation.fromCollection.fromFieldObjectID,
    fromCollectionAlias: relation.fromCollection.deAlias,
    fromCollection: relation.fromCollection.Name.toString(),
    fromCollectionCustomerKey: relation.fromCollection.CustomerKey.toString(),
    fromCollectionObjectID: relation.fromCollection.ObjectID,
    additionalJoins: relation.additionalJoins,
  });

  /**
   * Updates selectedDataExtensions to DESelect data format.
   * @param {object} collection - collection
   * @returns {object} An object with the properties `collection`, `alias`, `collectionObjectID` and
   * `collectionCustomerKey`
   */
  const formatDataExtensions = collection => ({
    collection: collection.Name.toString(),
    alias: collection.deAlias,
    collectionObjectID: collection.ObjectID,
    collectionCustomerKey: collection.CustomerKey,
  });

  /**
   * Saves all the properties of a data set in the db
   * @returns {void}
   */
  const handleSaveDataSet = async () => {
    if (selectedDataExtensions.length < 2) {
      // show swal error if request failed with error
      return throwSwal('error', 'Error', 'There should be at least two Selected Data Sources.');
    }

    if (!dataSetName) {
      // show swal error if name is not given
      return throwSwal('error', 'Error', 'Please enter a name for the data set.');
    }

    setSavingDataSet(true);

    const postData = {};

    // Updates relations data format to old DESelect data format.
    const relationsPostData = relations.map(relation => formatRelations(relation));

    // Updates collections data format to old DESelect data format.
    const collections = selectedDataExtensions.map(collection => formatDataExtensions(collection));

    postData.collections = collections;
    postData.relations = relationsPostData;
    postData.name = dataSetName;

    try {
      // if there is a data set id
      if (dataSetId) {
        // then update that data set
        await DataSetsAPI.updateDataSet(dataSetId, postData, axiosCancelToken.token);
      } else {
        // otherwise create a new data set
        await DataSetsAPI.createDataSet(postData, axiosCancelToken.token);
      }

      await openPanel(Constants.ADMIN_PANEL__MENU__DATA_SETS);

      setSavingDataSet(false);
    } catch (error) {
      if (!axios.isCancel(error)) throwSwal('error', 'Error', error);
      setSavingDataSet(false);
    }
  };

  /**
   * Get the data set button label
   * @returns {string} The data set button label
   */
  const renderDataSetLabel = () => {
    if (dataSetId && activePanel === Constants.ADMIN_PANEL__MENU_EDIT_DATA_SET) return 'Edit Data Set';
    if (activePanel === Constants.ADMIN_PANEL__MENU__NEW_DATA_SET) return 'New Data Set';

    return 'Data Sets';
  };

  // render loading text based on panel type
  if (loading) {
    return (
      <LoadingModal
        closeModal={() => openPanel(Constants.ADMIN_PANEL__MENU__DATA_SETS)}
        loadingText={dataSetId ? 'Data Set is loading...' : 'Loading...'}
        openPanel={openPanel}
        id="loadingmodal-wrapper"
      />
    );
  }

  /*
   * Format the data extensions for from DE dropdown
   * Dataviews are filtered out because it doesn't make sense for them to be in this dropdown
   */
  const dataExtensionsOptions = dataExtensions
    .map(de => ({
      value: de ? de.CustomerKey.toString() : '',
      title: de ? de.Name.toString() : '',
      text: de ? de.Name.toString() : '',
      key: de ? de.ObjectID : '',
    }));

  return (
    <div className="relation-container data-set-container">
      <div className="slds-page-header slds-m-bottom_medium">
        <div className="slds-page-header__row">
          <div className="slds-page-header__col-title">
            <div className="slds-media header-alignment">
              <div className="slds-media__figure">
                <span className="slds-icon_container slds-icon-standard-account" title="Data Sets">
                  <svg className="slds-icon slds-page-header__icon" aria-hidden="true">
                    <use
                      xlinkHref="/assets/icons/standard-sprite/svg/symbols.svg#picklist_type"
                    />
                  </svg>
                </span>
              </div>
              <div className="slds-media__body">
                <div className="slds-page-header__name">
                  <div className="slds-page-header__name-title">
                    <h1>
                      <span
                        className="slds-page-header__title slds-truncate"
                        title="Data Sets"
                        id="picklist-header-title"
                      >
                        {renderDataSetLabel()}
                      </span>
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="relation-panel-container table-common-look" id="data-set-panel">
        <div className="relation_wrapper">
          <div className="aggregation-content">
            <div className="relation_header">
              <div className="relation_header-title">Define a Data Set</div>
            </div>
            <div className="relation_info_text">
              <p className="info_text">
                As an admin, you can create data sets by defining relations between
                a data extension and other data extensions or data views. This can
                simplify the life of marketers in DESelect a lot, as they won&apos;t
                have to always think about which data extensions to pull in what order, and
                can easily allow users to add these &apos;data sets&apos; with a single click to
                their Selected Data Sources.
              </p>
            </div>

            <div className="data-set-name-input">
              <span className="data-set-name-label">Name</span>
              <input
                id="dataSet-name"
                name="value"
                type="text"
                min="1"
                max="2550"
                className="slds-input custom-values-radio field-name"
                aria-label="Data Set Name"
                value={dataSetName || ''}
                onChange={e => dispatch(setDataSetName(e.target.value))}
              />
            </div>

            <div className="field-container margin-top">
                <div className="field-content">
                  <div className="data-extensions-container">
                    <div className="flex">
                      <span className="label display-flex">
                        Data Source
                        <Tooltip
                          nubbinPosition={Constants.NUBBIN_POSITION__TOP_RIGHT}
                          type={Constants.TOOLTIP_TYPE__PREDEFINED_DATA_EXTENSIONS}
                        />
                      </span>
                    </div>
                    <div className="dropdown-field data-extension display-flex">
                      <Dropdown
                        id="parent-data-extensions-dropdown"
                        selection
                        className="target-data-extension-dropdown searchable-dropdown"
                        search
                        placeholder="Choose data extension"
                        loading
                        options={dataExtensionsOptions}
                        onChange={(e, data) => handleSetParentDE(data)}
                        value={String(parentDE?.CustomerKey || '')}
                        disabled={selectedDataExtensions.length > 0}
                      />
                      <button
                        id="add-parentDE-btn"
                        type="button"
                        className="slds-button slds-button_neutral ml-4px"
                        onClick={() => addParentDE()}
                        disabled={selectedDataExtensions.length > 0 || !parentDE.CustomerKey}
                      >
                        Add
                      </button>
                    </div>
                  </div>
                  <div className="selected-data-extensions">
                      <SelectedExtensions
                        selectedDataExtensions={selectedDataExtensions}
                        handleDeleteSelectedDE={handleDeleteSelectedDE}
                        matchedFields={[]}
                        dataExtensions={dataExtensions}
                        modalDataExtensions={modalDataExtensions}
                        relations={relations}
                        DEBorderMouseOver={DEBorderMouseOver}
                        setDEBorderMouseOver={setDEBorderMouseOver}
                        filterBorderMouseOver={filterBorderMouseOver}
                        setFilterBorderMouseOver={setFilterBorderMouseOver}
                        loaderSelectedDE={loaderSelectedDE}
                        setLoaderSelectedDE={setLoaderSelectedDE}
                        predefinedRelations={predefinedRelations}
                        predefinedRelationsMap={predefinedRelationsMap}
                        selectedDEsTree={selectedDEsTree}
                        selectedDERef={selectedDERef}
                        getDataExtensionOrDataViewFields={getDataExtensionOrDataViewFields}
                        setSelectedDataExtensions={setSelectedDataExtensions}
                        setModalDataExtensions={modalDEs => dispatch(setModalDataExtensions(modalDEs))}
                        setShowRelationalModal={setShowRelationalModal}
                        showRelatedDEs={showRelatedDEs}
                      />
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>

      <div className="slds-grid cancel-save-relation">
          <div className="slds-col_bump-left">
            <button
              id="cancel-data-set"
              type="button"
              className="slds-button slds-button_neutral"
              label="Cancel"
              onClick={() => handleCancel()}
            >
              Cancel
            </button>
            <button
              id="save-data-set"
              type="button"
              className="slds-button slds-button_brand"
              onClick={() => handleSaveDataSet()}
              disabled={savingDataSet}
            >
              {savingDataSet ?
                (
                  <div className="preview-loader-container">
                    <div
                      role="status"
                      className="slds-spinner slds-spinner_x-small"
                    >
                      <div className="slds-spinner__dot-a" />
                      <div className="slds-spinner__dot-b" />
                    </div>
                    <span className="when-pressed">
                      Saving...
                    </span>
                  </div>
                ) :
                (
                  <span>Save</span>
                )}
            </button>
          </div>
      </div>

      {showRelatedDEsModal && (
        <>
          <FolderDataExtensions
            dataExtensions={relatedDEs}
            isDataSetDEModal
            setShowRelatedDEsModal={setShowRelatedDEsModal}
            addRelatedDE={addRelatedDE}
          />
          <div className="slds-backdrop slds-backdrop_open" />
        </>
      )}
        {showRelationalModal && (
          <RelationModal
            showRelationalModal={showRelationalModal}
            setShowRelationalModal={setShowRelationalModal}
            modalDataExtensions={modalDataExtensions}
            setModalDataExtensions={modalDEs => dispatch(setModalDataExtensions(modalDEs))}
            relations={relations}
            setRelations={relations => dispatch(setRelations(relations))}
            selectedDataExtensions={selectedDataExtensions}
            setSelectedDataExtensions={setSelectedDataExtensions}
            predefinedRelationsMap={predefinedRelationsMap}
            predefinedRelations={predefinedRelations}
            isDataSetDEModal
          />
        )}
    </div>
  );
};

DataSetsPanel.propTypes = {
  /**
   * Id of a data set
   */
  dataSetId: PropTypes.string,
  /**
   * Function to open a certain panel
   */
  openPanel: PropTypes.func.isRequired,
  /**
   * A Data Set
   */
  selectedDataSet: PropTypes.instanceOf(Object),
  /**
   * Active panel
   */
  activePanel: PropTypes.string.isRequired,
  /**
   * Features info from cookie
   */
  featuresInfo: PropTypes.object,
};

export default connect(mapStateToProps(['featuresInfo']), null, null, { pure: false })(DataSetsPanel);
