import React from 'react';
import PropTypes from 'prop-types';
import swal from 'sweetalert2';
import classNames from 'classnames';
import axios from 'axios';

import Constants from '../../../../constants/constants';
import AvailableDECard from '../AvailableDECard/AvailableDECard';
import LoadingModal from '../../../shared/LoadingModal/LoadingModal';
import './styles.scss';
import Util from '../../../../util';
import SwalUtil from '../../../../utils/swal/swalUtil';
import filtersUtil from '../../../../utils/filters/filtersUtil';
import DataExtensionsAPI from '../../../../api/data-extensions';
import Tip from '../../../shared_v2/Tip/Tip';

const axiosCancelToken = axios.CancelToken.source();

const SelectedExtensions = ({
  selectedDataExtensions,
  handleDeleteSelectedDE,
  matchedFields,
  handleSetSelectionState,
  filtersRef,
  dataExtensions,
  modalDataExtensions,
  relations,
  getDataExtensionOrDataViewFields,
  DEBorderMouseOver,
  filterBorderMouseOver,
  loaderSelectedDE,
  customValues,
  predefinedRelations,
  selectedDEsTree,
  selectedDERef,
  showRelatedDEs,
  dataSets,
  parentDEOfDataSet,
  setLoaderSelectedDE,
  setSelectedDataExtensions,
  setModalDataExtensions,
  setShowRelationalModal,
  setDEBorderMouseOver,
  setFilterBorderMouseOver,
  handlePickListOptions,
  handleSetAppState,
  unionSelections,
  filterSets,
  isFilterBoxExpanded,
  isFilterBoxExpandedForFirstTime,
  showFiltersImmediately,
  handleExpandFiltersBox = () => {},
}) => {
  /**
   * Show pop up, in which you can change the name
   * @param {string} name - selection's name
   * @returns {void}
   * Function cannot be moved to AvailableDECard as it is used inside this component
   */
  const handleAliasPopUp = name => SwalUtil.fire({
    title: `Rename Data ${name[0] === '_' ? 'View' : 'Extension'}`,
    message: `Optionally: Provide an alias for the data ${name[0] === '_' ? 'view' : 'extension'}.`,
    options: {
      showCancelButton: true,
      confirmButtonText: 'Save',
      input: 'text',
      inputValue: name,
      inputAttributes: {
        maxlength: 100,
      },
      inputValidator: (newName) => {
        if (!newName) return 'Please fill out this field.';
        if (/["';]/g.test(newName)) return 'You can\'t use special characters \' " ; inside the alias.';

        return false;
      },
    },
  });

  /**
   * Finds predefined relations associated with the 2 given data extensions
   * @param {string} sourceObjectId - ObjectId of the first DE
   * @param {string} targetObjectId - ObjectId of the second DE
   * @returns {object} - null or the redefined relation
   */
  const findPredefinedRelation = (sourceObjectId, targetObjectId) => {
    /**
     * Evaluates whether predefined relation exists for selected DEs  (A -> B)
     * @param {object} relation - The relation to check
     * @returns {boolean} - true if ids match, false otherwise
     */
    const forwardRelationCondition = relation => sourceObjectId === relation.fromDEObjectId &&
      targetObjectId === relation.toDEObjectId;

    /**
     * Evaluates whether predefined relation exists for selected DEs  (B -> A)
     * @param {object} relation - The relation to check
     * @returns {boolean} - true if ids match, false otherwise
     */
    const backwardRelationCondition = relation => targetObjectId === relation.fromDEObjectId &&
      sourceObjectId === relation.toDEObjectId;

    // If predefined relations exist
    if (predefinedRelations && predefinedRelations.length) {
      // Loop over predefinedRelations and find a relation
      const foundRelation = predefinedRelations.find(
        predefinedRelation => forwardRelationCondition(predefinedRelation) ||
          backwardRelationCondition(predefinedRelation),
      );

      // If predefined relation exists
      if (foundRelation) {
        // Check and return a predefined relation if it exists for the selected DEs (A -> B)
        if (forwardRelationCondition(foundRelation)) {
          // Add rowID property to additionalJoins
          const modifiedAdditionalJoins = foundRelation.additionalJoins?.reduce((arr, item) => {
            item.rowID = item._id;
            arr.push(item);

            return arr;
          }, []);

          return { ...foundRelation, additionalJoins: modifiedAdditionalJoins };
        }

        // Swap additionalJoins' fields since relation is reversed.
        const reversedAdditionalJoins = foundRelation?.additionalJoins?.map(item => ({
          ...item,
          fromFieldObjectID: item.toFieldObjectID,
          toFieldObjectID: item.fromFieldObjectID,
          rowID: item._id,
        }));

        // Predefined relation exists, but is reversed (B -> A), so swap toFields and fromFields
        const swappedRelationObject = {
          ...Util.replaceOrdersInPredefinedRelation(foundRelation),
          additionalJoins: reversedAdditionalJoins,
        };

        // Return the swapped relation object
        return swappedRelationObject;
      }
    }

    // No predefined relations
    return null;
  };

  /**
   * Fetches picklist options for each data extension in the filter sets.
   * @param {object[]} filterSets - Filter sets
   * @param {object[]} selectedDataExtensions - Selected data extensions
   * @returns {void}
   */
  const fetchFilterSetsPicklistOptions = (filterSets, selectedDataExtensions) => {
    const filterSetsFromSelectedDataExtensions = filtersUtil.getFilterSetsToDisplay(filterSets, selectedDataExtensions);

    const filterSetDEsForPicklist = (filterSetsFromSelectedDataExtensions
      ?.map(filterSet => filtersUtil.getFilterSetSubqueryCollections(filterSet))
      ?.reduce((acc, filterSetDEs) => acc.concat(filterSetDEs), [])) || [];

    // Get picklists for each data extension
    filterSetDEsForPicklist
      ?.forEach(dataExtension => handlePickListOptions(dataExtension.fields, true, dataExtension));
  };
  /**
   * Check if Data Extension isSendable then update fields
   * @param {object} selectedCollection - Selected data extensions
   * @returns {void}
   */
  const addSendableFieldsToSendableDE = async (selectedCollection) => {
    // update selected Data Extension with more fields
    const selectedDataExtensionData =
      await DataExtensionsAPI.getDataExtension(selectedCollection[0].CustomerKey, axiosCancelToken.token);

    if (selectedDataExtensionData?.IsSendable) {
      // Create a new object with the updated properties
      const updatedCollectionItem = {
        ...selectedCollection[0],
        IsSendable: selectedDataExtensionData.IsSendable,
        SendableDataExtensionField: selectedDataExtensionData.SendableDataExtensionField,
        SendableSubscriberField: selectedDataExtensionData.SendableSubscriberField,
      };

      // Create a new array with the updated object and the rest of the elements from the original 'selectedCollection'
      const updatedCollection = [updatedCollectionItem, ...selectedCollection.slice(1)];

      // Return the new array instead of modifying the original 'selectedCollection'
      return updatedCollection;
    }

    // If 'selectedDataExtensionData.IsSendable' is falsy, just return the original 'selectedCollection'
    return selectedCollection;
  };

  /**
   * This func. executes when available data extension drag-dropped into selected data extension section
   * It pushes dragged data extension into "selectedDataExtensions" and sets its state
   * @param {number} customerKey - customer key
   * @param {string} name - DE's name
   * @param {string} id - DE's id
   * @param {number|null} parentId - id of parent element
   * @param {boolean} movingDE - Indicates whether an availableDE is being moved
   * @param {string} parentAlias - Alias of the DE from which the DE is being moved
   * @param {string} deAlias - deAlias of the moved DE
   * @param {array} dataSetSelectedDEs - selected DEs when dropping data set
   * @param {string} dataSetId - id of the current data set that is being dropped
   * @param {object} relation - relation for the de that is being dropped
   * @param {array} dataSetRelations - data set relations
   * @param {bool} showAliasPopup - if modal to changes alias should be shown
   * @param {array} dataExtensionFields - fields of the DE that is being dropped
   * @param {string} dataSetSelectedDEAlias - alias of the data set selected DE
   * @returns {void}
   */
  const handleDropToSelectedDE = async (
    customerKey,
    name,
    id,
    parentId = null,
    movingDE,
    parentAlias,
    deAlias,
    dataSetSelectedDEs,
    dataSetId,
    relation,
    dataSetRelations,
    showAliasPopup,
    dataExtensionFields,
  ) => {
    // user tries to drag field, if there is no name.
    if (!name) {
      SwalUtil.fire({
        type: Constants.SWAL__TYPE__ERROR,
        message: 'You can only drop data extensions here.',
      });

      return;
    }

    // Make a copy of selectedDEs and relations to reset it in case relationModal doesn't get saved
    const prevSelectedDEs = JSON.parse(JSON.stringify(selectedDataExtensions));
    const prevRelations = JSON.parse(JSON.stringify(relations));

    let deName = { value: deAlias || name };

    // Don't bring up popup when moving a DE or if we are dropping a data set
    if ((!movingDE && !dataSetId) || (dataSetId && showAliasPopup)) {
      deName = await handleAliasPopUp(name);

      // Return if the cancel button is pressed.
      if (!deName.value) return;
    }

    const dataExtensionsCopy = JSON.parse(JSON.stringify(dataExtensions)); // Clone the Object of array

    // Adds few properties to the copy of the data extension.
    const filterCollections =
      (DECopy, editStatus) => DECopy.filter((DE) => {
        if (DE.CustomerKey.toString() === customerKey.toString()) {
          /* eslint-disable no-param-reassign */
          DE.dragged = true;
          DE.edit = editStatus;
          // eslint-disable-next-line no-prototype-builtins
          DE.deAlias = deName.hasOwnProperty('value') ? deName.value.toString() : DE.Name;
          DE.id = DE.Name.toString() + new Date().getTime();
          DE.subCollections = [];
          DE.Name = DE.Name.toString();
          DE.CustomerKey = DE.CustomerKey.toString();

          if (dataSetId) {
            DE.dataSetId = dataSetId;
          }

          // Add ObjectID if DE doesn't have one
          if (!DE.ObjectID) DE.ObjectID = DE.CustomerKey.toString();

          return true;
          /* eslint-enable no-param-reassign */
        }

        return false;
      });

    // Initialize selectedCollections array if it is not parent node.
    if (deName.value.toString() && parentId === null) {
      if (handleSetSelectionState) {
        // Show loading modal
        handleSetSelectionState({ loaderSelectedDE: true });
      } else {
        setLoaderSelectedDE(true);
      }

      // Add few properties to the copy of the newly selected data extension
      let selectedCollection = filterCollections(dataExtensionsCopy, false);

      try {
        /*
         * get fields only if the getDataExtensionOrDataViewFields is passed to this component
         * and fields don't exist
         */
        if (getDataExtensionOrDataViewFields && !dataExtensionFields) {
          // Get data extension or data view fields
          selectedCollection[0].fields = await getDataExtensionOrDataViewFields(selectedCollection[0]);
        }

        if (dataExtensionFields) {
          selectedCollection[0].fields = dataExtensionFields;
        }

        if (handleSetSelectionState) {
          // update selected Data Extension with more fields
          selectedCollection = await addSendableFieldsToSendableDE(selectedCollection);

          // Update state in Selection
          handleSetSelectionState({ selectedDataExtensions: selectedCollection, loaderSelectedDE: false });
        } else {
          // Update state in the Data Set panel
          setSelectedDataExtensions(selectedCollection);
          setLoaderSelectedDE(false);
        }
        // Fetch picklist values for filtersets from dragged data extension
        fetchFilterSetsPicklistOptions(filterSets, selectedCollection);

        if (dataSetSelectedDEs) {
          return selectedCollection;
        }
      } catch (error) {
        if (handleSetSelectionState) {
          handleSetSelectionState({ error });
        }
      }
      // Initialize and push the dropped collection into subCollection array
    } else if (deName.value.toString() && parentId !== null) {
      if (handleSetSelectionState) {
        // Show loading modal
        handleSetSelectionState({ loaderSelectedDE: true });
      } else {
        setLoaderSelectedDE(true);
      }

      const DECopy = JSON.parse(JSON.stringify(dataExtensions)); // Clone the Object of array
      const selectedDeCopy = dataSetSelectedDEs || selectedDataExtensions;

      // Show Error - if the name is the same. Call the function again
      if (!movingDE && selectedDeCopy.filter(de => de.deAlias === deName.value.toString()).length > 0) {
        if (handleSetSelectionState) {
          // Show loading modal
          handleSetSelectionState({ loaderSelectedDE: false });
        } else {
          setLoaderSelectedDE(false);
        }

        // Show error if the name is the same
        await SwalUtil.fire({
          type: Constants.SWAL__TYPE__ERROR,
          message: Constants.ERROR__ALIAS_NOT_UNIQUE,
        });

        // Evoke the function again
        const selectedDEs = await handleDropToSelectedDE(
          customerKey,
          name,
          id,
          parentId,
          movingDE,
          parentAlias,
          deAlias,
          dataSetSelectedDEs,
          dataSetId,
          relation,
          dataSetRelations,
          true,
          dataExtensionFields,
        );

        if (dataSetSelectedDEs) {
          return selectedDEs;
        }

        return;
      }

      // Add few properties to the copy of the newly selected data extension
      let selectedCollection = filterCollections(DECopy, true);

      // update selected Data Extension with more fields
      selectedCollection = await addSendableFieldsToSendableDE(selectedCollection);

      // Don't get fields when moving a DE and fields don't exist
      if (!movingDE && !dataExtensionFields) {
        try {
          // Pass current DE and get extension of data view fields
          // eslint-disable-next-line require-atomic-updates

          selectedCollection[0].fields = await getDataExtensionOrDataViewFields(selectedCollection[0]);
        } catch (error) {
          if (handleSetSelectionState) {
            handleSetSelectionState({ error });
          }
        }
      }

      if (dataExtensionFields) {
        selectedCollection[0].fields = dataExtensionFields;
      }

      let parentNode = [];

      const nodeFinder =
        DEs => DEs.subCollections
          .map(de => de.id === parentId ? parentNode.push(de) : nodeFinder(de));

      // If yes - add DE to parentNode
      if (selectedDeCopy[0].id === parentId) {
        parentNode.push(selectedDeCopy[0]);
        /*
         * If not - loop over subCollections of the DE and if any element's id of
         * the subCollection is equal to parentId then add it to parentNode
         * If no match then loop over the subCollection of subCollection etc
         */
      } else if (selectedDeCopy[0].id !== parentId) {
        selectedDeCopy[0].subCollections.map(de => de.id === parentId ? parentNode.push(de) : nodeFinder(de));
      }

      const relationalModalId = new Date().getTime();

      // eslint-disable-next-line require-atomic-updates
      selectedCollection[0].relationalModalId = relationalModalId;
      parentNode.push(selectedCollection[0]);

      // Look for a predefined relation
      const foundPredefinedRelation = findPredefinedRelation(parentNode[0]?.ObjectID, parentNode[1]?.ObjectID);

      // get fields for fromCollection and toCollection
      const fieldsForFromCollection = Util.getSelectedDataExtensionFields(selectedDataExtensions, parentNode[0]);

      const fieldsForToCollection = Util.getSelectedDataExtensionFields(selectedDataExtensions, parentNode[1]);

      // If a predefined relation's been found, set relation's properties
      if (foundPredefinedRelation) {
        // eslint-disable-next-line no-param-reassign
        modalDataExtensions = {
          relationalModalId,
          fromCollection: { ...parentNode[0] },
          toCollection: { ...parentNode[1] },
          joinType: Constants.RELATIONTYPE__INNER,
          additionalJoins: foundPredefinedRelation.additionalJoins,
        };

        // if fields are not found for parentNode - assign it
        if (!modalDataExtensions.fromCollection?.fields?.length || !modalDataExtensions.toCollection?.fields?.length) {
          modalDataExtensions.fromCollection.fields = fieldsForFromCollection;
          modalDataExtensions.toCollection.fields = fieldsForToCollection;
        }

        // eslint-disable-next-line no-param-reassign
        modalDataExtensions.fromCollection.fromField = foundPredefinedRelation.fromFieldName;
        // eslint-disable-next-line no-param-reassign
        modalDataExtensions.fromCollection.fromFieldType = foundPredefinedRelation.fromFieldType;
        // eslint-disable-next-line no-param-reassign
        modalDataExtensions.fromCollection.fromFieldObjectID = foundPredefinedRelation.fromFieldObjectId;

        // eslint-disable-next-line no-param-reassign
        modalDataExtensions.toCollection.toField = foundPredefinedRelation.toFieldName;
        // eslint-disable-next-line no-param-reassign
        modalDataExtensions.toCollection.toFieldType = foundPredefinedRelation.toFieldType;
        // eslint-disable-next-line no-param-reassign
        modalDataExtensions.toCollection.toFieldObjectID = foundPredefinedRelation.toFieldObjectId;
      } else {
        // eslint-disable-next-line no-param-reassign
        modalDataExtensions = {
          relationalModalId,
          fromCollection: parentNode[0],
          toCollection: parentNode[1],
          joinType: Constants.RELATIONTYPE__INNER,
          additionalJoins: [],
        };

        parentNode = [];

        // if fields are not found for parentNode - assign it
        if (
          !modalDataExtensions.fromCollection?.fields?.length ||
          !modalDataExtensions.toCollection?.fields?.length) {
          if (modalDataExtensions.fromCollection) {
            modalDataExtensions.fromCollection.fields = fieldsForFromCollection;
          }

          if (modalDataExtensions.toCollection) {
            modalDataExtensions.toCollection.fields = fieldsForToCollection;
          }
        }

        // Check if there are existing fields for the data extension
        if (modalDataExtensions.fromCollection?.fields?.length &&
          modalDataExtensions.toCollection?.fields?.length) {
          // Set relation properties
          const fromField = modalDataExtensions.fromCollection.fields[0].Name?.toString?.();
          // eslint-disable-next-line no-param-reassign

          modalDataExtensions.fromCollection.fromField = fromField || modalDataExtensions.fromCollection.fields[0];
          // eslint-disable-next-line no-param-reassign
          modalDataExtensions.fromCollection.fromFieldType = modalDataExtensions.fromCollection.fields[0]
            .FieldType;
          // eslint-disable-next-line no-param-reassign
          modalDataExtensions.fromCollection.fromFieldObjectID = modalDataExtensions.fromCollection.fields[0].ObjectID;

          /**
           * Find the first compatible toField and
           * set it as the default value
           */
          const defaultToField = modalDataExtensions.toCollection.fields.find(f => Util.canFieldBeMapped(
            modalDataExtensions.fromCollection.fromFieldType,
            f.FieldType,
          ));

          const toField = modalDataExtensions.toCollection.fields[0].Name?.toString();

          // eslint-disable-next-line no-param-reassign
          modalDataExtensions.toCollection.toField = defaultToField ?
            defaultToField.Name?.toString() :
            toField;
          // eslint-disable-next-line no-param-reassign
          modalDataExtensions.toCollection.toFieldType = defaultToField ?
            defaultToField.FieldType :
            modalDataExtensions.toCollection.fields[0].FieldType;
          // eslint-disable-next-line no-param-reassign
          modalDataExtensions.toCollection.toFieldObjectID = defaultToField ?
            defaultToField?.ObjectID :
            modalDataExtensions.toCollection.fields[0]?.ObjectID;
        }
      }

      let deToMove;

      /**
       * Used to remove DEs from collections when moving them to another subcollection
       * @param {array} dataExtension - The root DE
       * @param {string} idToMatch - id with which to find a DE
       * @param {object|undefined} movedDe - The DE that is moved to a subcollection
       * @returns {void}
       */
      const addOrRemoveDE = (dataExtension, idToMatch, movedDe) => {
        if ((!movedDe && dataExtension.deAlias === idToMatch) || (movedDe && dataExtension.id === idToMatch)) {
          const idx = dataExtension.subCollections.findIndex(d => d.id === id);

          // If we're adding a DE to a subcollection
          if (movedDe) {
            // Add DE to subCollection
            dataExtension.subCollections.push(movedDe);
          } else {
            // Set deToMove
            deToMove = dataExtension.subCollections[idx];

            // Remove from subCollection
            dataExtension.subCollections.splice(idx, 1);
          }
        } else {
          dataExtension.subCollections
            .map((de) => {
              if ((!movedDe && de.deAlias === idToMatch) || (movedDe && de.id === idToMatch)) {
                const idx = de.subCollections.findIndex(d => d.id === id);

                if (movedDe) {
                  // Add to subCollection
                  de.subCollections.push(movedDe);
                } else {
                  // Remove from subCollection
                  deToMove = de.subCollections[idx];
                  de.subCollections.splice(idx, 1);
                }

                return true;
              }

              return addOrRemoveDE(de, idToMatch, movedDe);
            });
        }
      };

      let updatedRelations = [];

      if (movingDE && parentAlias) {
        // Remove from current subcollection

        // Find parent subcollection and remove de
        addOrRemoveDE(selectedDeCopy[0], parentAlias);

        // Find relation and remove it
        const indexOfRelationToRemove = relations.findIndex(rel => rel.toCollection.deAlias === deToMove.deAlias);

        updatedRelations = [
          ...relations.slice(0, indexOfRelationToRemove),
          ...relations.slice(indexOfRelationToRemove + 1),
        ];

        // Reset relationalModalId
        deToMove.relationalModalId = modalDataExtensions.relationalModalId;

        // Reset selectedCollection so as to copy its subcollections
        selectedCollection = [deToMove];

        if (foundPredefinedRelation) {
          if (modalDataExtensions.toCollection?.fields) {
            // Set toCollection's properties
            modalDataExtensions.toCollection.fields = deToMove.fields;
            modalDataExtensions.toCollection.subCollections = deToMove.subCollections;
          }
        } else {
          // Set toCollection's properties
          modalDataExtensions.toCollection = { ...modalDataExtensions.toCollection, ...deToMove };
        }
      }

      // Find relation and remove it
      const indexOfRelationToRemove = relations.findIndex(rel => rel.toCollection.deAlias === deToMove?.deAlias);

      updatedRelations = [
        ...relations.slice(0, indexOfRelationToRemove),
        ...relations.slice(indexOfRelationToRemove + 1),
      ];

      if (!dataSetSelectedDEs) {
        if (handleSetSelectionState) {
          handleSetSelectionState({ modalDataExtensions });
        } else {
          setModalDataExtensions(modalDataExtensions);
        }
      }

      const deToPush = movingDE ? deToMove : selectedCollection[0];

      // Add DE to subcollection
      addOrRemoveDE(selectedDeCopy[0], parentId, deToPush);

      const updatedSelectedDEsTree = { ...selectedDEsTree };

      updatedSelectedDEsTree[selectedCollection[0].id] = parentId;

      // make sure that subcollections in each relation are updated
      updatedRelations.forEach((relation) => {
        const subCollectionsFromCollection = modalDataExtensions?.fromCollection?.subCollections || [];
        const subCollectionsToCollection = modalDataExtensions?.toCollection?.subCollections || [];

        if (relation.fromCollection.deAlias === modalDataExtensions?.fromCollection?.deAlias) {
          relation.fromCollection.subCollections = subCollectionsFromCollection;
        }

        if (relation.toCollection.deAlias === modalDataExtensions?.toCollection?.deAlias) {
          relation.toCollection.subCollections = subCollectionsToCollection;
        }
      });

      let relationCopy;

      // update relation that is coming from a data set
      if (relation) {
        relationCopy = JSON.parse(JSON.stringify(relation));

        const selectedCollectionCopy = { ...selectedCollection[0] };

        relationCopy.fromCollection = modalDataExtensions.fromCollection;
        relationCopy.relationalModalId = relationalModalId;
        relationCopy.toCollection.deAlias = selectedCollectionCopy.deAlias;
      }
      // Fetch picklist values for filtersets from dragged data extension
      fetchFilterSetsPicklistOptions(filterSets, selectedCollection);

      if (handleSetSelectionState) {
        // Close loading modal
        handleSetSelectionState({
          loaderSelectedDE: false,
          showRelationalModal: !dataSetSelectedDEs,
          // Don't add DE to array if we're just moving it
          selectedDataExtensions: [
            ...(dataSetSelectedDEs || selectedDataExtensions),
            ...(movingDE ? [] : [selectedCollection[0]]),
          ],
          movingDE,
          prevSelectedDEs,
          selectedDEsTree: updatedSelectedDEsTree,
          prevRelations,
          ...movingDE && { relations: updatedRelations },
          ...relationCopy && { relations: [...dataSetRelations, relationCopy] },
        });
      } else {
        setLoaderSelectedDE(false);

        const selectedDataExtensionsDataSetsPanel = [
          ...selectedDataExtensions,
          ...[selectedCollection[0]],
        ];

        setSelectedDataExtensions(selectedDataExtensionsDataSetsPanel);
        setShowRelationalModal(!dataSetSelectedDEs);
      }

      if (dataSetSelectedDEs) {
        return [
          [...dataSetSelectedDEs, ...(movingDE ? [] : [selectedCollection[0]])],
          [...dataSetRelations, relationCopy],
        ];
      }
    }
  };

  if (selectedDERef) {
    /*
     * assign the handleDropToSelectedDE function to the ref so it could be
     * used in the data set modal and selection container
     */
    selectedDERef.current = handleDropToSelectedDE;
  }

  /**
   * Checks if there is data extension in the selected data extensions section
   * Then calls handleDrop func. to updates related data and sets it
   * @param {object} event - event
   * @param {object} handleDrop - callback
   * @param {object} draggingDataSet - if dragging a data set
   * @returns {null} - it returns null
   */
  const dropOnSelectedCollections = async (event, handleDrop, draggingDataSet) => {
    event.preventDefault();
    const customerKey = event.dataTransfer.getData('data-collection-customerkey');
    const name = event.dataTransfer.getData('data-collection-name');
    const deAlias = event.dataTransfer.getData('data-collection-alias') || '';
    const dragged = event.dataTransfer.getData('dragged') === 'true';
    const parentAlias = event.dataTransfer.getData('parentAlias');
    const id = event.dataTransfer.getData('id');
    const parentId = event.dataTransfer.getData('parentId');

    if (draggingDataSet) {
      // find data set
      const dataSet = dataSets.find(d => d.id === id);
      const dataExtensions = dataSet.selectedDataExtensions;

      // Fetch picklist values for filtersets from data extension in dataset
      const filterSetsFromSelectedCollections = filtersUtil.getFilterSetsToDisplay(filterSets, dataExtensions);

      const filterSetDEsForPicklist = (filterSetsFromSelectedCollections
        ?.map(filterSet => filtersUtil.getFilterSetSubqueryCollections(filterSet))
        .reduce((acc, filterSetDEs) => acc.concat(filterSetDEs), [])) ?? [];

      const mergedPicklistDataExtensions = [...dataExtensions, ...filterSetDEsForPicklist];

      // Get picklists for each data extensions
      const picklistPromises = mergedPicklistDataExtensions
        .map(dataExtension => handlePickListOptions(dataExtension.fields, true, dataExtension));

      // Get picklist values and then update the state
      Promise.allSettled(picklistPromises).then(() => {
        handleSetAppState({ unionSelections });
      });
    }

    /**
     * Finds parent DE of the DE that is being dropped and predefined relation
     * where is the DE is being used in
     * @param {array} selectedDataExtensions - data sets Selected DEs
     * @param {object} selectedDataExtension - current selected DE
     * @param {object} dataSetSelectedDEs - selected DEs from a data set
     * @param {object} relations - data set relations
     * @param {object} dataSetId - id of the data set
     * @returns {object} - it returns object with the parentDE and relation
     */
    const findParentDEAndRelation = (
      selectedDataExtensions,
      selectedDataExtension,
      dataSetSelectedDEs,
      relations,
    ) => {
      let parentDE;

      // for each subcollection DE find its parent DE
      selectedDataExtensions.forEach((selectedDE) => {
        selectedDE.subCollections.forEach((subCollection) => {
          if (subCollection.deAlias === selectedDataExtension.deAlias) {
            parentDE = selectedDE;
          }
        });
      });

      // find parent DE
      const selectedParentDE = dataSetSelectedDEs.find(de => de.ObjectID === parentDE.ObjectID);

      // find a relation between the parent DE and current dropped DE
      const relation = relations.find(relation => relation.toCollection.ObjectID === selectedDataExtension.ObjectID);

      return { selectedParentDE, relation, parentDE };
    };

    // If selected data extension exists already (on second drop)
    if (selectedDataExtensions.length > 0) {
      /*
       * Force user to drop the next DE on an existing DE in order to create a relation
       * Avoid any other location
       */
      if (
        (event.target.nodeName.toString().toLowerCase() !== 'div' &&
          event.target.nodeName.toString().toLowerCase() !== 'span' &&
          event.target.nodeName.toString().toLowerCase() !== 'button' &&
          event.target.nodeName.toString().toLowerCase() !== 'i' &&
          event.target.nodeName.toString().toLowerCase() !== 'svg' &&
          event.target.nodeName.toString().toLowerCase() !== 'use') ||
        event.target.id === 'selected-collections'
      ) {
        // Display error
        SwalUtil.fire({
          type: Constants.SWAL__TYPE__ERROR,
          message: name ?
            'Please drop your data extension on another selected data extension to define a relationship.' :
            'You can only drop data extensions here.',
        });

        return null;
      }
      // Search for the   id   of the parent to which newly added DE will have relation with
      let toDataExtensionNodeId;

      let toDataExtensionNodeCustomerKey;

      // If user drops on nodeName: button/span/or relation sentence - get parentNode's id
      if (
        event.target.nodeName.toString().toLowerCase() === 'span' ||
        event.target.nodeName.toString().toLowerCase() === 'button' ||
        (event.target.nodeName.toString().toLowerCase() === 'div')
      ) {
        toDataExtensionNodeId = event.target.id;
        toDataExtensionNodeCustomerKey = event.target.getAttribute('data-collection-customerkey');
        // If user drops on nodeName: 'i' - get its parent's parent id (because i's parent is a button)
      } else if (
        (event.target.nodeName.toString().toLowerCase() === 'i' &&
          event.target.parentNode.parentNode.nodeName.toString().toLowerCase() === 'div') ||
        event.target.nodeName.toString().toLowerCase() === 'svg'
      ) {
        toDataExtensionNodeId = event.target.parentNode.parentNode.parentNode.id;
        toDataExtensionNodeCustomerKey = event.target.parentNode.parentNode.parentNode.getAttribute(
          'data-collection-customerkey',
        );
      } else if (event.target.nodeName.toString().toLowerCase() === 'use') {
        toDataExtensionNodeId = event.target.parentNode.parentNode.parentNode.parentNode.id;
        toDataExtensionNodeCustomerKey = event.target.parentNode.parentNode.parentNode.parentNode.getAttribute(
          'data-collection-customerkey',
        );
      }

      // Generate selectedDEsTree
      const updatedSelectedDEsTree = selectedDataExtensions.reduce((object, currentDE) => {
        currentDE.subCollections.forEach((subC) => {
          object[subC.id] = currentDE.id;
        });

        return object;
      }, {});

      const currentParent = updatedSelectedDEsTree[toDataExtensionNodeId];

      /**
       * Checks if the parent is transferred to the descendant
       * @param {object} obj - An object with child DE's id as key and parent DE's id as value.
       * @param {string} currentParent - current parent ID
       * @param {string} id - the id of the transferred DE
       * @returns {boolean} - true if parent is moved to the descendant, false otherwise
       */
      const getParent = (obj, currentParent, id) => {
        if (!currentParent) return false; // movingDEToDescendant = false
        if (currentParent === id) return true; // movingDEToDescendant = true

        return getParent(obj, obj[currentParent], id);
      };

      // determine if DE is moved to its descendant
      const movingDEToDescendant = getParent(updatedSelectedDEsTree, currentParent, id);

      // Disallow moving DE to itself, to its parent
      if (id === toDataExtensionNodeId || parentId === toDataExtensionNodeId) return;

      // Disallow moving a DE to its descendant
      if (movingDEToDescendant) {
        // Display error
        return SwalUtil.fire({
          type: Constants.SWAL__TYPE__ERROR,
          message: 'You cannot drop this DE here.',
        });
      }

      // if a data set is being dragged
      if (draggingDataSet) {
        // find data set
        const dataSet = dataSets.find(d => d.id === id);

        // if a data set is dropped on the its parent DE
        if (dataSet.selectedDataExtensions[0].CustomerKey === toDataExtensionNodeCustomerKey) {
          let dataSetSelectedDEs = selectedDataExtensions;

          let updatedRelations = relations;

          const dataSetId = dataSet.id + new Date().getTime();

          // loop through the data set selected DEs
          for (let i = 0; i < dataSet.selectedDataExtensions.length; i += 1) {
            if (i !== 0) {
              const parentDEAndRelation = findParentDEAndRelation(
                dataSet.selectedDataExtensions,
                dataSet.selectedDataExtensions[i],
                dataSetSelectedDEs,
                dataSet.relations,
              );

              // find parent DE with the associated dataSetId
              const { selectedParentDE } = parentDEAndRelation;

              // find a relation between the parent DE and current dropped DE
              const { relation } = parentDEAndRelation;

              const { parentDE } = parentDEAndRelation;

              // drop selected DEs one by one
              // eslint-disable-next-line
              const selectedDEs = await handleDrop(
                dataSet.selectedDataExtensions[i].CustomerKey,
                dataSet.selectedDataExtensions[i].Name,
                dataSet.selectedDataExtensions[i].id,
                i === 1 ? toDataExtensionNodeId : selectedParentDE.id,
                selectedDERef ? false : dragged,
                selectedParentDE ? selectedParentDE.deAlias : parentDE.deAlias,
                dataSet.selectedDataExtensions[i].deAlias,
                dataSetSelectedDEs,
                dataSetId,
                relation,
                updatedRelations,
                false,
                dataSet.selectedDataExtensions[i].fields,
              );

              if (selectedDEs) {
                dataSetSelectedDEs = [...selectedDEs[0]];

                updatedRelations = [...selectedDEs[1]];
              } else return;
            }
          }
          // if a data set is not dropped on the its parent DE show an error message
        } else {
          // Display error
          return swal.fire({
            type: 'error',
            title: '<div class="error-title">Oops...</div>',
            html:
              /* eslint-disable max-len */
              '<p class="width_swal">You cannot drop this Data Set here.</p>',
            footer: '<div></div>',
            buttonsStyling: false,
            animation: false,
          });
        }
      } else {
        handleDrop(customerKey, name, id, toDataExtensionNodeId, dragged, parentAlias, deAlias);
      }
    } else {
      // if a data set is being dragged
      if (draggingDataSet) {
        // find data set
        const dataSet = dataSets.find(d => d.id === id);

        let dataSetSelectedDEs = [];

        const dataSetId = dataSet.id + new Date().getTime();

        let updatedRelations = [];

        // loop through the data set selected DEs and drop them one by one
        for (let i = 0; i < dataSet.selectedDataExtensions.length; i += 1) {
          // when dropping the first DE
          if (i === 0) {
            // eslint-disable-next-line
            const selectedDEs = await handleDrop(
              dataSet.selectedDataExtensions[i].CustomerKey,
              dataSet.selectedDataExtensions[i].Name,
              null,
              null,
              false,
              null,
              dataSet.selectedDataExtensions[i].deAlias,
              dataSetSelectedDEs,
              dataSetId,
              null,
              null,
              false,
              dataSet.selectedDataExtensions[i].fields,
            );

            dataSetSelectedDEs = selectedDEs;
          } else {
            const parentDEAndRelation = findParentDEAndRelation(
              dataSet.selectedDataExtensions,
              dataSet.selectedDataExtensions[i],
              dataSetSelectedDEs,
              dataSet.relations,
            );

            // find parent DE with the associated dataSetId
            const { selectedParentDE } = parentDEAndRelation;

            // find a relation between the parent DE and current dropped DE
            const { relation } = parentDEAndRelation;

            // eslint-disable-next-line
            const selectedDEs = await handleDrop(
              dataSet.selectedDataExtensions[i].CustomerKey,
              dataSet.selectedDataExtensions[i].Name,
              dataSet.selectedDataExtensions[i].id,
              selectedParentDE.id,
              selectedDERef ? false : dragged,
              selectedParentDE.Name,
              dataSet.selectedDataExtensions[i].deAlias,
              dataSetSelectedDEs,
              dataSetId,
              relation,
              updatedRelations,
              false,
              dataSet.selectedDataExtensions[i].fields,
            );

            dataSetSelectedDEs = [...selectedDEs[0]];

            updatedRelations = [...selectedDEs[1]];
          }
        }
      } else {
        // On initial drop
        handleDrop(customerKey, name);
      }
    }

    return null;
  };

  /**
   * Determines if the border should be displayed or not
   * @param {object} e - event
   * @returns {bool} true or false depends on the return condition
   */
  const borderElement = (e) => {
    const nameOfElement = e.target.firstChild.getAttribute('data-collection-name');

    return parentDEOfDataSet === nameOfElement || !parentDEOfDataSet;
  };

  /**
   * Create border on drag enter
   * @param {object} e - event
   * @returns {void}
   */
  const borderOnEnter = (e) => {
    // Disable drop on parent after first drop
    if (e.target.firstChild && e.target.firstChild.nodeName.toString().toUpperCase() === 'DIV') {
      // eslint-disable-next-line no-param-reassign
      e.target.style.pointerEvents = 'none';
    }
    // Border solid over every DE
    if (e.target.className === 'available-data-extension dragged-collection nohover') {
      e.target.classList.add('greensolid'); // overwrite
    }
    // No border on first div after first drop
    if (
      (e.target.firstChild &&
        e.target.firstChild.className === 'available-data-extension dragged-collection nohover') ||
      (e.target.nodeName.toString().toLowerCase() === 'div' &&
        e.target.className === 'selected-extension-v2')
    ) {
      return;
    }
    // Border on first drop
    if (
      e.target.nodeName.toString().toLowerCase() === 'div' &&
      e.target.className === 'selected-extension_wrapper' &&
      e.target.firstChild &&
      (e.target.firstChild.nodeName.toString().toLowerCase() === 'div' ||
        e.target.firstChild.nodeName.toString().toLowerCase() === 'p')
    ) {
      /*
       * If there is a name of the parent DE of a data set then
       * set the border of the element that has the name same as the
       * parent DE name of the data set or if the parent DE name doesn't exist
       */
      if (borderElement(e)) {
        // eslint-disable-next-line no-param-reassign
        e.target.firstChild.style.border = 'var(--solidBorder)';
      }

      /* eslint-disable no-param-reassign */
      e.target.style.border = '';
    }
  };

  /**
   * Destroy border on drag leave
   * @param {objects} e - event
   * @returns {void}
   */
  const borderOnLeave = (e) => {
    // Enable drop on parent if it is first drop
    if (e.target.firstChild && e.target.firstChild.nodeName.toString().toUpperCase() === 'DIV') {
      // eslint-disable-next-line no-param-reassign
      e.target.style.pointerEvents = '';
    }
    // Remove border solid over every DE
    if (e.target.className === 'available-data-extension dragged-collection nohover greensolid') {
      e.target.classList.remove('greensolid'); // delete overwritten class
    }
    // Keep dashed border after leave
    if (
      e.target.nodeName.toString().toLowerCase() === 'div' &&
      e.target.className === 'selected-extension_wrapper' &&
      e.target.firstChild &&
      (e.target.firstChild.nodeName.toString().toLowerCase() === 'div' ||
        e.target.firstChild.nodeName.toString().toLowerCase() === 'p')
    ) {
      /*
       * If there is a name of the parent DE of a data set then
       * set the border of the element that has the name same as the
       * parent DE name of the data set or if the parent DE name doesn't exist
       */
      if (borderElement(e)) {
        // eslint-disable-next-line no-param-reassign
        e.target.firstChild.style.border = 'var(--dashedBorder)';
      }
    }
  };

  /**
   * Destroy border on drag drop
   * @param {objects} e - event
   * @returns {void}
   */
  const noBorderOnDrop = (e) => {
    // Remove border if drop on DE
    if (e.target.className === 'available-data-extension dragged-collection nohover greensolid') {
      e.target.classList.remove('greensolid'); // delete overwritten class
    }
  };

  return (
    <>
      {loaderSelectedDE ?
        (
          <LoadingModal
            bgNone
            hideCloseButton
            id="DE-loadingmodal"
            loadingText="Loading..."
          />
        ) :
        null}
      <div
        className={classNames(
          'selected-extension-v2',
          { 'selected-extension-data-set': selectedDERef },
          { 'hide-data-extension': isFilterBoxExpanded && !showFiltersImmediately },
          { 'show-data-extension': !isFilterBoxExpanded && isFilterBoxExpandedForFirstTime },
          { 'hide-data-extension-immediately': showFiltersImmediately },
        )}>
        <h4
          className={classNames(
            'section-title',
            { 'section-title-data-sets': selectedDERef },
          )}
        >
          Selected Data Sources
        </h4>
        <div
          id="selected-collections"
          className={classNames(
            'selected-extension-v2_wrapper',
            {
              'height-95': !selectedDataExtensions.length,
            },
          )}
          onDragOver={e => e.preventDefault()}
          onDrop={(e) => {
            // Needed to prevent a redirect
            e.preventDefault();
            handleSetSelectionState({ DEBorderMouseOver: false });
            noBorderOnDrop(e);

            const draggingDataSet = e.dataTransfer.getData('draggingDataSet');

            return dropOnSelectedCollections(e, handleDropToSelectedDE, draggingDataSet);
          }}
          onDragEnter={e => borderOnEnter(e)}
          onDragLeave={e => borderOnLeave(e)}
          style={{ pointerEvents: filterBorderMouseOver ? 'none' : '' }}
        >
          {(selectedDataExtensions && selectedDataExtensions.length) ?
            (
              <>
                {selectedDataExtensions.map((dataExtension, i) => dataExtension.dragged && i < 1 ?
                  (
                    <AvailableDECard
                      key={dataExtension.id}
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...dataExtension}
                      Name={dataExtension.Name.toString()}
                      handleExpandFiltersBox={handleExpandFiltersBox}
                      CustomerKey={dataExtension.CustomerKey.toString()}
                      id={dataExtension.id.toString()}
                      handleDeleteSelectedDE={handleDeleteSelectedDE}
                      matchedFields={matchedFields}
                      handleSetSelectionState={handleSetSelectionState}
                      filtersRef={filtersRef}
                      selectedDataExtensions={selectedDataExtensions}
                      handleAliasPopUp={handleAliasPopUp}
                      relations={relations}
                      DEBorderMouseOver={DEBorderMouseOver}
                      filterBorderMouseOver={filterBorderMouseOver}
                      customValues={customValues}
                      parentId=""
                      showRelatedDEs={showRelatedDEs}
                      dataExtension={dataExtension}
                      parentDEOfDataSet={parentDEOfDataSet}
                      setSelectedDataExtensions={setSelectedDataExtensions}
                      setModalDataExtensions={setModalDataExtensions}
                      setShowRelationalModal={setShowRelationalModal}
                      setFilterBorderMouseOver={setFilterBorderMouseOver}
                      setDEBorderMouseOver={setDEBorderMouseOver}
                    />
                  ) :
                  null)}
              </>
            ) :
            (
              <p
                id="drag-and-drop-paragraph"
                className={classNames({ 'height-100': !selectedDataExtensions.length })}
                style={{
                  pointerEvents: DEBorderMouseOver ? 'none' : '',
                  border: DEBorderMouseOver ? 'var(--dashedBorder)' : 'var(--transparentBorder)',
                }}
              >
                <Tip
                  title="Tip:"
                  description="Drag and drop a data source (data extension, data view or data set)
                  from the left to this section to get started."
                  mode="light"
                />
              </p>
            )}
        </div>
        {
          (selectedDataExtensions &&
            selectedDataExtensions.length) ?
          <Tip
            title="Tip:"
            description="Drag and drop more data sources on top of the
                        previously selected data source(s) to define a relationship, or click the Filters button
                        below to start applying filters."
            mode="light"
          /> :
            null
        }
      </div>
    </>
  );
};

SelectedExtensions.propTypes = {
  /**
   * It keeps the selected data extensions for Selection.js
   * selected data extensions are stored as collections in database
   * It will be passed from Selection.js
   */
  selectedDataExtensions: PropTypes.instanceOf(Array).isRequired,
  /**
   * It helps to delete a selected data extension
   * it will be passed from Selection.js
   */
  handleDeleteSelectedDE: PropTypes.func.isRequired,
  /**
   * It keeps the matchedFields for a target data extension of the Selection
   * It will be passed from Selection.js
   */
  matchedFields: PropTypes.instanceOf(Array),
  /**
   * It helps to set the Selection`s state
   * It will be passed from Selection.js
   */
  handleSetSelectionState: PropTypes.func,
  /**
   * It keeps the reference of the selected filters
   */
  filtersRef: PropTypes.instanceOf(Object),
  /**
   * It keeps the data extensions after they are retrieved from SFMC
   * if dataViews feature is enabled, it will also contain dataViews as well
   */
  dataExtensions: PropTypes.instanceOf(Array),
  /**
   * It keeps the data extensions those will be shown in the Relation Modal
   */
  modalDataExtensions: PropTypes.instanceOf(Object),
  /**
   * It keeps the relation between selected data extensions
   * It will be passed from Selection.js
   */
  relations: PropTypes.instanceOf(Array),
  /**
   * It helps to retrieve fields of a data extension or data view from SFMC
   * It will be passed from Selection.js
   */
  getDataExtensionOrDataViewFields: PropTypes.func,
  /**
   * Keeps track whether Available DE are dragged
   */
  DEBorderMouseOver: PropTypes.bool.isRequired,
  /**
   * Keeps track whether Available Fields are dragged
   */
  filterBorderMouseOver: PropTypes.bool.isRequired,
  /**
   * Responsible for showing/hiding loader
   */
  loaderSelectedDE: PropTypes.bool.isRequired,
  /**
   * It keeps custom values data
   * It will be passed from Selection.js
   */
  customValues: PropTypes.instanceOf(Array),
  /**
   * An array containing relations that were defined in the admin panel
   */
  predefinedRelations: PropTypes.instanceOf(Array).isRequired,
  /**
   * An object with child DE's id as key and parent DE's id as value.
   * Used to prevent the dragging of DE's unto their descendants
   */
  selectedDEsTree: PropTypes.instanceOf(Object),
  /**
   * Ref from the data sets panel or selection container
   */
  selectedDERef: PropTypes.instanceOf(Object),
  /**
   * Shows only DEs that are related via predef relations with the currentDE
   */
  showRelatedDEs: PropTypes.func,
  /**
   * An array that contains data sets
   */
  dataSets: PropTypes.instanceOf(Array),
  /**
   * Parent Data Extension of the data set that is being dragged
   */
  parentDEOfDataSet: PropTypes.string,
  /**
   * Sets loader hook when drag and dropping a DE
   */
  setLoaderSelectedDE: PropTypes.func,
  /**
   * Sets selected DEs
   */
  setSelectedDataExtensions: PropTypes.func,
  /**
   * Keeps the details about the relationship between two DEs
   */
  setModalDataExtensions: PropTypes.func,
  /**
   * Opens the relationship modal between two DEs
   */
  setShowRelationalModal: PropTypes.func,
  /**
   * Sets a border to green when drag and dropping
   */
  setDEBorderMouseOver: PropTypes.func,
  /**
   * Sets a border to green when drag and dropping
   */
  setFilterBorderMouseOver: PropTypes.func,
  /**
   * Handles picklist options
   */
  handlePickListOptions: PropTypes.func,
  /**
   * Handles app state
   */
  handleSetAppState: PropTypes.func,
  /**
   * Selection's unionSelections
   */
  unionSelections: PropTypes.instanceOf(Array),
  /**
   * Selection's filterSets
   */
  filterSets: PropTypes.instanceOf(Array),
  /**
   * Handles the expansion of the filters box
   */
  handleExpandFiltersBox: PropTypes.func,
};

export default SelectedExtensions;
