/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';

import Constants from '../../../constants/constants';
import Tooltip from '../../shared_v2/Tooltip/Tooltip';

/**
 * ActionButtonGroup component renders a button that triggers a dropdown menu with
 * actions such as "Copy Selection", "Archive Selection", and "Delete Selection".
 *
 * @param {number} rowIndex - Index of the row for which the actions are being displayed.
 * @param {string} _id - Unique identifier for the item being acted upon.
 * @param {Function} handleOpenCopySelectionModal - Function to open the "Copy Selection" modal.
 * @param {Function} handleOpenArchiveSelectionModal - Function to open the "Archive Selection" modal.
 * @param {Function} handleOpenDeleteSelectionModal - Function to open the "Delete Selection" modal.
 *
 * @returns {JSX.Element} A dropdown action button group component.
 */
const SelectionActions = ({
  handleOpenTemplateSettings,
  handleOpenScheduleSelection,
  disableScheduleSelectionBtn,
  backToWaterFall,
  featureSelectionTemplate,
  enabledScheduleSelection,
}) => {
  // State to track dropdown visibility
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  /**
   * Handle button click based on the label of the clicked action.
   * @param {string} label - The label of the clicked action.
   * @returns {void}
   */
  const handleButtonClick = (label) => {
    switch (label) {
      case 'Create Template':
        handleOpenTemplateSettings();
        break;
      case 'Schedule Selection':
        handleOpenScheduleSelection();
        break;
      default:
        break;
    }
    setIsOpen(false); // Close dropdown after selection
  };

  // Effect to handle clicks outside of the dropdown
  useEffect(() => {
    /**
     * Handle click events outside the dropdown to close it.
     * @param {MouseEvent} event - The mouse event.
     * @returns {void}
     */
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    // Attach the event listener for clicks outside the dropdown
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      // Clean up the event listener on component unmount
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  const isScheduleDisabled = disableScheduleSelectionBtn || backToWaterFall?._id;

  return (
    <div className="slds-btn-actions">
      <div role="group">
        <div
          className={`slds-dropdown-trigger slds-dropdown-trigger_click slds-button_last
            ${isOpen ? 'slds-is-open' : ''}`}
          ref={dropdownRef}
        >
          <button
            type="button"
            className="slds-button slds-button_icon slds-button_icon-border-filled"
            aria-haspopup="true"
            aria-expanded={isOpen}
            title="Show More"
            onClick={() => setIsOpen(!isOpen)}
          >
            <svg className="slds-button__icon" aria-hidden="true">
              <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#threedots_vertical" />
            </svg>
            <span className="slds-assistive-text">Show Actions</span>
          </button>

          {/* Conditionally render dropdown based on isOpen */}
          {isOpen && (
            <div className="slds-dropdown slds-dropdown_right slds-dropdown_actions">
              <ul className="slds-dropdown__list" role="menu">
                <li
                 className="slds-dropdown__item"
                 role="presentation"
                 onClick={featureSelectionTemplate ? () => handleButtonClick('Create Template') : undefined}
                >
                <a
                href="#"
                role="menuitem"
                tabIndex="0"
                >
                    <span className={featureSelectionTemplate ? 'slds-link-v2' : 'slds-disabled-link-v2'}>
                        <svg
                        className="slds-button__icon slds-icon-text-default create-btn"
                        aria-hidden="true">
                        <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#insert_template" />
                        </svg>
                        <span className="slds-truncate des-lib-datatable-actions-v2" title="Create Template">
                            Create Template
                        </span>
                    </span>
                    {!featureSelectionTemplate && (
                         <Tooltip
                         nubbinPosition={Constants.NUBBIN_POSITION__TOP_RIGHT}
                         type={Constants.TOOLTIP_TYPE__UNAVAILABLE_FEATURE}
                         />
                    )}
                </a>
                </li>
                <li
                className="slds-dropdown__item"
                role="presentation"
                onClick={isScheduleDisabled ? undefined : () => handleButtonClick('Schedule Selection')}
                >
                <a
                    href="#"
                    role="menuitem"
                    tabIndex="0"
                    >
                    <span className={isScheduleDisabled ? 'slds-disabled-link-v2' : 'slds-link-v2'}>
                    <svg
                        className={`slds-button__icon slds-icon-text-default schedule-btn ${enabledScheduleSelection ?
                          'schedule-active' :
                          'schedule'}`}
                        aria-hidden="true"
                    >
                        <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#shift_scheduling_operation" />
                    </svg>
                    <span className="slds-truncate des-lib-datatable-actions-v2" title="Schedule Selection">
                        Schedule Selection
                    </span>
                    </span>
                    {disableScheduleSelectionBtn && (
                        <Tooltip
                            nubbinPosition={Constants.NUBBIN_POSITION__TOP_RIGHT}
                            type={Constants.TOOLTIP_TYPE__UNAVAILABLE_FEATURE}
                        />
                    )}
                </a>
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SelectionActions;
