import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import { ToastContainer } from 'react-toastify';
import { connect } from 'react-redux';

import mapStateToProps from '../../../../mapStateToProps';
import AvailableFields from '../../Filters/AvailableFields/AvailableFields';
import SelectedTargetFields from '../SelectedTargetFields/SelectedTargetFields';
import TargetDE from '../TargetDE/TargetDE';
import Constants from '../../../../constants/constants';
import CustomValues from '../CustomValues/CustomValues';
import Features from '../../../../features';
import SwalUtil from '../../../../utils/swal/swalUtil';
import TargetDefinitionUtil from './targetDefinitionUtil';
import WarningAlert from '../../../shared/WarningAlert/WarningAlert';
import SourceLimiting from '../SourceLimiting/SourceLimiting';

const TargetDefinition = ({
  selectedDataExtensions,
  targetDataExtensionFields,
  matchedFields,
  targetDataExtensionCustomerKey,
  handleSetSelectionState,
  unionType,
  isArchived,
  unionSelections,
  handleSetAppState,
  unionSelectionsIndex,
  selectionType,
  newTargetDataExtension,
  editNewAutoTargetDE,
  createNewAutoTargetDE,
  newTargetDataExtensionFields,
  subscriberFieldNames,
  prevMatchedFields,
  formatAvailableDEs,
  dropToTargetDataExtensionField,
  handleAddFieldToTargetDE,
  manageSubscriberRelationship,
  targetDataExtensions,
  editTargetDataExtension,
  prevTargetDEFields,
  showSaveToast,
  dataAction,
  showDataActionModal,
  showAddValuesModal,
  showSourceLimitingModal,
  customValues,
  DEBorderMouseOver,
  filterBorderMouseOver,
  handleFiltersSave,
  dynamicCustomValuesFilters,
  getDataExtensionOrDataViewFields,
  editCustomValueIndex,
  switchToDedup,
  prioDeduplication,
  openSettings,
  usePrioDeduplication,
  singleDEStyling,
  advancedDeduplicationRules,
  handleAdvancedDedupFiltersSave,
  advancedDedupFilterSaveIndex,
  previousAdvancedDeduplicationRules,
  deletedMappedFieldsFromSFMC,
  saveSelection,
  pickLists,
  handlePickListOptions,
  handleRemoveFilterLine,
  sortLimit,
  showSortLimitModal,
  relations,
  axiosCancelToken,
  aggregationFilters,
  dataExtensions,
  loadingAllAvailableDataExtensions,
  loadingForTargetDataExtensions,
  predefinedRelations,
  applyTimezoneSettingsToAllDateFields,
  handleSetTimezoneToAllDateFields,
  timezoneSettingsForAllDateFields,
  targetDEsFolderId,
  targetDEsFolders,
  targetDataExtension,
  addSubscriberOption,
  targetCollectionObjectID,
  foldersSettings,
  selectionName,
  globalCustomValues,
  showEssentialsUpgradeModal,
  defaultSendRelationshipField,
  sourceLimitingEnabled,
  featuresInfo,
}) => {
  // throw a swal message if there are deleted fields
  if (deletedMappedFieldsFromSFMC && deletedMappedFieldsFromSFMC.length > 0) {
    // tracks the number of deleted fields
    const deletedFields = [];

    deletedMappedFieldsFromSFMC.forEach((deletedField) => {
      // create unique values for the fields
      // eslint-disable-next-line no-param-reassign
      deletedField.fields = [...new Set(deletedField.fields)];
      deletedField.fields.forEach((field) => {
        deletedFields.push(field);
      });
    });

    SwalUtil.fire({
      title: 'Missing field',
      // eslint-disable-next-line max-len
      message: `${deletedFields.length >= 2 ? 'Fields' : 'Field'} ${deletedMappedFieldsFromSFMC.map(deletedField => `<span class='dark_HTML'>${deletedField.fields.join(', ')}</span> on data extension <span class='dark_HTML'>${deletedField.dataExtension.toString()}</span>\n`)}
      could not be found and ${deletedFields.length >= 2 ? 'have ' : 'has '}been removed from the Mapped Fields.`,
      options: {
        confirmButtonText: 'OK',
      },
    });
    handleSetSelectionState({ deletedMappedFieldsFromSFMC: [] });
    // save selection to update mapped fields
    saveSelection();
  }
  /**
   * Switch between tabs
   * @param {number} index - index of a union
   * @returns {void}
   */
  const handleChangeUnionSelectionIndex = (index) => {
    handleSetAppState({ unionSelectionsIndex: index });
  };

  // eslint-disable-next-line max-len
  const featureAutoCreateTargetDEIsEnabled = Features.isFeatureEnabled(featuresInfo, Constants.FEATURE__AUTO_CREATE_TARGET_DATA_EXTENSION);

  // Make sure we always have at least an empty array for unionSelections to avoid errors on map function
  if (!unionSelections) {
    // eslint-disable-next-line no-param-reassign
    unionSelections = [];
  }

  /**
   * Function for changing the sendable and subscriber field names
   * @param {string} field - field
   * @param {boolean} withoutSetState - indicates that new state should not be set in the function
   * @returns {void}
   */
  const handleChangeSendableAndSubscriberField = (field, withoutSetState) => {
    if (!field || field === 'none') {
      handleSetSelectionState(prevState => ({
        newTargetDataExtension: {
          ...prevState.newTargetDataExtension,
          relationship: {
            ...prevState.newTargetDataExtension.relationship,
            sendableDataExtensionField: { name: '', type: '' },
            sendableSubscriberField: { name: '' },
          },
        },
      }));

      return;
    }

    // If the typeof field is string, parse it
    if (typeof field === 'string') {
      // eslint-disable-next-line no-param-reassign
      field = JSON.parse(field);
    }

    let subscriberFieldName = '';
    const name = field ? field.Name.toString() : '';
    const fieldType = field ? field.FieldType : null;

    if (
      fieldType === Constants.FILTERLINE__FIELDTYPE__TEXT ||
      fieldType === Constants.FILTERLINE__FIELDTYPE__EMAILADDRESS
    ) {
      subscriberFieldName = Constants.SUBSCRIBER_FIELD__SUBSCRIBER_KEY;
    } else if (fieldType === Constants.FILTERLINE__FIELDTYPE__NUMBER) {
      subscriberFieldName = Constants.SUBSCRIBER_FIELD__SUBSCRIBER_ID;
    }

    if (withoutSetState) { return subscriberFieldName; }

    handleSetSelectionState(prevState => ({
      newTargetDataExtension: {
        ...prevState.newTargetDataExtension,
        relationship: {
          ...prevState.newTargetDataExtension.relationship,
          sendableDataExtensionField: {
            ...prevState.newTargetDataExtension.relationship
              .sendableDataExtensionField,
            name,
            type: fieldType,
          },
          sendableSubscriberField: {
            ...prevState.newTargetDataExtension.relationship
              .sendableSubscriberField,
            name: subscriberFieldName,
          },
        },
      },
    }));
  };

  /**
   * Renders tab menu items for union selections
   * @returns {object} HTML for the tab menu items for union selections
   */
  const generateHTMLForMenuItemUnionSelections = () => {
    if (
      (targetDataExtensionCustomerKey || (editNewAutoTargetDE && featureAutoCreateTargetDEIsEnabled)) &&
      selectionType === Constants.SELECTION__TYPE__UNION
    ) {
      return (
        <ul className="slds-tabs_scoped__nav" role="tablist" id="targetdefinition-union-selection-tabs">
          {unionSelections.map((unionSelection, i) => (
            <li
              key={`tabDE-${unionSelection.unionSelectionsIndex ? unionSelection.unionSelectionsIndex : i}`}
              className={`slds-tabs_scoped__item ${i === unionSelectionsIndex ?
                'slds-is-active' :
                ''} ${editNewAutoTargetDE ?
                'disable-tab' :
                ''}`}
              title={unionSelection.tabName}
              role="presentation"
            >
              <a
                className={`slds-tabs_scoped__link TD-nav-link ${
                  i === unionSelectionsIndex ? 'union-tab-active active' : ''
                } ${editNewAutoTargetDE ? 'disable-mouse-events' : ''}`}
                href="#!"
                role="tab"
                tabIndex="0"
                aria-selected="true"
                aria-controls={`tab-scoped-${i}`}
                id={`tab-${i}`}
                data-target="#"
                onClick={() => handleChangeUnionSelectionIndex(i)}
              >
                <p>{unionSelection.tabName}</p>
              </a>
            </li>
          ))}
        </ul>
      );
    }

    return null;
  };

  /**
   * Renders tab content for union selections
   * @returns {object} HTML for the tab content for union selections
   */
  const generateHTMLForContentUnionSelections = () => {
    if (targetDataExtensionCustomerKey || (editNewAutoTargetDE && featureAutoCreateTargetDEIsEnabled)) {
      if (selectionType === Constants.SELECTION__TYPE__UNION) {
        return (
          <>
            {unionSelections.map((unionSelection, i) => (
              <div
                className={`slds-tabs_scoped__content slds-show tab-content tab-pane ${
                  i === unionSelectionsIndex ?
                    ' active' :
                    ''
                }`}
                key={`tabDE-${unionSelection.unionSelectionsIndex ? unionSelection.unionSelectionsIndex : i}`}
              >
                <div
                  className={`${
                    singleDEStyling ?
                      'AF-wrapper-single' :
                      'AF-wrapper'
                  }`}
                  id={`tab-${i}`}
                  aria-labelledby={`tab-${i}`}
                  role="tabpanel"
                >
                  <div className="available-fields mt-2-per sticky-content">
                      <AvailableFields
                        hideCollectionAlias={false}
                        createNewAutoTargetDE={createNewAutoTargetDE}
                        selectedDataExtensions={unionSelection.selectedDataExtensions}
                        handleAddFieldToTargetDE={handleAddFieldToTargetDE}
                        editNewAutoTargetDE={editNewAutoTargetDE}
                        newTargetDataExtensionFields={newTargetDataExtensionFields}
                        handleSetSelectionState={handleSetSelectionState}
                        matchedFields={matchedFields}
                        dropToTargetDataExtensionField={dropToTargetDataExtensionField}
                        manageSubscriberRelationship={manageSubscriberRelationship}
                        editTargetDataExtension={editTargetDataExtension}
                        targetDataExtensionFields={targetDataExtensionFields}
                        DEBorderMouseOver={DEBorderMouseOver}
                        filterBorderMouseOver={filterBorderMouseOver}
                        inTargetDefinition
                      />
                      {/* Custom values */}
                      <AvailableFields
                        hideCollectionAlias={false}
                        customValues={unionSelection.customValues}
                        editNewAutoTargetDE={editNewAutoTargetDE}
                        dropToTargetDataExtensionField={dropToTargetDataExtensionField}
                        matchedFields={matchedFields}
                        handleSetSelectionState={handleSetSelectionState}
                        DEBorderMouseOver={DEBorderMouseOver}
                        filterBorderMouseOver={filterBorderMouseOver}
                        inTargetDefinition
                        globalCustomValues={TargetDefinitionUtil.getRelatedGlobalCustomValues(
                          globalCustomValues,
                          unionSelection.selectedDataExtensions,
                        )}
                        showEssentialsUpgradeModal={showEssentialsUpgradeModal}
                      />
                  </div>
                  <div className={`${singleDEStyling ? 'TD-wrapper-single' : 'TD-wrapper'}`}>
                    <SelectedTargetFields
                      targetDataExtensionFields={targetDataExtensionFields}
                      matchedFields={unionSelection.matchedFields}
                      relations={unionSelection.relations}
                      selectedDataExtensions={unionSelection.selectedDataExtensions}
                      targetDataExtensionCustomerKey={targetDataExtensionCustomerKey}
                      handleSetSelectionState={handleSetSelectionState}
                      newTargetDataExtensionFields={newTargetDataExtensionFields}
                      editNewAutoTargetDE={editNewAutoTargetDE}
                      newTargetDataExtension={newTargetDataExtension}
                      subscriberFieldNames={subscriberFieldNames}
                      handleAddFieldToTargetDE={handleAddFieldToTargetDE}
                      dropToTargetDataExtensionField={dropToTargetDataExtensionField}
                      dataAction={dataAction}
                      showDataActionModal={showDataActionModal}
                      showSourceLimitingModal={showSourceLimitingModal}
                      DEBorderMouseOver={DEBorderMouseOver}
                      filterBorderMouseOver={filterBorderMouseOver}
                      openSettings={openSettings}
                      prevTargetDEFields={prevTargetDEFields}
                      targetDataExtensions={targetDataExtensions}
                      editTargetDataExtension={editTargetDataExtension}
                      showSortLimitModal={showSortLimitModal}
                      sortLimit={sortLimit}
                      prioDeduplication={prioDeduplication}
                      usePrioDeduplication={usePrioDeduplication}
                      targetDataExtension={targetDataExtension}
                      addSubscriberOption={addSubscriberOption}
                      handleChangeSendableAndSubscriberField={handleChangeSendableAndSubscriberField}
                      unionSelections={unionSelections}
                      sourceLimitingEnabled={sourceLimitingEnabled}
                      handleSetAppState={handleSetAppState}
                    />
                  </div>
                </div>
                {/* Renders only for the currently selected union  */}
                {(showAddValuesModal || editCustomValueIndex !== '') &&
                unionSelection.unionSelectionsIndex === unionSelectionsIndex ?
                  (
                    <CustomValues
                      tab={i}
                      handleSetSelectionState={handleSetSelectionState}
                      customValues={unionSelection.customValues || []}
                      editCustomValueIndex={editCustomValueIndex}
                      matchedFields={matchedFields}
                      selectedDataExtensions={unionSelection.selectedDataExtensions}
                      handleFiltersSave={handleFiltersSave}
                      dynamicCustomValuesFilters={dynamicCustomValuesFilters}
                      getDataExtensionOrDataViewFields={getDataExtensionOrDataViewFields}
                      DEBorderMouseOver={DEBorderMouseOver}
                      filterBorderMouseOver={filterBorderMouseOver}
                      pickLists={pickLists}
                      handlePickListOptions={handlePickListOptions}
                      handleRemoveFilterLine={handleRemoveFilterLine}
                      relations={relations}
                      axiosCancelToken={axiosCancelToken}
                      aggregationFilters={aggregationFilters}
                      dataExtensions={dataExtensions}
                      loadingAllAvailableDataExtensions={loadingAllAvailableDataExtensions}
                      predefinedRelations={predefinedRelations}
                      applyTimezoneSettingsToAllDateFields={applyTimezoneSettingsToAllDateFields}
                      timezoneSettingsForAllDateFields={timezoneSettingsForAllDateFields}
                      handleSetTimezoneToAllDateFields={handleSetTimezoneToAllDateFields}
                      globalCustomValues={TargetDefinitionUtil.getRelatedGlobalCustomValues(
                        globalCustomValues,
                        unionSelection.selectedDataExtensions,
                      )}
                    />
                  ) :
                  null}
              </div>
            ))}
          </>
        );
      }

      return (
        <div className={`${singleDEStyling ? 'AF-wrapper-single' : 'AF-wrapper'}`}>
          <div className="available-fields mt-2-per sticky-content">
              <AvailableFields
                hideCollectionAlias={false}
                selectedDataExtensions={selectedDataExtensions}
                editNewAutoTargetDE={editNewAutoTargetDE}
                newTargetDataExtensionFields={newTargetDataExtensionFields}
                handleSetSelectionState={handleSetSelectionState}
                matchedFields={matchedFields}
                handleAddFieldToTargetDE={handleAddFieldToTargetDE}
                dropToTargetDataExtensionField={dropToTargetDataExtensionField}
                manageSubscriberRelationship={manageSubscriberRelationship}
                DEBorderMouseOver={DEBorderMouseOver}
                filterBorderMouseOver={filterBorderMouseOver}
              />
              {/* Custom values */}
              <AvailableFields
                hideCollectionAlias={false}
                customValues={customValues}
                editNewAutoTargetDE={editNewAutoTargetDE}
                dropToTargetDataExtensionField={dropToTargetDataExtensionField}
                matchedFields={matchedFields}
                handleSetSelectionState={handleSetSelectionState}
                DEBorderMouseOver={DEBorderMouseOver}
                filterBorderMouseOver={filterBorderMouseOver}
                globalCustomValues={TargetDefinitionUtil.getRelatedGlobalCustomValues(
                  globalCustomValues,
                  selectedDataExtensions,
                )}
              />
            <div className={`${singleDEStyling ? 'TD-wrapper-single' : 'TD-wrapper'}`}>
              <SelectedTargetFields
                targetDataExtensionFields={targetDataExtensionFields}
                matchedFields={matchedFields}
                relations={relations}
                selectedDataExtensions={selectedDataExtensions}
                handleSetSelectionState={handleSetSelectionState}
                newTargetDataExtensionFields={newTargetDataExtensionFields}
                editNewAutoTargetDE={editNewAutoTargetDE}
                newTargetDataExtension={newTargetDataExtension}
                subscriberFieldNames={subscriberFieldNames}
                handleAddFieldToTargetDE={handleAddFieldToTargetDE}
                dropToTargetDataExtensionField={dropToTargetDataExtensionField}
                editTargetDataExtension={editTargetDataExtension}
                prevTargetDEFields={prevTargetDEFields}
                prevMatchedFields={prevMatchedFields}
                dataAction={dataAction}
                showDataActionModal={showDataActionModal}
                showSourceLimitingModal={showSourceLimitingModal}
                DEBorderMouseOver={DEBorderMouseOver}
                filterBorderMouseOver={filterBorderMouseOver}
                openSettings={openSettings}
                targetDataExtensions={targetDataExtensions}
                showSortLimitModal={showSortLimitModal}
                sortLimit={sortLimit}
                prioDeduplication={prioDeduplication}
                usePrioDeduplication={usePrioDeduplication}
                targetDataExtension={targetDataExtension}
                addSubscriberOption={addSubscriberOption}
                handleChangeSendableAndSubscriberField={handleChangeSendableAndSubscriberField}
                unionSelections={unionSelections}
                sourceLimitingEnabled={sourceLimitingEnabled}
                handleSetAppState={handleSetAppState}
              />
            </div>
            {showAddValuesModal || editCustomValueIndex !== '' ?
              (
                <CustomValues
                  tab={0}
                  handleSetSelectionState={handleSetSelectionState}
                  customValues={customValues}
                  editCustomValueIndex={editCustomValueIndex}
                  matchedFields={matchedFields}
                  selectedDataExtensions={selectedDataExtensions}
                  handleFiltersSave={handleFiltersSave}
                  dynamicCustomValuesFilters={dynamicCustomValuesFilters}
                  getDataExtensionOrDataViewFields={getDataExtensionOrDataViewFields}
                  DEBorderMouseOver={DEBorderMouseOver}
                  filterBorderMouseOver={filterBorderMouseOver}
                  pickLists={pickLists}
                  handlePickListOptions={handlePickListOptions}
                  handleRemoveFilterLine={handleRemoveFilterLine}
                  aggregationFilters={aggregationFilters}
                  predefinedRelations={predefinedRelations}
                  applyTimezoneSettingsToAllDateFields={applyTimezoneSettingsToAllDateFields}
                  timezoneSettingsForAllDateFields={timezoneSettingsForAllDateFields}
                  handleSetTimezoneToAllDateFields={handleSetTimezoneToAllDateFields}
                  globalCustomValues={TargetDefinitionUtil.getRelatedGlobalCustomValues(
                    globalCustomValues,
                    selectedDataExtensions,
                  )}
                />
              ) :
              null}
          </div>
        </div>
      );
    }

    return null;
  };

  // Available fields is green one.
  return (
    // render target data extension dropdown
    <div className="target-definition" id="selection-body">

      {isArchived && <WarningAlert text={Constants.WARNING_TEXT__ARCHIVED_SELECTION_READ_ONLY} />}

      <div className="target-definition_panel">
        <div className="target-definition_wrapper" id="target-DE">
          <TargetDE
            previousAdvancedDeduplicationRules={previousAdvancedDeduplicationRules}
            advancedDedupFilterSaveIndex={advancedDedupFilterSaveIndex}
            defaultSendRelationshipField={defaultSendRelationshipField}
            advancedDeduplicationRules={advancedDeduplicationRules}
            targetDataExtensionCustomerKey={targetDataExtensionCustomerKey}
            handleSetSelectionState={handleSetSelectionState}
            unionType={unionType}
            matchedFields={matchedFields}
            targetDataExtensionFields={targetDataExtensionFields}
            handleSetAppState={handleSetAppState}
            unionSelections={unionSelections}
            selectionType={selectionType}
            newTargetDataExtension={newTargetDataExtension}
            createNewAutoTargetDE={createNewAutoTargetDE}
            editNewAutoTargetDE={editNewAutoTargetDE}
            selectedDataExtensions={selectedDataExtensions}
            newTargetDataExtensionFields={newTargetDataExtensionFields}
            prevMatchedFields={prevMatchedFields}
            prevTargetDEFields={prevTargetDEFields}
            formatAvailableDEs={formatAvailableDEs}
            targetDataExtensions={targetDataExtensions}
            editTargetDataExtension={editTargetDataExtension}
            showSaveToast={showSaveToast}
            dataAction={dataAction}
            switchToDedup={switchToDedup}
            prioDeduplication={prioDeduplication}
            usePrioDeduplication={usePrioDeduplication}
            handleFiltersSave={handleFiltersSave}
            filterBorderMouseOver={filterBorderMouseOver}
            DEBorderMouseOver={DEBorderMouseOver}
            getDataExtensionOrDataViewFields={getDataExtensionOrDataViewFields}
            handleAdvancedDedupFiltersSave={handleAdvancedDedupFiltersSave}
            pickLists={pickLists}
            handlePickListOptions={handlePickListOptions}
            handleRemoveFilterLine={handleRemoveFilterLine}
            sortLimit={sortLimit}
            loadingForTargetDataExtensions={loadingForTargetDataExtensions}
            subscriberFieldNames={subscriberFieldNames}
            targetCollectionObjectID={targetCollectionObjectID}
            applyTimezoneSettingsToAllDateFields={applyTimezoneSettingsToAllDateFields}
            timezoneSettingsForAllDateFields={timezoneSettingsForAllDateFields}
            handleSetTimezoneToAllDateFields={handleSetTimezoneToAllDateFields}
            targetDEsFolderId={targetDEsFolderId}
            targetDEsFolders={targetDEsFolders}
            targetDataExtension={targetDataExtension}
            handleChangeSendableAndSubscriberField={handleChangeSendableAndSubscriberField}
            featureAutoCreateTargetDEIsEnabled={featureAutoCreateTargetDEIsEnabled}
            foldersSettings={foldersSettings}
            isArchived={isArchived}
            selectionName={selectionName}
            openSettings={openSettings}
            addSubscriberOption={addSubscriberOption}
          />
          <ToastContainer
            enableMultiContainer
            containerId={Constants.NOTIFICATION__CONTAINER_ID__TARGET_DEFINITION}
            limit={1}
          />
        </div>
      </div>

      {switchToDedup === false ?
        (
          <div className="slds-tabs_scoped">
            {// render tab menu items for union selections
              generateHTMLForMenuItemUnionSelections()
            }

            {// Render tab content for union selections
              generateHTMLForContentUnionSelections()
            }
          </div>
        ) :
        null}

      {
        showSourceLimitingModal && (
          <SourceLimiting
            unionSelections={unionSelections}
            handleSetSelectionState={handleSetSelectionState}
            sourceLimitingEnabled={sourceLimitingEnabled}
            handleSetAppState={handleSetAppState}
          />
        )
      }
    </div>
  );
};

TargetDefinition.propTypes = {
  /**
   * checks if using single DE styling or not
   */
  singleDEStyling: PropTypes.bool.isRequired,
  /**
   * Filters Rules for the advanced deduplication
   */
  advancedDeduplicationRules: PropTypes.instanceOf(Array).isRequired,
  /**
   * saves the filters for the advanced deduplication
   */
  handleAdvancedDedupFiltersSave: PropTypes.func.isRequired,
  /**
   * gets the index for the actual rule being worked on
   */
  advancedDedupFilterSaveIndex: PropTypes.number,
  /**
   * holds the state of the selected filter in case the cancel button is working
   */
  previousAdvancedDeduplicationRules: PropTypes.instanceOf(Array).isRequired,
  /**
   * It keeps the value of ac/de creation status
   * It will be passed from Selection.js
   */
  editNewAutoTargetDE: PropTypes.bool.isRequired,
  /**
   * It shows if user clicked on "Create Data Extension" button and open a modal to input info
   * about new DE
   */
  createNewAutoTargetDE: PropTypes.bool.isRequired,
  /**
   * It keeps the fields which user drag and drop during the process of creating a new DE
   */
  newTargetDataExtensionFields: PropTypes.instanceOf(Array),
  /**
   * It keeps the selected data extensions for Selection.js
   * selected data extensions are stored as collections in database
   * It will be passed from Selection.js
   */
  selectedDataExtensions: PropTypes.instanceOf(Array).isRequired,
  /**
   * It keeps the fields of a existing target data extension
   * It will be passed from Selection.js
   */
  targetDataExtensionFields: PropTypes.instanceOf(Array).isRequired,
  /**
   * It keeps the matchedFields for a target data extension of the Selection
   * It will be passed from Selection.js
   */
  matchedFields: PropTypes.instanceOf(Array).isRequired,
  /**
   * It keeps the Customer Key of a selected existing target data extension
   * It will be passed from Selection.js
   */
  targetDataExtensionCustomerKey: PropTypes.string.isRequired,
  /**
   * It helps to set the Selection`s state
   * It will be passed from Selection.js
   */
  handleSetSelectionState: PropTypes.func.isRequired,
  /**
   * It keeps the type of the union type of selections (union or union all)
   * It will be passed from Selection.js
   */
  unionType: PropTypes.string,
  /**
   * @property {object} unionSelections.relations => it keeps the relation between selected data extensions
   * @property {object} unionSelections.collections => it keeps the selected data extensions
   * @property {object} unionSelections.fields => it keeps the selected fields for
   * target data extensions (matchedFields)
   * @property {object} unionSelections.filters => it keeps the filters of a Selection
   * It will be passed from App.js
   */
  unionSelections: PropTypes.instanceOf(Object),
  /**
   * It sets the App component`s state
   * This prop will be passed from App.js component through Selection.js
   */
  handleSetAppState: PropTypes.func.isRequired,
  /**
   * This prop keeps the unionSelectionsIndex of Union Selection
   * This prop will be passed from App.js component if the appendDataExtension feature is enabled
   */
  unionSelectionsIndex: PropTypes.number,
  /**
   * It keeps the type of selection (Union or Simple)
   * It will be passed from Selection.js
   */
  selectionType: PropTypes.string,
  /**
   * It keeps the info about a New Auto Create Data Extension
   */
  newTargetDataExtension: PropTypes.instanceOf(Object),
  /**
   * It keeps the fields matched in Target Definition screen so we don't lose them
   * if we cancel creation of New Data Extension
   */
  prevMatchedFields: PropTypes.instanceOf(Array),
  /**
   * It keeps subscriber field names
   */
  subscriberFieldNames: PropTypes.instanceOf(Array),
  /**
   * It helps to format available Data Extensions
   * Sort them by name and push data views in available Data Extensions
   * if feature for data views is enabled
   */
  formatAvailableDEs: PropTypes.func.isRequired,
  /**
   * It helps to match a field between available fields and target DE fields
   */
  dropToTargetDataExtensionField: PropTypes.func,
  /**
   * It helps to add a new field to a DE during the process of creating a new DE
   */
  handleAddFieldToTargetDE: PropTypes.func,
  /**
   * It helps to manage subscriber relationship while creating a data extension
   * It will be passed from Selection.js
   */
  manageSubscriberRelationship: PropTypes.func.isRequired,
  /**
   * It keeps the target data extensions after they are retrieved from SFMC
   * data views will not be inside target data extensions
   */
  targetDataExtensions: PropTypes.instanceOf(Array),
  /**
   * boolean state from Selection for going in or out the edit target de mode
   */
  editTargetDataExtension: PropTypes.bool.isRequired,
  /**
   * keeps target de fields before entering the edit target de mode
   * It will be passed from Selection.js
   */
  prevTargetDEFields: PropTypes.instanceOf(Array),
  /**
   * It keeps data action state (overwrite, append, update)
   */
  dataAction: PropTypes.string.isRequired,
  /**
   * It keeps the boolean state which is used to open or close data action modal
   * It will be passed from Selection.js
   */
  showDataActionModal: PropTypes.bool.isRequired,

  /**
   * It keeps the boolean state which is used to open or close source limiting modal
   */
  showSourceLimitingModal: PropTypes.bool.isRequired,
  /**
   * Boolean - shows/close toast
   */
  showSaveToast: PropTypes.bool.isRequired,
  /**
   * Keeps track whether Available DE are dragged
   */
  DEBorderMouseOver: PropTypes.bool.isRequired,
  /**
   * Keeps track whether Available Fields are dragged
   */
  filterBorderMouseOver: PropTypes.bool.isRequired,
  /**
   * Boolean - shows/close custom values modal
   */
  showAddValuesModal: PropTypes.bool.isRequired,
  /**
   * It keeps custom values data
   * It will be passed from Selection.js
   */
  customValues: PropTypes.instanceOf(Array).isRequired,
  /**
   * It keeps index of custom value we want to edit
   * It will be passed from Selection.js
   */
  editCustomValueIndex: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  /**
   * Decide if Dedup is screen will be opened or not
   */
  switchToDedup: PropTypes.bool,
  /**
   * Keep the required data for prioDedup
   */
  prioDeduplication: PropTypes.instanceOf(Object),
  /**
   * Opens settings
   */
  openSettings: PropTypes.bool.isRequired,
  /**
   * It keeps if the deduplication rules will be applied
   *
   */
  usePrioDeduplication: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  /**
   * It helps to save the selected filters for the selection
   * it will be passed from Selection.js
   */
  handleFiltersSave: PropTypes.func.isRequired,
  /**
   * It helps to save the selected filters for the criteria
   * it will be passed from Selection.js
   */
  dynamicCustomValuesFilters: PropTypes.instanceOf(Object),
  /**
   * It helps to retrieve fields of a data extension or data view from SFMC
   * It will be passed from Selection.js
   */
  getDataExtensionOrDataViewFields: PropTypes.func.isRequired,
  /**
   * Keeps searched picklist
   * It will be passed from Selection.js
   */
  pickLists: PropTypes.instanceOf(Array).isRequired,
  /**
   * Responsible for adding/deleting fields Object IDs when searching picklist for the options
   */
  handlePickListOptions: PropTypes.func,
  /**
   * It keeps mapped fields which are deleted in SFMC
   * It will be passed from Selection.js
   */
  deletedMappedFieldsFromSFMC: PropTypes.instanceOf(Array).isRequired,
  /**
   * It saves the selection to database with the given data for the Selection
   * It will be passed from Selection.js
   */
  saveSelection: PropTypes.func.isRequired,
  /**
   * It Removes a given filterLine
   * It is passed from Selection.js
   */
  handleRemoveFilterLine: PropTypes.func,
  /*
   * It keeps the boolean state which is used to open or close sort and limit modal
   * It will be passed from Selection.js
   */
  showSortLimitModal: PropTypes.bool.isRequired,
  /**
   * It keeps data of sort and limit
   */
  sortLimit: PropTypes.instanceOf(Object).isRequired,
  /**
   * It keeps the relation between selected data extensions
   * It will be passed from Selection.js
   */
  relations: PropTypes.instanceOf(Array),
  /**
   * It helps to cancel a subscription of an API call to backend
   * It will be passed from Selection.js
   */
  axiosCancelToken: PropTypes.instanceOf(Object),
  /**
   * It helps to save the selected filters for the aggregation
   * it will be passed from Selection.js
   */
  aggregationFilters: PropTypes.instanceOf(Object),
  /**
   * It keeps the data extensions after they are retrieved from SFMC
   * if dataViews feature is enabled, it will also contain dataViews as well
   */
  dataExtensions: PropTypes.instanceOf(Array),
  /**
   * Responsible for the loading status of Target Data Extensions
   */
  loadingForTargetDataExtensions: PropTypes.bool.isRequired,
  /**
   * An array containing relations that were defined for Data Extensions
   */
  predefinedRelations: PropTypes.instanceOf(Array).isRequired,
  /**
   * It keeps the Object ID of a selected existing target data extension
   * It will be passed from Selection.js
   */
  targetCollectionObjectID: PropTypes.string.isRequired,
  /**
   * Indicates whether timezone settings are applied to all date filters
   */
  applyTimezoneSettingsToAllDateFields: PropTypes.bool,
  /**
   * Handles the setting of timezone settings to all date filters
   */
  handleSetTimezoneToAllDateFields: PropTypes.func,
  /**
   * An object containing timezone details
   */
  timezoneSettingsForAllDateFields: PropTypes.instanceOf(Object),
  /*
   * Selected id of the target DE folder
   */
  targetDEsFolderId: PropTypes.number,
  /**
   * Stored targetDEsFolders folders in selection state
   */
  targetDEsFolders: PropTypes.instanceOf(Array),
  /**
   * It keeps data for selected target data extension
   */
  targetDataExtension: PropTypes.instanceOf(Object).isRequired,
  /**
   * Function for adding Subscriber Field option in dropdown
   */
  addSubscriberOption: PropTypes.instanceOf(Object).isRequired,
  /**
   * Keeps folder filtering information
   */
  foldersSettings: PropTypes.instanceOf(Object),
  /**
   * Indicates if selection is archived or not
   */
  isArchived: PropTypes.bool.isRequired,
  /**
   * Stores the selection name
   */
  selectionName: PropTypes.string.isRequired,
  /**
   * Shared Custom Values defined for this BU
   */
  globalCustomValues: PropTypes.instanceOf(Array),
  /**
   * It toggles a feature advert modal on with specific feature
   */
  showEssentialsUpgradeModal: PropTypes.func,
  /**
   * Object containing default send relationship settings
   */
  defaultSendRelationshipField: PropTypes.object,
  /**
   * Boolean indicating if source limiting is enabled
   */
  sourceLimitingEnabled: PropTypes.bool,
  /**
   * Responsible for the loading status of all available data extensions
   */
  loadingAllAvailableDataExtensions: PropTypes.bool,
  /**
   * Features info from cookie
   */
  featuresInfo: PropTypes.object,
};

export default connect(mapStateToProps(['featuresInfo']), null, null, { pure: false })(TargetDefinition);
