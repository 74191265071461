import '@DESelectMC/deselect-component-library/dist/cjs/index.css';
import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import { debounce } from 'lodash';
import { NavBar } from '@DESelectMC/deselect-component-library';
import { ButtonGroup } from '@salesforce/design-system-react';
import { connect } from 'react-redux';

import mapStateToProps from '../../../mapStateToProps';
import Select from '../../shared/Select/Select';
import Constants from '../../../constants/constants';
import Button from '../../shared/Button/Button';
import Input from '../../shared/Input/Input';
import Util from '../../../util';
import Features from '../../../features';
import GuidanceTip from '../../../components/shared/GuidanceTip/GuidanceTip';

/**
 * Get the search criteria constant label from a search criteria
 * @param {string} searchCriteria - Is the search criteria name
 * @returns {string} The search criteria constant label corresponding to searchCriteria
 */
const searchCriteriaLabel = (searchCriteria) => {
  if (searchCriteria === Constants.SELECTION__SEARCH_CRITERIA__NAME) {
    return Constants.SELECTION__SEARCH_CRITERIA__NAME__LABEL;
  }
  if (searchCriteria === Constants.SELECTION__SEARCH_CRITERIA__CREATED_BY_ID) {
    return Constants.SELECTION__SEARCH_CRITERIA__CREATED_BY_ID__LABEL;
  }
  if (searchCriteria === Constants.SELECTION__SEARCH_CRITERIA__UPDATED_BY_ID) {
    return Constants.SELECTION__SEARCH_CRITERIA__UPDATED_BY_ID__LABEL;
  }

  return '';
};

const NavbarHeader = ({
  handleSearch,
  handleNavigator,
  handleChangeSearchCriteria,
  searchCriteria,
  selections,
  filterSelectList,
  searchField,
  searchCriteriaValue,
  // searchBarLonger,
  handleSetOverviewState,
  overviewSection,
  handleSetAppState,
  refreshSelections,
  handleOpenCalendarView,
  userInfo,
  orgInfo,
  featuresInfo,
}) => {
  const hasReadOnlyAccess = userInfo?.hasReadOnlyAccess;
  // use Reacts useRef to store the debounced function across renders
  const debouncedSearch = React.useRef(
    /**
     * Returns a function, that, as long as it continues to be invoked, will not
     * be triggered. The function will be called after it stops being called for
     * 500 milliseconds.
     * @returns {void}
     */
    debounce(async () => {
      try {
        // fetch selections
        await refreshSelections();

        // stop loading selections in overview table
        handleSetOverviewState({ loadingSelectionsList: false });
      } catch (error) {
        handleSetOverviewState({ error });
      }
    }, 500),
  ).current;

  /**
   * Handles changing value in the search input
   * @param {object} e - JS Event
   * @param {string} criteria - selected criteria
   * @returns {void}
   */
  const handleChange = async (e, criteria) => {
    // start loading selections in overview table
    handleSetOverviewState({ loadingSelectionsList: true });
    const isSelectValue = criteria === Constants.SELECTION__SEARCH_CRITERIA__CREATED_BY_ID ||
      criteria === Constants.SELECTION__SEARCH_CRITERIA__UPDATED_BY_ID;

    handleSearch(e, isSelectValue);
    debouncedSearch();
  };

  React.useEffect(() => {
    return () => {
      // cancel the debounced function
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  // waterfall selection feature flag
  // eslint-disable-next-line max-len
  const featureWaterfallSelectionIsEnabled = Features.isFeatureEnabled(featuresInfo, Constants.FEATURE__WATERFALL_SELECTIONS);

  // schedule selection feature flag
  // eslint-disable-next-line max-len
  const featureScheduleSelectionIsEnabled = Features.isFeatureEnabled(featuresInfo, Constants.FEATURE__SCHEDULE_SELECTIONS);

  /*
   * classNames for Selections button
   * const classNameForSelectionsButton = classNames(
   *   'selections-section-button',
   *   // eslint-disable-next-line quote-props
   *   { 'selected': overviewSection === Constants.OVERVIEW__SECTION__SELECTIONS },
   * );
   */

  /*
   * classNames for Waterfall Selections button
   * const classNameForWaterfallButton = classNames(
   *   'waterfall-section-button',
   *   {
   *     selected: overviewSection === Constants.OVERVIEW__SECTION__WATERFALL_SELECTIONS,
   *     'unavailable-feature': !featureWaterfallSelectionIsEnabled,
   *   },
   * );
   */

  /*
   * classNames for Waterfall Selections button
   * const classNameForScheduledSelectionsButton = classNames(
   *   'scheduled-section-button',
   *   {
   *     'unavailable-feature': !featureScheduleSelectionIsEnabled,
   *   },
   * );
   */

  /*
   * classNames for new selection button label
   * const newSelectionButtonLabel = overviewSection === Constants.OVERVIEW__SECTION__SELECTIONS ?
   *   'New Selection' :
   *   'New Waterfall Selection';
   */

  /**
   * Opens New Selection depending on the selected section
   * @returns {void}
   */
  const handleOpenNewSelection = async () => {
    if (overviewSection === Constants.OVERVIEW__SECTION__WATERFALL_SELECTIONS) {
      handleNavigator(Constants.NAVIGATION__WATERFALL_SELECTION);
    } else {
      // Check for limits for essentials edition
      if (orgInfo?.edition === Constants.ORGANISATION__EDITION__ESSENTIALS) {
        if (selections?.length >= Constants.ESSENTIALS_EDITION_MAX_SELECTIONS_COUNT) {
          await Util.handleUpgradeWarning(Constants.ESSENTIALS_EDITION_MAX_SELECTIONS_LIMIT_LABEL);

          return;
        }
      }
      handleNavigator(Constants.NAVIGATION__SELECTION);
    }
  };

  /**
   * Handles switching between sections in Overview
   * @param {string} section - selected section
   * @returns {void}
   */
  const handleChangeOverviewSection = (section) => {
    // change the section
    handleSetAppState({ overviewSection: section });

    // reset folderId and search values
    handleSetOverviewState({
      filterFolderId: null,
      searchCriteria: Constants.SELECTION__SEARCH_CRITERIA__NAME,
      searchCriteriaValue: '',
      searchField: '',
    });
  };

  /**
   * get label for the source limiting option in the Tools dropdown
   * @param {string} label - The name of the label.
   * @returns {node} - JSX node
   */
  const getOtherLabels = (label) => {
    return (
      <span
        title={label}
        className="des-label-color">
        {label}
      </span>
    );
  };

  const optionsForSearchCriteria = [
    {
      value: Constants.SELECTION__SEARCH_CRITERIA__NAME,
      label: getOtherLabels(Constants.SELECTION__SEARCH_CRITERIA__NAME__LABEL),
    },
    {
      value: Constants.SELECTION__SEARCH_CRITERIA__CREATED_BY_ID,
      label: getOtherLabels(Constants.SELECTION__SEARCH_CRITERIA__CREATED_BY_ID__LABEL),
    },
    {
      value: Constants.SELECTION__SEARCH_CRITERIA__UPDATED_BY_ID,
      label: getOtherLabels(Constants.SELECTION__SEARCH_CRITERIA__UPDATED_BY_ID__LABEL),
    },
  ];

  /**
   * Opens Calendar View or Feature Advert Modal if the feature is not enabled
   * @returns {void}
   */
  const handleOpenScheduleCalendarView = () => {
    if(featureScheduleSelectionIsEnabled) {
      handleOpenCalendarView();
    }else{
      handleSetOverviewState({
        featureAdvertModal: {
          show: true,
          feature: Constants.FEATURE__SCHEDULE_SELECTIONS,
        },
      });
    }
  };

  /**
   * Opens Waterfall Selections or Feature Advert Modal if the feature is not enabled
   * @returns {void}
   */
  const handleOpenWaterfallSelections = () => {
    if(featureWaterfallSelectionIsEnabled) {
      handleChangeOverviewSection(Constants.OVERVIEW__SECTION__WATERFALL_SELECTIONS);
    }else{
      handleSetOverviewState({
        featureAdvertModal: {
          show: true,
          feature: Constants.FEATURE__WATERFALL_SELECTIONS,
        },
      });
    }
  };

  const selectOptions = [
    { value: '', label: <span className="des-label-color">All</span> },
    ...filterSelectList(selections, searchCriteria).map(option => ({
      ...option,
      label: <span className="des-label-color">{option.label}</span>,
    })),
  ];

  const selectLabel = selectOptions.find(option => option.value === searchCriteriaValue)?.label;

  return (
    <div className="des-overview_wrapper" id="overview-header">
      <div className="overview-title-section-wrapper">
        <div className="navbar-css">
        <NavBar
          options={Util.getNavBarOptions(userInfo, orgInfo)}
          selected="Segment"
        />
        </div>

        <div className="overview-sections">
          <ButtonGroup id="selection-wf-switch-group">
            <Button
              label="Selections"
              onClick={() => handleChangeOverviewSection(Constants.OVERVIEW__SECTION__SELECTIONS)}
              id="selections-section"
              variant={overviewSection === Constants.OVERVIEW__SECTION__SELECTIONS ? 'brand' : 'neutral'}
              type="button"
            />
            <Button
              label="Waterfall Selections"
              className={featureWaterfallSelectionIsEnabled ? '' : 'unavailable-feature'}
              onClick={handleOpenWaterfallSelections}
              id="waterfall-selections-section"
              variant={overviewSection === Constants.OVERVIEW__SECTION__WATERFALL_SELECTIONS ? 'brand' : 'neutral'}
              type="button"
              disabled={!featureWaterfallSelectionIsEnabled}
              tooltip={featureWaterfallSelectionIsEnabled ?
                null :
                {
                  type: Constants.TOOLTIP_TYPE__UNAVAILABLE_FEATURE,
                  align: Constants.SLDS_TOOLTIP_POSITION__BOTTOM_LEFT,
                  id: 'waterfall-selection-tooltip',
                }}
            />
          </ButtonGroup>
        </div>
      </div>
      <div className="des-overview_wrapper_middleContainer">
        {searchCriteria === Constants.SELECTION__SEARCH_CRITERIA__UPDATED_BY_ID ?
          (
            <Select
              id="updatedBy-search"
              onChange={e => handleChange(e, searchCriteria)}
              value={searchCriteriaValue}
              options={selectOptions}
              label={getOtherLabels(selectLabel)}
              checkmark
              className="des-select__updated-by"
              />
          ) :
          null}

        {searchCriteria === Constants.SELECTION__SEARCH_CRITERIA__CREATED_BY_ID ?
          (
            <Select
              id="createBy-search"
              onChange={e => handleChange(e, searchCriteria)}
              value={searchCriteriaValue}
              options={selectOptions}
              label={getOtherLabels(selectLabel)}
              checkmark
              className="des-select__created-by"
            />
          ) :
          null}

        {searchCriteria === Constants.SELECTION__SEARCH_CRITERIA__NAME ?
          (
            <>
              <Input
                id="selection-search"
                placeholder={'Search Selections'}
                className="des-input__name-search"
                onChange={e => handleChange(e, searchCriteria)}
                iconLeft="search"
                value={searchField}
                assistiveText={{
                  icon: 'Search',
                }}
              />
              <div className="guidance-parent-search-selection">
                  <GuidanceTip tipId="selection-search-tip" />
              </div>
            </>
          ) :
          null}
        {/* Dropdown for criteria */}
        <Select
          id="dropdown-search"
          onChange={e => handleChangeSearchCriteria(e.target.value)}
          value={searchCriteria}
          options={optionsForSearchCriteria}
          label={searchCriteriaLabel(searchCriteria)}
          className="des-select__criteria"
          buttonClassName="des-select__criteria-button"
          checkmark
        />
      </div>
      <div className="des-overview_wrapper_button">
        <Button
          iconCategory="utility"
          iconName="event"
          iconSize="large"
          onClick={handleOpenScheduleCalendarView}
          id="des-calendar-view-btn"
          className={`des-calendar-view-btn ${featureScheduleSelectionIsEnabled ? '' : 'unavailable-feature'}`}
          disabled={!featureScheduleSelectionIsEnabled}
          tooltip={featureScheduleSelectionIsEnabled ?
            null :
            {
              type: Constants.TOOLTIP_TYPE__UNAVAILABLE_FEATURE,
              align: Constants.SLDS_TOOLTIP_POSITION__BOTTOM,
              id: 'calendar-view-tooltip',
            }}
        />
        <Button
          onClick={handleOpenNewSelection}
          id={overviewSection === Constants.OVERVIEW__SECTION__WATERFALL_SELECTIONS ?
            'new-waterfall-selection' :
            'new-selection'}
          className="des-new-selection-btn"
          type="button"
          variant="brand"
          iconCategory="utility"
          iconName="add"
          label="Create New"
          iconPosition="left"
          disabled={hasReadOnlyAccess}
        />

        <div className="guidance-parent-new-selection">
          <GuidanceTip tipId="new-selection-tip" toolTipPosition="right" />
        </div>
      </div>
    </div>
  );
};

NavbarHeader.propTypes = {
  /**
   * It helps to search for selections on the Overview page
   */
  handleSearch: PropTypes.func.isRequired,
  /**
   * handleNavigator prop is passed from App.js and it helps to navigate between Overview and Selection
   */
  handleNavigator: PropTypes.func.isRequired,
  /**
   * handleChangeCriteria is passed from Overview.js and it helps to change the search criteria for selections
   * on Overview page
   */
  handleChangeSearchCriteria: PropTypes.func.isRequired,
  /**
   * search criteria prop keeps the value of the property to search in the selections on Overview Page
   */
  searchCriteria: PropTypes.string.isRequired,
  /**
   * List of all selections
   */
  selections: PropTypes.instanceOf(Array),
  /**
   * Function to create a list of possible options for select, out of selection list
   */
  filterSelectList: PropTypes.func.isRequired,
  /**
   * Input of the search
   */
  searchField: PropTypes.string.isRequired,
  /**
   * Chosen option from possible options in select
   */
  searchCriteriaValue: PropTypes.string.isRequired,
  /**
   * Boolean making search input longer/shorter
   */
  // searchBarLonger: PropTypes.bool.isRequired,
  /**
   * Function setting state of overview.js
   */
  handleSetOverviewState: PropTypes.func.isRequired,
  /**
   * Defines which section in Overview is selected - selections or waterfall
   */
  overviewSection: PropTypes.string.isRequired,
  /**
   * handleSetAppState prop is passed from App.js
   * and it helps to set state of app
   */
  handleSetAppState: PropTypes.func.isRequired,
  /**
   * Refresh and set the selections state on the front page
   */
  refreshSelections: PropTypes.func.isRequired,
  /**
   * Handles the opening of calendar view on the overview page
   */
  handleOpenCalendarView: PropTypes.func,
  /**
   * User info from cookie
   */
  userInfo: PropTypes.object,
  /**
   * Org info from cookie
   */
  orgInfo: PropTypes.object,
  /**
   * Features info from cookie
   */
  featuresInfo: PropTypes.object,
};

export default connect(
  mapStateToProps(['userInfo', 'orgInfo', 'featuresInfo']),
  null,
  null,
  { pure: false },
)(NavbarHeader);
