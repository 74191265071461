/* eslint-disable no-await-in-loop */
import React, { Component } from 'react';
import { Provider, connect } from 'react-redux';
import axios from 'axios';
import PropTypes from 'prop-types';
import { ToastContainer, toast } from 'react-toastify';
import qs from 'qs';
import 'react-toastify/dist/ReactToastify.css';
import './styles.scss';
import _ from 'lodash';
import moment from 'moment-timezone';
import classNames from 'classnames';

import mapStateToProps from '../../mapStateToProps';
import DataExtensions from '../../components_v2/Selection/DataExtensions/DataExtensions/DataExtensions';
import Preview from '../../components_v2/Selection/TargetDefinition/Preview';
import Constants from '../../constants/constants';
import Util from '../../util';
import Features from '../../features';
// eslint-disable-next-line import/no-cycle
import DataExtensionsAPI from '../../api/data-extensions';
import DataViewsAPI from '../../api/data-views';
import SelectionsAPI from '../../api/selections';
import LoadingModal from '../../components_v2/shared/LoadingModal/LoadingModal';
import PickListAPI from '../../api/picklists';
import RelationsAPI from '../../api/relations';
import DataViews from '../../constants/dataViews';
import ScheduleSelectionModal from '../../components_v2/Selection/ScheduleSelectionModal/ScheduleSelectionModal';
import UpdateSelectionModal from '../../components/Selection/UpdateSelectionModal/UpdateSelectionModal';
import selectionUpdatesUtil from '../../utils/selectionUpdates/selectionUpdatesUtil';
import TemplateSettingsModal from '../../components/Selection/TemplateSettingsModal/TemplateSettingsModal';
import Toast from '../../components/shared/Toast/Toast';
import filtersUtil from '../../utils/filters/filtersUtil';
import timeUtil from '../../utils/time/timeUtil';
import SwalUtil from '../../utils/swal/swalUtil';
import SelectionNameModal from '../../components_v2/Selection/SelectionNameModal/SelectionNameModal';
import ActiveUsers from '../../components/Selection/ActiveUsers/ActiveUsers';
import LoadingText from '../../components/shared/LoadingText/LoadingText';
import DataSetsAPI from '../../api/data-sets';
import FilterSetsAPI from '../../api/filter-sets';
import GlobalCustomValuesAPI from '../../api/global-custom-values';
import SelectionUtil from './selectionUtil';
import SettingsAPI from '../../api/settings';
import store from '../../redux/store/store';
import FeatureAdvertContainer from '../../components/AdminPanel/FeatureAdvertContainer/FeatureAdvertContainer';
import BehavioralFilterSets from '../../constants/behavioralFilterSets';
import Banner from '../../components/Selection/Banner/Banner';
import DeedeeAIAPI from '../../api/deedeeAI';
// eslint-disable-next-line import/no-cycle
import DataExtensionsMultipleTabsv2 from '../../components_v2/DataExtensionsMultipleTabs/DataExtensionsMultipleTabs';
import TargetDefinition from '../../components_v2/Selection/TargetDefinition/TargetDefinition/TargetDefinition';
import SelectionNavbar from '../../components_v2/NewNavbar/SelectionNavBar/SelectionNavBar';

class NewSelection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // Change "collections" -> data extension
      selectionName: '', // Selection name of the selection
      selectionTemplate: null,
      selectionNavigator: Constants.NAVIGATION__SELECTION_CRITERIA,
      previewDedupNumberOfRecords: null, // Deduplicated number of records
      dataExtensions: [], // Available data extensions
      loadedDataExtensions: [],
      targetDataExtensions: [], // It stores target data extensions
      selectedDataExtensions: [], // Selected Data extensions will be kept in this state
      selectedFilters: {}, // Object with filters and operator property
      showRelationalModal: false, // Relational modal state
      modalDataExtensions: {}, // Stores the relation
      relations: [], // If user clicks save button in the relational modal, all the info in the modalDataExtensions
      // State pushes into this and modalDataExtensions state turns to empty array
      fromFieldMissing: false,
      toFieldMissing: false,
      currentSelectionId: props.currentSelectionId || '',
      targetDataExtensionFields: [], // Saves fields of the picked target data extension into this state.
      prevMatchedFields: [], // Stores the current targetDE`s matched fields during creation of a new de
      matchedFields: [], // Stores matched fields in the Target Definition Page.
      targetDataExtensionCustomerKey: '',
      targetDataExtensionName: '',
      targetCollectionObjectID: '',
      targetDataExtension: {},
      numberOfResults: null,
      previewStatus: null,
      previewCountTaskStatus: null,
      previewData: [],
      previewError: null,
      fieldsMetaData: [],
      lastRanDate: '',
      previewQueryActivityId: '',
      previewDataExtensionObjectID: '',
      previewDeduplicationDEObjectID: '',
      previewDataExtensionCustomerKey: '',
      isSelectionRequestDone: false,
      isDataExtensionRequestDone: false,
      isFieldsRequestDone: false,
      isTargetExtensionRequestDone: false,
      loadExistingSelection: false,
      basicModeStatus: false,
      error: null,
      unionType: Constants.SELECTION__UNION_TYPE__UNION,
      tabName: props.tabName || null,
      selectionType: String,
      newTargetDataExtension: {
        name: '',
        description: '',
        folderId: null,
        contentType: '',
        dataRetentionPolicy: {
          dataRetentionPolicy: false,
          individualRecords: false,
          allRecordsAndDE: false,
          allRecords: false,
          periodAfter: false,
          periodOn: false,
          resetRetentionPeriodOnImport: false,
          dataRetentionPeriod: Constants.DATA_RETENTION_POLICY__PERIOD__DAYS,
          dataRetentionPeriodLength: 1,
          dataExtensionPeriodDate: '',
          toggle: false,
        },
        relationship: {
          isSendable: false,
          isTestable: false,
          sendableDataExtensionField: {
            name: '',
            type: '',
          },
          sendableSubscriberField: {
            name: '',
          },
        },
      },
      prioDeduplication: {
        multipleSortingOptionLines: [],
        mode: Constants.PRIO_DEDUP__MODE__BASIC__VALUE,
        type: Constants.PRIO_DEDUP__SORTING_PRIO__DEFINE_VALUES__VALUE,
        deduplicationFieldObjectID: '',
        criteriaFieldObjectID: '',
        priorities: [],
        sortOrder: Constants.PRIO_DEDUP__SORT_ORDER__ASC,
      },
      newTargetDataExtensionFields: [],
      createNewAutoTargetDE: false,
      editNewAutoTargetDE: false,
      subscriberFieldNames: [],
      // Boolean state for going in or out the edit target de mode
      editTargetDataExtension: false,
      // data with edited target DE
      editedTargetDataExtension: {},
      // Keeps target de fields before entering the edit target de mode
      prevTargetDEFields: [],
      showSaveToast: false,
      dataAction: Constants.DATA_ACTION__OVERWRITE,
      showDataActionModal: false,
      showSortLimitModal: false,
      showAddValuesModal: false,
      showSourceLimitingModal: false,
      customValues: [],
      // * Dropzone variables * //
      DEBorderMouseOver: false,
      filterBorderMouseOver: false,
      dynamicCustomValuesFilters: {},
      editCustomValueIndex: '',
      switchToDedup: false,
      usePrioDeduplication: false,
      openSettings: false,
      loaderSelectedDE: false, // Loader on Selected Extension
      disablePreviewBTN: false,
      previousAdvancedDeduplicationRules: [],
      advancedDeduplicationRules: [],
      advancedDedupFilterSaveIndex: null,
      pickListFieldObjectIDs: [],
      pickLists: [],
      deletedMappedFieldsFromSFMC: [],
      containsCustomValues: false,
      containsCustomValuesIndex: [],
      isFiltersUpdatedDone: false,
      loadingSubQueryFields: {},
      sortLimit: {
        enabled: false,
        select: {
          type: Constants.SORT_LIMIT__SELECT_TYPE__NUMBER,
          value: 0,
        },
        orderBy: {
          type: Constants.SORT_LIMIT__ORDER_BY_TYPE__FIELD,
          fieldObjectID: '',
          sort: '',
        },
      },
      runPreviewQueryActivityStarted: false,
      previewDataRetrieved: false,
      loadingBarIcon: true,
      previewTaskCompleted: false,
      switchedToOtherTab: false,
      previewDeduplicationTaskStatus: null,
      showScheduleSelectionModal: false,
      scheduledRun: {
        enabled: false,
        mode: Constants.SCHEDULE_SELECTION__MODE__ONCE,
        runOnce: {
          dateValue: null,
          timeValue: null,
        },
        runRepeat: {
          repeatMode: null,
          minutesValue: null,
          hoursValue: null,
          daysOfWeek: [],
          daysValue: null,
          nextScheduledRunDate: null,
          monthsValue: null,
        },
        timezone: null,
        UTCOffset: null,
      },
      showAggregationModal: false,
      aggregationFilters: {},
      loadingForDataExtensions: false,
      loadingAllAvailableDataExtensions: false,
      allAvailableDataExtensions: [],
      loadingForTargetDataExtensions: false,
      predefinedRelations: [],
      predefinedRelationsMap: {},
      subQueryDataExtensions: [],
      showUpdateSelectionModal: false,
      previewTaskQuery: '',
      runStatus: null,
      taskCompletedDate: null,
      captureSelectionChange: false,
      selectionUpdates: [],
      autoFixingSelection: false,
      isAutofixSuccessful: false,
      availableDEsFolderId: null,
      showTemplateSettingsModal: false,
      isTemplate: false,
      isArchived: false,
      copiedFromTemplate: false,
      selectionDescription: '',
      templateDescription: '',
      templateInstructions: '',
      selectionCreator: '',
      scheduledWaterfallSelections: [],
      isSelectionNewlyCreated: true,
      availableDEsFolders: [],
      foldersSettings: {},
      movingDE: false,
      prevSelectedDEs: [],
      prevRelations: [],
      selectedDEsTree: {},
      applyTimezoneSettingsToAllDateFields: false,
      timezoneSettingsForAllDateFields: {
        convertTimezone: false,
        convertToTimezone: '',
        convertFromTimezone: Constants.TIME_ZONE__CENTRAL_STANDARD_TIME__VALUE,
      },
      targetDEsFolderId: null,
      targetDEsFolders: [],
      clickedSave: false,
      scheduleDisabled: false,
      hasScheduleBeenEnabled: false,
      dataSets: [],
      filterSets: [],
      defaultSendRelationshipField: null,
      parentDEOfDataSet: '',
      selectionMode: null,
      basicModeSelectionSourceType: null,
      selectionsSchedules: [],
      selectedDataSet: null,
      switchFromAdvancedToBasic: false,
      globalCustomValues: [],
      previewTaskId: null,
      dataExtensionSearchField: '',
      timer: null,
      showBanner: false,
      featureAdvertModal: {
        show: false,
        feature: '',
      },
      sourceLimitingEnabled: false,
      unionSourceLimitData: this.extractSourceLimitData(props.unionSelections),
      isSelectedDEChanged: false,
      isSelectedFieldsTriggeredCustomValue: false,
      isSelectedFieldsCustomValuesModalSaved: false,
      isCustomValueModalTriggeredInEditMode: false,
      isFilterBoxExpanded: false,
      isFilterBoxExpandedForFirstTime: false,
      showFiltersImmediately: false,
    };

    this.selectedDERef = React.createRef();
    this.tabsScrollPositionOnClick = 0;
    this.sourceTabsHeight = '150px';
    this.axiosCancelToken = axios.CancelToken.source();
  }

  // deepcode ignore ReactIncorrectReturnValue: <please specify a reason of ignoring this>
  async componentDidMount() {
    const {
      handleGlobalNavigator,
      handleSetAppState,
      currentSelectionId,
      featuresInfo,
    } = this.props;

    // eslint-disable-next-line max-len
    const featureAppendDataExtensionsIsEnabled = Features.isFeatureEnabled(featuresInfo, Constants.FEATURE__APPEND_DATA_EXTENSIONS);
    const featureBasicMode = Features.isFeatureEnabled(featuresInfo, Constants.SELECTION_MODE__BASIC);
    const featureGlobalCustomValues = Features.isFeatureEnabled(featuresInfo, Constants.FEATURE__GLOBAL_CUSTOM_VALUES);
    const featureDeedeeAI = Features.isFeatureEnabled(featuresInfo, Constants.FEATURE__DEEDEE_AI);

    handleGlobalNavigator(Constants.NAVIGATION__SELECTION_CRITERIA);
    handleSetAppState({ isSelectionMounted: true });
    this.setIsComponentMounted(true);

    // clear any existing timers to avoid multiple timers running at the same time
    if (this.deedeeAIFeedbackModalTimer) {
      clearTimeout(this.deedeeAIFeedbackModalTimer);
    }
    // sets states of preview page to their initial values
    const setPreviewStates = () => {
      this.setState({
        numberOfResults: null,
        previewCountTaskStatus: null,
        previewDataExtensionCustomerKey: '',
        previewDedupNumberOfRecords: null,
        previewError: null,
        previewQueryActivityId: '',
        previewStatus: null,
      });
    };

    /**
     * while selection loading, set isMounting true
     * to work as expected
     */
    this.isMounting = true;

    // If target de is removed/changed we won't load preview data
    let checkTargetDataExtensionIsValid = true;

    // Load details of current selection
    if (currentSelectionId) {
      let selection = {};

      let scheduledWaterfallSelections = [];

      // response for all data extensions used in Selection
      let selectedDataExtensionsWithTargetDE;

      this.setState({
        loadExistingSelection: true,
        loadingForDataExtensions: true,
        loadingForTargetDataExtensions: true,
      });

      // Get selection
      try {
        selection = await SelectionsAPI.getSelection(currentSelectionId, this.axiosCancelToken.token);
        scheduledWaterfallSelections = await SelectionsAPI.getScheduledWaterfallSelections(
          currentSelectionId,
          this.axiosCancelToken.token,
        );

        if (selection) {
          let runAgain = true;

          let currentRun = 0;
          const maxRuns = 2;

          while (runAgain && currentRun < maxRuns) {
            runAgain = false;
            currentRun += 1;
            // get filter sets and update filter sets sate
            const filterSets = await FilterSetsAPI.getFilterSets(this.axiosCancelToken.token);
            // add behavioral filtersets

            const newBehavioralFilterSets = BehavioralFilterSets;

            newBehavioralFilterSets.map((filter) => {
              return filterSets.push(filter);
            });

            // get Send relationship settings
            const defaultSendRelationshipField = await SettingsAPI.getSendRelationshipSettings(
              this.axiosCancelToken.token,
            );

            // Update state
            this.setState({
              filterSets: filterSets || [],
              defaultSendRelationshipField,
              isArchived: !!selection?.isArchived,
              previewTaskId: selection?.previewTaskId,
            });

            // Check if feature is enabled
            if (featureGlobalCustomValues) {
              // Fetch BU's shared custom values
              const globalCustomValues = await GlobalCustomValuesAPI.getGlobalCustomValues(this.axiosCancelToken.token);

              // Create correct format and update in state
              this.setState({
                globalCustomValues: globalCustomValues?.map(globalCustomValue => ({
                  ...globalCustomValue.value,
                  name: globalCustomValue.name,
                  valueType: globalCustomValue.type,
                  description: globalCustomValue.description,
                  selectedDataExtensions: globalCustomValue.selectedDataExtensions,
                  isGlobal: true,
                  _id: globalCustomValue._id,
                })) || [],
              });
            }

            // array for all data extensions (with targetDE)
            const allDataExtensions = [];

            // array for available data extensions (without Target DE)
            const dataExtensions = [];

            // add data extensions used in collections into arrays
            if (selection.unionSelections && selection.unionSelections.length) {
              selection.unionSelections.map(us => us.collections && us.collections.length &&
                us.collections.map(col => allDataExtensions.push(col) && dataExtensions.push(col)));
            }

            if (selection.unionSelections && selection.unionSelections.length) {
              // it's for unionSelections with subquery filters
              selection.unionSelections.forEach((us) => {
                // define selectedFilters
                const selectedFilters = us.filters && us.filters[0] ?
                  us.filters[0] :
                  { filters: [], operator: Constants.FILTERLINE__OPERATOR__AND };

                // define filtersArray
                const filtersArray = selectedFilters.filters || [];

                // get the object with Data Extension from filters array
                this.getSubQueryCollection(filtersArray, allDataExtensions, dataExtensions);
              });
            }

            // now push targetCollectionCustomerKey into the allDataExtensions array
            if (selection.targetCollectionCustomerKey) {
              allDataExtensions.push({
                collectionCustomerKey: selection.targetCollectionCustomerKey.toString(),
                collectionObjectID: selection.targetCollectionObjectID,
                type: Constants.DESELECT__DATA_EXTENSION__TYPE__TARGET,
              });
            }

            // prepare state for selection to get custom values
            let stateForSelections;

            if (
              (selection.type === Constants.SELECTION__TYPE__UNION ||
                selection.unionType === Constants.SELECTION__TYPE__UNION) &&
              selection.unionSelections && selection.unionSelections.length
            ) {
              const { globalCustomValues } = this.state;
              const unionSelectionsState = [];

              // Convert the value stored in a selection into the required states to get custom values
              const stateForSelectionPromises = selection.unionSelections.map(unionSelection => (
                // load state for custom values
                this.loadStateForSelection(
                  [],
                  [],
                  [],
                  [],
                  unionSelection.customValues || [],
                  true,
                  null,
                  globalCustomValues,
                  unionSelection.sourceLimit || null,
                )
              ));

              try {
                // wait until all promises are resolved
                stateForSelections = await Promise.all(stateForSelectionPromises);

                let stateForSelectionNotFound = false;

                if (stateForSelections && stateForSelections.length) {
                  // eslint-disable-next-line no-loop-func
                  stateForSelections.forEach((stateForSelection, idx) => {
                    // check if there is a selection for the given index
                    const unionSelection = selection.unionSelections[idx];

                    if (stateForSelection) {
                      // return selection state for each tab
                      // eslint-disable-next-line no-param-reassign
                      stateForSelection.tabName = unionSelection.tabName;
                      unionSelectionsState.push(stateForSelection);
                    } else {
                      stateForSelectionNotFound = true;
                    }
                  });
                }

                // StateForSelection could be null if a selected DE is not found
                if (stateForSelectionNotFound) return;
              } catch (error) {
                if (!axios.isCancel(error)) this.setState({ error });
              }
            } else {
              // otherwise load state for a single selection
              stateForSelections = await this.loadStateForSelection([], [], [], [], selection.customValues || [], true);
            }

            /*
             * Search for all DEs used in aggregation custom values
             * Check in all tabs
             */
            if (stateForSelections && stateForSelections.length) {
              stateForSelections.forEach((unionSelection) => {
                const { customValues = [] } = unionSelection;

                // get all Data Extensions used in aggregation custom values
                for (let idx = customValues.length - 1; idx >= 0; idx -= 1) {
                  const customValue = customValues[idx];

                  /*
                   * we are looking for aggregation because there is an option to choose a different DE
                   * than in selection criteria and target DE
                   */
                  if (customValue.valueType === Constants.CUSTOM_VALUES__FIELD_TYPE__AGGREGATION_VALUE) {
                    // destructuring of needed properties
                    const { aggregationValue } = customValue;
                    const { relations } = aggregationValue;
                    const { fromCollectionCustomerKey, fromCollectionObjectId } = relations;

                    /*
                     * push data extension property (whose field is used in aggregation) into two arrays.
                     * this is useful later in case selectedDE is separated from targetDE
                     * if fromCollectionObjectId not exists return fromCollectionCustomerKey -> case for data view
                     */
                    allDataExtensions.push({
                      collectionCustomerKey: fromCollectionCustomerKey.toString(),
                      collectionObjectID: fromCollectionObjectId || fromCollectionCustomerKey.toString(),
                    });

                    dataExtensions.push({
                      collectionCustomerKey: fromCollectionCustomerKey.toString(),
                      collectionObjectID: fromCollectionObjectId || fromCollectionCustomerKey.toString(),
                    });
                  }
                }
              });
            }

            // remove duplicate data extensions from allDataExtensions array
            const dataExtensionsWithoutDuplicate = allDataExtensions.filter((v, i, a) => a.findIndex(
              t => (t?.collectionCustomerKey?.toString() === v?.collectionCustomerKey?.toString()),
            ) === i);

            // define CustomerKey and ObjectID for Target DE
            const targetDEObjectID = selection.targetCollectionObjectID;
            const targetDECustomerKey = selection.targetCollectionCustomerKey;

            // If preview target DE exists, get preview Data Extension and fieldsMetaData
            if (selection.previewDataExtensionCustomerKey) {
              this.getPreviewDataExtensionCustomerKey(selection);
            }

            // variable for Data Extensions used in Selection (without TargetDE)
            let filteredDE = [];

            // variable for target Data Extension
            let targetDataExtension;

            /**
             * Function returns new Promise after fetching all selected data extensions with targetDE
             * @param {array} dataExtensionsForSelection - data extensions used in selection and needed to open it
             * @returns {Promise<Void>} void
             */
            const fetchDataExtensions = async dataExtensionsForSelection => new Promise((resolve) => {
              // get all selected data extensions with target DE
              resolve(DataExtensionsAPI.getDataExtensionsAndFieldsByCustomerKeys(
                this.axiosCancelToken.token,
                dataExtensionsForSelection,
                null,
              ));
            });

            /**
             * Function returns new Promise after retrieving all predefined relations
             * @returns {Promise<Void>} void
             */
            const getAllRelations = () => (new Promise((resolve) => {
              resolve(this.getRelations());
            }));

            /*
             * call functions asynchronously
             * get predefined relations and selected data extensions needed to open a selection
             */
            try {
              const fetchedData = await Promise.all([fetchDataExtensions(dataExtensionsWithoutDuplicate),
                getAllRelations()]);

              // get fetched data extensions
              const fetchedDataExtensions = fetchedData[0];

              // assign results from fetching data extensions to declared variable
              selectedDataExtensionsWithTargetDE = fetchedDataExtensions;
            } catch (error) {
              if (!axios.isCancel(error)) this.setState({ error });
            }

            // start loading available Data Extensions and Target Data Extensions - queries are meant to be asynchronous
            this.loadTargetDataExtensions();

            this.loadDataExtensions(currentSelectionId);

            // if response contains data extensions
            if (selectedDataExtensionsWithTargetDE && selectedDataExtensionsWithTargetDE.length) {
              // if selection has target Data Extension
              if (targetDEObjectID) {
                // get targetDE from the received data extensions
                const getTargetDE = selectedDataExtensionsWithTargetDE.filter(de => de.ObjectID?.toString() ===
                  targetDEObjectID?.toString() || de.CustomerKey?.toString() === targetDECustomerKey?.toString());

                targetDataExtension = getTargetDE;

                // validate Target DE, if it exists
                if (targetDataExtension[0]) {
                  this.validateTargetDataExtension(selection, targetDataExtension[0]);
                }
              } else {
                // in another case targetDataExtension does not exist
                targetDataExtension = [];
              }

              // filter Data Extensions and return only available DE, without target DE
              filteredDE = selectedDataExtensionsWithTargetDE.filter((de) => {
                const findObjectIDsForDE = dataExtensions.find(na => na.collectionObjectID?.toString() ===
                  de.ObjectID?.toString() || na.collectionCustomerKey?.toString() === de.CustomerKey?.toString());

                if (findObjectIDsForDE?.collectionObjectID) {
                  return de;
                }

                return false;
              });

              // Set dragged state for DEs
              if (filteredDE && filteredDE.length) {
                for (let i = 0; i < filteredDE.length; i += 1) {
                  filteredDE[i].dragged = false;
                }
              }

              // get picklists for all fields used in selection
              const allFieldsObjectIds = [];

              filteredDE.forEach(de => de?.fields && allFieldsObjectIds.push(de.fields));

              await this.handlePickListOptions(allFieldsObjectIds.flat(), true);
            }

            const { subQueryDataExtensions } = this.state;

            // define data extensions used in filters (for inResults and relation filters)
            const dataExtensionsForSubQuery = filteredDE.filter((de) => {
              const findFromSubQueryDE = subQueryDataExtensions.find(subQueryDE => subQueryDE
                .collectionObjectID.toString() ===
                de.ObjectID.toString() || subQueryDE.collectionCustomerKey.toString() === de.CustomerKey.toString());

              if (findFromSubQueryDE) {
                return de;
              }

              return false;
            });

            /*
             * before we set state for selection, check if ObjectID has changed in any of the
             * selected or target data extension
             */
            const dataExtensionsWithChangedObjectIds = selectedDataExtensionsWithTargetDE.reduce((
              missingDEs,
              selectedDE,
            ) => {
              // get DE by Customer Key
              const getDEByCustomerKey = allDataExtensions.find(de => (
                de.collectionCustomerKey?.toString() === selectedDE.CustomerKey?.toString()));

              // get DE by Object ID
              const getDEByObjectID = allDataExtensions.find(de => (
                de.collectionObjectID?.toString() === selectedDE.ObjectID?.toString()));

              // if Customer Key is the same and Object ID has changed, then put this DE into the array
              if (getDEByCustomerKey?.collectionCustomerKey && !getDEByObjectID?.collectionObjectID) {
                return [...missingDEs, getDEByCustomerKey];
              }

              return missingDEs;
            }, []);

            // if ObjectID for selectedDE has changed, let's start auto-fixing selection
            if (dataExtensionsWithChangedObjectIds?.length ||
              dataExtensionsWithoutDuplicate?.length !== selectedDataExtensionsWithTargetDE?.length) {
              this.setState({ autoFixingSelection: true });

              try {
                // fetch auto-fixed selection with updated data
                // eslint-disable-next-line require-atomic-updates
                selection = await SelectionsAPI.autoFixSelection(currentSelectionId, this.axiosCancelToken.token);

                this.setState({ isAutofixSuccessful: true });
                runAgain = true;
              } catch (error) {
                this.setState({ autoFixingSelection: false });

                await SwalUtil.fire({
                  type: Constants.SWAL__TYPE__ERROR,
                  // eslint-disable-next-line max-len
                  messageHTML: `${error?.message} <br> ${error?.actualError}`,
                });
              }
            }

            // set data extensions with fields
            this.setState({ subQueryDataExtensions: dataExtensionsForSubQuery });

            // based on the fetched data, get the targetDE
            // eslint-disable-next-line no-loop-func
            const getCurrentTargetDE = targetDataExtension?.find(tde => tde.ObjectID?.toString() ===
              selection.targetCollectionObjectID?.toString() ||
              tde.CustomerKey?.toString() === selection.targetCollectionCustomerKey?.toString());

            /*
             * if selection exists, assign fetched data extensions, target data extension,
             * get the previewStatus state from selection and assign it to previewStatus
             * get the runStatus from selection and assign it to runStatus
             */
            this.setState({
              isSelectionRequestDone: true,
              previewStatus: selection.previewStatus,
              previewDeduplicationTaskStatus: selection.previewDeduplicationTaskStatus,
              dataExtensions: filteredDE,
              isDataExtensionRequestDone: true,
              targetDataExtensions: targetDataExtension,
              previewTaskQuery: selection.previewTaskQuery,
              runStatus: selection?.runStatus || null,
              taskCompletedDate: selection?.taskCompletedDate || null,
              targetDataExtensionCustomerKey: getCurrentTargetDE?.CustomerKey || '',
              targetCollectionObjectID: getCurrentTargetDE?.ObjectID || '',
              // save target DE data without fields
              targetDataExtension: _.omit(getCurrentTargetDE, 'fields') || {},
            });

            // if current selection is running
            if (selection?.runStatus && selection.runStatus !== Constants.STATUS_COMPLETE &&
              selection.runStatus !== Constants.STATUS_ERROR) {
              // get current selection state every 3 second to track runStatus
              this.interval = setInterval(async () => {
                try {
                  const getCurrentSelection = await SelectionsAPI.getSelection(
                    currentSelectionId,
                    this.axiosCancelToken.token,
                  );

                  // if runStatus for current selection is complete or there is an error
                  if (getCurrentSelection?.runStatus === Constants.STATUS_COMPLETE ||
                    getCurrentSelection?.runStatus === Constants.STATUS_ERROR) {
                    // change the state and clear interval
                    this.setState({
                      runStatus: getCurrentSelection.runStatus,
                      taskCompletedDate: getCurrentSelection.taskCompletedDate,
                    });

                    clearInterval(this.interval);
                  }
                } catch (error) {
                  this.setState({ error });
                }
              }, 6000);
            }
          }
        } else {
          // if selection does not exist, display the message
          const result = await SwalUtil.fire({
            type: Constants.SWAL__TYPE__ERROR,
            message: 'Something went wrong when trying to get this selection. It does not exists anymore.',
            options: {
              confirmButtonText: 'OK',
              allowOutsideClick: false,
            },
          });

          // after pressing the confirm button, go to the Overview or WF
          if (result.value) this.handleNavigateBack();

          return;
        }
      } catch (error) {
        if (!axios.isCancel(error)) this.setState({ error });

        return;
      }

      // Check if user has the right permissions to load this selection
      if (selection.type === Constants.SELECTION__TYPE__UNION && !featureAppendDataExtensionsIsEnabled) {
        await this.handleFeatureMissing(Constants.FEATURE__APPEND_DATA_EXTENSIONS_LABEL);

        return;
      }

      // Get Target Data Extension Fields
      let targetDataExtensionFields = [];

      let targetDataExtension;

      let unionSelectionsTargetDE = [];

      const { targetDataExtensions, scheduledRun, timezoneSettingsForAllDateFields } = this.state;

      try {
        // Check if Target DE still exists
        if (selection.targetCollectionObjectID) {
          // check if targetDataExtension is also selected in the Selection Criteria
          if (selection.unionSelections && selection.unionSelections.length) {
            const unionSelectionsWithTargetDE = selection.unionSelections.map(unionSelection => (
              unionSelection.collections && unionSelection.collections.length &&
              unionSelection.collections.filter(us => us?.collectionObjectID?.toString() ===
                selection?.targetCollectionObjectID?.toString())
            ));

            if (unionSelectionsWithTargetDE && unionSelectionsWithTargetDE.length) {
              // check if it found any object with target DE
              unionSelectionsTargetDE = unionSelectionsWithTargetDE.filter(fc => fc && fc.length > 0);
            }
          }

          // variable for checking if TargetDE exists
          let findTargetDataExtension;

          if (targetDataExtensions && targetDataExtensions.length) {
            // check if targetDataExtension exists in available Target DEs
            findTargetDataExtension = targetDataExtensions.find(tde => tde.ObjectID.toString() ===
              selection.targetCollectionObjectID.toString());
          }

          /**
           * if targetDataExtensionResponse is not null, that means the target DE exists.
           */
          if (findTargetDataExtension) {
            targetDataExtension = findTargetDataExtension;
          } else if ((!unionSelectionsTargetDE.length || !unionSelectionsTargetDE) && this.isMounting) {
            /**
             * check if targetDataExtension is selected in unionSelections collections
             * if so, the message will show that the selected Data Extension does not exist
             * (line no 2091 func. handleDataExtensionMissing)
             * if not, display message that targetDE does not exist and set targetDataExtensionCustomerKey
             * and matchedFields to be empty
             * if user press x while loading selection, don't show error message
             */
            checkTargetDataExtensionIsValid = false;
            this.setState({
              matchedFields: [],
              targetDataExtensionCustomerKey: '',
              targetCollectionObjectID: '',
            });

            // redirect the user to target DE
            this.handleSelectionNavigator(Constants.NAVIGATION__TARGET_DEFINITION);

            // if the target DataExtension is not found, show a swal with the message

            await SwalUtil.fire({
              type: Constants.SWAL__TYPE__ERROR,
              // eslint-disable-next-line max-len
              message: 'This target Data Extension is not available anymore. Please choose another target Data Extension.',
            });
          }

          // if targetDataExtension and its fields exist, then define this fields
          if (targetDataExtension && targetDataExtension.fields) {
            const targetDEFields = targetDataExtension.fields;

            // check if there are fields in targetDE
            if (targetDEFields && targetDEFields.length) {
              targetDataExtensionFields = targetDEFields;
            }
          }
        }
        this.setState({ isTargetExtensionRequestDone: true });
      } catch (error) {
        if (!axios.isCancel(error)) this.setState({ error });
      }
      const { isSelectionRequestDone } = this.state;

      // If DeedeeAI feature is enabled, check if selection has DeedeeAI request
      if (featureDeedeeAI && isSelectionRequestDone && selection?.createdByDeedeeAI) {
        // Check if selection has DeedeeAI request
        const deedeeAIRequest = await DeedeeAIAPI.getDeedeeAIRequest(
          { selectionId: currentSelectionId },
          this.axiosCancelToken.token,
        );

        // The check is updated to verify if deedeeAIRequest and deedeeAIRequest.data exist and is an object
        if (deedeeAIRequest && deedeeAIRequest.data &&
          typeof deedeeAIRequest.data === 'object' &&
          !(deedeeAIRequest.data instanceof Array)) {
          const openAIResponse = JSON.parse(deedeeAIRequest.data.chatGPTresponse.choices[0].message.content);
          const selectionDeedeeAIRequest = {
            openAIDescription: openAIResponse?.info,
            openAISQLQuery: openAIResponse?.query,
            processingLog: deedeeAIRequest.data.processingLog,
            selectionId: deedeeAIRequest.data.selectionId,
            userPrompt: deedeeAIRequest.data.userPrompt,
            feedback: deedeeAIRequest.data.feedback,
            deedeeAIRequestId: deedeeAIRequest.data._id,
            status: true,
          };

          this.deedeeAIFeedbackModalTimer = setTimeout(() => {
            handleSetAppState({
              deedeeAIRequestFeedback: selectionDeedeeAIRequest,
              showDeedeeAIFeedbackModal: !selectionDeedeeAIRequest?.feedback?.rating,
            });
          }, 1000);
        }
      }

      // Set states for selection data we retrieved
      this.setState({
        selectionName: selection.name,
        selectionType: selection.type,
        targetDataExtensionFields: targetDataExtensionFields || [],
        unionType: selection.unionType,
        // Retrieve dataAction from selection
        dataAction: selection.dataAction,
        prioDeduplication: selection.prioDeduplication,
        usePrioDeduplication: selection.usePrioDeduplication,
        advancedDeduplicationRules: selection.prioDeduplication.advancedDeduplicationRules || [],
        sortLimit: selection.sortLimit,
        sourceLimitingEnabled: selection.sourceLimitingEnabled,
        scheduledRun: selection.scheduledRun ? selection.scheduledRun : scheduledRun,
        hasScheduleBeenEnabled: selection?.scheduledRun?.enabled || false,
        isTemplate: selection.isTemplate || false,
        copiedFromTemplate: selection.copiedFromTemplate || false,
        selectionDescription: selection.selectionDescription || '',
        templateDescription: selection.templateDescription || '',
        templateInstructions: selection.templateInstructions || '',
        selectionCreator: selection.createdById || '',
        scheduledWaterfallSelections,
        isSelectionNewlyCreated: false,
        applyTimezoneSettingsToAllDateFields: selection.applyTimezoneSettingsToAllDateFields || false,
        timezoneSettingsForAllDateFields: selection.timezoneSettingsForAllDateFields ?
          selection.timezoneSettingsForAllDateFields :
          timezoneSettingsForAllDateFields,
        selectionMode: featureBasicMode ?
          selection.selectionMode || Constants.SELECTION_MODE__ADVANCED :
          null,
        basicModeSelectionSourceType: selection.basicModeSelectionSourceType,
      });

      const { selectionType, globalCustomValues } = this.state;

      if (
        selectionType === Constants.SELECTION__TYPE__UNION &&
        selection.unionSelections && selection.unionSelections.length
      ) {
        const unionSelectionsState = [];

        // Convert the value stored in a selection into the required states
        const stateForSelectionPromises = selection.unionSelections.map(unionSelection => (
          this.loadStateForSelection(
            unionSelection.relations,
            unionSelection.collections,
            targetDataExtensionFields ? unionSelection.fields : [],
            // If target de is not valid, matched fields should be empty
            unionSelection.filters,
            unionSelection.customValues || [],
            null,
            unionSelection.selectedDataSet,
            globalCustomValues,
            unionSelection.sourceLimit || null,
            selectedDataExtensionsWithTargetDE,
          )
        ));

        try {
          const stateForSelections = await Promise.all(stateForSelectionPromises);

          let stateForSelectionNotFound = false;

          stateForSelections.forEach((stateForSelection, idx) => {
            const unionSelection = selection.unionSelections[idx];

            if (stateForSelection) {
              // eslint-disable-next-line no-param-reassign
              stateForSelection.tabName = unionSelection.tabName;
              unionSelectionsState.push(stateForSelection);
            } else {
              stateForSelectionNotFound = true;
            }
          });

          // StateForSelection could be null if a selected DE is not found
          if (stateForSelectionNotFound) return;
        } catch (error) {
          if (!axios.isCancel(error)) this.setState({ error });
        }
        handleSetAppState({ unionSelections: unionSelectionsState, origin: 'selection' });
      } else {
        const newState = await this.loadStateForSelection(
          selection.relations,
          selection.collections,
          targetDataExtensionFields ? selection.fields : [],
          // If target de is not valid, matched fields should be empty
          selection.filters,
          selection.customValues || [],
          selectedDataExtensionsWithTargetDE,
        );

        this.setState(newState);
      }
      await this.afterLoadChecks();

      this.setState({
        isFieldsRequestDone: true,
      });

      // If target data extension is not invalid
      if (checkTargetDataExtensionIsValid) {
        /**
         * This.loadPreview(previewQueryActivityId, previewDataExtensionCustomerKey);
         * Set initial values of preview states
         */
      } else {
        setPreviewStates();
      }
    } else {
      // We are not loading an existing selection
      const { handleAddElementToAppArrayState } = this.props;

      if (featureAppendDataExtensionsIsEnabled) {
        // Create one new tab
        const newSelection = new NewSelection({ tabName: 'New Source 1' });

        handleAddElementToAppArrayState('unionSelections', newSelection.state);
        this.setState({ selectionType: Constants.SELECTION__TYPE__UNION });
      } else {
        this.setState({ selectionType: Constants.SELECTION__TYPE__SIMPLE });
      }

      // get available Data Extensions and Target Data Extensions
      this.loadDataExtensions(currentSelectionId);

      this.loadTargetDataExtensions();

      this.getRelations();
    }

    const {
      selectedDataExtensions,
      selectedFilters,
      advancedDeduplicationRules,
      prioDeduplication,
      customValues,
      filterSets,
    } = this.state;

    // Fetch filterSet picklist for existing selected data extensions
    this.fetchFilterSetsPicklistOptions(filterSets, selectedDataExtensions);

    // Update Filters

    // Main filters
    filtersUtil.updateFiltersFieldName(selectedDataExtensions, selectedFilters);

    // Filters for Advanced Deduplication
    advancedDeduplicationRules.forEach(rule => filtersUtil.updateFiltersFieldName(selectedDataExtensions, rule));

    if (prioDeduplication.advancedDeduplicationRules !== undefined) {
      prioDeduplication.advancedDeduplicationRules.map(
        rule => filtersUtil.updateFiltersFieldName(selectedDataExtensions, rule),
      );
    }

    // Filters for Custom Values
    customValues.forEach((value) => {
      if ('criteria' in value) {
        value.criteria.forEach((criteria) => {
          filtersUtil.updateFiltersFieldName(selectedDataExtensions, criteria.when);
        });
      }
    });

    this.setState({ isFiltersUpdatedDone: true });

    document.addEventListener('click', this.handleCloseSettingDiv);

    /*
     * clone the initial state after componentDidMount
     * to capture changes when clicking cancel
     */
    this.cloneSelectionState();

    const { autoFixingSelection, targetDataExtensionCustomerKey } = this.state;

    // if selection was auto-fixed
    if (autoFixingSelection && targetDataExtensionCustomerKey) {
      this.setState({
        autoFixingSelection: false,
        captureSelectionChange: true,
      });

      // show toast after while for better visual perception
      toast.success(
        <Toast notificationText={Constants.NOTIFICATION__TEXT__AUTOFIXED} />,
        {
          position: toast.POSITION.TOP_RIGHT,
          className: 'toast-autofix',
          toastId: Constants.NOTIFICATION__TOAST_ID__AUTOFIX,
          autoClose: false,
          containerId: Constants.NOTIFICATION__CONTAINER_ID__SELECTION,
          draggable: false,
          pauseOnHover: false,
          pauseOnFocusLoss: false,
        },
      );
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    const { featuresInfo } = this.props;
    const featureSourceLimiting = Features.isFeatureEnabled(featuresInfo, Constants.FEATURE__SOURCE_LIMITING);
    const { loadingForDataExtensions, dataExtensions } = this.state;

    // Run fields check again after all available DEs are loaded
    if (prevState?.loadingForDataExtensions && !loadingForDataExtensions) {
      await this.checkMissingFieldsInFilters(dataExtensions);
    }

    if (!featureSourceLimiting && prevState?.sourceLimitingEnabled) {
      this.setState({ sourceLimitingEnabled: false });
    }

    const {
      isFiltersUpdatedDone,
      targetDataExtensionCustomerKey,
      scheduleDisabled,
      targetCollectionObjectID,
    } = this.state;

    const { unionSelections } = this.props;

    if (unionSelections?.length && prevState.unionSelections?.length !== unionSelections?.length) {
      if (unionSelections.length > 5) {
        this.sourceTabsHeight = '10rem';
      } else {
        this.sourceTabsHeight = `${unionSelections.length * 2}rem`;
      }
    }

    /*
     * update state in copied object after
     * clicking save button to reset changes
     */
    if (prevState?.showSaveToast) {
      this.copiedSelectionState = JSON.parse(JSON.stringify(this.state));
    }

    if (targetCollectionObjectID && prevState?.targetCollectionObjectID !== targetCollectionObjectID) {
      this.getSelectionSchedules(targetCollectionObjectID);
    }

    // after mounting the component
    if (this.getIsComponentMounted() && isFiltersUpdatedDone && unionSelections?.length) {
      // out of all union selections
      const getInvalidUnion = unionSelections.find((union) => {
        // check if there is at least one union that cannot be run to disable the schedule button
        const validationResult = this.validateIfSelectionCanBeRun(
          union.selectedDataExtensions,
          targetDataExtensionCustomerKey,
          union.matchedFields,
        );

        if (validationResult.error) {
          return union;
        }

        return null;
      });

      /*
       * assign new state for schedule button and turn off schedule selection if it's enabled,
       * leave the data inside and if all requirements are met, restore enabled status to the previous one
       */
      if (getInvalidUnion && !scheduleDisabled || !getInvalidUnion && scheduleDisabled) {
        this.handleChangeScheduleDisabledStatus();
      }
    }

    /**
     * If union selections are changed, update unionSourceLimitData
     * This is used when comparing if state has changed when leaving Selection.
     */
    if (unionSelections !== prevProps?.unionSelections) {
      this.setState({
        unionSourceLimitData: this.extractSourceLimitData(unionSelections),
      });
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.selectionType === Constants.SELECTION__TYPE__UNION) {
      const newState = {};
      const unionSelection = nextProps.unionSelections[nextProps.unionSelectionsIndex];

      if (
        (unionSelection && nextProps.unionSelectionsIndex !== prevState.unionSelectionsIndex) ||
        (unionSelection && unionSelection.tabName !== prevState.tabName)
      ) {
        newState.selectedDataExtensions = unionSelection.selectedDataExtensions;
        newState.unionSelectionsIndex = nextProps.unionSelectionsIndex;
        newState.relations = unionSelection.relations;
        newState.modalDataExtensions = unionSelection.modalDataExtensions;
        newState.selectedFilters = unionSelection.selectedFilters;
        newState.matchedFields = unionSelection.matchedFields;
        newState.tabName = unionSelection.tabName;
        newState.customValues = unionSelection.customValues;
        newState.selectedDataSet = unionSelection.selectedDataSet;
        if (unionSelection && unionSelection.selectionNavigator !== prevState.selectionNavigator) {
          newState.targetDataExtensionFields = prevState.targetDataExtensionFields;
        }

        if (unionSelection?.sourceLimit?.type) {
          newState.sourceLimit = unionSelection.sourceLimit;
        } else {
          newState.sourceLimit = Constants.DEFAULT_SOURCE_LIMIT;
        }

        return newState;
      }

      return null;
    }

    return null;
  }

  componentWillUnmount() {
    const { handleClearCurrentSelection, backToWaterFall } = this.props;

    this.axiosCancelToken.cancel({
      message: 'all async subscriptions have been canceled',
    });
    handleClearCurrentSelection(backToWaterFall?.folderId || '');
    this.setIsComponentMounted(false);
    document.removeEventListener('click', this.handleCloseSettingDiv);

    if (this.deedeeAIFeedbackModalTimer) {
      clearTimeout(this.deedeeAIFeedbackModalTimer);
    }
    // clear interval
    clearInterval(this.interval);

    /**
     * while selection loading, close is clicked isMounting is used
     * to bypass swal alerts as component is unmounted
     */
    this.isMounting = false;
  }

  /**
   *  Hepls memorize the position of the scroll on the tabs
   * @param {number} position - position of the scroll
   * @returns {void}
   */
  setTabsScrollPositionOnClick = (position) => {
    this.tabsScrollPositionOnClick = position;
  };

  /**
   * Sets the status of the component
   * @param {boolean} status - Is the component mounted?
   * @returns {void}
   */
  setIsComponentMounted(status) {
    this.IsComponentMounted = status;
  }

  /**
   * Gets the status of the component
   * @returns {boolean} The status of the component
   */
  getIsComponentMounted() {
    return this.IsComponentMounted;
  }

  async getSelectionSchedules(targetCollectionObjectID) {
    const selectionsSchedules =
      await SelectionsAPI.getSelectionsSchedules([targetCollectionObjectID], this.axiosCancelToken.token);

    this.setState({ selectionsSchedules: selectionsSchedules || [] });
  }
  /**
   * Update data Extension Search Field
   * @param {string} value - search term
   * @returns {void}
   */

  handleDataExtensionSearchFieldChange = (value) => {
    this.setState({ dataExtensionSearchField: value });
  };

  /**
   * Update Data Extensions object
   * @param {Array} dataExtensions - new data extension object
   * @returns {void}
   */

  updateDataExtensionsObject = (dataExtensions, searchValue) => {
    const { loadedDataExtensions } = this.state;

    if (searchValue) {
      this.setState({ dataExtensions });
    } else {
      this.setState({ dataExtensions: loadedDataExtensions });
    }
  };

  /**
   * Function to revert behavioral filter set to it's original state
   * @param {Array} newFilterSets - array of filterSets
   * @returns {void}
   */
  revertBehavioralFilterSet = (newFilterSets) => {
    this.setState({ filterSets: newFilterSets });
  };

  /**
   * Changes the status of scheduleDisabled and the enabled property in scheduleRun
   * @returns {void}
   */
  handleChangeScheduleDisabledStatus = () => {
    const { scheduleDisabled, hasScheduleBeenEnabled, scheduledRun } = this.state;

    this.setState({
      scheduleDisabled: !scheduleDisabled,
      // if all requirements are met, restore enabled status to the previous one, in other case disable scheduling
      scheduledRun: scheduleDisabled ?
        { ...scheduledRun, enabled: hasScheduleBeenEnabled } :
        { ...scheduledRun, enabled: false },
    });
  };

  /**
   * Function helps to get preview Data Extension and
   * allows the query run in parallel with fetching data extensions
   * @param {object} selection - selection
   * @returns {void}
   */
  getPreviewDataExtensionCustomerKey = async (selection) => {
    let previewTargetDataExtension;

    try {
      // get preview Target Data extension with fields
      const previewTargetDataExtensionResponse = await
      DataExtensionsAPI.getDataExtensionsAndFieldsByCustomerKeys(
        this.axiosCancelToken.token,
        [{
          collectionCustomerKey: selection.previewDataExtensionCustomerKey,
          collectionObjectID: selection.previewDataExtensionObjectID,
        }],
        null,
      );

      // if the data has been fetched, assign it to a variable previewTargetDataExtension
      if (previewTargetDataExtensionResponse && previewTargetDataExtensionResponse[0]) {
        const dataResponse = previewTargetDataExtensionResponse[0];

        previewTargetDataExtension = dataResponse;
      }

      // If preview target DE is valid and preview status is completed (with priodedup or without)
      if (selection && selection.previewStatus === Constants.STATUS_COMPLETE) {
        if (previewTargetDataExtension && previewTargetDataExtension.fields &&
          previewTargetDataExtension.fields.length) {
          // assign fields to fieldsMetaData
          this.setState({ fieldsMetaData: previewTargetDataExtension.fields });
        }

        const { fieldsMetaData } = this.state;

        // if the run preview (with priodedup or without) is completed load preview table
        this.loadPreviewTable(
          fieldsMetaData || null,
          previewTargetDataExtension ? selection.previewDataExtensionCustomerKey : null,
          selection.previewStatus,
          selection.previewTaskCompletedDate || selection.runAutomationCompletedDate,
          selection.previewAutomationError,
          selection.previewDeduplicationTaskStatus,
          selection.previewDataExtensionObjectID || null,
        );

        // set numberOfResults and previewTaskCompleted if the preview is completed
        this.setState({
          numberOfResults: selection.numberOfPreviewRecords,
          previewTaskCompleted: true,
          previewDedupNumberOfRecords: selection.previewDedupNumberOfRecords,
        });

        if (previewTargetDataExtension) {
          if (selection.previewStatus === Constants.STATUS_COMPLETE) {
            // Set preview states if it's still valid
            this.setState({
              previewQueryActivityId: selection.previewQueryActivityId,
              previewDataExtensionCustomerKey: selection.previewDataExtensionCustomerKey,
              previewDataExtensionObjectID: selection.previewDataExtensionObjectID,
              previewDeduplicationDEObjectID: selection.previewDeduplicationDEObjectID,
              previewDedupNumberOfRecords: selection.previewDedupNumberOfRecords,
              numberOfResults: selection.numberOfPreviewRecords,
            });
          }
        } else {
          // sets states of preview page to their initial values
          this.setState({
            numberOfResults: null,
            previewDedupNumberOfRecords: null,
            previewCountTaskStatus: null,
            previewDataExtensionCustomerKey: '',
            previewError: null,
            previewQueryActivityId: '',
            previewStatus: null,
          });
        }
      } else if (selection && selection.previewQueryActivityId) {
        /*
         * if  run preview is not completed, check if there is previewQueryActivityId
         * so we don't need to start the preview again
         */
        this.setState({ runPreviewQueryActivityStarted: true });
      }
    } catch (error) {
      if (!axios.isCancel(error)) {
        this.setState({ error });
      }
    }
  };

  /**
   * Get the object with data extension using in subQuery filters
   * @param {array} nestedFilters - an array with filters
   * @param {array} arrayWithAllDEs - array with all data extensions used in selection
   * @param {array} arrayWithDEs - array with available data extensions, without TargetDE
   * @returns {void}
   */
  getSubQueryCollection = (nestedFilters, arrayWithAllDEs, arrayWithDEs) => {
    let subQueryFilterWithDE;

    // loop over the filters array to find object with DE
    for (let j = 0; j < nestedFilters.length; j += 1) {
      if (nestedFilters[j].subQuery && nestedFilters[j].subQuery.collections[0]) {
        let collection = nestedFilters[j].subQuery.collections[0];

        // if collection has no collectionObjectID property
        if (!collection.collectionObjectID) {
          // assign collectionObjectID the same as collectionCustomerKey -> case for data views
          collection = { ...collection, collectionObjectID: collection.collectionCustomerKey };
        }

        // if the object is found, add it to arrays with data extensions
        subQueryFilterWithDE = collection;
        arrayWithAllDEs.push(subQueryFilterWithDE);
        arrayWithDEs.push(subQueryFilterWithDE);
      } else if (nestedFilters[j].filters) {
        // if the object has children with filters array then go back and execute the function again
        this.getSubQueryCollection(nestedFilters[j].filters, arrayWithAllDEs, arrayWithDEs);
      }
    }

    // set data extensions used in filters
    this.setState({ subQueryDataExtensions: arrayWithDEs });
  };

  /**
   * Loads the table with preview data
   * @param {object[]} fieldsMetaData - preview data
   * @param {string} previewDECustomerKey - DE customer key
   * @param {number} previewStatus - task status
   * @param {string} lastRanDate - last run date
   * @param {string} previewAutomationError - task error
   * @param {number} previewDeduplicationTaskStatus - task status of pridedup
   * @param {string} previewDataExtensionObjectID - DE object ID
   * @returns {void}
   */
  loadPreviewTable = async (
    fieldsMetaData,
    previewDECustomerKey,
    previewStatus,
    lastRanDate,
    previewAutomationError,
    previewDeduplicationTaskStatus,
    previewDataExtensionObjectID,
  ) => {
    const { usePrioDeduplication } = this.state;

    let previewData;

    const getDEData = async (customerKey) => {
      try {
        const res = await DataExtensionsAPI.getDataExtensionData(
          customerKey,
          previewDataExtensionObjectID,
          this.axiosCancelToken.token,
          20,
        );

        return res;
      } catch (error) {
        this.setState({ previewError: error });
      }

      return null;
    };

    if (previewDECustomerKey) {
      previewData = await getDEData(previewDECustomerKey);
    }

    if (!fieldsMetaData) {
      let getCollectionResult;

      try {
        getCollectionResult = await DataExtensionsAPI.getDataExtensionFields(
          previewDECustomerKey,
          this.axiosCancelToken.token,
        );
        getCollectionResult = getCollectionResult.data;
      } catch (error) {
        this.setState({ previewError: error });
      }

      // getCollectionResult is not undefined?
      if (getCollectionResult) {
        /* eslint-disable require-atomic-updates */
        // eslint-disable-next-line no-param-reassign
        fieldsMetaData = getCollectionResult;
      } else {
        // set as empty array to prevent null exception
        // eslint-disable-next-line no-param-reassign
        fieldsMetaData = [];
        /* eslint-enable require-atomic-updates */
      }
      this.setState({ fieldsMetaData });
    }

    const newData = [];

    if (previewData && previewData.length > 0 && fieldsMetaData && fieldsMetaData.length > 0) {
      const previewDataValues = previewData.map(data => Object.values(data));

      // in this loop we are getting key values and sort them to be in order with the retrieved fields
      previewDataValues.forEach((data) => {
        /*
         * getData API will return this:
         * [{
         *   keys: {
         *     email address: dev1@deselect.com
         *   },
         * },
         * {
         *   values: {
         *     name: dev1,
         *   }
         * }]
         */

        // So we will take keys from the first object (eg. 'email address' from the previous comment example)
        const firstKeyObjects = Object.keys(data[0]);

        // And we will take keys from the second object (eg. 'name' from the previous comment example)
        const secondKeyObjects = Object.keys(data[1]);

        let sortedArray = [];

        // loop through array of returned fields
        fieldsMetaData.forEach((metaData) => {
          if (firstKeyObjects && firstKeyObjects.length > 0) {
            firstKeyObjects.forEach((key) => {
              // check if the keys from the first object are matched with the field name
              if (key.toLowerCase() === metaData.Name.toString().toLowerCase()) {
                // if so, push it into array
                sortedArray = [...sortedArray, data[0][key]];
              }
            });
          }

          if (secondKeyObjects && secondKeyObjects.length > 0) {
            secondKeyObjects.forEach((key) => {
              // check if the keys from the second object are matched with the field name
              if (key.toLowerCase() === metaData.Name.toString().toLowerCase()) {
                // if so, push it into array
                sortedArray = [...sortedArray, data[1][key]];
              }
            });
          }
        });

        // now new data will have data in order with the retrieved fields
        newData.push(sortedArray);
      });
    }

    this.setState({
      previewData: newData || [],
      previewStatus,
      // update the lastRanDate
      lastRanDate,
      // set previewDataRetrieved to true if the previewData is loaded
      previewDataRetrieved: true,
      loadingBarIcon: true,
      previewDeduplicationTaskStatus,
    });

    // If automation with no results, show customized message in case error has occurred
    if (!previewData && previewDECustomerKey) {
      this.setState({ previewError: 'Error: Check your connection' });
    } else if (usePrioDeduplication && !previewData.length) {
      this.setState({ previewError: previewAutomationError });
    }
  };

  /**
   * Checks if fields used in priority deduplication are present in the target data extension
   * - On basic mode criteria field and deduplication field are both required for priodedup to work
   *  if either of the fields is missing, user is notified and both field ids are reset
   * - On advanced mode, if any of the fields used in filters are missing, user is notified and
   *  the missing fields are removed.
   * @returns {void} sets priodedup fields to null if either of the fields is not present
   */
  checkPrioDeduplicationMissingFields = async () => {
    const { prioDeduplication, targetDataExtensionFields } = this.state;
    const {
      advancedDeduplicationRules, criteriaFieldObjectID, deduplicationFieldObjectID, mode,
    } = prioDeduplication;

    const uniqueField = targetDataExtensionFields.find(
      field => field.ObjectID === deduplicationFieldObjectID,
    );

    const priorityField = targetDataExtensionFields.find(
      field => field.ObjectID === criteriaFieldObjectID,
    );

    const missingFields = [];

    if (mode === Constants.PRIO_DEDUP__MODE__BASIC__VALUE) {
      if (criteriaFieldObjectID && deduplicationFieldObjectID) {
        const updatedPrioDeduplication = {
          ...prioDeduplication,
          deduplicationFieldObjectID: uniqueField && priorityField ? uniqueField.ObjectID : '',
          criteriaFieldObjectID: priorityField && uniqueField ? priorityField.ObjectID : '',
        };

        this.setState({
          prioDeduplication: updatedPrioDeduplication,
        });

        if (!uniqueField || !priorityField) {
          await SwalUtil.fire({
            type: Constants.SWAL__TYPE__ERROR,
            title: 'Missing Deduplication Field',
            message: `${uniqueField === undefined ?
              'Unique Field' :
              'Priority Field'} used in Deduplication is missing from the Target Data extension
              and the feature has been disabled. Please check your Deduplication settings.`,
          });
          this.handleSelectionNavigator(Constants.NAVIGATION__TARGET_DEFINITION);
        }
      }
    }

    if (mode === Constants.PRIO_DEDUP__MODE__ADVANCED__VALUE) {
      // Check if any of the fields used in filters are missing
      advancedDeduplicationRules.forEach((rule) => {
        const { filters } = rule;

        filters.forEach((filter) => {
          const { fieldObjectID } = filter;

          const field = targetDataExtensionFields.find(
            field => field.ObjectID === fieldObjectID,
          );

          if (!field) {
            missingFields.push(fieldObjectID);
          }
        });
      });

      const updatedAdvancedDeduplicationRules = advancedDeduplicationRules.map((rule) => {
        const { filters } = rule;

        const updatedFilters = filters.filter(filter => !missingFields.includes(filter.fieldObjectID));

        return {
          ...rule,
          filters: updatedFilters,
        };
      });

      const updatedPrioDeduplication = {
        ...prioDeduplication,
        deduplicationFieldObjectID: uniqueField ? uniqueField.ObjectID : '',
      };

      this.setState({
        prioDeduplication: updatedPrioDeduplication,
        advancedDeduplicationRules: updatedAdvancedDeduplicationRules,
      });

      if (!uniqueField || missingFields.length > 0) {
        await SwalUtil.fire({
          type: Constants.SWAL__TYPE__ERROR,
          title: 'Missing Deduplication Field',
          message: `${missingFields.length > 0 ?
            'Field(s) used in Advanced Filters for' :
            'Unique field used in'}
            Deduplication is missing from the Target Data extension
            and the settings have been updated. Please check your Deduplication settings.`,
        });
        this.handleSelectionNavigator(Constants.NAVIGATION__TARGET_DEFINITION);
      }
    }
  };

  /**
   * Checks if there are missing fields in relations
   * @param {boolean} shouldNavigate - Should navigate back to selection criteria?
   * @returns {boolean} True if there are missing relations. False otherwise
   */
  checkMissingFieldsInRelations = async (shouldNavigate) => {
    const {
      handleGlobalNavigator,
      unionSelections,
      unionSelectionsIndex,
      handleSetAppState,
    } = this.props;

    for (let k = 0; k < unionSelections.length; k += 1) {
      const { relations, selectedDataExtensions, tabName } = unionSelections[k];
      // Loop over relations to look for deleted fields

      for (let i = 0; i < relations.length; i += 1) {
        const relation = relations[i];
        const {
          fromCollection,
          toCollection,
          additionalJoins,
        } = relation;

        const {
          ObjectID: fromCollectionObjectID,
          fromField: fromFieldName,
          fromFieldObjectID,
        } = fromCollection;

        const {
          ObjectID: toCollectionObjectID,
          toFieldObjectID,
          toField: toFieldName,
        } = toCollection;

        const missingFields = [];
        const missingFieldsMap = {};

        let fromFieldMissing = false;

        let toFieldMissing = false;

        // Find DE in selectedDataExtensions
        let fromDE;

        /**
         * Find fromDE
         * In case it is a Data View try to match on Customer Key because Data Views do not have ObjectIDs
         */
        if (String(fromCollection?.CustomerKey).startsWith('_')) {
          fromDE = selectedDataExtensions.find(de => de.CustomerKey === fromCollection.CustomerKey);
        } else {
          fromDE = selectedDataExtensions.find(de => de.ObjectID === fromCollectionObjectID);
        }

        // Throw DE missing error if DE could not be found
        if (!fromDE) return this.handleDataExtensionMissing(relation.fromCollection);
        // Find fromField
        const fromField = fromDE.fields.find(
          f => f.ObjectID === fromFieldObjectID ||
            f.Name === fromFieldName,
        );

        // If field is not found, set fieldMissing to true for error handling
        if (!fromField) {
          fromFieldMissing = true;

          // Only render missing field on the swal table once
          if (!missingFieldsMap[fromFieldObjectID]) {
            missingFields.push({ name: fromFieldName, deName: fromDE.Name, tabName });
            missingFieldsMap[fromFieldObjectID] = true;
          }
          fromCollection.deletedFieldName = fromFieldName;

          // If there are other fields, set one of them as the selected field
          if (fromCollection.fields && fromCollection.fields.length) {
            const field = fromCollection.fields[0];

            fromCollection.fromField = field.Name;
            fromCollection.fromFieldType = field.FieldType;
            fromCollection.fromFieldObjectID = field.ObjectID;
          }
        }

        // Find DE in selectedDataExtensions
        let toDE;

        /**
         * Find toDE
         * In case it is a Data View try to match on Customer Key because Data Views do not have ObjectIDs
         */
        if (String(toCollection?.CustomerKey).startsWith('_')) {
          toDE = selectedDataExtensions.find(de => de.CustomerKey === toCollection.CustomerKey);
        } else {
          toDE = selectedDataExtensions.find(de => de.ObjectID === toCollectionObjectID);
        }

        // Throw DE missing error if DE could not be found
        if (!toDE) this.handleDataExtensionMissing(relation.toCollection);

        // Find toField
        const toField = toDE.fields.find(
          f => f.ObjectID === toFieldObjectID ||
            f.Name === toFieldName,
        );

        // If field is not found, set fieldMissing to true for error handling
        if (!toField) {
          toFieldMissing = true;

          if (!missingFieldsMap[toFieldObjectID]) {
            missingFields.push({ name: toFieldName, deName: toDE.Name, tabName });
            missingFieldsMap[toFieldObjectID] = true;
          }
          toCollection.deletedFieldName = toFieldName;

          // If there are other fields, set one of them as the selected field
          if (toCollection.fields && toCollection.fields.length) {
            // find first compatible field and set it as default field
            const field = toCollection.fields.find(f => Util.canFieldBeMapped(
              fromCollection.fromFieldType,
              f.FieldType,
            ));

            const fieldToSet = field || toCollection.fields[0];

            toCollection.toField = fieldToSet.Name;
            toCollection.toFieldType = fieldToSet.FieldType;
            toCollection.toFieldObjectID = fieldToSet.ObjectID;
          }
        }

        // Look for missing additionalJoins fields
        additionalJoins.forEach((additionalJoin) => {
          const {
            fromFieldObjectID: additionalJoinFromFieldObjectId,
            toFieldObjectID: additionalJoinToFieldObjectId,
            fromFieldName: additionalJoinFromFieldName,
            toFieldName: additionalJoinToFieldName,
          } = additionalJoin;

          // Find fromField
          const additionalJoinFromField = fromDE.fields.find(
            f => f.ObjectID === additionalJoinFromFieldObjectId,
          );

          if (!additionalJoinFromField) {
            // Only render missing field on the swal table once
            if (!missingFieldsMap[additionalJoinFromFieldObjectId]) {
              missingFields.push({
                name: additionalJoinFromFieldName || additionalJoinFromFieldObjectId,
                deName: fromDE.Name,
                tabName,
              });
              missingFieldsMap[additionalJoinFromFieldObjectId] = true;
            }
            additionalJoin.fromFieldMissing = true;
            additionalJoin.deletedFromFieldName = additionalJoinFromFieldName;

            // If there are other fields, set one of them as the selected field
            if (fromCollection.fields && fromCollection.fields.length) {
              const field = fromCollection.fields[0];

              additionalJoin.fromFieldName = field.Name;
              additionalJoin.fromFieldType = field.FieldType;
              additionalJoin.fromFieldObjectID = field.ObjectID;
            }
          }

          // Find toField
          const additionalJoinToField = toDE.fields.find(
            f => f.ObjectID === additionalJoinToFieldObjectId,
          );

          if (!additionalJoinToField) {
            // Only render missing field on the swal table once
            if (!missingFieldsMap[additionalJoinToFieldObjectId]) {
              missingFields.push({
                name: additionalJoinToFieldName || additionalJoinToFieldObjectId,
                deName: toDE.Name,
                tabName,
              });
              missingFieldsMap[additionalJoinToFieldObjectId] = true;
            }
            additionalJoin.toFieldMissing = true;
            additionalJoin.deletedToFieldName = additionalJoinToFieldName;

            // If there are other fields, set one of them as the selected field
            if (toCollection.fields && toCollection.fields.length) {
              // Get fromFieldType
              const fromFieldType = fromCollection.fields
                .find(fCF => fCF.ObjectID === additionalJoin.fromFieldObjectID)?.FieldType;

              fromCollection.fields.find(f => fromFieldType === f.FieldType);

              // find first compatible field and set it as default field
              const field = toCollection.fields.find(f => Util.canFieldBeMapped(
                fromFieldType,
                f.FieldType,
              ));

              const fieldToSet = field || toCollection.fields[0];

              additionalJoin.toField = fieldToSet.Name;
              additionalJoin.toFieldType = fieldToSet.FieldType;
              additionalJoin.toFieldObjectID = fieldToSet.ObjectID;
            }
          }
        });

        if (missingFields.length) {
          const multipleMissingFields = missingFields.length > 1;

          const message = `The following field${multipleMissingFields ? 's were ' : ' was '}deleted from the ` +
            `selected Data Extension${multipleMissingFields ? 's' : ''}. Please update the relationship to continue.`;

          /* eslint-disable no-await-in-loop */
          const result = await Util.handleMissingOrRenamedField(message, missingFields);

          if (result.value) {
            // Navigate to the right union selection
            if (k !== unionSelectionsIndex) {
              this.handleSetSelectionState({ unionSelections: unionSelections[k] });
              handleSetAppState({ unionSelectionsIndex: k });
            }

            // Set the state
            this.handleSetSelectionState({
              showRelationalModal: true,
              modalDataExtensions: relation,
              fromFieldMissing,
              toFieldMissing,
            });

            // Navigate to proper position in the application
            if (shouldNavigate) {
              this.setState({
                selectionNavigator: Constants.NAVIGATION__SELECTION_CRITERIA,
                captureSelectionChange: true,
              });
              handleGlobalNavigator(Constants.NAVIGATION__SELECTION_CRITERIA);
            }

            return false;
          }
        }
      }
    }
    this.setState({ checkMissingFieldsInRelationsDone: true });

    return true;
  };

  /**
   * Check if there's any missing filter
   * @returns {string} - return formulaLabels
   */
  checkIncompleteFilterList = () => {
    const { selectedFilters } = this.state;

    // label for formula type
    const labelType = {
      [Constants.FORMULA__VALUE__COUNT]: 'count',
      [Constants.FORMULA__VALUE__AVERAGE]: 'average',
      [Constants.FORMULA__VALUE__SUM]: 'sum',
      [Constants.FORMULA__VALUE__MINIMUM]: 'minimum',
      [Constants.FORMULA__VALUE__MAXIMUM]: 'maximum',

    };

    // get list of incomplete formulas
    const incompleteFiltersList = selectedFilters?.filters?.filter(item => item.relation === true &&
      item.subQuery?.relationData?.formula !== Constants.FORMULA__VALUE__COUNT &&
      item.subQuery?.dataExtensionFields.filter(field => field.FieldType ===
        Constants.FILTERLINE__FIELDTYPE__NUMBER ||
        field.FieldType === Constants.FILTERLINE__FIELDTYPE__DECIMAL).length === 0);

    // set label for each incomplete formula
    const formulaLabels = incompleteFiltersList?.map(i => i.subQuery?.relationData.formula)
      .map(a => labelType[a]).filter((item, i, array) => array.indexOf(item) === i).join(' / ');

    // return formula labels
    return formulaLabels;
  };

  /**
   * Check if there's any missing filter
   * @returns {void}
   */
  checkIncompleteFilter = async () => {
    const { selectedFilters } = this.state;
    const { handleGlobalNavigator } = this.props;

    /*
     * check if selected filters contains any relation
     * and relation is not count and any relation contains
     * no match field
     */
    const isNoMatchFieldExists = selectedFilters?.filters?.some(item => item.relation === true &&
      item.subQuery?.relationData?.formula !== Constants.FORMULA__VALUE__COUNT &&
      item.subQuery?.dataExtensionFields.filter(field => field.FieldType ===
        Constants.FILTERLINE__FIELDTYPE__NUMBER ||
        field.FieldType === Constants.FILTERLINE__FIELDTYPE__DECIMAL).length === 0);

    // If filter is incomplete and relation selected then show swal error
    if (isNoMatchFieldExists) {
      // get formula labels
      const formulas = await this.checkIncompleteFilterList();

      // Fire swal error
      await SwalUtil.fire({
        type: Constants.SWAL__TYPE__ERROR,
        // eslint-disable-next-line max-len
        message: `You have one or more <b class="bold-swal">${formulas}</b> filters that don't have a field selected. Please fix this before continuing.`,
      });
      // Navigate to proper position in the application
      this.setState({ selectionNavigator: Constants.NAVIGATION__SELECTION_CRITERIA });
      handleGlobalNavigator(Constants.NAVIGATION__SELECTION_CRITERIA);

      // Return false
      return false;
    }

    return true;
  };

  /**
   * Checks if the schedule is valid
   * @returns {boolean} true if valid, false otherwise
   */
  checkValidSchedule = async () => {
    const { scheduledRun } = this.state;
    const { handleGlobalNavigator } = this.props;

    /*
     * If schedule is set to run once, make sure that the set date and time have not passed
     */
    let isScheduleInvalid = false;

    // Check if scheduling is enabled, if so, check if mode is run once
    if (scheduledRun?.enabled && scheduledRun?.mode === Constants.SCHEDULE_SELECTION__MODE__ONCE) {
      const { runOnce, timezone } = scheduledRun;

      // Make sure date time has not passed, if so, set schedule as invalid
      if (timeUtil.hasPassed(runOnce, timezone, true)) isScheduleInvalid = true;
    }

    // If schedule is not valid show swal error
    if (isScheduleInvalid) {
      // Fire swal error
      await SwalUtil.fire({
        type: Constants.SWAL__TYPE__ERROR,
        // eslint-disable-next-line max-len
        message: 'The scheduling settings are not valid.<br>Make sure the selected date and time have not passed.<br>Please fix this before continuing.',
      });

      // Navigate to proper position in the application
      this.setState({ selectionNavigator: Constants.NAVIGATION__SELECTION_CRITERIA });
      handleGlobalNavigator(Constants.NAVIGATION__SELECTION_CRITERIA);

      // Return false
      return false;
    }

    return true;
  };

  /**
   * Checks to do with what has been loaded.
   * @returns {void}
   */
  afterLoadChecks = async () => {
    await this.checkMissingFieldsInFilters();
    await this.checkMissingFieldInCustomValue();
    await this.checkMissingFieldsInRelations();
    await this.addObjectIdToSubQueryFields();
    await this.addDeAliasToDynamicCustomValues();
    await this.addObjectIdToDataViews();
    await this.checkPrioDeduplicationMissingFields();
  };

  /**
   * Adds ObjectId to DataViews
   * @returns {void}
   */
  addObjectIdToDataViews = async () => {
    const { unionSelections, handleSetAppState } = this.props;

    // Loop over unionSelections array
    for (let i = 0; i < unionSelections.length; i += 1) {
      const unionSelection = unionSelections[i];
      const { selectedDataExtensions } = unionSelection;

      // Loop over selectedDataExtensions
      for (let j = 0; j < selectedDataExtensions.length; j += 1) {
        const selectedDE = selectedDataExtensions[j];

        // If the De's name starts with an underscore
        if (selectedDE.Name.startsWith('_') && !selectedDE.ObjectID) {
          // Set ObjectId to CustomerKey
          selectedDE.ObjectID = selectedDE.CustomerKey;
        }
      }
    }

    // Set App state
    handleSetAppState({ unionSelections });
  };

  /**
   * Scans the unionSelection array and adds dataExtensionAlias to dynamic customValues
   * @returns {void}
   */
  addDeAliasToDynamicCustomValues = async () => {
    const { unionSelections, handleSetAppState } = this.props;

    // Loop over unionSelections array
    for (let i = 0; i < unionSelections.length; i += 1) {
      const unionSelection = unionSelections[i];
      const { customValues, selectedDataExtensions } = unionSelection;

      // If customValues array is not empty
      if (customValues && customValues.length) {
        // Get dynamic custom values
        const dynamicCVs = customValues.filter(
          cv => cv.valueType === Constants.CUSTOM_VALUES__FIELD_TYPE__DYNAMIC_VALUE,
        );

        // If dynamicCVs array is not empty
        if (dynamicCVs && dynamicCVs.length) {
          dynamicCVs.forEach((dynamicCV) => {
            dynamicCV.criteria.forEach((criterion) => {
              // If then is truthy and it is an object
              if (criterion.then && typeof criterion.then === 'object') {
                if (!criterion.then.dataExtensionAlias) {
                  /**
                   * Get DE by using ObjectId -> The first time this is used, there's a chance that the wrong deAlias
                   * will be set when two same DEs(with different aliases) are used.
                   */
                  const selectedDE = selectedDataExtensions.find(de => de.ObjectID ===
                    criterion.then.dataExtensionObjectId) || {};

                  // Set dataExtensionAlias
                  // eslint-disable-next-line no-param-reassign
                  criterion.then.dataExtensionAlias = selectedDE.deAlias || '';
                }
              }
            });

            // If defaultDataExtensionAlias has not been set before, set it.
            if (dynamicCV.defaultValue && !dynamicCV.defaultValue.defaultDataExtensionAlias &&
              dynamicCV.defaultValue.defaultDataExtensionObjectId
            ) {
              const defaultDE = selectedDataExtensions.find(
                de => de.ObjectID === dynamicCV.defaultValue.defaultDataExtensionObjectId,
              ) || {};

              // Set default DE alias
              // eslint-disable-next-line no-param-reassign
              dynamicCV.defaultValue.defaultDataExtensionAlias = defaultDE.deAlias || '';
            }
          });
        }
      }
    }

    // Set App state
    handleSetAppState({ unionSelections });
  };

  /**
   * Scans the unionSelection array and adds availableFieldObjectID to subQuery fields
   * @returns {void}
   */
  addObjectIdToSubQueryFields = async () => {
    const { unionSelections } = this.props;

    /**
     * Adds the availableFieldObjectID property to subQuery fields
     * @param {Object} selectedFilters - selected filters
     * @returns {void}
     */
    const addObjectIdToFields = (selectedFilters) => {
      const { filters = [] } = selectedFilters;

      // Loop through all filters
      for (let j = 0; j < filters.length; j += 1) {
        // Nested filter
        if (filters[j].filters) {
          // Call the function on the nested filter
          addObjectIdToFields(filters[j]);
        } else {
          // Get subQuery field
          const subQueryField = (filters[j].subQuery && filters[j].subQuery.fields &&
            filters[j].subQuery.fields.length > 0 && filters[j].subQuery.fields[0]);

          // Check to make sure subQueryField exists and it has no availableFieldObjectID
          if (subQueryField && !subQueryField.availableFieldObjectID) {
            const { field: subQueryFieldName } = subQueryField;
            const { dataExtensionFields } = filters[j].subQuery || [];

            let field;

            if (dataExtensionFields && dataExtensionFields.length) {
              // Get field
              field = dataExtensionFields.find(f => f.Name === subQueryFieldName);
            }

            // If field has been found
            if (field) {
              // Set the availableFieldObjectID property
              subQueryField.availableFieldObjectID = field.ObjectID;
            }
          }
        }
      }
    };

    // Loop through all unionSelections
    for (let i = 0; i < unionSelections.length; i += 1) {
      const { selectedFilters: editedFilters } = unionSelections[i];

      // Add availableFieldObjectID to subQuery fields
      addObjectIdToFields(editedFilters);

      // Save the filters
      this.handleFiltersSave(editedFilters, i, false);
    }
  };

  /**
   * Reduce the array of filters to those that should be removed
   * @param {array} filters - An array containing filters to be checked
   * @param {object} filterProperty - filter properties needed to perform the check function
   * @returns {array} Reduced array of filters that needs to be removed
   */
  reduceFiltersToRemovedFilters = (filters, filterProperty) => filters.reduce((filtersArray, filter) => {
    // get the filter to be removed if a field existing in the SubQuery filter has been removed
    const getFilterToRemove = this.checkIfFieldExistsInSubQueryFilters(
      filterProperty.id,
      filter,
      filterProperty.subQueryFilters,
      filterProperty.handleChangeUnionSelectionIndex,
    );

    // if the filter is found
    if (getFilterToRemove?.length) {
      // add it to filters array
      return [...filtersArray, ...getFilterToRemove];
    }

    // return reduced array with filters that needs to be removed
    return filtersArray;
  }, []);

  /**
   * Function that calls the remove filterline function in case of a removed field or DE in filters
   * @param {object} filterToRemove- object with filter property to be removed
   * @param {string} filterToRemove.id - id of the filterline
   * @param {string} filterToRemove.fieldName - Name of the field
   * @param {string} filterToRemove.dataExtensionName - Name of the Data Extension
   * @param {boolean} filterToRemove.isDERemoved - true/false whether DE has been removed
   * @param {string} filterToRemove.fromDEForRelationFilter - the DE from which the relation filter is removed
   * @param {string} filterToRemove.subQueryFilterID - id of subQuery filter to be removed
   * @param {boolean} filterToRemove.isSubQuery - true/false whether removed filter belongs to subQuery.filters
   * @param {boolean} filterToRemove.isRelatedRecord - true/false whether the filter is for related records
   * @param {array} filterToRemove.subQueryFilters - subQuery filters
   * @param {boolean} filterToRemove.nested - Indicates whether the function call is nested
   * @param {boolean} filterToRemove.missingFieldInSubQuery - Indicates whether subQuery filter is removed,
   *  because the field was not found
   * @param {boolean} filterToRemove.isRelationFilter - Indicates whether the filter is a relation filter type
   * @returns {void}
   */
  removeFilterLineInFilters = async ({
    id,
    fieldName,
    dataExtensionName,
    isDERemoved,
    fromDEForRelationFilter,
    subQueryFilterID,
    isSubQuery,
    isRelatedRecord,
    subQueryFilters,
    nested,
    missingFieldInSubQuery,
    isRelationFilter,
  }) => this.handleRemoveFilterLine({
    filterLineId: id,
    field: fieldName,
    dataExtension: dataExtensionName,
    subQueryModalFilterLineId: subQueryFilterID,
    isSubQuery,
    isRelatedRecord,
    handleFiltersSave: this.handleFiltersSave,
    subQueryFilters,
    nested,
    removedSubQueryDE: isDERemoved,
    fromDEForRelationFilter,
    missingFieldInSubQuery,
    isRelationFilter,
  });

  /**
   * Checks if there are missing fields in filters
   * @param {Array} dataExtensions - List of available data extensions
   * @returns {void}
   */
  checkMissingFieldsInFilters = async (dataExtensions) => {
    const { unionSelections, unionSelectionsIndex, handleSetAppState } = this.props;
    const { predefinedRelations } = this.state;

    // Loop over the unionSelections array to look for missing fields
    for (let k = 0; k < unionSelections.length; k += 1) {
      const { selectedFilters } = unionSelections[k];
      const { selectedDataExtensions } = this.state;

      /**
       * Function that navigate to the selection view if currently not on it.
       * @returns {void}
       */
      const handleChangeUnionSelectionIndex = () => {
        if (k !== unionSelectionsIndex) {
          handleSetAppState({ unionSelectionsIndex: k });
        }
      };

      // Get all filters from all containers and put them in an array
      const filters = filtersUtil.getAllFilters(selectedFilters, []);

      for (let i = 0; i < filters.length; i += 1) {
        let { field } = filters[i];
        const { subQuery, relation } = filters[i];
        const { fieldObjectID, collectionAlias, id } = filters[i];

        let removeFilterLineID = null;

        if (selectedDataExtensions && selectedDataExtensions.length > 0 && fieldObjectID) {
          // find data extension with collectionAlias
          const selectedFilterDataExtension = selectedDataExtensions.filter(ex => ex.deAlias === collectionAlias);

          // If selectedFilterDataExtension array is not empty
          if (selectedFilterDataExtension && selectedFilterDataExtension.length > 0) {
            let fieldExists = false;

            let isRelatedRecord = false;

            // update field
            for (
              let j = 0;
              j < (selectedFilterDataExtension[0].fields && selectedFilterDataExtension[0].fields.length);
              j += 1) {
              if (selectedFilterDataExtension[0].fields[j].ObjectID === filters[i].fieldObjectID) {
                // eslint-disable-next-line no-param-reassign
                field = selectedFilterDataExtension[0].fields[j].Name.toString();
                fieldExists = true;

                if (filters[i]?.relation && dataExtensions) {
                  const fieldAssociatedRelation = predefinedRelations.find(
                    relation => relation._id === filters[i]?.relationId,
                  );

                  const relationFieldToDE = dataExtensions?.filter((de) => {
                    if (Util.startsWithUnderScore(de?.Name)) {
                      return de.Name === fieldAssociatedRelation?.toDEName;
                    }

                    return de.ObjectID === fieldAssociatedRelation?.toDEObjectId;
                  });

                  if (!relationFieldToDE || !fieldAssociatedRelation) {
                    fieldExists = false;
                    isRelatedRecord = true;
                  }
                }

                break;
              }
            }

            // If the field is missing
            if (!fieldExists && removeFilterLineID !== id) {
              // check and navigate to the proper view in selection
              handleChangeUnionSelectionIndex();

              // assign id to avoid double modal for the same id
              removeFilterLineID = id;

              // Remove the missing field from the the filters array
              /* eslint-disable no-await-in-loop */
              await this.removeFilterLineInFilters({
                id,
                fieldName: field,
                isRelatedRecord,
                dataExtensionName: selectedFilterDataExtension[0].Name.toString(),
              });
            }
          }

          // check if field has been removed in subQuery filter
          if (subQuery?.filters?.filters?.length) {
            // Get an array with filters that should be removed
            const filtersToRemove = this.reduceFiltersToRemovedFilters(
              subQuery.filters.filters,
              { id, subQueryFilters: subQuery.filters.filters, handleChangeUnionSelectionIndex },
            );

            if (filtersToRemove?.length) {
              // loop through each filter and remove it
              for (let m = 0; m < filtersToRemove.length; m += 1) {
                // navigate to the proper view in selection
                handleChangeUnionSelectionIndex();

                // create an object with properties to remove the filter
                const subQueryDataToRemoveFilter = {
                  subQueryFilterID: filtersToRemove[m].id,
                  isSubQuery: true,
                  subQueryFilters: filtersToRemove[m].subQueryFilters,
                  nested: true,
                  missingFieldInSubQuery: true,
                  isRelationFilter: relation,
                };

                // for the filter with 'compared' property
                if (filtersToRemove[m].compared === true) {
                  await this.removeFilterLineInFilters({
                    id: filtersToRemove[m].filter.id,
                    fieldName: filtersToRemove[m].filter.comparedField.Name,
                    dataExtensionName: filtersToRemove[m].filter.comparedDataExtension.Name,
                    ...subQueryDataToRemoveFilter,
                  });
                } else {
                  // for filter without 'compared' property
                  await this.removeFilterLineInFilters({
                    id: filtersToRemove[m].filter.id,
                    fieldName: filtersToRemove[m].filter.field,
                    dataExtensionName: filtersToRemove[m].filter.collectionAlias,
                    ...subQueryDataToRemoveFilter,
                  });
                }
              }
            }
          }

          // if compared data extension and field exists
          if (filters[i].comparedField && filters[i].comparedDataExtension &&
            selectedFilterDataExtension[0]?.fields?.length && removeFilterLineID !== id) {
            // find compared Data Extension
            const comparedDE = selectedDataExtensions.find(de => de.ObjectID ===
              filters[i].comparedDataExtension.ObjectID);

            if (comparedDE) {
              // check if compared field exists
              const comparedField = comparedDE.fields.find(cmpField => cmpField.ObjectID ===
                filters[i].comparedField.ObjectID);

              if (!comparedField) {
                // assign id to avoid double modal for the same id
                removeFilterLineID = id;

                // if the field is not found, then remove this filterline and navigate to the proper view in selection
                handleChangeUnionSelectionIndex();
                await this.removeFilterLineInFilters({
                  id,
                  fieldName: filters[i].comparedField.Name.toString(),
                  dataExtensionName: comparedDE.Name.toString(),
                });
              }
            }
          }

          const { subQueryDataExtensions } = this.state;

          // check if field or data extension has been removed in relation filter
          if (subQuery?.relationData && subQuery?.selectedDataExtension && !Util.objectIsEmpty(subQuery.relationData) &&
            subQueryDataExtensions?.length) {
            // check if DE and field used in relation filter exists
            if (await this.checkIfRelationFilterExists(filters[i], selectedFilters, k)) {
              // if exists, check the fields that are used in relation filter
              await this.checkMissingFieldsOrDEInSubQuery(
                id,
                subQueryDataExtensions,
                subQuery.relationData.collectionAlias,
                subQuery.relationData.fieldObjectID,
                subQuery.relationData.field,
                handleChangeUnionSelectionIndex,
                subQuery.selectedDataExtension.ObjectID,
              );
            } else {
              // if not exists, remove this filterline and navigate to the proper view in selection
              handleChangeUnionSelectionIndex();
              await this.removeFilterLineInFilters({
                id,
                fieldName: subQuery.relationData.field,
                dataExtensionName: subQuery.relationData.collectionAlias,
                isDERemoved: true,
                fromDEForRelationFilter: filters[i].collectionAlias,
              });
            }
          }

          /**
           * check if field or data extension has been removed in inResults filter
           * do not check for Data Views since they can't be deleted
           */
          if (subQuery?.fields?.length && subQuery?.collections?.length &&
            subQueryDataExtensions?.length && !relation && !subQuery.fields[0].collectionAlias.startsWith('_')) {
            await this.checkMissingFieldsOrDEInSubQuery(
              id,
              subQueryDataExtensions,
              subQuery.fields[0].collectionAlias,
              subQuery.fields[0].availableFieldObjectID,
              subQuery.fields[0].field,
              handleChangeUnionSelectionIndex,
              // pass collectionCustomerKey for data views
              subQuery.collections[0].collectionObjectID || subQuery.collections[0].collectionCustomerKey,
            );
          }
        }
      }
    }
  };

  /**
   * Function that checks and calls the remove filterline function in case of a removed field or DE in subQuery filters
   * @param {string} id - id of the filterline
   * @param {object} filter - filter object
   * @param {array} subQueryFilters - array with subQuery filters
   * @param {function} handleChangeUnionSelectionIndex - function to navigate to the proper view in selection
   * @returns {array|*} array with filters to remove or nothing if no filters are found
   */
  checkIfFieldExistsInSubQueryFilters = (id, filter, subQueryFilters, handleChangeUnionSelectionIndex) => {
    const { subQueryDataExtensions, selectedDataExtensions } = this.state;

    // when in filters are nested another filters
    if (filter?.filters?.length) {
      // perform this function again and get the array with filters that should be removed
      return this.reduceFiltersToRemovedFilters(
        filter.filters,
        { id, subQueryFilters, handleChangeUnionSelectionIndex },
      );
    }

    // if the filter is an object with fieldObjectID and there are DEs used in filters
    if (filter.fieldObjectID && subQueryDataExtensions?.length && subQueryFilters) {
      // get DE used in filter from subQueryDataExtensions
      const subQueryDE = subQueryDataExtensions.find(de => de.Name === filter.collectionAlias);

      // if DE used in filter has fields
      if (subQueryDE?.fields?.length) {
        // check if field used in filters exists
        const fieldInSubQueryDE = subQueryDE.fields.find(field => field.ObjectID === filter.fieldObjectID);

        if (!fieldInSubQueryDE) {
          // if the field is not found, then return array with filter object
          return [{
            filter,
            subQueryFilters,
            id,
            compared: false,
          }];
        }
      }
    }

    // if the filter has compared DE and compared field, check if compared field exists
    if (filter.comparedDataExtension && filter.comparedField && selectedDataExtensions?.length) {
      // get selected Data Extension
      const selectedDE = selectedDataExtensions.find(dataExt => dataExt.ObjectID ===
        filter.comparedDataExtension.ObjectID);

      if (selectedDE?.fields?.length) {
        // find field that exists in compared field
        const fieldInSelectedDE = selectedDE.fields.find(field => field.ObjectID === filter.comparedField.ObjectID);

        if (!fieldInSelectedDE) {
          // if the field is not found, then return array with filter object
          return [{
            filter,
            subQueryFilters,
            id,
            compared: true,
          }];
        }
      }
    }

    return null;
  };

  /**
   * Function that checks and calls the remove filterline function in case of a removed field or DE
   * in relation filter or inResult filter
   * @param {string} id - id of the filterline
   * @param {array} availableDataExtensions - array with data extensions to check
   * @param {string} collectionAlias - collection alias from filter object
   * @param {string} availableFieldObjectID - objectID of the field used in the filter
   * @param {string} field - name of the field used in the filter
   * @param {function} handleChangeUnionSelectionIndex - function to navigate to the proper view in selection
   * @param {string} collectionObjectID - Object ID of data extension from filter object
   * @returns {void}
   */
  checkMissingFieldsOrDEInSubQuery = async (
    id,
    availableDataExtensions,
    collectionAlias,
    availableFieldObjectID,
    field,
    handleChangeUnionSelectionIndex,
    collectionObjectID,
  ) => {
    // find data extension (we compare using CustomerKey for data views)
    const subQueryDataExtension = availableDataExtensions.find(de => de.ObjectID === collectionObjectID ||
      de.CustomerKey === collectionObjectID);

    if (subQueryDataExtension?.fields && availableFieldObjectID) {
      // check if field exists
      const selectedField = subQueryDataExtension.fields.find(subQueryField => (
        subQueryField.ObjectID === availableFieldObjectID
      ));

      if (!selectedField) {
        // if the field is not found, then remove this filterline and navigate to the proper view in selection
        handleChangeUnionSelectionIndex();
        await this.removeFilterLineInFilters({
          id,
          fieldName: field,
          dataExtensionName: subQueryDataExtension.Name.toString(),
        });

        // return id to avoid double modal for the same id
        return id;
      }

      return false;
    } if (!subQueryDataExtension) {
      // if the data extension is not found, then remove this filterline and navigate to the proper view in selection
      handleChangeUnionSelectionIndex();
      await this.removeFilterLineInFilters({
        id,
        fieldName: field,
        dataExtensionName: collectionAlias,
        isDERemoved: true,
      });

      // return id to avoid double modal for the same id
      return id;
    }

    return false;
  };

  /**
   * Check that fields used in custom values still exist.
   * @returns {void} May show a Swal message and redirect if needed.
   */
  checkMissingFieldInCustomValue = async () => {
    const { unionSelections, featuresInfo } = this.props;
    const featureDataViews = Features.isFeatureEnabled(featuresInfo, Constants.FEATURE__DATA_VIEWS);
    const { dataExtensions } = this.state;

    // fetched data extensions before open the selection
    const fetchedDataExtensions = dataExtensions?.length ? [...dataExtensions] : [];

    // Contains pairs of custom value name and data extension name with missing field
    const missingFieldIn = [];

    // Contains all filters in aggregation custom value for which fields have been removed
    const aggregationFiltersWithMissingFields = [];

    /*
     * informs whether the access to the selection should be blocked,
     * because there is custom value with data views without active dataView feature flag
     */
    let disabledAccessToSelection = false;

    /**
     * Delete a custom value and remove it from mapped field
     * @param {object} unionSelection - The union selection to use to retrieve custom values and matched fields
     * @param {string} name - The name of the custom value
     * @param {string} DEName - The name of the data extension where the missing field is
     * @param {string} deOrField - Defines what has been removed - DE or a field
     * @returns {void}
     */
    const deleteCustomValue = (unionSelection, name, DEName, deOrField) => {
      const { customValues, matchedFields, tabName } = unionSelection;
      // Delete from custom values

      customValues.forEach((cv, idx) => {
        if (cv.name === name) {
          customValues.splice(idx, 1);
        }
      });

      // Delete from mapped fields - One custom value can be mapped to multiple fields
      for (let i = 0; i < matchedFields.length; i += 1) {
        // `availableFieldObjectID` includes 'custom Values' in the ID
        if ((!matchedFields[i].availableFieldObjectID ||
          matchedFields[i].availableFieldObjectID.includes('customValues')) &&
          matchedFields[i].availableFieldName === name) {
          matchedFields.splice(i, 1);
          i -= 1;
        }
      }

      missingFieldIn.push([name, DEName, deOrField, tabName]);
    };

    /**
     * Checks if a field still exists given one predicate to find the DE and another one to find the field in this DE.
     * @param {array<object>} availableDataExtensions - The array containing all available data extensions
     * @param {function} DEPredicate - Predicate used to find the DE we want to check
     * @param {function} fieldPredicate - Predicate used to find the field in the DE we want to check
     * @returns {object} 3 possible cases:
     * - Cannot find the DE in `availableDataExtensions`: { error: true, missingDE: true }
     * - Cannot find field in DE: { error: true, missingField: true, selectedDE: {the DE found using DEFilter} }
     * - Field still exists:
     *   { error: false, selectedDE: {the DE found using DEFilter}, selectedField: {the field found} }
     */
    const checkFieldStillExists = (availableDataExtensions, DEPredicate, fieldPredicate) => {
      // Handle missing DE
      const selectedDE = availableDataExtensions.find(DEPredicate);

      if (!selectedDE) return { error: true, missingDE: true }; // Will be handled during loading in `componentDidMount`

      // Handle missing field
      const selectedField = selectedDE.fields.find(fieldPredicate);

      if (!selectedField) return { error: true, missingField: true, selectedDE };

      return { error: false, selectedDE, selectedField };
    };

    /**
     * Checks if a field still exists given one predicate to find the DE and another one to find the field in this DE.
     * @param {array<object>} availableDataExtensions - The array containing all available data extensions
     * @param {function} dataExtensionPredicate - Predicate used to find the DE we want to check
     * @param {function} fieldPredicate - Predicate used to find the field in the DE we want to check
     * @param {function} fromFieldPredicate - Predicate used to find the fromField in the DE we want to check
     * @returns {object} 3 possible cases:
     * - Cannot find the DE in `availableDataExtensions`: { error: true, missingDE: true }
     * - Cannot find field in DE: { error: true, missingField: true, selectedDE: {the DE found using DEFilter} }
     * - Field still exists:
     *   { error: false, selectedDE: {the DE found using DEFilter}, selectedField: {the field found} }
     */
    const checkFieldStillExistsInAvailableDE = async (
      availableDataExtensions,
      dataExtensionPredicate,
      fieldPredicate,
      fromFieldPredicate,
    ) => {
      // Handle missing DE
      const selectedDE = availableDataExtensions.find(dataExtensionPredicate);

      if (!selectedDE) return { error: true, missingDE: true }; // Will be handled during loading in `componentDidMount`

      // fetch the fields of DE or dataview
      selectedDE.fields = await this.getDataExtensionOrDataViewFields(selectedDE);

      // Handle missing field
      const selectedField = selectedDE?.fields?.find(fieldPredicate);

      // display an error if the field is deleted
      if (!selectedField) return { error: true, missingField: true, selectedDE };

      // Handle missing field used as fromField in aggregation custom value
      if (fromFieldPredicate) {
        const fromFieldResult = selectedDE?.fields?.find(fromFieldPredicate);

        // display an error if the field is deleted
        if (!fromFieldResult) return { error: true, missingField: true, selectedDE };
      }

      return { error: false, selectedDE, selectedField };
    };

    /**
     * Checks if a field used in aggregation filter exists
     * @param {object} aggregationFilterLine - filterLine data from aggregation custom value
     * @param {object} aggregationFilterLine.filtersBranch - branch with filters
     * @param {array} aggregationFilterLine.availableFields - retrieved available fields for the DE used in filter
     * @param {string} aggregationFilterLine.subQueryModalFilterLineId - filterline id of aggregation filter
     * @param {array} aggregationFilterLine.aggregationFilters - filters in aggregation filters
     * @param {object} aggregationFilterLine.aggregationCustomValue - aggregation custom value
     * @returns {void}
     */
    const checkFieldStillExistsInAggregationFilters = ({
      filtersBranch, availableFields, subQueryModalFilterLineId, aggregationFilters, aggregationCustomValue,
    }) => {
      // if filters are nested, perform this function again
      if (filtersBranch.filters) {
        filtersBranch.filters.forEach((filterBranch) => {
          checkFieldStillExistsInAggregationFilters({
            filtersBranch: filterBranch,
            availableFields,
            subQueryModalFilterLineId: filtersBranch.id,
            aggregationFilters: filtersBranch.filters,
            aggregationCustomValue,
          });
        });
      } else {
        // get the field from available fields
        const getFieldInAvailableFields = availableFields.find(field => field.ObjectID ===
          filtersBranch?.fieldObjectID);

        // if field is missing, push properties into an array to delete filterLine using this field
        if (!getFieldInAvailableFields) {
          this.setState({ showAggregationModal: true });
          aggregationFiltersWithMissingFields.push({
            filtersBranch,
            subQueryModalFilterLineId,
            aggregationCustomValue,
            aggregationFilters,
          });
        } else if (filtersBranch.comparedField) {
          // if there is a field to compare in the filter, find that compared field
          const getComparedField = availableFields.find(field => field.ObjectID ===
            filtersBranch?.comparedField.ObjectID);

          // if compared field does not exists, push properties into an array to delete filterLine using this field
          if (!getComparedField) {
            this.setState({ showAggregationModal: true });
            aggregationFiltersWithMissingFields.push({
              filtersBranch,
              subQueryModalFilterLineId,
              aggregationCustomValue,
              aggregationFilters,
              comparedField: true,
            });
          }
        }
      }
    };

    /**
     * Returns a swal message if Data Extension or a Field is removed in aggregation custom value
     * @param {string} cvName - The name of the custom value
     * @param {string} deName - The name of the Data Extension
     * @param {string} DEOrField - specifies what has been removed - Data Extension or Field
     * @param {string} tabName - the name of the tab for which custom values is removed
     * @returns {object} swal object with message
     */
    const deleteErrorSwalMsgForCustomValue = async (cvName, deName, DEOrField, tabName) => {
      // get response when data extension or field used in custom value has been removed
      const response = selectionUpdatesUtil.removedFieldOrDEInCustomValueResponse(cvName, deName, DEOrField);

      const { selectionUpdates } = this.state;
      const { message, objectType } = response;

      // capture the change in selection and save data in selection updates
      this.setState({
        captureSelectionChange: true,
        selectionUpdates:
          [...selectionUpdates, {
            actionType: Constants.UPDATE_SELECTION__ACTION_TYPE__REMOVE,
            message,
            objectType,
            tabName,
          }],
        isFieldsRequestDone: true,
      });
    };

    /**
     * Check dynamic type
     * @param {object} unionSelection - The union selection to check
     * @param {string} name - The name of the custom value
     * @param {object} aggregationValue - Aggregation value
     * @returns {void}
     */
    const handleAggregationValue = async (unionSelection, name, aggregationValue) => {
      const { selectedDataExtensions } = unionSelection;
      const { dataExtensionCustomerKey, fieldObjectId, relations } = aggregationValue;

      const {
        toCollectionAlias, toFieldObjectId, fromFieldObjectId, fromCollectionObjectId,
      } = relations;
      const fieldsToCheckfromDE = [];
      const fieldsToChecktoDE = [];

      // variable that determines whether error message will occur
      let errorMsg = false;

      // if access to the selection is not disabled
      if (!disabledAccessToSelection) {
        // set fields to check for aggregation relation to collection
        fieldsToChecktoDE.push([toCollectionAlias, toFieldObjectId]);

        for (let i = 0; i < fieldsToChecktoDE.length; i += 1) {
          const [alias, objectId] = fieldsToChecktoDE[i];

          const {
            error,
            missingDE,
            missingField,
            selectedDE,
          } = await checkFieldStillExists(
            selectedDataExtensions,
            DE => DE.deAlias === alias.toString(),
            f => f.ObjectID === objectId,
          );

          // Will be handled during loading in `componentDidMount`
          if (error && missingDE && alias !== null) {
            deleteCustomValue(
              unionSelection,
              name,
              selectedDE.Name.toString(),
              Constants.FIELD_OR_DATA_EXTENSION__DATA_EXTENSION,
            );

            // an error message will be shown
            errorMsg = true;

            /*
             * loop through array separately for dataextension and remove
             * show swal pop up
             */
            for (let j = missingFieldIn.length - 1; j >= 0; j -= 1) {
              const [cvName, deName, fieldOrDE, tabName] = missingFieldIn[j];

              // show swal error message about deleted DE
              await deleteErrorSwalMsgForCustomValue(cvName, deName, fieldOrDE, tabName);
            }
          } if (error && missingField && objectId !== null) {
            deleteCustomValue(
              unionSelection,
              name,
              selectedDE.Name.toString(),
              Constants.FIELD_OR_DATA_EXTENSION__FIELD,
            );
            // an error message will appear
            errorMsg = true;

            /*
             *  loop through missing fields separately for dataextension because fields
             *  are loaded from API and show swal popup.
             */
            for (let j = missingFieldIn.length - 1; j >= 0; j -= 1) {
              const [cvName, deName, fieldOrDE, tabName] = missingFieldIn[j];

              // show swal error message about deleted field in DE
              await deleteErrorSwalMsgForCustomValue(cvName, deName, fieldOrDE, tabName);
            }
          }
        }

        // check further if the field or DE exists when no errors have been displayed before
        if (!errorMsg) {
          // set fields to check for aggregation selected dataextension
          fieldsToCheckfromDE.push([fromCollectionObjectId, fieldObjectId, fromFieldObjectId]);

          for (let i = 0; i < fieldsToCheckfromDE.length; i += 1) {
            const [deObjectID, fieldId, fromFieldId] = fieldsToCheckfromDE[i];
            const {
              error,
              missingDE,
              missingField,
              selectedDE,
            } = await checkFieldStillExistsInAvailableDE(
              fetchedDataExtensions,
              DE => DE.ObjectID === deObjectID?.toString(),
              f => f.ObjectID === fieldId,
              fromField => fromField.ObjectID === fromFieldId,
            );

            // if DE or field is deleted, remove from custom value and mapped fields
            if (error && missingDE && deObjectID !== null) {
              // delete custom value when DE is removed
              deleteCustomValue(
                unionSelection,
                name,
                dataExtensionCustomerKey?.toString(),
                Constants.FIELD_OR_DATA_EXTENSION__DATA_EXTENSION,
              );
              errorMsg = true;
            } if (error && missingField && fieldId !== null) {
              // delete custom value when field is removed
              deleteCustomValue(
                unionSelection,
                name,
                selectedDE.Name.toString(),
                Constants.FIELD_OR_DATA_EXTENSION__FIELD,
              );
              errorMsg = true;
            }

            // if selected DE or selected field exists, then check in filters
            if (!error && fieldId !== null && !errorMsg) {
              // get filters from aggregationValue
              const { filters } = aggregationValue;
              const aggregationFilters = filters?.length && filters[0]?.filters;

              // get available fields from DE used in aggregation custom value
              const availableFields = selectedDE.fields;

              // add custom value name to aggregation value
              const aggregationCV = { ...aggregationValue, name };

              if (aggregationFilters?.length) {
                // loop through all filters looking for the missing field
                aggregationFilters.forEach((aggregationFilter) => {
                  checkFieldStillExistsInAggregationFilters({
                    filtersBranch: aggregationFilter,
                    availableFields,
                    subQueryModalFilterLineId: '0',
                    aggregationFilters,
                    aggregationCustomValue: aggregationCV,
                  });
                });
              }
            }

            if (missingFieldIn?.length) {
              // loop through each element in missingFieldIn and show swal pop up
              for (let j = missingFieldIn.length - 1; j >= 0; j -= 1) {
                const [cvName, deName, deOrField, tabName] = missingFieldIn[j];

                // show swal error message about deleted DE or field in custom value
                await deleteErrorSwalMsgForCustomValue(cvName, deName, deOrField, tabName);
              }
            }

            if (aggregationFiltersWithMissingFields?.length) {
              // loop through each element in aggregationFiltersWithMissingFields
              for (let l = 0; l < aggregationFiltersWithMissingFields.length; l += 1) {
                const {
                  filtersBranch,
                  subQueryFilters,
                  subQueryModalFilterLineId,
                  aggregationCustomValue,
                  comparedField,
                } = aggregationFiltersWithMissingFields[l];

                /*
                 * remove filterline with removed field in aggregation custom value and
                 * show swal error message
                 */
                await this.handleRemoveFilterLine({
                  filterLineId: filtersBranch.id,
                  fieldExists: true,
                  field: comparedField ? filtersBranch.comparedField.Name : filtersBranch.field,
                  dataExtension: filtersBranch.collectionAlias,
                  subQueryModalFilterLineId,
                  handleFiltersSave: this.handleFiltersSave,
                  filterType: Constants.FILTER_TYPE__CUSTOM_VALUES,
                  subQueryFilters,
                  aggregationCustomValue,
                });
              }
            }
          }
        }
      }
    };

    /**
     * Check dynamic type
     * @param {object} unionSelection - The union selection to check
     * @param {object} obj - A custom value
     * @param {string} obj.name - The name of the custom value
     * @param {array<object>} obj.criteria - The criteria used in the dynamic value
     * @returns {void}
     */
    const handleDynamicValue = async (unionSelection, { name, criteria = [] }) => {
      const { selectedDataExtensions } = unionSelection;

      criteria.forEach(({ when }) => {
        const resultFilters = [];

        /**
         * Gets all when statement filters and puts them in an array
         * @param {object} selectedFilters - An object containing an array of filters and operator
         * @returns {array} An array of filters
         */
        const getWhenFilters = (selectedFilters) => {
          for (let i = 0; i < selectedFilters.length; i += 1) {
            // Nested filter
            if (selectedFilters[i].filters) {
              getWhenFilters(selectedFilters[i].filters);
            } else {
              resultFilters.push(selectedFilters[i]);
            }
          }

          return resultFilters;
        };

        getWhenFilters(when.filters).forEach(({
          collectionAlias,
          fieldObjectID,
          comparedDataExtension = {},
          comparedField = {},
        }) => {
          const {
            error,
            missingDE,
            missingField,
            selectedDE,
          } = checkFieldStillExists(
            selectedDataExtensions,
            DE => DE.deAlias === collectionAlias.toString(),
            f => f.ObjectID === fieldObjectID,
          );

          let removedField = false;

          if (error && missingDE) return; // Will be handled during loading in `componentDidMount`
          if (error && missingField && !removedField) {
            deleteCustomValue(
              unionSelection,
              name,
              selectedDE.Name.toString(),
              Constants.FIELD_OR_DATA_EXTENSION__FIELD,
            );

            removedField = true;
          } else if (comparedDataExtension.fields) {
            // Check compared field if any
            const cmpFiled = comparedDataExtension.fields.find((f) => {
              // check if the compare field has an objectID
              if (f && f.ObjectID && comparedField && comparedField.ObjectID) {
                return f.ObjectID === comparedField.ObjectID;
              }

              // if not, return field ObjectID - it's for fields with 'no field to compare'
              return f.ObjectID;
            });

            // Handle missing compare fields
            if (!cmpFiled) {
              deleteCustomValue(
                unionSelection,
                name,
                selectedDE.Name.toString(),
                Constants.FIELD_OR_DATA_EXTENSION__FIELD,
              );
            }
          }
        });
      });
    };

    /**
     * Check formula type
     * @param {object} unionSelection - The union selection to check
     * @param {object} obj - A custom value
     * @param {string} obj.name - The name of the custom value
     * @param {string} obj.formula - Type of formula value (Date difference, timestamp, transform date)
     * @param {string} obj.dataExtensionObjectId - The ObjectID of the Data Extension selected in the custom value
     * @param {string} obj.fieldObjectId - The ObjectID of the Field selected in the custom value
     * @param {object} obj.field1 - First field of date difference
     * @param {object} obj.field2 - Second field of date difference
     * @returns {void}
     */
    const handleFormulaValue = (unionSelection, {
      name,
      formula,
      // Transform date
      dataExtensionObjectId,
      fieldObjectId,
      // Date difference
      field1,
      field2,
      // row number
      orderBy,
    }) => {
      const { selectedDataExtensions } = unionSelection;

      // find selected data extension - for row number custom value
      const selectedCollection = selectedDataExtensions?.find(de => de.deAlias === orderBy?.collectionAlias);

      const fieldsToCheck = [];

      if (formula === Constants.CUSTOM_VALUES__FORMULA_TYPE__TRANSFORM) {
        // case for transform date
        fieldsToCheck.push([dataExtensionObjectId, fieldObjectId]);
      } else if (formula === Constants.CUSTOM_VALUES__FORMULA_TYPE__DATE_DIFFERENCE) {
        // case for date difference
        fieldsToCheck.push(
          [field1.dataExtensionObjectId, field1.fieldObjectId],
          [field2.dataExtensionObjectId, field2.fieldObjectId],
        );
      } else if (formula === Constants.CUSTOM_VALUES__FORMULA_TYPE__ROW_NUMBER) {
        // case for row number formula
        fieldsToCheck.push([selectedCollection.ObjectID, orderBy?.fieldObjectID]);
      } else return;

      for (let i = 0; i < fieldsToCheck.length; i += 1) {
        const [dataExtensionObjectID, fieldObjectID] = fieldsToCheck[i];

        const {
          error,
          missingDE,
          missingField,
          selectedDE,
        } = checkFieldStillExists(
          selectedDataExtensions,
          DE => DE.ObjectID === dataExtensionObjectID,
          f => f.ObjectID === fieldObjectID,
        );

        // Will be handled during loading in `componentDidMount`
        if (error && missingDE && dataExtensionObjectID !== null) return;

        if (error && missingField && fieldObjectID !== null) {
          deleteCustomValue(unionSelection, name, selectedDE.Name.toString(), Constants.FIELD_OR_DATA_EXTENSION__FIELD);

          // This return is for date difference, as if both fields are missing we don't want to delete twice the value
          return;
        }
      }
    };

    // Check in all tab
    unionSelections.forEach((unionSelection) => {
      const { customValues = [] } = unionSelection;

      /**
       * Function for finding data view in data extensions
       * @param {array} deArray - array with data extensions
       * @param {string} deCustomerKey - customer key of the data extension we are looking for
       * @returns {object|*} object with data view if found
       */
      const findDataViewInDataExtensions = (deArray, deCustomerKey) => deArray.find(de => (
        de.CustomerKey === deCustomerKey && !de.CategoryID
      ));

      // check if data views exists in aggregation custom value
      const customValueWithDataView = customValues.find((cv) => {
        const deCustomerKey = cv?.aggregationValue?.dataExtensionCustomerKey;

        // if data views exists return this custom value
        if (findDataViewInDataExtensions(fetchedDataExtensions, deCustomerKey)) { return cv; }

        return false;
      });

      // if custom value with data view is found
      if (customValueWithDataView) {
        const { dataExtensionCustomerKey } = customValueWithDataView?.aggregationValue || {};

        // get data views from aggregation custom value
        const dataViews = findDataViewInDataExtensions(fetchedDataExtensions, dataExtensionCustomerKey);

        // if feature for data views is disabled, then block the access to display the selection
        if (dataViews && !featureDataViews) { disabledAccessToSelection = true; }
      }

      /**
       * Check missing fields for all custom values
       * To avoid problem with index (as we may delete custom values), we iterate backwards
       */

      for (let idx = customValues.length - 1; idx >= 0; idx -= 1) {
        const customValue = customValues[idx];

        switch (customValue.valueType) {
          case Constants.CUSTOM_VALUES__FIELD_TYPE__DYNAMIC_VALUE:
            handleDynamicValue(unionSelection, customValue);
            break;
          case Constants.CUSTOM_VALUES__FIELD_TYPE__FORMULA:
            handleFormulaValue(unionSelection, customValue);
            break;
          case Constants.CUSTOM_VALUES__FIELD_TYPE__AGGREGATION_VALUE:
            handleAggregationValue(unionSelection, customValue.name, customValue.aggregationValue);
            break;
          default:
            break;
        }
      }
    });

    // if selection access is disabled, it means that there is no data view feature
    if (disabledAccessToSelection) {
      await this.handleFeatureMissing(Constants.FEATURE__DATA_VIEWS_LABEL);
    } else {
      // If any missing field, we iterate backwards as values in missingFieldIn were added like that
      for (let i = missingFieldIn.length - 1; i >= 0; i -= 1) {
        const [cVName, dEName, deOrField, tabName] = missingFieldIn[i];

        await deleteErrorSwalMsgForCustomValue(cVName, dEName, deOrField, tabName);
      }
    }
  };

  /**
   * Gets predefined relations
   * @returns {Promise<array>} - predefined relations
   */
  getRelations = async () => {
    try {
      const relations = await RelationsAPI.getRelations(this.axiosCancelToken.token);

      const predefinedRelationsMap = {};

      relations.forEach((relation) => {
        /*
         * for each relation create an object with the key: relation ID and
         * value consisting of the array fromFieldObjectId and toFieldObjectId
         */
        predefinedRelationsMap[relation._id] = [relation?.fromFieldObjectId?.toString(),
          relation?.toFieldObjectId?.toString()];
      });

      // Set state
      this.setState({ predefinedRelations: relations, predefinedRelationsMap });
    } catch (error) {
      if (!axios.isCancel(error)) this.setState({ error });
    }
  };

  /**
   * Load Target Data extensions
   * @returns {Promise<any>} Will setState for `targetDataExtensions`.
   */
  loadTargetDataExtensions = async () => {
    try {
      const targetDE = await DataExtensionsAPI.getDataExtensions(
        this.axiosCancelToken.token,
        Constants.DATAEXTENSION__FILTER_MODE__TARGET,
      );

      this.setState({
        targetDataExtensions: targetDE,
        loadingForTargetDataExtensions: false,
      });
    } catch (error) {
      if (!axios.isCancel(error)) this.setState({ error });
    }
  };

  /**
   * Load Data sets
   * @returns {void}
   */
  loadAllAvailableDataExtensions = async () => {
    const { featuresInfo } = this.props;
    const featureDataSets = Features.isFeatureEnabled(featuresInfo, Constants.FEATURE__DATA_SETS);

    this.setState({
      loadingAllAvailableDataExtensions: true,
    });

    const fetchedDataExtensions = await DataExtensionsAPI.getDataExtensions(
      this.axiosCancelToken.token,
      Constants.DATAEXTENSION__FILTER_MODE__AVAILABLE,
    );

    const allAvailableDataExtensions = this.addDataViews(fetchedDataExtensions);

    let fetchedDataSets;

    if (featureDataSets) {
      const { predefinedRelations } = this.state;

      const predefinedRelationsCopy = JSON.parse(JSON.stringify(predefinedRelations));

      // get data sets
      const dataSets = await DataSetsAPI.getDataSets(this.axiosCancelToken.token);

      const dataExtensionsCopy = JSON.parse(JSON.stringify(allAvailableDataExtensions));

      const dataSetsCopy = [];

      dataSets.forEach((dataSet) => {
        let isCollectionFound = true;

        // find the data sets where the selected DEs still exist
        dataSet.collections?.forEach((collection) => {
          if (!dataExtensionsCopy.find(de => (de.ObjectID === collection.collectionObjectID) ||
            (de.CustomerKey === collection.collectionCustomerKey))) {
            isCollectionFound = false;
          }
        });

        if (isCollectionFound) {
          dataSetsCopy.push(dataSet);
        }
      });

      if (dataSetsCopy?.length && dataSetsCopy[0]._id) {
        // In case that predef relations have been changed update relations for the data sets
        dataSetsCopy.forEach((dataSet) => {
          dataSet.relations?.forEach((relation) => {
            predefinedRelationsCopy.forEach((predefRelation) => {
              // if relation has the same fromDE and toDE
              if (relation.fromCollectionCustomerKey === predefRelation.fromDECustomerKey &&
                relation.toCollectionCustomerKey === predefRelation.toDECustomerKey) {
                relation.additionalJoins = predefRelation.additionalJoins;
                relation.fromField = predefRelation.fromFieldName;
                relation.fromFieldObjectID = predefRelation.fromFieldObjectId;
                relation.fromFieldType = predefRelation.fromFieldType;
                relation.toField = predefRelation.toFieldName;
                relation.toFieldObjectID = predefRelation.toFieldObjectId;
                relation.toFieldType = predefRelation.toFieldType;
                // if relation has fromDE and toDE in the reverse order
              } else if (relation.fromCollectionCustomerKey === predefRelation.toDECustomerKey &&
                relation.toCollectionCustomerKey === predefRelation.fromDECustomerKey) {
                relation.additionalJoins = predefRelation.additionalJoins;
                relation.fromField = predefRelation.toFieldName;
                relation.fromFieldObjectID = predefRelation.toFieldObjectId;
                relation.fromFieldType = predefRelation.toFieldType;
                relation.toField = predefRelation.fromFieldName;
                relation.toFieldObjectID = predefRelation.fromFieldObjectId;
                relation.toFieldType = predefRelation.fromFieldType;
              }

              predefRelation.additionalJoins.forEach((join) => {
                if (!join.rowID) {
                  join.rowID = Util.uuid();
                }
              });
            });
          });
        });

        // format data sets relations and selected DEs
        const formattedDataSets = dataSetsCopy.map((dataSet) => {
          return Util.formatRelationsAndSelectedDEs(
            dataSet.relations,
            dataExtensionsCopy,
            dataSet.collections,
            true,
            this.setDataExtensionProperties,
            this.handleDataExtensionMissing,
            this.getDataExtensionOrDataViewFields,
            this.updateSubquerySelectedDEFields,
            this.updateComparableFiltersFields,
            this.getMatchedFieldsStateForSelection,
            this.addToSelectedDataExtensions,
            null,
            null,
            axios,
            null,
            null,
          );
        });

        fetchedDataSets = await Promise.all(formattedDataSets);

        fetchedDataSets = fetchedDataSets.map((dataSet, i) => {
          return {
            ...dataSet,
            name: dataSetsCopy[i].name,
            id: dataSetsCopy[i]._id,
          };
        });
        const dataSetDEs = fetchedDataSets.map((dataSet) => {
          return dataSet.selectedDataExtensions;
        }).flat().map(de => (
          {
            collectionCustomerKey: de.CustomerKey,
            collectionObjectID: de.ObjectID,
          }
        ));

        const dataExtensionsWithFields =
          await DataExtensionsAPI.getDataExtensionsAndFieldsByCustomerKeys(
            this.axiosCancelToken.token,
            _.uniq(dataSetDEs, 'customerKey'),
            null,
          );

        // get fields for each data sets selected DE
        for (let i = 0; i < fetchedDataSets.length; i += 1) {
          for (let j = 0; j < fetchedDataSets[i].selectedDataExtensions.length; j += 1) {
            const fields =
              dataExtensionsWithFields.find(field => field.ObjectID ===
                fetchedDataSets[i].selectedDataExtensions[j].ObjectID)?.fields;

            if (fields) {
              // Set DE fields
              fetchedDataSets[i].selectedDataExtensions[j].fields = fields;

              // eslint-disable-next-line
              fetchedDataSets[i].relations?.forEach((relation) => {
                if (relation.fromCollection.ObjectID === fetchedDataSets[i].selectedDataExtensions[j].ObjectID) {
                  // Assign fields and update additional joins for the fromDE part of relation
                  relation.fromCollection.fields = fields;

                  // Update the additionalJoins
                  fields.forEach((field) => {
                    relation.additionalJoins.forEach((join) => {
                      if (field.ObjectID === join.fromFieldObjectID) {
                        join.fromFieldObjectID = field.ObjectID;
                      } else if (field.ObjectID === join.toFieldObjectID) {
                        join.toFieldObjectID = join.fromFieldObjectID;
                        join.fromFieldObjectID = field.ObjectID;
                      }
                    });
                  });
                }

                if (relation.toCollection.ObjectID === fetchedDataSets[i].selectedDataExtensions[j].ObjectID) {
                  // Assign fields and update additional joins for the toDE part of relation
                  relation.toCollection.fields = fields;

                  fields.forEach((field) => {
                    relation.additionalJoins.forEach((join) => {
                      if ((field.ObjectID === join.fromFieldObjectID) ||
                        (field.ObjectID === join.toFieldObjectID)) {
                        join.toFieldObjectID = field.ObjectID;
                      }
                    });
                  });
                }
              });
            }
          }
        }
      }
    }

    this.setState({
      allAvailableDataExtensions,
      dataSets: fetchedDataSets || [],
      loadingAllAvailableDataExtensions: false,
    });
  };

  /**
   * Load Data extensions
   * @param {string} currentSelectionId - The current selection Id
   * @returns {Promise<any>} Will setState for `dataExtensions` and  `isDataExtensionRequestDone`.
   */
  loadDataExtensions = async (currentSelectionId) => {
    try {
      // Load data extensions asynchronously
      this.loadAllAvailableDataExtensions();

      const dataExtensions = await DataExtensionsAPI.getLimitedDataExtensions(
        this.axiosCancelToken.token,
        Constants.DATAEXTENSION__FILTER_MODE__AVAILABLE,
      );

      this.formatAvailableDEs(dataExtensions);

      // Set dragged state for DEs
      const DELength = dataExtensions.length;

      if (dataExtensions && DELength) {
        for (let i = 0; i < DELength; i += 1) {
          dataExtensions[i].dragged = false;
        }
      }

      this.setState({
        dataExtensions,
        loadedDataExtensions: dataExtensions,
        isDataExtensionRequestDone: true,
        loadingForDataExtensions: false,
      });

      if (!currentSelectionId) this.handleUpdateUnionSelectionsIfAppendDataExtensionsIsEnabled();
    } catch (error) {
      if (!axios.isCancel(error)) this.setState({ error });
    }
  };

  /**
   * This function closes 'setting button' on Target Definition page when clicked outside of the button
   * @param {object} e - e.target
   * @returns {void}
   */
  handleCloseSettingDiv = (e) => {
    const { openSettings } = this.state;

    if (
      e.target.className ===
      'slds-button slds-button_icon slds-button_icon-border-filled'
    ) return;
    if (openSettings) {
      if (
        e.target.className !==
        'slds-dropdown slds-dropdown_right slds-dropdown_actions active' ||
        e.target.getAttribute('href') !== '#!'
      ) {
        this.setState({ openSettings: !openSettings });
      }
    }
  };

  /**
   * Push dataViews in dataExtensions if feature 'dataViews' is enabled and then sort it
   * @param {object} dataExtensions - List of data extensions
   * @returns {void}
   */
  formatAvailableDEs = (dataExtensions) => {
    const { featuresInfo } = this.props;
    const featureDataViews = Features.isFeatureEnabled(featuresInfo, Constants.FEATURE__DATA_VIEWS);

    if (featureDataViews) {
      dataExtensions.push(...DataViews);
    }

    Util.sortArrayOfObjects(dataExtensions, 'Name');
  };

  /**
   * Push dataViews in dataExtensions if that feature is enabled
   * @param {object} dataExtensions - List of data extensions
   * @returns {Array} - Combination of data extensions and data views
   */
  addDataViews = (dataExtensions) => {
    const { featuresInfo } = this.props;
    const featureDataViews = Features.isFeatureEnabled(featuresInfo, Constants.FEATURE__DATA_VIEWS);

    if (featureDataViews) {
      return [...dataExtensions, ...DataViews];
    }

    return dataExtensions;
  };

  /**
   * Update unionSelections[i] to newState
   * @param {object} newState - this.state
   * @param {number} i - index of union selection
   * @returns {void}
   */
  handleUpdateUnionSelections = (newState, i) => {
    const { unionSelections, handleSetAppState } = this.props;

    unionSelections[i] = newState;
    handleSetAppState({ unionSelections });
  };

  handleUpdateUnionSelectionsIfAppendDataExtensionsIsEnabled = () => {
    const { selectionType } = this.state;

    if (selectionType === Constants.SELECTION__TYPE__UNION) {
      const { unionSelectionsIndex } = this.props;

      this.handleUpdateUnionSelections(this.state, unionSelectionsIndex);
    }
  };

  /**
   * Finds data extension in selected data extensions by deAlias.
   * If there are multiple data extensions with the same deAlias, it will then
   * find a match by ObjectID and deId.
   * @param {array} selectedDataExtensions - array with selected data extensions
   * @param {object} filter - object with filter data
   * @returns {object|*} object with selected data extension if found
   */
  findSelectedDE = (selectedDataExtensions, filter) => {
    const possibleDEs = selectedDataExtensions
      .filter(de => de.deAlias === filter.collectionAlias);

    if (possibleDEs.length === 1) {
      return possibleDEs[0];
    }

    return possibleDEs.find(de => de.ObjectID === filter.deId);
  };

  /**
   * Finds and return predefined relation data for given id
   * @param {string} relationId - relation id
   * @param {object} relationFilter - object with relation filter data
   * @returns {object|*} object with predefined relation if found
   */
  returnPredefinedRelationById = (relationId, relationFilter) => {
    const { predefinedRelations } = this.state;
    const { unionSelections } = this.props;

    let selectedDE;

    // find union selection with selectedDE used as fromDE in relation filter
    const unionForSelectedDE = unionSelections.find(us => (us?.selectedDataExtensions?.length &&
      this.findSelectedDE(us.selectedDataExtensions, relationFilter)
    ));

    // find selectedDE used as fromDE in relation filter
    if (unionForSelectedDE?.selectedDataExtensions) {
      selectedDE = this.findSelectedDE(unionForSelectedDE.selectedDataExtensions, relationFilter);
    }

    // if selectedDE is found
    if (predefinedRelations?.length && selectedDE?.ObjectID) {
      // get predefined relation by ID
      const predefinedRelation = predefinedRelations.find(relation => relation._id === relationId);

      if (predefinedRelation && relationFilter?.collectionAlias) {
        // check if toDE in predefined relation appears as selectedDE in the filter
        const toDEAsSelectedDE = predefinedRelation.toDEObjectId === selectedDE.ObjectID &&
          predefinedRelation.relation === Constants.RELATION__TYPE__ONE_TO_ONE;

        // check if fromDE in predefined relation appears as selectedDE in the filter
        const fromDEAsSelectedDE = predefinedRelation.fromDEObjectId === selectedDE.ObjectID;

        // check if relation is valid for selectedDE
        const validateRelationForSelectedDE = toDEAsSelectedDE || fromDEAsSelectedDE;

        // if relation is valid for selectedDE
        if (validateRelationForSelectedDE) {
          /*
           * check if DE in relation filter exists in reverse order than in predefined relation
           * (selected DE in relation filter (collectionAlias) = toDE in predefined relation)
           */
          const reverseOrder = toDEAsSelectedDE || !fromDEAsSelectedDE;

          // if relation filter exists in reverse order
          if (reverseOrder) {
            // return it in reverse order
            return { ...Util.replaceOrdersInPredefinedRelation(predefinedRelation), reverseOrder: true };
          }

          // in case of normal order, return predefined relation
          return { ...predefinedRelation, reverseOrder: false };
        }
      } else {
        // check if we can find another relation with the same properties (DEs, fields) and use that relation

        // get fromDE object and fromFieldObjectID
        const fromDEUsedInRelationFilter = selectedDE;
        const fromFieldObjectIDUsedInRelationFilter = relationFilter.fieldObjectID;

        // get toDE object and toFieldObjectID
        const toDEUsedInRelationFilter = relationFilter.subQuery.selectedDataExtension;
        const toFieldObjectIDUsedInRelationFilter = relationFilter.subQuery.fields[0].availableFieldObjectID;

        // check for normal order
        const predefinedRelationWithTheSamePropertiesInNormalOrder = predefinedRelations.find(relation => (
          (relation.fromDEObjectId === fromDEUsedInRelationFilter?.ObjectID &&
            relation.fromFieldObjectId === fromFieldObjectIDUsedInRelationFilter &&
            relation.toDEObjectId === toDEUsedInRelationFilter?.ObjectID &&
            relation.toFieldObjectId === toFieldObjectIDUsedInRelationFilter)
        ));

        // check for reverse order, but relation has to be 1-1
        const predefinedRelationWithTheSamePropertiesInReverseOrder = predefinedRelations.find(relation => (
          (relation.fromDEObjectId === toDEUsedInRelationFilter?.ObjectID &&
            relation.fromFieldObjectId === toFieldObjectIDUsedInRelationFilter &&
            relation.toDEObjectId === fromDEUsedInRelationFilter?.ObjectID &&
            relation.toFieldObjectId === fromFieldObjectIDUsedInRelationFilter &&
            relation.relation === Constants.RELATION__TYPE__ONE_TO_ONE)
        ));

        if (predefinedRelationWithTheSamePropertiesInNormalOrder) {
          // in case of normal order, return predefined relation
          return { ...predefinedRelationWithTheSamePropertiesInNormalOrder, reverseOrder: false };
        } if (predefinedRelationWithTheSamePropertiesInReverseOrder) {
          // if relation filter exists in reverse order, return it after reordering
          return {
            ...Util.replaceOrdersInPredefinedRelation(predefinedRelationWithTheSamePropertiesInReverseOrder),
            reverseOrder: true,
          };
        }

        return null;
      }
    }

    return null;
  };

  /**
   * Update relation filter if the field in predefined relation has changed
   * @param {object} filterData - all data for the updated filter
   * @param {object} filterData.filtersBranch - the branch in which the relation filter is located
   * @param {object} filterData.filterObject - object with relation filter
   * @param {object} filterData.predefinedRelation - predefined relation for relation filter
   * @param {boolean} filterData.changedFromRelationField - true/false depending on whether field for fromDE has been
   * changed in predefined relations
   * @param {boolean} filterData.changedToRelationField - true/false depending on whether field for toDE in subQuery.
   * fields has been changed in predefined relations
   * @param {boolean} filterData.changedFieldInRelationData - true/false depending on whether field for toDE in
   * relationData has been changed in predefined relations
   * @param {object} filterData.fieldForRelationData - object with field that exists in subQuery.relationData in
   * relation filter
   * @param {number} filterData.currentSelectionIndex - the current index number for the displayed selection
   * @param {string} filterData.newRelationId - the new relationId for the filter
   * @returns {void}
   */
  handleUpdateRelationFilterAfterFieldChange = async ({
    filtersBranch,
    filterObject,
    predefinedRelation,
    changedFromRelationField,
    changedNameFromRelationField,
    changedToRelationField,
    changedNameToRelationField,
    changedFieldInRelationData,
    fieldForRelationData,
    currentSelectionIndex,
    newRelationId,
  }) => {
    const { filters } = filtersBranch;

    // loop through all filters to find filterObject
    filters?.forEach((filter) => {
      // if there are filters nested in the filters, perform this function again
      if (filter.filters) {
        this.handleUpdateRelationFilterAfterFieldChange({
          filtersBranch: filter,
          filterObject,
          predefinedRelation,
          changedFromRelationField,
          changedNameFromRelationField,
          changedToRelationField,
          changedNameToRelationField,
          changedFieldInRelationData,
          fieldForRelationData,
          currentSelectionIndex,
          newRelationId,
        });

        // if filterObject is found in filters
      } else if (filter.id === filterObject.id) {
        let filterUpdateData = { status: false };

        /*
         * if field for toDE has changed in predefined relations, we need to update subQuery.fields[0] and
         * subQuery.relationData (in case of formula = 'count')
         */
        if (changedToRelationField || changedNameToRelationField) {
          // show data updates only when field has been changed (not field name)
          if (changedToRelationField) filterUpdateData = { status: true };

          // update subQuery.fields[0]
          if (filter?.subQuery?.fields?.length) {
            /* eslint-disable no-param-reassign */
            filter.subQuery.fields[0].availableFieldObjectID = predefinedRelation.toFieldObjectId;
            filter.subQuery.fields[0].field = predefinedRelation.toFieldName;
          }

          // update subQuery.relationData for COUNT formula
          if (filter?.subQuery?.relationData?.formula === Constants.FORMULA__VALUE__COUNT) {
            filter.subQuery.relationData.fieldObjectID = predefinedRelation.toFieldObjectId;
            filter.subQuery.relationData.fieldType = predefinedRelation.toFieldType;
            filter.subQuery.relationData.field = predefinedRelation.toFieldName;
          }
        }

        // update subQuery.relationData for other formulas than COUNT
        if (changedFieldInRelationData && fieldForRelationData) {
          // do not set update data, because in this case the field name has changed, which we do not show in the modal
          filterUpdateData = { status: false };

          if (filter?.subQuery?.relationData?.formula !== Constants.FORMULA__VALUE__COUNT) {
            filter.subQuery.relationData.fieldObjectID = fieldForRelationData.ObjectID;
            filter.subQuery.relationData.fieldType = fieldForRelationData.FieldType;
            filter.subQuery.relationData.field = fieldForRelationData.Name;
          }
        }

        // if field for fromDE has changed in predefined relations, we need to update field data in filter object
        if (changedFromRelationField || changedNameFromRelationField) {
          // show data updates only when field has been changed (not field name)
          if (changedFromRelationField) filterUpdateData = { status: true };

          // update field data
          filter.field = predefinedRelation.fromFieldName;
          filter.fieldObjectID = predefinedRelation.fromFieldObjectId;
          filter.fieldType = predefinedRelation.fromFieldType;
        }

        // if relationId has changed, then assign new relationId
        if (newRelationId && filter?.relationId) {
          // do not save this information in selectionUpdates
          filterUpdateData = { status: false };

          filter.relationId = newRelationId;
          /* eslint-enable no-param-reassign */
        }

        const { unionSelections, unionSelectionsIndex, handleSetAppState } = this.props;

        // get the tabName for which the relation filter changes
        const { tabName } = unionSelections[currentSelectionIndex];

        // navigate to the proper selection view
        if (currentSelectionIndex !== unionSelectionsIndex) {
          handleSetAppState({ unionSelectionsIndex: currentSelectionIndex });
        }

        // if the status in filterUpdateData is true it means that the data will be shown in selection Update modal
        if (filterUpdateData?.status) {
          // get proper message and object type
          const { message, objectType } = selectionUpdatesUtil
            .updatedFieldOrIdInRelationFilterResponse(
              filterUpdateData,
              filter?.collectionAlias,
              filter.subQuery?.relationData?.collectionAlias,
            );

          const { selectionUpdates } = this.state;

          // set this data in selectionUpdates array
          this.setState({
            captureSelectionChange: true,
            selectionUpdates: [...selectionUpdates, {
              actionType: Constants.UPDATE_SELECTION__ACTION_TYPE__UPDATE,
              objectType,
              message,
              tabName,
            }],
          });
        }
      }
    });
  };

  /**
   * Check if the relationFilter exists based on the fetched predefinedRelations
   * @param {object} filterObject - relation filter object
   * @param {array} selectedFilters - relation filter object
   * @param {number} currentSelectionIndex - the current index number for the displayed selection
   * @returns {object} object with predefinedRelation for relation filter (if valid)
   */
  checkIfRelationFilterExists = async (filterObject, selectedFilters, currentSelectionIndex) => {
    let predefinedRelation;

    if (filterObject.relationId) {
      // get predefined relation for relation filter
      predefinedRelation = this.returnPredefinedRelationById(filterObject.relationId, filterObject);
    }

    if (predefinedRelation) {
      // validate toDE in predefined relation
      const validateRelationForToDE = predefinedRelation.toDEObjectId ===
        filterObject.subQuery.selectedDataExtension.ObjectID;

      // if relation is valid
      if (validateRelationForToDE) {
        // check if the fromField has been changed in relation filter
        const changedFromRelationField = predefinedRelation.fromFieldObjectId !==
          filterObject.fieldObjectID;

        // check if fromField name has been changed
        const changedNameFromRelationField = !changedFromRelationField &&
          predefinedRelation.fromFieldName !== filterObject.field;

        // check if the toField has been changed in relation filter
        const changedToRelationField = predefinedRelation.toFieldObjectId !==
          filterObject.subQuery.fields[0].availableFieldObjectID;

        // check if toField name has been changed
        const changedNameToRelationField = !changedToRelationField &&
          predefinedRelation.toFieldName !== filterObject.subQuery.fields[0].field;

        // get field that exists in relationData (this may be a field other than toDEField)
        const fieldForRelationData = predefinedRelation.toDEFields.find(field => field.ObjectID ===
          filterObject.subQuery.relationData.fieldObjectID);

        // check if name for this field has changed (if it does not exist, the filter will be removed)
        const changedFieldInRelationData = fieldForRelationData &&
          fieldForRelationData.Name !== filterObject.subQuery.relationData.field;

        let newRelationId;

        // check if relationId has changed
        if (filterObject.relationId !== predefinedRelation._id) {
          newRelationId = predefinedRelation._id;
        }

        // a condition that informs whether anything has changed in the relation filter
        const captureChangeInRelationFilter = changedFromRelationField || changedToRelationField ||
          changedFieldInRelationData || newRelationId || changedNameFromRelationField || changedNameToRelationField;

        // if any of the fields or relationId has changed, update it in relation filter
        if (captureChangeInRelationFilter) {
          this.handleUpdateRelationFilterAfterFieldChange({
            filtersBranch: selectedFilters,
            filterObject,
            predefinedRelation,
            changedFromRelationField,
            changedNameFromRelationField,
            changedToRelationField,
            changedNameToRelationField,
            changedFieldInRelationData,
            fieldForRelationData,
            currentSelectionIndex,
            newRelationId,
          });
        }

        // return relation
        return predefinedRelation;
      }
    }

    return null;
  };

  /**
   * Find data extension so we can add all properties that data extension should have
   * @param {object} extension - "Refreshed" DE which we are going to use in existing selection
   * @param {object} filterObject - relation data to remove the filter
   * @returns {object|*} An object if data extension exists or returns nothing and redirects to overview
   */
  findDataExtension = async (extension, filterObject) => {
    const { dataExtensions } = this.state;

    let exists = false;

    for (let i = 0; i < dataExtensions.length; i += 1) {
      // This if is for checking if regular DEs in filters still exist
      if (extension.ObjectID && extension.ObjectID === dataExtensions[i].ObjectID) {
        // eslint-disable-next-line no-param-reassign
        extension = { ...extension, ...dataExtensions[i] };
        exists = true;
        break;
        // This if is for checking if regular DEs in SUBQUERY filters still exist
      } else if (extension.collectionObjectID && extension.collectionObjectID === dataExtensions[i].ObjectID) {
        // eslint-disable-next-line no-param-reassign
        extension = { ...extension, ...dataExtensions[i] };
        exists = true;
        break;
        // This if is for checking if Data Views in SUBQUERY filters still exist
      } else if (extension.collectionCustomerKey &&
        // eslint-disable-next-line no-param-reassign
        extension.collectionCustomerKey === dataExtensions[i].CustomerKey
      ) {
        /* eslint-disable no-param-reassign */
        extension = { ...extension, ...dataExtensions[i] };
        extension.Name = extension.collectionCustomerKey;
        extension.deAlias = extension.collectionCustomerKey;
        /* eslint-enable no-param-reassign */
        exists = true;
        break;
        // This if is for checking if Data Views in filters still exist
      } else if (extension.CustomerKey === dataExtensions[i].CustomerKey) {
        /* eslint-disable no-param-reassign */
        extension = { ...extension, ...dataExtensions[i] };
        extension.Name = extension.CustomerKey;
        extension.deAlias = extension.CustomerKey;
        /* eslint-enable no-param-reassign */
        exists = true;
        break;
      }
    }

    // Check if DE/Data View still exists
    if (exists) {
      // eslint-disable-next-line no-param-reassign, require-atomic-updates
      extension.dragged = true;

      return extension;
    }

    if (extension.Name) {
      // needed exception as value has to be set even if its a param
      // eslint-disable-next-line no-param-reassign, require-atomic-updates
      extension.Name = extension.Name.toString();
    }

    let inSubQuery;

    if (filterObject?.subQuery?.collections?.length) {
      // check if DE exists in subQueryDataExtensions used in filters
      inSubQuery = filterObject.subQuery.collections[0].collectionObjectID === extension.collectionObjectID;
    }

    // if this data extension is used in subQueryDataExtensions
    if (inSubQuery) {
      // call a function checking DE and fields in filters
      await this.checkMissingFieldsInFilters();
    } else {
      // show modal with the information that selected DE does not exist
      await this.handleDataExtensionMissing(extension.Name || extension.collection);
    }

    return false;
  };

  /**
   * If we change field name in SFMC update compared field when loading selection
   * @param {object[]} extension - DE
   * @param {object[]} field - Matched fields in target definition
   * @returns {object[]} The new compared field
   */
  updateComparedField = (extension, field) => {
    let fieldExists = false;

    for (let i = 0; i < extension.fields.length; i += 1) {
      if (field && field.ObjectID === extension.fields[i].ObjectID) {
        // eslint-disable-next-line no-param-reassign
        field = { ...field, ...extension.fields[i] };
        fieldExists = true;
        break;
      }
    }

    if (fieldExists) {
      return field;
    }

    return field;
  };

  /**
   * Function for updating subquery properties
   * @param {object[]} extension - DE
   * @param {object} subQuery - sub query of filters
   * @returns {void}
   */
  updateSubQueryProperties = (extension, subQuery) => {
    extension?.forEach((ext) => {
      if (ext.CustomerKey.toString() === subQuery?.selectedDataExtension?.CustomerKey.toString()) {
        /* eslint-disable no-param-reassign */
        subQuery.selectedDataExtension.Name = ext.Name.toString();
        subQuery.selectedDataExtension.deAlias = ext.Name.toString();
        // Id should be unique
        subQuery.selectedDataExtension.id = subQuery.selectedDataExtension.Name.toString() + new Date().getTime();
        subQuery.collections[0].collection = ext.Name.toString();
        subQuery.collections[0].alias = ext.Name.toString();
        // assign ObjectID property for filters using data views
        if (!subQuery.collections[0].collectionObjectID) { subQuery.collections[0].collectionObjectID = ext.ObjectID; }
        subQuery.fields[0].collectionAlias = ext.Name.toString();

        if (subQuery?.relationData?.collectionAlias) {
          // update DE name in relation data for relation filter
          subQuery.relationData.collectionAlias = ext.Name.toString();
        }
        /* eslint-enable no-param-reassign */
      }
    });

    if (subQuery?.filters?.filters) {
      subQuery.filters.filters?.forEach((field) => {
        // eslint-disable-next-line no-param-reassign
        field.collectionAlias = subQuery.selectedDataExtension.deAlias.toString();
      });
    }
  };

  /**
   * It shows multiselect dropdown by default for a filter
   * @param {object[]} filtersBranch - selectedFilters
   * @returns {void}
   */
  updateSelectedFiltersIsPicklistOptions = (filtersBranch) => {
    const filtersArray = filtersBranch.filters || [];

    // Loop through current level of filterline
    for (let i = 0; i < filtersArray.length; i += 1) {
      // If there are filters inside filters call function again
      if (filtersArray[i].filters) {
        this.updateSelectedFiltersIsPicklistOptions(filtersArray[i]);
      } else {
        if (typeof filtersArray[i].isPicklistOptions === 'undefined' ||
          !filtersArray[i].isPicklistOptions) {
          filtersArray[i].isPicklistOptions = true;
        }
      }
    }
  };

  /**
   * If subquery exists we go through subquery selected DE fields
   * And call API to get DE fields
   * @param {object[]} filtersBranch - selectedFilters
   * @returns {void}
   */
  updateSubquerySelectedDEFields = async (filtersBranch) => {
    const { orgInfo, featuresInfo } = this.props;
    const featureSubQueriesIsEnabled = Features.isFeatureEnabled(featuresInfo, Constants.FEATURE__SUB_QUERIES);
    const { dataExtensions } = this.state;
    const promisesArray = [];
    const filtersArray = filtersBranch.filters || [];

    // Loop through current level of filterline
    for (let i = 0; i < filtersArray.length; i += 1) {
      // If there are filters inside filters call function again
      if (filtersArray[i].filters) {
        this.updateSubquerySelectedDEFields(filtersArray[i], 'self');
      } else {
        /**
         * Check if it has subquery object and if it includesSubQueries
         * Then get the fields and assign it
         */
        const isInResultsOrNotInResults = filtersArray[i].criteria === Constants.FILTERLINE__CRITERIA__IN_RESULTS ||
          filtersArray[i].criteria === Constants.FILTERLINE__CRITERIA__NOT_IN_RESULTS;

        if (
          isInResultsOrNotInResults &&
          filtersArray[i].subQuery &&
          filtersArray[i].includesSubQuery &&
          filtersArray[i].subQuery.collections &&
          filtersArray[i].subQuery.collections.length > 0
        ) {
          if (featureSubQueriesIsEnabled || (filtersArray[i].relation && Util.isDESelectFreeUser(orgInfo))) {
            try {
              // Find subQuery DE
              this.setState(prevState => ({
                loadingSubQueryFields: {
                  ...prevState.loadingSubQueryFields,
                  [filtersArray[i].id]: true,
                },
              }));
              promisesArray.push(
                new Promise((resolve, reject) => {
                  this.findDataExtension(filtersArray[i].subQuery.collections[0], filtersArray[i])
                    .then((selectedDE) => {
                      filtersArray[i].subQuery.selectedDataExtension = selectedDE;

                      this.getDataExtensionOrDataViewFields(selectedDE)
                        .then((v) => {
                          this.forceUpdate();

                          return resolve([v, i]);
                        }).then(() => {
                          const { loadingSubQueryFields } = this.state;
                          const updatedLoadingSubQueryFields = { ...loadingSubQueryFields };

                          this.setState({ loadingSubQueryFields: updatedLoadingSubQueryFields });
                          updatedLoadingSubQueryFields[filtersArray[i].id] = false;
                        })
                        .catch(reject);
                    })
                    .catch(reject);
                }),
              );
            } catch (error) {
              if (!axios.isCancel(error)) this.setState({ error });
            }
          } else {
            // eslint-disable-next-line no-await-in-loop
            await this.handleFeatureMissing(Constants.FEATURE__SUB_QUERIES_LABEL);

            return;
          }
        }
      }
    }

    try {
      // Await all requests
      const promiseResults = await Promise.all(promisesArray);

      for (let i = 0; i < promiseResults.length; i += 1) {
        const [subQuerySelectedDEFields, filterIndex] = promiseResults[i];
        const currentFilter = filtersArray[filterIndex];

        /**
         * If there are fields that means that DE still exists so set subquery selected DE fields
         * Otherwise show a message that says 'Data Extension cannot be found...'
         */
        if (subQuerySelectedDEFields) {
          this.updateSubQueryProperties(dataExtensions, currentFilter?.subQuery);

          if (currentFilter?.subQuery?.selectedDataExtension) {
            /* eslint-disable require-atomic-updates */
            currentFilter.subQuery.selectedDataExtension.fields = subQuerySelectedDEFields;

            // keep only fields in dataExtensionFields that can be mapped
            currentFilter.subQuery.dataExtensionFields =
              this.findMatchedFields(currentFilter.subQuery, currentFilter.fieldType, subQuerySelectedDEFields);

            // check if name for field in subQuery is correctly set
            const subQueryField = subQuerySelectedDEFields.find(field => field.ObjectID ===
              currentFilter.subQuery.fields[0].availableFieldObjectID);

            // assign a name to the field belonging to subQuery
            if (subQueryField?.Name?.toString()) {
              currentFilter.subQuery.fields[0].field = subQueryField.Name.toString();
            }
          }
          /* eslint-enable require-atomic-updates */
        } else {
          /**
           * Function getDataExtensionOrDataViewFields returns null if feature 'dataViews' is disabled
           * In that case we don't want to see error message data missing...
           * In other case getDataExtensionOrDataViewFields function returns 'undefined'
           * That means DE is deleted so we want to see error data missing...
           */
          if (subQuerySelectedDEFields === null) return;
          if (subQuerySelectedDEFields === undefined) {
            // eslint-disable-next-line no-await-in-loop
            await this.handleDataExtensionMissing(currentFilter.subQuery.selectedDataExtension.Name.toString());

            return;
          }
        }
      }
    } catch (error) {
      if (!axios.isCancel(error)) this.setState({ error });
    }
  };

  /**
   * This function finds fields for data extension that can be mapped with subQuery fields
   * @param {object} subQuery - subQuery object
   * @param {string} fieldType - fieldType from filter object
   * @param {array} receivedDataExtensionFields - array with fields for selected data extension
   * @returns {array} array with mapped fields for Data Extension
   */
  findMatchedFields = (subQuery, fieldType, receivedDataExtensionFields) => {
    // check if any field is mismatched
    const filterUnavailableField = subQuery.fields.filter(sq => !sq.availableFieldObjectID);

    // declare variable for mapped fields
    let filterMappedFields;

    if (receivedDataExtensionFields && receivedDataExtensionFields.length) {
      // filter only fields that match the field type
      filterMappedFields = receivedDataExtensionFields.filter(f => Util.canFieldBeMapped(fieldType, f.FieldType));
    }

    // declare a variable that returns the matching fields for data extension
    let mappedDataExtensionFields;

    if (filterUnavailableField.length === 0 && filterMappedFields.length === receivedDataExtensionFields.length) {
      // if all fields for data extension match then return these fields
      mappedDataExtensionFields = receivedDataExtensionFields;
    } else if (filterUnavailableField.length === 0 && filterMappedFields.length !==
      receivedDataExtensionFields.length) {
      // if not all fields for data extension are matched, return only those matched
      mappedDataExtensionFields = filterMappedFields;
    } else if (filterUnavailableField.length !== 0) {
      // if a field is found with no matching fields, return an empty array
      mappedDataExtensionFields = [];
    }

    return mappedDataExtensionFields;
  };

  /**
   * This functions updates the comparable fields for comparedDataExtension of the compared filter
   * @param {object} filtersBranch - branch of filters
   * @returns {void}
   */
  updateComparableFiltersFields = async (filtersBranch) => {
    const { featuresInfo } = this.props;
    const featureCompareFields = Features.isFeatureEnabled(featuresInfo, Constants.FEATURE__COMPARE_FIELDS);
    const promisesArray = [];
    const filtersArray = filtersBranch.filters || [];

    for (let f = 0; f < filtersArray.length; f += 1) {
      if (filtersArray[f].filters) {
        this.updateComparableFiltersFields(filtersArray[f]);
      } else {
        // Check if it is compare fields filter
        if (filtersArray[f]?.isCompareFieldsFilter) {
          if (featureCompareFields) {
            promisesArray.push(
              new Promise((resolve, reject) => {
                // Find compared data extension
                this.findDataExtension(filtersArray[f].comparedDataExtension)
                  .then((DE) => {
                    filtersArray[f].comparedDataExtension = DE;

                    // If feature is enabled then update it's availableFields
                    this.getDataExtensionOrDataViewFields(DE)
                      .then(v => resolve([v, f]))
                      .catch(reject);
                  })
                  .catch(reject);
              }),
            );
          } else {
            // If feature is disabled then throw an error
            // eslint-disable-next-line no-await-in-loop
            await this.handleFeatureMissing(Constants.FEATURE__COMPARE_FIELDS_LABEL);

            return;
          }
        } else if (
          filtersArray[f]?.includesSubQuery &&
          filtersArray[f]?.subQuery?.filters?.filters
        ) {
          for (let sf = 0; sf < filtersArray[f].subQuery.filters.filters.length; sf += 1) {
            if (filtersArray[f].subQuery.filters.filters[sf].filters) {
              this.updateComparableFiltersFields(filtersArray[f].subQuery.filters.filters[sf]);
            } else {
              // Check if it is compare fields filter
              if (filtersArray[f].subQuery.filters.filters[sf].isCompareFieldsFilter) {
                if (featureCompareFields) {
                  // Find subquery compared data extension
                  promisesArray.push(
                    new Promise((resolve, reject) => {
                      // Find subquery compared data extension
                      this.findDataExtension(filtersArray[f].subQuery.filters.filters[sf].comparedDataExtension)
                        .then((comparedDE) => {
                          filtersArray[f].subQuery.filters.filters[sf].comparedDataExtension = comparedDE;

                          // If feature is enabled then update it's availableFields
                          this.getDataExtensionOrDataViewFields(comparedDE)
                            .then(v => resolve([v, f, sf]))
                            .catch(reject);
                        })
                        .catch(reject);
                    }),
                  );
                } else {
                  // If feature is disabled then throw an error
                  // eslint-disable-next-line no-await-in-loop
                  await this.handleFeatureMissing(Constants.FEATURE__COMPARE_FIELDS_LABEL);

                  return;
                }
              }
            }
          }
        }
      }
    }

    const promiseResults = await Promise.all(promisesArray);

    for (let i = 0; i < promiseResults.length; i += 1) {
      const [comparableFields, filterIndex, subQueryFilterIndex] = promiseResults[i];
      const currentFilter = filtersArray[filterIndex];

      if (currentFilter?.isCompareFieldsFilter) {
        /**
         * If feature is enabled and the api call is succeed then assign it to
         * It`s comparableFields property
         */
        if (comparableFields) {
          currentFilter.comparableFields = comparableFields;
          if (currentFilter.comparedField) {
            // Update compared field
            currentFilter.comparedField = this.updateComparedField(
              currentFilter.comparedDataExtension,
              currentFilter.comparedField,
            );
          }
        } else if (comparableFields === undefined) {
          // If the api call did return undefined then throw an error
          // eslint-disable-next-line no-await-in-loop
          await this.handleDataExtensionMissing(currentFilter.comparedDataExtension.Name.toString());

          return;
        } else if (comparableFields === null) {
          // If it`s null do nothing
          return;
        }
      } else if (
        currentFilter?.includesSubQuery &&
        currentFilter?.subQuery?.filters?.filters
      ) {
        if (comparableFields) {
          /**
           * If feature is enabled and the api call is succeed then assign it to
           * It`s comparableFields property
           */
          currentFilter.subQuery.filters.filters[subQueryFilterIndex].comparableFields = comparableFields;
          // Update subquery compared field
          currentFilter.subQuery.filters.filters[subQueryFilterIndex].comparedField = this.updateComparedField(
            currentFilter.subQuery.filters.filters[subQueryFilterIndex].comparedDataExtension,
            currentFilter.subQuery.filters.filters[subQueryFilterIndex].comparedField,
          );
        } else if (comparableFields === undefined) {
          // If the api call did return undefined then throw an error
          // eslint-disable-next-line no-await-in-loop
          await this.handleDataExtensionMissing(
            currentFilter.subQuery.filters.filters[subQueryFilterIndex].comparedDataExtension.Name.toString(),
          );

          return;
        } else if (comparableFields === null) {
          // If it`s null do nothing
          return;
        }
      }
    }
  };

  /**
   * Updates DESelect Data Format to subQuery state
   * @param {object} filtersBranch - filters
   * @param {array} dataExtensionsCopy - Copy of data extensions state
   * @returns {void}
   */
  updateSubQuerySelectedDataExtension = ({ filters: filtersBranch } = [], dataExtensionsCopy) => {
    for (let f = 0; f < filtersBranch.length; f += 1) {
      if (filtersBranch[f].filters) {
        this.updateSubQuerySelectedDataExtension(filtersBranch[f], dataExtensionsCopy);
      } else {
        // Check if filter`s includesSubQuery is exist/true and has subQuery properties
        if (filtersBranch[f].includesSubQuery && filtersBranch[f].subQuery) {
          let selectedDataExtensionOrDataView;
          /**
           * Check subQuery collections` alias starts with _
           * If so it`s a dataView
           */

          if (Util.startsWithUnderScore(filtersBranch[f].subQuery.collections[0].alias.toString())) {
            // Find the dataViews and assign to a variable
            selectedDataExtensionOrDataView = DataViews.find(
              dv => dv.Name.toString() === filtersBranch[f].subQuery.collections[0].alias.toString(),
            );
          } else {
            /**
             * If collection is not a dataView find it in the dataExtensions array
             * And assign to a variable
             */
            selectedDataExtensionOrDataView = dataExtensionsCopy.find(
              de => de.ObjectID === filtersBranch[f].subQuery.collections[0].collectionObjectID,
            );
          }
          /**
           * If the variable exists or has a value then assign it to subQuery
           * SelectedDataExtension property
           */
          if (selectedDataExtensionOrDataView) {
            /* eslint-disable no-param-reassign */
            filtersBranch[f].subQuery.collections[0].collectionCustomerKey =
              selectedDataExtensionOrDataView.CustomerKey.toString();

            // eslint-disable-next-line no-param-reassign
            filtersBranch[f].subQuery.selectedDataExtension = selectedDataExtensionOrDataView;
          }
        }
      }
    }
  };

  /**
   * Adds all needed properties into DE
   * @param {object} extension - DE
   * @param {string} alias - Alias of DE
   * @param {object[]} subCollections - Sub collections
   * @param {string} toFromField - Indicates previous field
   * @param {number} toFromIndex - Indicates previous index
   * @param {number} relationsIndex - Indicates index in relations array
   * @param {string} toFromFieldType - Indicates type of previous field
   * @param {string} toFromFieldObjectID - Indicates ObjectId of the field
   * @returns {void}
   */
  setDataExtensionProperties = (
    extension,
    alias,
    subCollections,
    toFromField,
    toFromIndex,
    relationsIndex,
    toFromFieldType,
    toFromFieldObjectID,
  ) => {
    /* eslint-disable no-param-reassign */
    extension.dragged = true;
    extension.id = alias + new Date().getTime();
    extension.deAlias = alias;
    // Push all subCollections, filter(remove) them if there is/are subCollections' subCollections
    extension.subCollections = (extension.subCollections ?
      [...extension.subCollections, subCollections] :
      subCollections
    ).filter(collection => !(collection && collection.length >= 0));
    // 0 -> From (parent extension) properties
    if (toFromIndex === 0) {
      // 1 -> To (child extension) properties
    } else if (toFromIndex === 1) {
      extension.edit = true;
      extension.toField = toFromField;
      extension.toFieldType = toFromFieldType;
      extension.toFieldObjectID = toFromFieldObjectID;
      /**
       * Create unique Id for relationalModal using getTime() and relationsIndex (adding to ensure
       * RelationalModalIds built in same millisecond are still unique)
       */
      extension.relationalModalId = Number.parseInt(
        new Date().getTime() + '' + (typeof relationsIndex === 'number' ? relationsIndex : 0),
      );
    }
    /* eslint-enable no-param-reassign */
  };

  /**
   * Pushes related data extensions (if there is no duplication) into selectedDataExtension state
   * @param {object[]} selectedDataExtensions - Selected DE
   * @param {object} toCollection - To which collection
   * @param {object} fromCollection - From which collection
   * @returns {object[]} The selected data extensions with new related data extension items
   */
  addToSelectedDataExtensions = (selectedDataExtensions, toCollection, fromCollection) => {
    // If both toCollection and fromCollection don't exist; push both of them.
    if (
      selectedDataExtensions.filter(ex => ex.deAlias === toCollection.deAlias) &&
      selectedDataExtensions.filter(ex => ex.deAlias === toCollection.deAlias).length === 0 &&
      selectedDataExtensions.filter(ex => ex.deAlias === fromCollection.deAlias) &&
      selectedDataExtensions.filter(ex => ex.deAlias === fromCollection.deAlias).length === 0
    ) {
      // Add both from and to collection
      // eslint-disable-next-line no-param-reassign
      selectedDataExtensions = [...selectedDataExtensions, fromCollection, toCollection];
      // Push fromCollection if toCollection exists in selectedDataExtensions and fromCollection does not.
    } else if (
      selectedDataExtensions.filter(ex => ex.deAlias === fromCollection.deAlias) &&
      selectedDataExtensions.filter(ex => ex.deAlias === fromCollection.deAlias).length === 0) {
      // Add only from collection
      // eslint-disable-next-line no-param-reassign
      selectedDataExtensions = [...selectedDataExtensions, fromCollection];
      // Push toCollection if fromCollection exists in selectedDataExtensions and toCollection does not.
    } else if (
      selectedDataExtensions.filter(ex => ex.deAlias === toCollection.deAlias) &&
      selectedDataExtensions.filter(ex => ex.deAlias === toCollection.deAlias).length === 0
    ) {
      // Add only to collection
      // eslint-disable-next-line no-param-reassign
      selectedDataExtensions = [...selectedDataExtensions, toCollection];
    }

    return selectedDataExtensions;
  };

  /**
   * Convert the stored value of a selection into the required states
   * @param {object[]} relations - DE relations
   * @param {object[]} collections - DE
   * @param {object[]} fields - Matched  fields in target definition
   * @param {object[]} filters - Selected filters
   * @param {array} customValues - Array of custom values
   * @param {boolean} requestIsNotDone - parameter indicating that isFieldsRequestDone is not true
   * @param {object} selectedDataSet - selected dataSet when using basic mode
   * @param {array} globalCustomValues - Array of shared custom values
   * @param {object} sourceLimit - Limit of source
   * @param {array} selectedDataExtensionsWithTargetDE - Array of selected data extensions with target DE
   * @returns {void}
   */
  loadStateForSelection = async (
    relations,
    collections,
    fields,
    filters,
    customValues,
    requestIsNotDone,
    selectedDataSet,
    globalCustomValues,
    sourceLimit,
    selectedDataExtensionsWithTargetDE,
  ) => {
    const { allAvailableDataExtensions } = this.state;

    const dataExtensions = allAvailableDataExtensions.length ?
      allAvailableDataExtensions :
      selectedDataExtensionsWithTargetDE || [];

    const dataExtensionsCopy = JSON.parse(JSON.stringify(dataExtensions));
    const fieldsMap = new Map();

    // Default valueß
    let returnState = {
      isFieldsRequestDone: !requestIsNotDone,
      matchedFields: [],
      modalDataExtensions: {},
      relations: [],
      selectedFilters: [],
      selectedDataExtensions: [],
      customValues,
      sourceLimit,
    };

    const selectedFilters = filters && filters[0] ?
      filters[0] :
      { filters: [], operator: Constants.FILTERLINE__OPERATOR__AND };

    this.updateSubQuerySelectedDataExtension(selectedFilters, dataExtensionsCopy);

    this.updateSelectedFiltersIsPicklistOptions(selectedFilters);

    if (customValues?.length) {
      customValues.forEach((cv) => {
        // for aggregation custom value check if CustomerKey has changed
        if (cv?.aggregationValue) {
          const { aggregationValue } = cv;
          const { dataExtensionCustomerKey } = aggregationValue;
          const { fromCollectionObjectId } = aggregationValue.relations;

          // get the DE by ObjectID
          const deWithCustomerKey = dataExtensionsCopy.find(de => (
            de.ObjectID?.toString() === fromCollectionObjectId?.toString()));

          // update CustomerKey in aggregationValue if it has been changed
          if (deWithCustomerKey && dataExtensionCustomerKey?.toString() !==
            deWithCustomerKey?.CustomerKey?.toString()) {
            // eslint-disable-next-line no-param-reassign
            cv.aggregationValue.dataExtensionCustomerKey = deWithCustomerKey.CustomerKey;
            // eslint-disable-next-line no-param-reassign
            cv.aggregationValue.relations.fromCollectionCustomerKey = deWithCustomerKey.CustomerKey;
          }
        }
      });
    }

    // format regular relations and selected DEs
    const formattedRelationsAndSelectedDEs = await Util.formatRelationsAndSelectedDEs(
      relations,
      dataExtensionsCopy,
      collections,
      false,
      this.setDataExtensionProperties,
      this.handleDataExtensionMissing,
      this.getDataExtensionOrDataViewFields,
      this.updateSubquerySelectedDEFields,
      this.updateComparableFiltersFields,
      this.getMatchedFieldsStateForSelection,
      this.addToSelectedDataExtensions,
      selectedFilters,
      fieldsMap,
      axios,
      customValues,
      fields,
      globalCustomValues,
    );

    let formattedSelectedDataSet = {};

    if (selectedDataSet?.collections.length) {
      const existingDataSetWithoutCollections = _.omit(selectedDataSet, 'collections');
      const loadedSelectedDataSetState = await Util.loadStatesForDataSet(
        selectedDataSet.relations,
        dataExtensions,
        selectedDataSet.collections,
        this.setDataExtensionProperties,
        this.getDataExtensionOrDataViewFields,
        this.handleDataExtensionMissing,
        this.addToSelectedDataExtensions,
        axios,
      );

      formattedSelectedDataSet = {
        ...existingDataSetWithoutCollections,
        ...loadedSelectedDataSetState,
      };
    }

    returnState = { ...returnState, ...formattedRelationsAndSelectedDEs, selectedDataSet: formattedSelectedDataSet };

    return returnState;
  };

  /**
   * Helps to get searched picklists
   * @param {array} fields - Fields for searching if there is defined picklist for it
   * @param {boolean} add - Determines if we are adding or deleting field object ids for searching in the picklists
   * @param {object} dataExtension - Data Extension for which field we get picklist
   * @param {boolean} removedSubQuery - true / false depending on whether DE from subQuery is removed
   * @returns {void}
   */
  handlePickListOptions = async (fields, add, dataExtension, removedSubQuery) => {
    const { filterSets } = this.state;
    const { unionSelections, featuresInfo } = this.props;
    const featurePickLists = Features.isFeatureEnabled(featuresInfo, Constants.FEATURE__PICKLISTS);
    // If picklists feature is enabled

    if (featurePickLists) {
      const subQueryFiltersDEs = [];

      if (unionSelections?.length) {
        // loop through all union selections
        unionSelections.forEach((union) => {
          const { selectedFilters } = union;

          // get all filters for each union
          const filtersArray = filtersUtil.getAllFilters(selectedFilters, []);

          // for each filter in union selection
          filtersArray.forEach((filter) => {
            if (filter?.subQuery?.selectedDataExtension) {
              // add selected DE used in subQuery filters
              subQueryFiltersDEs.push(filter.subQuery.selectedDataExtension);
            }
          });
        });
      }

      const { pickListFieldObjectIDs } = this.state;
      const pickListFieldObjectIDsCopy = [...pickListFieldObjectIDs];

      let fieldObjectIds = [];

      // Add fields Object Ids for searching in picklists
      if (add) {
        if (fields) fieldObjectIds = fields.map(({ ObjectID }) => ObjectID);

        // check if fields exist in pickListFieldObjectIDs array
        const fieldsExistsInPickListFieldObjectIDs = Util
          .checkElementsInArray(fieldObjectIds, pickListFieldObjectIDsCopy);

        // push fieldObjectIds into pickListFieldObjectIDsCopy
        pickListFieldObjectIDsCopy.push(...fieldObjectIds);

        // fetch picklists only for new added fields
        if (!fieldsExistsInPickListFieldObjectIDs) {
          try {
            // API call for searching picklists
            const pickLists = await PickListAPI.searchPicklists(
              fieldObjectIds,
              this.axiosCancelToken.token,
            );

            const { pickLists: existingPickLists } = this.state;

            // merge existing pickLists with retrieved PickLists and remove duplicates
            const updatedPickLists = Util.removeDuplicateObjectsFromArray(
              [...existingPickLists, ...pickLists],
              it => it._id,
            );

            // update the state
            this.setState({
              pickLists: updatedPickLists,
              pickListFieldObjectIDs: [...new Set(pickListFieldObjectIDsCopy)],
            });
          } catch (error) {
            if (!axios.isCancel(error)) this.setState({ error });
          }
        }

        // set new pickListFieldObjectIDs with added fields ObjectId, without duplicate values
        this.setState({ pickListFieldObjectIDs: [...new Set(pickListFieldObjectIDsCopy)] });
      } else {
        const { pickLists } = this.state;
        const selectedDE = [];

        // If data extension is used in filterSet subquery do not remove picklist data
        if (filterSets?.length) {
          const filterSetDE = filterSets.map((filterSet) => {
            return filtersUtil.getFilterSetSubqueryCollections(filterSet);
          }).filter(it => it.length).flat().find(de => de?.CustomerKey === dataExtension?.CustomerKey);

          // Reset data extension if it is used in filterSet
          if (filterSetDE?.CustomerKey === dataExtension?.CustomerKey) {
            dataExtension = [];
          }
        }

        // loop through all selectedDataExtensions in union selections
        if (unionSelections?.length) {
          unionSelections.forEach((union) => {
            // for each union selection
            if (union.selectedDataExtensions) {
              // and for each selected data extension
              union.selectedDataExtensions.forEach((de) => {
                // push data extension into the array
                selectedDE.push(de);
              });
            }
          });
        }

        // Remove field object ids for searching in the picklists
        if (fields && dataExtension) {
          let filteredPicklists;

          let selectedDEContainingRemovedField;

          let subQueryDEsContainingRemovedField = [];

          fields.forEach((field) => {
            /**
             * Helps to check if the deleted field belongs to the checked DE
             * @param {object} dataExtensionToCheck - data extension for which we check if removed field exists
             * @param {object} removedField - removed field
             * @returns {boolean} - true / false depending on whether it is found
             */
            const checkFieldInDE = (dataExtensionToCheck, removedField) => (
              dataExtensionToCheck.fields.find(deField => deField.ObjectID === removedField.ObjectID)
            );

            // check if removed field belongs to selectedDE
            selectedDEContainingRemovedField = selectedDE?.find(de => (
              checkFieldInDE(de, field)
            ));

            // if not, check if removed field belongs to DE in subQuery filters
            if (!selectedDEContainingRemovedField) {
              subQueryDEsContainingRemovedField = subQueryFiltersDEs?.filter(subQueryDE => (
                checkFieldInDE(subQueryDE, field)
              ));
            }

            /*
             * when DE is not found or in case of subQuery filter, subQueryDEsContainingRemovedField containing 1
             * object with removed DE, then remove this field from pickListFieldObjectIDsCopy
             */
            if (!selectedDEContainingRemovedField && (!subQueryDEsContainingRemovedField?.length ||
              (subQueryDEsContainingRemovedField?.length === 1 && removedSubQuery))) {
              const idxToRemove = pickListFieldObjectIDsCopy.findIndex(ObjectID => ObjectID === field.ObjectID);

              if (idxToRemove >= 0) pickListFieldObjectIDsCopy.splice(idxToRemove, 1);
            }
          });

          // if removed field does not belong to DE used in selection criteria, then remove this picklist
          if (!selectedDEContainingRemovedField && (!subQueryDEsContainingRemovedField?.length ||
            (subQueryDEsContainingRemovedField?.length === 1 && removedSubQuery)) &&
            pickLists?.length) {
            filteredPicklists = pickLists.filter(picklist => picklist.dataExtensionCustomerKey !==
              dataExtension.CustomerKey);
          } else {
            filteredPicklists = pickLists || [];
          }

          // set new state for the pickLists and pickListFieldObjectIDs
          this.setState({
            pickLists: filteredPicklists,
            pickListFieldObjectIDs:
              [...new Set(pickListFieldObjectIDsCopy)],
          });
        }
      }
    }
  };

  /**
   * Fetches picklist options for each data extension in the filter sets.
   * @param {object[]} filterSets - Filter sets
   * @param {object[]} selectedDataExtensions - Selected data extensions
   * @returns {void}
   */
  fetchFilterSetsPicklistOptions = (filterSets, selectedDataExtensions) => {
    const filterSetsFromSelectedDataExtensions = filtersUtil.getFilterSetsToDisplay(filterSets, selectedDataExtensions);

    const filterSetDEsForPicklist = filterSetsFromSelectedDataExtensions
      ?.map(filterSet => filtersUtil.getFilterSetSubqueryCollections(filterSet))
      ?.reduce((acc, filterSetDEs) => acc.concat(filterSetDEs), []);

    // Get picklists for each data extension
    filterSetDEsForPicklist
      ?.forEach(dataExtension => this.handlePickListOptions(dataExtension.fields, true, dataExtension));
  };

  /**
   * Helps to retrieve fields of a data extension or data view from SFMC
   * @param {object[]} selectedCollection - Selected collection
   * @param {boolean} isComparedField - Determined if we are calling this function for compared fields
   * @returns {void}
   */
  getDataExtensionOrDataViewFields = async (selectedCollection, isComparedField) => {
    const { featuresInfo } = this.props;
    const featureDataViews = Features.isFeatureEnabled(featuresInfo, Constants.FEATURE__DATA_VIEWS);

    if (selectedCollection) {
      /**
       * Check if selected DE has CategoryID
       * If it has then move on to find DE
       * If it doesn't have then move on to find Data Views
       */
      if (selectedCollection && !selectedCollection.CategoryID && !selectedCollection.relation) {
        /**
         * If feature 'dataViews' is 'true' get data views fields
         * Else show error message
         */
        if (featureDataViews) {
          try {
            // eslint-disable-next-line no-param-reassign, require-atomic-updates
            selectedCollection.fields = await DataViewsAPI.getDataViewFields(
              selectedCollection.CustomerKey.toString(),
              this.axiosCancelToken.token,
            );
          } catch (error) {
            if (!axios.isCancel(error)) this.setState({ error });
          }
        } else {
          await this.handleFeatureMissing(Constants.FEATURE__DATA_VIEWS_LABEL);

          /**
           * I am returning null so we can differentiate data views feature is disabled and
           * Data view is deleted
           */
          return null;
        }
        // Prepare fields so you can use it properly
        this.prepareDataViewsFields(selectedCollection);

        if (selectedCollection.fields) {
          // Sort data view fields by Name
          Util.sortArrayOfObjects(selectedCollection.fields, 'Name');
        }
      } else {
        // Get data extension fields
        const {
          dataExtensions, subQueryDataExtensions, selectedDataExtensions, predefinedRelations,
        } = this.state;

        let fieldsForSelectedCollection;

        // get customer key depending on the selectedCollection object
        const collectionCustomerKey = selectedCollection.relation ?
          this.returnPredefinedRelationById(selectedCollection.relationId, selectedCollection)?.toDECustomerKey :
          selectedCollection.CustomerKey;

        /**
         * Helps to find data extension for the customer key and returns its fields
         * @param {array} arrayWithDataExtensions - array with data extensions to check
         * @returns {array} fields of the data extension
         */
        const getDataExtensionFields = (arrayWithDataExtensions) => {
          // find data extension by customer key
          const getDEByCustomerKey = arrayWithDataExtensions.find(de => de.CustomerKey &&
            de.CustomerKey === collectionCustomerKey);

          // if data extension is found for the customer key and contains fields
          if (getDEByCustomerKey?.fields) {
            // return getCustomerKey object
            return getDEByCustomerKey.fields;
          }

          return null;
        };

        if (dataExtensions?.length) {
          // find fields in dataExtensions
          fieldsForSelectedCollection = getDataExtensionFields(dataExtensions);

          // if fields are not found in data extensions
          if (!fieldsForSelectedCollection && subQueryDataExtensions?.length) {
            // get fields from subQueryDataExtensions
            fieldsForSelectedCollection = getDataExtensionFields(subQueryDataExtensions);
          }

          if (!fieldsForSelectedCollection && selectedDataExtensions?.length) {
            // if not found, keep looking in selectedDataExtensions
            fieldsForSelectedCollection = getDataExtensionFields(selectedDataExtensions);
          }

          if (!fieldsForSelectedCollection && predefinedRelations?.length) {
            // if not found, keep looking in predefinedRelations
            predefinedRelations.forEach((relation) => {
              if (relation.toDECustomerKey === collectionCustomerKey && relation.toDEFields) {
                // search in toDEFields
                fieldsForSelectedCollection = relation.toDEFields;
              } else if (relation.fromDECustomerKey === collectionCustomerKey && relation.fromDEFields) {
                // search in fromDEFields
                fieldsForSelectedCollection = relation.fromDEFields;
              }
            });
          }
        }

        // prevent from errors, check if fields exists
        if (fieldsForSelectedCollection?.length) {
          // make a delay to avoid errors
          const timeout = n => new Promise(cb => setTimeout(cb, n));

          await timeout(100);

          // assign fields from the fetched fields
          // eslint-disable-next-line require-atomic-updates, no-param-reassign
          selectedCollection.fields = fieldsForSelectedCollection;
        } else {
          try {
            // if it doesn't find the fields, get new ones
            const fields = await DataExtensionsAPI.getDataExtensionFields(
              collectionCustomerKey,
              this.axiosCancelToken.token,
            );

            if (fields && fields.data) {
              // eslint-disable-next-line require-atomic-updates, no-param-reassign
              selectedCollection.fields = fields.data;
            } else {
              // eslint-disable-next-line require-atomic-updates, no-param-reassign
              selectedCollection.fields = [];
            }
          } catch (error) {
            if (!axios.isCancel(error)) this.setState({ error });
          }
        }
      }

      /**
       * If we are not calling this function for compared fields
       * Call handlePickListOptions
       */
      if (!isComparedField) {
        await this.handlePickListOptions(selectedCollection.fields, true);
      }

      return selectedCollection.fields;
    }

    return false;
  };

  /**
   * Function for adjusting data view fields for further usage
   * @param {object[]} selectedCollection - Selected collection
   * @returns {void}
   */
  prepareDataViewsFields = (selectedCollection) => {
    const dataExtension = {
      CustomerKey: selectedCollection.CustomerKey,
    };

    selectedCollection.fields?.forEach((field) => {
      /* eslint-disable no-param-reassign */
      field.CustomerKey = `[${selectedCollection.CustomerKey}].[${field.Name.toString()}]`;
      field.DataExtension = dataExtension;
      /* eslint-enable no-param-reassign */
    });
  };

  /**
   * Show a swal to the user indicating the feature is not enabled, and bring user back to the overview screen
   * @param {string} feature - Name of feature
   * @returns {void}
   */
  handleFeatureMissing = async (feature) => {
    // Only throw alert when the component is mounted
    if (this.getIsComponentMounted()) {
      this.axiosCancelToken.cancel(`Missing feature: ${feature}.`);

      await SwalUtil.fire({
        type: Constants.SWAL__TYPE__ERROR,
        // eslint-disable-next-line max-len
        message: `You do not have the feature for <b>${feature}</b> enabled to open this type of selection.`,
        options: {
          allowOutsideClick: false,
        },
      }).then((result) => {
        if (result.isConfirmed) this.handleNavigateBack();
      });
    }
  };

  /**
   * Handle navigate back to overview or WF
   * @returns {void}
   */
  handleNavigateBack = async () => {
    const { handleNavigator, backToWaterFall, handleSetAppState } = this.props;

    if (backToWaterFall) {
      return handleSetAppState({
        backToWaterFall: null,
        currentSelectionName: '',
        currentSelectionId: backToWaterFall._id,
        navigator: Constants.NAVIGATION__WATERFALL_SELECTION,
        globalNavigator: Constants.NAVIGATION__WATERFALL_SELECTION,
        folderId: backToWaterFall.folderId,
      });
    }

    // Navigate back to overview
    handleNavigator(Constants.NAVIGATION__OVERVIEW);
  };

  /**
   * Show a swal to the user indicating the Data Extension cannot be found, and bring user back to the overview screen
   * @param {string} dataExtensionName - Name of DE
   * @returns {void}
   */
  handleDataExtensionMissing = async (dataExtensionName) => {
    // Only throw alert when the component is mounted
    const { isAutofixSuccessful } = this.state;

    if (this.getIsComponentMounted() && !isAutofixSuccessful) {
      this.axiosCancelToken.cancel(`Missing data extension: ${dataExtensionName}.`);

      // Throw error indicating the selection cannot be opened because of the missing data extension
      await SwalUtil.fire({
        type: Constants.SWAL__TYPE__ERROR,
        // eslint-disable-next-line max-len
        message: `Selected Data Extension <b>${dataExtensionName}</b> cannot be found. Therefore, this selection cannot be opened.`,
      }).then((result) => {
        if (result.isConfirmed) this.handleNavigateBack();
      });
    }
  };

  /**
   * Get state of matched fields
   * @param {object[]} selectedDataExtensions - Selected DE
   * @param {object[]} fields - Matched fields in target definition
   * @param {object[]} customValues - The custom values
   * @param {object[]} globalCustomValues - The shared custom values
   * @returns {void}
   */
  getMatchedFieldsStateForSelection = (selectedDataExtensions, fields, customValues, globalCustomValues) => {
    const { targetDataExtensionFields } = this.state;
    const matchedFields = [];
    const deletedMappedFieldsFromSFMC = [];

    if (fields && fields.length && selectedDataExtensions && selectedDataExtensions.length) {
      fields.forEach((field) => {
        // Get DE based on collectionAlias
        const filteredDataExtensions = selectedDataExtensions.filter(
          extension => extension.deAlias === field.collectionAlias,
        );

        let filteredDataExtension;

        if (filteredDataExtensions && filteredDataExtensions.length > 0) {
          [filteredDataExtension] = filteredDataExtensions;
        }

        /**
         * FilteredDataExtension has fields ? do : continue.
         * Get details of the fields in the DE
         */
        if (filteredDataExtension && filteredDataExtension.fields && field.availableFieldObjectID) {
          // If the objectID includes a plus sign then split it and use it
          const formattedAvailableFieldObjectID = field.availableFieldObjectID.includes('+') ?
            field.availableFieldObjectID.split('+')[0] :
            field.availableFieldObjectID;

          // Find field details based on objectId
          const filteredField = filteredDataExtension.fields.find(
            f => f.ObjectID === formattedAvailableFieldObjectID,
          );

          // Create matched field if field can be found
          if (filteredField) {
            // Find target field this field is matched with based on ObjectID
            const targetDataExtensionField =
              targetDataExtensionFields.find(tf => tf.ObjectID === field.targetDataExtensionFieldObjectID);

            // Create new matched field
            const newMatchedField = {
              availableFieldName: filteredField.Name.toString(),
              availableFieldObjectID: filteredField.ObjectID,
              availableFieldDataExtensionCustomerKey: filteredDataExtension.CustomerKey,
              availableFieldDataExtensionAlias:
                targetDataExtensionField?.deAlias ? targetDataExtensionField.deAlias : filteredDataExtension.deAlias,
              targetDataExtensionFieldObjectID: targetDataExtensionField ? targetDataExtensionField.ObjectID : null,
              targetDataExtensionFieldName: targetDataExtensionField ?
                targetDataExtensionField.Name.toString() :
                null,
              availableFieldIsRequired: `${filteredField.IsRequired}`,
              availableFieldMaxLength: filteredField.MaxLength,
            };

            matchedFields.push(newMatchedField);
          } else {
            // For tracking deleted fields from certain data extension
            const dataExtensionExists = [];

            // Find data extensions with deleted fields
            deletedMappedFieldsFromSFMC.forEach((deletedField) => {
              if (deletedField.dataExtension === field.collectionAlias) {
                dataExtensionExists.push(deletedField.dataExtension);
              }
            });

            // Push deleted fields into object with corresponding data extension
            if (dataExtensionExists.length > 0) {
              deletedMappedFieldsFromSFMC.forEach((deletedField) => {
                dataExtensionExists.forEach((de) => {
                  if (deletedField.dataExtension === de) {
                    deletedField.fields.push(field.field);
                  }
                });
              });

              // Otherwise push new object with deleted fields and data extension from which fields are deleted
            } else {
              deletedMappedFieldsFromSFMC.push({ dataExtension: field.collectionAlias, fields: [field.field] });
            }
            this.setState({ deletedMappedFieldsFromSFMC });
          }
        }
      });
    }
    if (customValues?.length || globalCustomValues?.length) {
      fields.forEach((field) => {
        const matchedField = SelectionUtil.getCustomValueMatchedField(
          field,
          field?.globalCustomValueId?.length ? globalCustomValues : customValues,
          targetDataExtensionFields,
          selectedDataExtensions,
        );

        if (matchedField) matchedFields.push(matchedField);
      });
    }

    return matchedFields;
  };

  /**
   * Validates if the minimum elements of one selection are present to build a valid SQL query
   * If something is wrong, return error and move user to proper position in the application
   * @param {object[]} collections - Selected collections
   * @param {string} targetCollectionCustomerKey - Customer key of targeted collection
   * @param {object} fields - Fields in in target DE
   * @returns {object} An object with `err` and `navRoute` properties
   */
  validateIfSelectionCanBeRun = (collections, targetCollectionCustomerKey, fields) => {
    let error;

    let navRoute;

    let requiredFields;

    let requiredFieldsMapped;
    const notMappedRequiredFields = [];
    const { targetDataExtensionFields } = this.state;

    if (collections && collections.length === 0) {
      error = 'Please add at least 1 Selected Data Extension.';
      navRoute = Constants.NAVIGATION__SELECTION_CRITERIA;
    } else if (!targetCollectionCustomerKey) {
      error = 'Please select a Target Data Extension.';
      navRoute = Constants.NAVIGATION__TARGET_DEFINITION;
    } else if (!fields || (fields && fields.length === 0)) {
      error = 'Please add at least one field to Selected Fields.';
      navRoute = Constants.NAVIGATION__TARGET_DEFINITION;
    } else {
      // Get all non nullable fields (required)
      requiredFields = targetDataExtensionFields.filter(field => field.IsRequired) || [];

      for (let i = 0; i < requiredFields.length; i += 1) {
        requiredFieldsMapped = false;
        for (let j = 0; j < fields.length; j += 1) {
          // Check if ObjectID of required field exists in fields (mapped fields)
          if (requiredFields[i].ObjectID === fields[j].targetDataExtensionFieldObjectID) {
            requiredFieldsMapped = true;
            break;
          }
        }
        /**
         * If ObjectID of required field doesn't exist (not mapped) in fields (mapped fields)
         * Add that field to the notMappedRequiredFields
         */
        if (!requiredFieldsMapped) {
          notMappedRequiredFields.push(requiredFields[i].Name.toString());
        }
      }
      // If required fields are not mapped throw an error message with field name
      if (notMappedRequiredFields && notMappedRequiredFields.length > 0) {
        // Here will be fields names separate by comma and space after comma
        let notMappedRequiredFieldsString = '';

        notMappedRequiredFields.forEach((field) => {
          // Separate fields names by comma and space after comma (, )
          notMappedRequiredFieldsString += `${field}, `;
        });

        // Remove space and comma on the end of string
        notMappedRequiredFieldsString = notMappedRequiredFieldsString
          .substring(0, notMappedRequiredFieldsString.length - 2);

        error =
          `Please map required field${notMappedRequiredFields.length > 1 ? 's' : ''}:
          <b>${notMappedRequiredFieldsString}</b>.`;
        navRoute = Constants.NAVIGATION__TARGET_DEFINITION;
      }
    }

    return { error, navRoute };
  };

  /**
   * Check if all elements of one selection or all unionSelections are filled out
   * @param {boolean} showNotificationAboutSelectionCheck - Should the popup about selection check be shown?
   * @returns {boolean} True if query can be run, false otherwise
   */
  validateIfQueryCanBeRun = async (showNotificationAboutSelectionCheck = false) => {
    const { handleGlobalNavigator } = this.props;
    const { scheduledRun: { enabled } } = this.state;

    const data = this.getQueryData();

    let error;

    let navRoute;

    if (data) {
      if (data.unionSelections && data.unionSelections.length > 0) {
        for (let i = 0; i < data.unionSelections.length; i += 1) {
          const unionSelection = data.unionSelections[i];
          // Check if all minimum necessary fields are filled up
          const validationResult = this.validateIfSelectionCanBeRun(
            unionSelection.collections,
            data.targetCollectionCustomerKey,
            unionSelection.fields,
          );

          ({ error, navRoute } = validationResult);

          if (error) {
            // Open the proper tab
            const { handleSetAppState } = this.props;

            handleSetAppState({ unionSelectionsIndex: i });

            break;
          }
        }
      } else {
        const validationResult = this.validateIfSelectionCanBeRun(
          data.collections,
          data.targetCollectionCustomerKey,
          data.fields,
        );

        ({ error, navRoute } = validationResult);
      }
    } else {
      error = 'Unable to get query data';
    }

    // If any error from validateIfSelectionCanBeRun exists, display it
    if (error) {
      // If scheduledRun is enabled and showNotificationAboutSelectionCheck is true
      if (enabled && showNotificationAboutSelectionCheck) await this.notifyUserAboutSelectionCheck();

      await SwalUtil.fire({
        message: error,
        type: Constants.SWAL__TYPE__ERROR,
      });
      // Navigate to proper position in the application
      this.setState({ selectionNavigator: navRoute });
      handleGlobalNavigator(navRoute);

      return false;
    }

    return true;
  };

  /**
   * Notifies the user during selection save about selection check when schedule is enabled
   * @returns {Promise<SweetAlertResult>} A Promise of SweetAlertResult
   */
  notifyUserAboutSelectionCheck = async () => SwalUtil.fire({
    title: 'Selection Scheduled',
    message: 'This selection is scheduled to run. Therefore, we need to make sure the ' +
      'selection is complete before it can be saved. Please follow the instruction that appears after ' +
      'clicking OK and try again.',
  });

  /**
   * Format data in a clean standardized way the server expects, without states
   * @param {object} queryData - data to be formatted
   * @returns {object} The post data
   */
  formatData = (queryData) => {
    /**
     * Updates selectedDataExtensions to DESelect data format.
     * @param {object} collection - collection
     * @returns {object} An object with the properties `collection`, `alias`, `collectionObjectID` and
     * `collectionCustomerKey`
     */
    const formatDataExtensions = collection => ({
      collection: collection.Name.toString(),
      alias: collection.deAlias,
      collectionObjectID: collection.ObjectID,
      collectionCustomerKey: collection.CustomerKey,
    });

    /**
     * Updates matchedFields state to DESelect data format.
     * @param {object} field - matched fields from target definition
     * @returns {object} An object with the properties `field`, `collectionAlias`, `availableFieldObjectID` and
     * `targetDataExtensionFieldObjectID
     */
    const formatMatchedFields = field => ({
      field: field.availableFieldName,
      collectionAlias: field.availableFieldDataExtensionAlias,
      availableFieldObjectID: field.availableFieldObjectID,
      targetDataExtensionFieldObjectID: field.targetDataExtensionFieldObjectID,
      globalCustomValueId: field.globalCustomValueId || '',
    });

    /**
     * Updates relations state to DESelect data format.
     * @param {object} relation - relation between DE
     * @returns {object} A relation
     */
    const formatRelations = relation => ({
      type: relation.joinType,
      toField: relation.toCollection.toField,
      toFieldType: relation.toCollection.toFieldType,
      toFieldObjectID: relation.toCollection.toFieldObjectID,
      toCollectionAlias: relation.toCollection.deAlias,
      toCollection: relation.toCollection.Name.toString(),
      toCollectionCustomerKey: relation.toCollection.CustomerKey.toString(),
      toCollectionObjectID: relation.toCollection.ObjectID,
      fromField: relation.fromCollection.fromField,
      fromFieldType: relation.fromCollection.fromFieldType,
      fromFieldObjectID: relation.fromCollection.fromFieldObjectID,
      fromCollectionAlias: relation.fromCollection.deAlias,
      fromCollection: relation.fromCollection.Name.toString(),
      fromCollectionCustomerKey: relation.fromCollection.CustomerKey.toString(),
      fromCollectionObjectID: relation.fromCollection.ObjectID,
      additionalJoins: relation.additionalJoins,
    });

    /**
     * Check if passed argument contain any filters.
     * If not , assign default
     */
    const selectedFilters = queryData.filters && queryData.filters[0] ?
      { ...queryData.filters[0] } :
      { filters: [], operator: Constants.FILTERLINE__OPERATOR__AND };

    /**
     * Set filter field object id if filter field type doesn't have that property (for older selections)
     * @param {*} filter - The filter
     * @param {*} subquery - The subquery
     * @returns {void}
     */
    const setFilterFieldObjectID = (filter, subquery) => {
      if (queryData.collections && queryData.collections.length > 0) {
        const collection = queryData.collections.filter(de => de.deAlias === filter.collectionAlias);

        // If collection is empty that means filter is in subquery
        if (collection.length === 0) {
          // Loop through dataExtensionFields and find field Name same as filter.field (filter field name)
          for (let i = 0; i < subquery?.dataExtensionFields?.length; i += 1) {
            if (subquery.dataExtensionFields[i].Name.toString() === filter.field.toString()) {
              return subquery.dataExtensionFields[i].ObjectID;
            }
          }
        } else {
          // If there is collection then find filter by name in collection's fields and return field ObjectID
          for (let i = 0; i < collection[0].fields.length; i += 1) {
            if (collection[0].fields[i].Name.toString() === filter.field.toString()) {
              return collection[0].fields[i].ObjectID;
            }
          }
        }
      }

      return null;
    };

    /**
     * Format comparedDataExtension, comparableFields, comparedField add fieldObjectID property
     * @param {object} filtersBranch - filters
     * @returns {void}
     */
    const formatFilters = async (filtersBranch) => {
      const filtersArray = filtersBranch.filters;

      for (let f = 0; f < filtersArray.length; f += 1) {
        if (filtersArray[f].filters) {
          formatFilters(filtersArray[f]);
        } else {
          /**
           * If property fieldObjectID doesn't exist find filter field objectID
           * And assing it to the filter fieldObjectID
           */
          if (!filtersArray[f].fieldObjectID) {
            filtersArray[f].fieldObjectID = setFilterFieldObjectID(filtersArray[f]);
          }
          // Check if it is compare fields filter
          if (filtersArray[f].isCompareFieldsFilter) {
            // If compared data extension is not data view (data views don't have ObjectID)
            if (filtersArray[f].comparedDataExtension.ObjectID) {
              // Format compareDataExtension
              filtersArray[f].comparedDataExtension = {
                ObjectID: filtersArray[f].comparedDataExtension.ObjectID,
                deAlias: filtersArray[f].comparedDataExtension.deAlias,
                Name: filtersArray[f].comparedDataExtension.Name.toString(),
              };
            } else {
              filtersArray[f].comparedDataExtension = {
                CustomerKey: filtersArray[f].comparedDataExtension.CustomerKey,
              };
            }
            // Format comparableFields to empty object so we won't save this in DB
            filtersArray[f].comparableFields = {};
            if (filtersArray[f].comparedField && filtersArray[f].comparedField.ObjectID) {
              // Format comparedField
              filtersArray[f].comparedField = {
                ObjectID: filtersArray[f].comparedField.ObjectID,
                Name: filtersArray[f].comparedField.Name.toString(),
                FieldType: filtersArray[f].comparedField.FieldType,
              };
            }
          } else if (filtersArray[f].includesSubQuery &&
            filtersArray[f].subQuery &&
            filtersArray[f].subQuery.filters &&
            filtersArray[f].subQuery.filters.filters) {
            for (let sf = 0; sf < filtersArray[f].subQuery.filters.filters.length; sf += 1) {
              if (filtersArray[f].subQuery.filters.filters[sf].filters) {
                formatFilters(filtersArray[f].subQuery.filters.filters[sf]);
              } else {
                /**
                 * If property fieldObjectID doesn't exist find filter field objectID
                 * And assing it to the filter fieldObjectID
                 */
                if (!filtersArray[f].subQuery.filters.filters[sf].fieldObjectID) {
                  filtersArray[f].subQuery.filters.filters[sf].fieldObjectID = setFilterFieldObjectID(
                    filtersArray[f].subQuery.filters.filters[sf],
                    filtersArray[f].subQuery,
                  );
                }
                // Check if it is compare fields filter
                if (filtersArray[f].subQuery.filters.filters[sf].isCompareFieldsFilter) {
                  // If compared data extension is not data view (data views don't have ObjectID)
                  if (filtersArray[f].subQuery.filters.filters[sf].comparedDataExtension.ObjectID) {
                    // Format subquery comparedDataExtension
                    filtersArray[f].subQuery.filters.filters[sf].comparedDataExtension = {
                      ObjectID: filtersArray[f].subQuery.filters.filters[sf].comparedDataExtension.ObjectID,
                      deAlias: filtersArray[f].subQuery.filters.filters[sf].comparedDataExtension.deAlias.toString(),
                      Name: filtersArray[f].subQuery.filters.filters[sf].comparedDataExtension.Name.toString(),
                    };
                  } else {
                    filtersArray[f].subQuery.filters.filters[sf].comparedDataExtension = {
                      CustomerKey:
                        filtersArray[f].subQuery.filters.filters[sf].comparedDataExtension.CustomerKey.toString(),
                    };
                  }
                  // Format comparableFields to empty object so we won't save this in DB
                  filtersArray[f].subQuery.filters.filters[sf].comparableFields = {};
                  if (filtersArray[f].subQuery.filters.filters[sf].comparedField &&
                    filtersArray[f].subQuery.filters.filters[sf].comparedField.ObjectID) {
                    // Format subquery comparedField
                    filtersArray[f].subQuery.filters.filters[sf].comparedField = {
                      ObjectID: filtersArray[f].subQuery.filters.filters[sf].comparedField.ObjectID,
                      Name: filtersArray[f].subQuery.filters.filters[sf].comparedField.Name.toString(),
                      FieldType: filtersArray[f].subQuery.filters.filters[sf].comparedField.FieldType,
                    };
                  }
                }
              }
            }
          } if (!filtersArray[f].isCompareFieldsFilter) {
            filtersArray[f].comparableFields = {};
          }
        }
      }
    };

    /**
     * Updates subQuery state to DESelect data format
     * @param {object} filters - Filters
     * @returns {void}
     */
    const formatSubQueryData = (filters) => {
      const filtersBranch = filters.filters || [];

      for (let f = 0; f < filtersBranch.length; f += 1) {
        if (filtersBranch[f].filters) {
          formatSubQueryData(filtersBranch[f]);
        } else {
          if (filtersBranch[f].includesSubQuery && filtersBranch[f].subQuery) {
            // Check if collections` alias start with _underscore or not
            if (!Util.startsWithUnderScore(filtersBranch[f].subQuery.collections[0].alias)) {
              filtersBranch[f].subQuery.collections[0].collectionObjectID =
                filtersBranch[f].subQuery?.selectedDataExtension?.ObjectID ||
                null;
            }

            // Remove unnecessary properties
            delete filtersBranch[f].subQuery.dataExtensionFields;
            delete filtersBranch[f].subQuery.selectedDataExtension;
            delete filtersBranch[f].subQuery.fields[0].alias;
          }
        }
      }
    };

    /**
     * Sort fields in the order of the target data extension fields
     * This is important for union sql queries
     * @param {object} fields - Fields
     * @returns {object} The sorted fields
     */
    const sortFields = (fields) => {
      let sortedFields = [];
      const { targetDataExtensionFields } = this.state;

      if (targetDataExtensionFields) {
        for (let tdf = 0; tdf < targetDataExtensionFields.length; tdf += 1) {
          for (let f = 0; f < fields.length || 0; f += 1) {
            if (targetDataExtensionFields[tdf].ObjectID === fields[f].targetDataExtensionFieldObjectID) {
              sortedFields.push(fields[f]);
            }
          }
        }
      } else {
        sortedFields = fields;
      }

      return sortedFields;
    };

    const postData = queryData;
    const selectedFiltersCopy = JSON.parse(JSON.stringify(selectedFilters));

    formatFilters(selectedFiltersCopy);
    formatSubQueryData(selectedFiltersCopy);

    if (postData.filters && postData.filters[0]) {
      postData.filters[0] = selectedFiltersCopy;
    }

    // Updates collections data format to old DESelect data format.
    const collections = queryData.collections.map(collection => formatDataExtensions(collection));

    // Format relations and collections inside selectedDataSet
    if (queryData.selectedDataSet?.id) {
      const formattedDataSet = {
        ..._.omit(queryData.selectedDataSet, ['selectedDataExtensions', 'selectedDEsTree']),
        collections: queryData.selectedDataSet.selectedDataExtensions.map(
          collection => formatDataExtensions(collection),
        ),
        relations: queryData.selectedDataSet.relations.map(relation => formatRelations(relation)),
      };

      postData.selectedDataSet = formattedDataSet;
    }

    postData.collections = collections;

    // Updates fields data format to old DESelect data format
    let fields = queryData.fields.map(field => formatMatchedFields(field));

    // We need to guarantee all fields are in the order of the fields in the target data extension
    fields = sortFields(fields);
    postData.fields = fields;

    // Updates relations data format to old DESelect data format.
    const relations = queryData.relations.map(relation => formatRelations(relation));

    postData.relations = relations;

    return postData;
  };

  /**
   * Function that checks for duplicate selection name and returns it if it's valid
   * @param {string} newSelectionName - new selection name that comes from popup or input
   * @param {string} currentName - current selection name
   * @param {string} selectionId - current selection id
   * @returns {string|null} valid, not duplicated selection name or null if selection name is not valid
   */
  validateSelectionName = async (newSelectionName, currentName, selectionId) => {
    try {
      const { isSelectionRequestDone } = this.state;

      // define query params to fetch selections by property
      const query = qs.stringify({
        propertyName: Constants.SELECTION__SEARCH_CRITERIA__NAME,
        propertyValue: newSelectionName?.toString().trim(),
      });

      // get selections with the same name
      const selections = await SelectionsAPI.getSelectionsByProperty(this.axiosCancelToken.token, query);

      // check if there is a second selection with duplicate name
      const duplicateSelection = Util.findDuplicateName(selections, newSelectionName, currentName, selectionId);

      // If there is a duplicated selection in Selection Name Modal (when user enter New Selection)
      if (duplicateSelection && !isSelectionRequestDone && selectionId === '') {
        return null;
      }

      // If there is a duplicated selection display the error
      if (duplicateSelection) {
        // eslint-disable-next-line no-await-in-loop
        const swalResponse = await SwalUtil.fire({
          type: Constants.SWAL__TYPE__ERROR,
          title: 'Duplicate Selection Name',
          // eslint-disable-next-line max-len
          message: 'There is already a selection with this name. Please provide a unique name for your selection.',
          options: {
            input: 'text',
            inputValue: currentName,
            confirmButtonText: 'Confirm',
            showCancelButton: true,
            allowOutsideClick: false,
            inputValidator: newName => !newName && 'Please fill out this field.',
          },
        });

        // If user clicks on cancel button do nothing
        if (swalResponse.dismiss) {
          return null;
        }

        // call the function again
        return this.validateSelectionName(swalResponse.value, currentName, selectionId);
      }

      // If there is no duplicated selection, then save new name in state and return it
      if (newSelectionName) {
        const trimmedName = newSelectionName?.toString().trim();

        this.setState({
          selectionName: trimmedName,
        });

        return trimmedName;
      }

      return null;
    } catch (error) {
      if (!axios.isCancel(error)) this.setState({ error });
    }
  };

  /**
   * Get the data in a structured format and make api ca
   * ll to save selection to server
   * Returns true if selection was saved successfully, false otherwise
   * @param {Boolean} isRun - Is the save triggered by the run button?
   * @param {Boolean} nothingHasChanged - Were there any changes in the selection?
   * @param {Boolean} isFromTemplate - Is the selection being created from template?
   * @param {Boolean} copyPayload - Payload for copy selection
   * @returns {object} An object with `success` and `selectionId` properties
   */
  saveSelection = async (isRun, nothingHasChanged, isFromTemplate, copyPayload) => {
    const {
      selectionName,
      currentSelectionId,
      runStatus,
      taskCompletedDate,
    } = this.state;
    const { handleSetAppState, userInfo } = this.props;

    // This is relevant for both simple and union types, to get selection name etc
    const queryData = this.getQueryData();
    const newSelectionName = selectionName?.toString().trim();

    // Check for DUPLICATION
    if (!newSelectionName || newSelectionName === '') {
      // If name is missing
      await SwalUtil.fire({
        title: 'Name',
        message: 'Please enter a name for the selection',
        type: Constants.SWAL__TYPE__ERROR,
      });

      // eslint-disable-next-line consistent-return
      return { success: false };
    }

    try {
      const validSelectionName = await this.validateSelectionName(newSelectionName, selectionName, currentSelectionId);

      let confirmed = true;

      // In case there is a retry and changes were made, save should disable the retry
      if (runStatus === Constants.STATUS_RETRY && !nothingHasChanged) {
        const retryDate = moment(taskCompletedDate)
          .add(15, 'minutes')
          .format(Util.getUserDateTimeFormat(userInfo));

        // show warning of unsaved changes
        confirmed = (await SwalUtil.fire({
          title: 'Confirmation needed',
          message: `Please note that this selection is scheduled to retry at ${retryDate}.
          ${isRun ?
            `Manually running this selection now will cancel the retry, however any other schedules
            will not be impacted.` :
            `Any changes to this selection will cancel the retry.
              You can run this selection manually or wait for the next scheduled run to execute.`
          }`,
          options: {
            confirmButtonText: 'OK',
            showCancelButton: true,
            allowOutsideClick: false,
          },
        }))?.value;

        if (confirmed) {
          queryData.runStatus = Constants.STATUS_ERROR;
          queryData.taskStatus = Constants.STATUS_ERROR;
        } else return;
      }

      if (!confirmed || !validSelectionName) return;

      const saveOrCopySelection = () => {
        if (isFromTemplate) {
          return SelectionsAPI.copySelection(
            copyPayload,
            this.axiosCancelToken.token,
          );
        }

        return SelectionsAPI.saveSelection(
          {
            ...queryData,
            name: validSelectionName || queryData?.name,
            isCreationFromSqlQuery: copyPayload?.isCreationFromSqlQuery,
            sqlQuery: copyPayload?.sqlQuery,
          },
          this.axiosCancelToken.token,
        );
      };

      // Send the new data
      const result = await saveOrCopySelection();

      if (result?.success) {
        handleSetAppState({ currentSelectionId: result.selectionId });
        // Update currentSelectionId state
        this.setState({
          currentSelectionId: result.selectionId,
        });

        // eslint-disable-next-line consistent-return
        return { success: true, selectionId: result.selectionId };
      }
    } catch (error) {
      if (!axios.isCancel(error)) {
        SwalUtil.fire({
          type: Constants.SWAL__TYPE__ERROR,
          message: error,
        });

        this.setState({ error });
      }

      // eslint-disable-next-line consistent-return
      return { success: false };
    }
  };

  /**
   * Returns selection data in a standardized format,
   * Which applies for both simple selections or elements of unionSelection
   * @param {string} name - Name of selection data
   * @param {object[]} collections - Array of selected data extensions
   * @param {object[]} relations - Array of relations types
   * @param {object} filtersArray - Array of filters {filters, operator}
   * @param {object[]} fields - Array of available fields
   * @param {string} tabName - Tab's name
   * @param {array} customValues - Array of custom values
   * @param {object} selectedDataSet - dataSet used when using basic mode
   * @param {object} sourceLimit - Limit of the source
   * @param {boolean} sourceLimitingEnabled - Global source limiting status
   * @returns {object} The standardized selection format
   */
  standardizeSelectionData = (
    name,
    collections,
    relations,
    filtersArray = [],
    fields,
    tabName,
    customValues,
    selectedDataSet,
    sourceLimit,
    sourceLimitingEnabled,
  ) => {
    let filters = null;
    /**
     * Check if any filters are added
     * If new filters are added
     * Delete some parts of it and assign their changed version
     */

    if (filtersArray.filters && filtersArray.filters.length > 0) {
      // eslint-disable-next-line no-param-reassign
      filtersArray.filters = this.refineSelectedFilters(filtersArray.filters);

      const filtersArrayCopy = filtersUtil.removeEmptyFilters(filtersArray);

      filters = [filtersArrayCopy];
    }

    if (sourceLimit) {
      sourceLimit.sourceLimitingEnabled = sourceLimitingEnabled;
    }

    return {
      name,
      collections,
      relations,
      filters,
      fields,
      tabName,
      customValues,
      selectedDataSet,
      sourceLimit,
    };
  };

  /**
   * This function deletes showGroupButton and previousValue from each object in the selectedFilters.filters array
   * @param {object} selectedFilters - Selected filters
   * @returns {object} The selected filters without those properties
   */
  refineSelectedFilters = selectedFilters => selectedFilters.map((filter) => {
    /**
     * Check if filter has another filters array nested in it
     * That means two or more filters are grouped together
     */
    if (Object.prototype.hasOwnProperty.call(filter, 'filters')) {
      this.refineSelectedFilters(filter.filters);
    } else {
      ['showGroupButton', 'previousValue', 'previousMinValue',
        'previousMaxValue', 'previousInValue', 'previousNotInValue'].forEach((item) => {
        // eslint-disable-next-line no-param-reassign
        delete filter[item];
      });
    }

    return filter;
  });

  /**
   * Get the data from the different elements in the screen
   * @returns {void}
   */
  getQueryData = () => {
    let data = {};
    // Simple format: get values from state
    const {
      selectionName,
      selectionTemplate,
      selectedDataExtensions,
      relations,
      selectedFilters,
      matchedFields,
      targetDataExtensionCustomerKey,
      unionType,
      currentSelectionId,
      selectionType,
      dataAction,
      prioDeduplication,
      usePrioDeduplication,
      customValues,
      advancedDeduplicationRules,
      sortLimit,
      scheduledRun,
      isTemplate,
      copiedFromTemplate,
      selectionDescription,
      templateDescription,
      templateInstructions,
      targetCollectionObjectID,
      targetDataExtensions,
      applyTimezoneSettingsToAllDateFields,
      timezoneSettingsForAllDateFields,
      selectionMode,
      basicModeSelectionSourceType,
      sourceLimitingEnabled,
    } = this.state;
    const { unionSelections, folderId } = this.props;

    const copyOfUnionSelections = JSON.parse(JSON.stringify(unionSelections));

    // If selection type is UNION
    if (selectionType === Constants.SELECTION__TYPE__UNION) {
      // Initialize [] formattedUnionSelections
      const formattedUnionSelections = [];

      // If unionSelections exists
      for (let i = 0; i < copyOfUnionSelections.length || 0; i += 1) {
        // Format each item of unionSelection
        const unionSelection = copyOfUnionSelections[i];

        // Make standardized version of the object you will be saving
        const unionSelectionData = this.standardizeSelectionData(
          null,
          unionSelection.selectedDataExtensions, // Collections
          unionSelection.relations, // Relations
          unionSelection.selectedFilters, // Filters
          unionSelection.matchedFields, // Fields
          unionSelection.tabName,
          unionSelection.customValues,
          unionSelection.selectedDataSet,
          unionSelection.sourceLimit,
          sourceLimitingEnabled,
        );

        // We are pushing here the standardized version
        formattedUnionSelections.push(this.formatData(unionSelectionData));
      }

      data.filters = null;
      data.fields = null;
      data.collections = null;
      data.relations = null;
      data.unionSelections = formattedUnionSelections;
      data.unionType = unionType;
      data.dataAction = dataAction;
      data.sortLimit = sortLimit;
      data.scheduledRun = scheduledRun;
      data.isTemplate = isTemplate;
      data.selectionDescription = selectionDescription;
      data.copiedFromTemplate = copiedFromTemplate;
      data.templateDescription = templateDescription;
      data.templateInstructions = templateInstructions;
      data.applyTimezoneSettingsToAllDateFields = applyTimezoneSettingsToAllDateFields;
      data.timezoneSettingsForAllDateFields = timezoneSettingsForAllDateFields;
      data.selectionMode = selectionMode;
      data.basicModeSelectionSourceType = basicModeSelectionSourceType;
    } else {
      // Else if SIMPLE
      data = this.standardizeSelectionData(
        selectionName,
        selectedDataExtensions,
        relations,
        selectedFilters,
        matchedFields,
      );
      // We are pushing here the standardized version
      data = this.formatData(data);
    }

    data.type = selectionType;
    data.name = selectionName;
    data.selectionTemplate = selectionTemplate;
    data.currentSelectionId = currentSelectionId;
    data.dataAction = dataAction;
    data.sortLimit = sortLimit;
    data.customValues = customValues;
    data.scheduledRun = scheduledRun;
    data.sourceLimitingEnabled = sourceLimitingEnabled;
    let targetDataExtensionObjectID;

    // double check in case targetDE has no ObjectID saved in state
    if (targetDataExtensionCustomerKey && !targetCollectionObjectID) {
      // find targetDE by customer key
      const targetCollectionFilter = targetDataExtensions.filter(
        de => de.CustomerKey === targetDataExtensionCustomerKey,
      );

      let targetCollection;

      if (targetCollectionFilter && targetCollectionFilter.length > 0) {
        [targetCollection] = targetCollectionFilter;

        // find ObjectID in targetCollectionFilter
        targetDataExtensionObjectID = targetCollection.ObjectID;
      }
    }

    data.targetCollectionCustomerKey = targetDataExtensionCustomerKey;
    data.targetCollectionObjectID = targetCollectionObjectID || targetDataExtensionObjectID || '';
    data.prioDeduplication = prioDeduplication;
    data.usePrioDeduplication = usePrioDeduplication;
    data.folderId = folderId || '';
    data.prioDeduplication.advancedDeduplicationRules = advancedDeduplicationRules;

    return data;
  };

  /**
   * General function to handle the state of Selection
   * @param {object} newState - State to be set
   * @param {function} cb - Callback function
   * @param {function} anotherCB - Another callback function
   * @returns {void}
   */
  handleSetSelectionState = (newState, cb, anotherCB) => {
    if (!cb) {
      // eslint-disable-next-line no-param-reassign
      cb = () => {
        this.handleUpdateUnionSelectionsIfAppendDataExtensionsIsEnabled();
      };
    }

    this.setState(newState, cb);
    // Call the second callback if it exists.
    if (anotherCB) anotherCB();
  };

  /*
   * Handles the disabling of preview if the user is not an admin or
   * the template selection is not created by the active user
   * @returns {boolean | swal modal} - Determines whether saving should be disabled
   */
  checkIfPreviewShouldBeDisabled = async () => {
    const { isTemplate, selectionCreator } = this.state;
    const { userInfo } = this.props;

    if (isTemplate && !userInfo.isAdmin && userInfo.id !== selectionCreator) {
      await SwalUtil.fire({
        title: 'Cannot preview selection',
        message: `This selection is marked as a template. Only admins or the person who
        created this selection can preview it.`,
        options: {
          confirmButtonText: 'OK',
        },
      });

      return false;
    }

    return true;
  };

  // Updates selectionNavigator state and decides which page will be rendered.
  handleSelectionNavigator = async (buttonName) => {
    const { handleGlobalNavigator } = this.props;
    const { loadedDataExtensions } = this.state;

    handleGlobalNavigator(buttonName);

    this.setState({ dataExtensionSearchField: '', dataExtensions: loadedDataExtensions });

    // If user try to go to the Preview page validate if everything is alright on Target Definition page
    if (buttonName === Constants.NAVIGATION__PREVIEW) {
      if (await this.validateIfQueryCanBeRun(false) &&
        await this.checkMissingFieldsInRelations(true) &&
        await this.checkIncompleteFilter() &&
        await this.checkValidSchedule() &&
        await this.checkIfPreviewShouldBeDisabled()
      ) {
        this.setState(
          {
            selectionNavigator: buttonName,
          },
          () => {
            this.handleUpdateUnionSelectionsIfAppendDataExtensionsIsEnabled();
          },
        );
      }
    } else {
      this.setState(
        {
          selectionNavigator: buttonName,
        },
        () => {
          this.handleUpdateUnionSelectionsIfAppendDataExtensionsIsEnabled();
        },
      );
    }
  };

  /**
   * Delete a selected DE
   * @param {string} dataExtensionIdToRemove - Id of DE to be removed
   * @param {function} handleExpandFiltersBox - Function to expand filters box
   * @returns {void}
   */
  handleDeleteSelectedDE = async (dataExtensionIdToRemove, handleExpandFiltersBox = () => { }) => {
    const {
      selectedDataExtensions,
      matchedFields,
      customValues,
    } = this.state;

    const filtersOfRemovedDE = [];
    const matchedFieldsOfRemovedDE = [];

    let selectedDEDefaultValue = false;

    /**
     * Traverse all filters and check alias names
     * @param {string} DEIdToRemove - DE id to remove
     * @param {object} dataExtension - The data extension
     * @param {array<object>} filtersBranch - The filter branch
     * @returns {void}
     */
    const traverseFilters = (DEIdToRemove, dataExtension, filtersBranch) => {
      const { selectedFilters } = this.state;
      const filtersArray = filtersBranch || selectedFilters.filters;

      filtersArray.forEach((filter) => {
        if (filter.filters) {
          traverseFilters(DEIdToRemove, dataExtension, filter.filters);
        } else {
          // Check if field belongs to the deleted DE or compared DE is the same as the deleted DE
          if (filter.collectionAlias === dataExtension.deAlias ||
            filter.comparedDataExtension?.deAlias === dataExtension.deAlias
          ) {
            filtersOfRemovedDE.push(filter);
          }
        }
      });
    };

    /**
     * Traverse the matched fields
     * @param {object} dataExtension - The data extension
     * @returns {void}
     */
    const traverseMatchedFields = (dataExtension) => {
      const { targetDataExtension } = this.state;

      const targetDataExtensionAvailable = !_.isEmpty(targetDataExtension);

      // Check if there is a field of the de that we want to remove
      const filteredMatchedFields = matchedFields.filter(
        field => field.availableFieldDataExtensionCustomerKey === dataExtension.CustomerKey &&
          field.availableFieldDataExtensionAlias === dataExtension.deAlias,
      );
      // Pop up an error if there is

      if (targetDataExtensionAvailable && filteredMatchedFields && filteredMatchedFields.length > 0) {
        matchedFieldsOfRemovedDE.push(filteredMatchedFields);
      }
    };

    /**
     * Checks if selected data extension is used as default value in custom values
     * @param {object} dataExtension - Selected data extension which we want to remove from selected DEs
     * @returns {boolean} Depends if selected data extension is used for default value
     */
    const checkDefaultValue = (dataExtension) => {
      customValues.forEach((value) => {
        if (value.defaultValue && value.defaultValue.defaultDataExtensionObjectId &&
          value.defaultValue.defaultDataExtensionObjectId === dataExtension.ObjectID) {
          selectedDEDefaultValue = true;
        }
      });

      return selectedDEDefaultValue;
    };

    // Redirect user to Target Definition page if user clicks confirm
    const matchedFieldsPopUpResult = async () => {
      const matchedFieldResult = await SwalUtil.fire({
        type: Constants.SWAL__TYPE__ERROR,
        // eslint-disable-next-line max-len
        message: 'Data Extension cannot be deleted as fields of this data extension are used as Selected Fields in the Target Data Extension.',
        options: {
          confirmButtonText: 'Remove fields',
          showCancelButton: true,
        },
      });

      if (matchedFieldResult.value) {
        this.handleSelectionNavigator(Constants.NAVIGATION__TARGET_DEFINITION);
      }

      return matchedFieldResult;
    };

    /**
     * @param {Object} dataExtension - DataExtension to be removed
     * @returns {Promise<SweetAlertResult>} Swal modal
     */
    const customValuesContainsDataExtensionPopUp = async (dataExtension) => {
      const { containsCustomValuesIndex } = this.state;
      const customValuesNames = [];

      // Get the names of the custom values that uses the DE based on the index
      containsCustomValuesIndex.forEach((index) => {
        customValuesNames.push(customValues[index].name);
      });

      return SwalUtil.fire({
        title: 'Remove Data Extension',
        messageHTML:
          `<p class=width_swal>
             Data Extension
             <span class="bold_swal">
               ${dataExtension.Name}
             </span>
             is used in custom values, please remove them first:
           </p>
            ${customValuesNames.join(0).split(0).map(value => `<div class="bold_swal">${value}</div><br/>`)
    .join('')}
            `,
        type: Constants.SWAL__TYPE__ERROR,
        options: {
          confirmButtonText: 'Confirm',
        },
      });
    };

    /**
     * Handles the custom values swal popup error message
     * Redirect user to Target Definition page if user clicks confirm
     * @returns {void}
     */
    const customValuesPopUpResult = async () => {
      const customValuesResult = await SwalUtil.fire({
        // eslint-disable-next-line max-len
        message: 'Data Extension cannot be deleted as fields of this data extension are used as Default value in Custom Values.',
        type: Constants.SWAL__TYPE__ERROR,
        options: {
          confirmButtonText: 'Change Default value',
          showCancelButton: true,
        },
      });

      if (customValuesResult.value) {
        this.handleSelectionNavigator(Constants.NAVIGATION__TARGET_DEFINITION);
      }
    };

    // Variable to keep track of how many data extensions were removed
    const removedDataExtension = [];

    /**
     * Check if any custom value uses a data extension or field of it in them
     * @param {Object} dataExtension - DataExtension to be removed
     * @returns {bool} Returns true if custom values contains the DE, false otherwise
     */
    const customValuesContainsDataExtension = (dataExtension) => {
      let containsDataExtension = false;

      let containsCustomValuesIndex = [];

      /**
       * Go through all the dynamic value filters and check if they use any field from the selected Data Extension
       * @param {Object} filter - Filter to traverse
       * @param {index} index - Index of the custom value
       * @returns {void}
       */
      const traverseDynamicCustomValueFilters = (filter, index) => {
        // If it's a filter group
        if ('filters' in filter && 'operator' in filter) {
          filter.filters.forEach(childFilter => traverseDynamicCustomValueFilters(childFilter, index));
        } else {
          // If it's a filter line

          // Find deIndex
          const de = dataExtension.fields.find(field => field.ObjectID === filter.fieldObjectID ||
            dataExtension.ObjectID === filter.comparedDataExtension?.ObjectID);

          // If de is found
          if (de) {
            containsDataExtension = true;
            containsCustomValuesIndex.push(index);
          }
        }
      };

      // Go through all the custom values and check which ones use the selected Data Extension
      customValues.forEach((value, index) => {
        if (value.valueType &&
          value.valueType === Constants.CUSTOM_VALUES__FIELD_TYPE__DYNAMIC_VALUE) { // Dynamic Value
          value.criteria.forEach((criteria) => {
            traverseDynamicCustomValueFilters(criteria.when, index);
          });
        } else if (value.valueType &&
          value.formula &&
          value.valueType === 'formula' &&
          value.formula === Constants.CUSTOM_VALUES__FORMULA_TYPE__TRANSFORM) { // Transform Date
          if (value.dataExtensionObjectId === dataExtension.ObjectID) {
            containsDataExtension = true;
            containsCustomValuesIndex.push(index);
          }
        } else if (value.valueType &&
          value.formula &&
          value.valueType === 'formula' &&
          value.formula === Constants.CUSTOM_VALUES__FORMULA_TYPE__DATE_DIFFERENCE) { // Date Difference
          if (value.field1 && value.field1.format === Constants.CUSTOM_VALUES__FORMULA_FORMAT__FIELD) {
            if (value.field1.dataExtensionObjectId === dataExtension.ObjectID) {
              containsDataExtension = true;
              containsCustomValuesIndex.push(index);
            }
          }
          if (value.field2 && value.field2.format === Constants.CUSTOM_VALUES__FORMULA_FORMAT__FIELD) {
            if (value.field2.dataExtensionObjectId === dataExtension.ObjectID) {
              containsDataExtension = true;
              containsCustomValuesIndex.push(index);
            }
          }
        } else if (value.valueType &&
          value.valueType === Constants.CUSTOM_VALUES__FIELD_TYPE__AGGREGATION_VALUE) {
          // check if dataExtension exist in aggregation custom value
          if (value.aggregationValue && value.aggregationValue.relations &&
            value.aggregationValue.relations?.toCollectionAlias === dataExtension.deAlias) {
            containsDataExtension = true;
            containsCustomValuesIndex.push(index);
          }
        } else if (value.valueType &&
          value.formula && value?.formula === Constants.CUSTOM_VALUES__FORMULA_TYPE__ROW_NUMBER) {
          // check if dataExtension exist in aggregation custom value
          if (value?.orderBy?.collectionAlias === dataExtension.deAlias) {
            containsDataExtension = true;
            containsCustomValuesIndex.push(index);
          }
        }
      });

      // Remove duplicates for the index array
      containsCustomValuesIndex =
        containsCustomValuesIndex.filter((item, index) => containsCustomValuesIndex.indexOf(item) === index);
      this.setState({ containsCustomValuesIndex });

      return containsDataExtension;
    };

    /**
     * Removes data extension for selected data extension if that is possible
     * @param {String} DEIdToRemove - id of data extension we want to remove from selected data extensions
     * @param {Object} dataExtension - data extension we want to remove
     * @param {Array} subCollectionParent - subCollection of the parent de
     * @param {Number} i - index of the DE we want to remove
     * @param {Boolean} isSubCollection - check if a DE we want to remove is a subCollection or not
     * @returns {object} The removed data extension
     */
    const handleRemovalOfOneDataExtension = async (
      DEIdToRemove,
      dataExtension,
      subCollectionParent,
      i,
      isSubCollection,
    ) => {
      const { relations, selectedFilters } = this.state;
      // Check matched fields

      traverseMatchedFields(dataExtension);
      // Is there selectedFilters ? call func. : do nothing
      if (selectedFilters.filters) {
        traverseFilters(DEIdToRemove, dataExtension);
      }

      if (matchedFieldsOfRemovedDE && matchedFieldsOfRemovedDE.length > 0) {
        await matchedFieldsPopUpResult();
      } else if (filtersOfRemovedDE && filtersOfRemovedDE.length > 0) {
        /*
         * Throw swal error message when user tries to delete DE from selected DEs and
         * That DE has fields which are used as filters
         */
        await SwalUtil.fire({
          message: 'Data Extension cannot be deleted as fields of this collection are used as filters.',
          type: Constants.SWAL__TYPE__ERROR,
        });
        handleExpandFiltersBox();
      } else if (checkDefaultValue(dataExtension)) {
        await customValuesPopUpResult();
      } else if (customValuesContainsDataExtension(dataExtension)) {
        this.setState({ containsCustomValues: true });
        await customValuesContainsDataExtensionPopUp(dataExtension);
        await this.handleSelectionNavigator(Constants.NAVIGATION__TARGET_DEFINITION);
      } else if (filtersOfRemovedDE && filtersOfRemovedDE.length === 0 &&
        matchedFieldsOfRemovedDE && matchedFieldsOfRemovedDE.length === 0) {
        if (isSubCollection) {
          // This line is the problem, should not be on subCollections but the parent of dataExtension
          removedDataExtension.push(subCollectionParent.splice(i, 1));

          // Remove dataExtension with the id we want to remove
          const updatedSelectedDataExtensions = selectedDataExtensions.filter(
            selectedDataExtension => selectedDataExtension.id !== DEIdToRemove,
          );

          // Remove relation with dataExtension we want to remove
          const updatedRelations = relations.filter(
            relation => dataExtension.relationalModalId &&
              relation.relationalModalId !== dataExtension.relationalModalId,
          );

          this.setState(
            {
              relations: updatedRelations,
              selectedDataExtensions: updatedSelectedDataExtensions,
            },
            () => {
              this.handleUpdateUnionSelectionsIfAppendDataExtensionsIsEnabled();
            },
          );

          // Remove field Object IDs from picklist Object Ids for subCollection we are deleting
          await this.handlePickListOptions(dataExtension.fields, false, dataExtension);

          return dataExtension;
        }

        // It pushes some value just to indicate that one DE has been removed
        removedDataExtension.push(1);
        this.setState({ selectedDataExtensions: [], selectedDataSet: null }, () => {
          this.handleUpdateUnionSelectionsIfAppendDataExtensionsIsEnabled();
        });

        // Remove field Object IDs from picklist Object Ids for DE we are deleting
        await this.handlePickListOptions(dataExtension.fields, false, dataExtension);
      }

      return null;
    };

    /**
     * Loop through all selected DE and remove one if id matches
     * @param {object} dataExtension - all available data extensions
     * @returns {void}
     */
    const traverseDEs = async (dataExtension) => {
      // Remove from subCollection if they exists
      if (dataExtension && dataExtension.subCollections.length > 0) {
        for (let i = 0; i < dataExtension.subCollections.length; i += 1) {
          // DataExtension.subCollections.forEach(async (subCollection, i) => {

          // Recursively call fn once again if there are still subCollections
          if (dataExtension.subCollections[i]?.subCollections?.length > 0) {
            // eslint-disable-next-line no-await-in-loop
            await traverseDEs(dataExtension.subCollections[i]);
          } else {
            // If ids are matched splice dataExtension and update relations
            if (dataExtension.subCollections[i].id === dataExtensionIdToRemove) {
              // eslint-disable-next-line no-await-in-loop
              await handleRemovalOfOneDataExtension(
                dataExtensionIdToRemove,
                dataExtension.subCollections[i],
                dataExtension.subCollections,
                i,
                true,
              );
            }
          }
        }
      } else {
        // Remove from current level
        if (dataExtension && dataExtension.id === dataExtensionIdToRemove) {
          await handleRemovalOfOneDataExtension(dataExtensionIdToRemove, dataExtension, null, null, false);
        }
      }
    };

    let res;

    if (dataExtensionIdToRemove !== 'undefined') {
      res = await SwalUtil.fire({
        title: 'Remove Data Extension',
        message: 'Are you sure you want to remove this data extension?',
        options: {
          showCancelButton: true,
          confirmButtonText: 'Remove',
          cancelButtonText: 'Cancel',
        },
      });
      if (res.value) {
        await traverseDEs(selectedDataExtensions[0]);
      }
    }

    // Sets the state values back so it is ready for the next time
    this.setState({ containsCustomValues: false, containsCustomValuesIndex: [] });
  };

  /**
   * Handle filter saves for advanced deduplication
   * @param {object} filters - filters
   * @returns {void}
   */
  handleAdvancedDedupFiltersSave = (filters) => {
    const { advancedDeduplicationRules, advancedDedupFilterSaveIndex } = this.state;

    const newFiltersState = advancedDeduplicationRules;

    newFiltersState[advancedDedupFilterSaveIndex] = {
      filters: filters.filters,
      operator: filters.operator,
    };

    this.setState(
      {
        advancedDeduplicationRules: newFiltersState,
      },
      () => {
        this.handleUpdateUnionSelectionsIfAppendDataExtensionsIsEnabled();
      },
    );
  };

  /**
   * Handle filter saves
   * @param {object} filters - Filters
   * @param {number} currentSelectionIndex - index of the current selection
   * @property {array} filters.filters - Filters filters
   * @param {Boolean} aggregationFilterRemoveClicked - filter remove from aggregation
   * @param {Boolean} missingFieldInSubQuery - filter removed due to missing field in subQuery
   * @param {Boolean} missingFieldInAggregation - filter removed due to missing field in aggregation
   * @param {bool} isInResultsFormula - check if criteria in filterline is in results formula
   * @param {string} parentId - id of a parent
   * @returns {void}
   */
  handleFiltersSave = (
    filters,
    currentSelectionIndex,
    aggregationFilterRemoveClicked,
    missingFieldInSubQuery,
    missingFieldInAggregation,
    isInResultsFormula,
    parentId,
  ) => {
    const { showAddValuesModal, editCustomValueIndex, showAggregationModal } = this.state;
    const { unionSelections, unionSelectionsIndex, handleSetAppState } = this.props;

    // eslint-disable-next-line no-param-reassign
    currentSelectionIndex = typeof currentSelectionIndex === 'undefined' ?
      unionSelectionsIndex :
      currentSelectionIndex;
    // If the selection we're updating is not the active one, update it through unionSelections
    if (currentSelectionIndex !== unionSelectionsIndex) {
      const currentSelectedFilters = { ...unionSelections[currentSelectionIndex].selectedFilters };

      currentSelectedFilters.filters = filters.filters;
      currentSelectedFilters.operator = filters.operator;

      unionSelections[currentSelectionIndex].selectedFilters = currentSelectedFilters;
      handleSetAppState({ unionSelections });

      return;
    }

    if ((showAddValuesModal || editCustomValueIndex !== '') && !showAggregationModal) {
      this.setState(
        {
          dynamicCustomValuesFilters: {
            filters: filters.filters,
            operator: filters.operator,
          },
        },
        () => {
          this.handleUpdateUnionSelectionsIfAppendDataExtensionsIsEnabled();
        },
      );
    } else if ((showAddValuesModal && showAggregationModal) || (showAggregationModal && editCustomValueIndex !== '')) {
      // set filter removal click
      const aggregationFilterRemove = aggregationFilterRemoveClicked || false;

      /*
       * set and save filters in aggregationFilters state
       * when selected by aggregationValues filter modal
       */
      this.setState(
        {
          aggregationFilters: {
            filters: filters.filters,
            operator: filters.operator,
            aggregationFilterRemoveClicked: aggregationFilterRemove,
          },
        },
        () => {
          this.handleUpdateUnionSelectionsIfAppendDataExtensionsIsEnabled();
        },
      );
    } else if (isInResultsFormula) {
      const { selectedFilters } = this.state;

      const parentFilter = Util.findFilterElement(parentId, selectedFilters.filters || []);

      parentFilter.subQuery.formulas = filters;
      this.setState({
        selectedFilters,
      });
    } else if (!missingFieldInSubQuery && !missingFieldInAggregation) {
      this.setState(
        {
          selectedFilters: {
            filters: filters.filters,
            operator: filters.operator,
          },
        },
        () => {
          this.handleUpdateUnionSelectionsIfAppendDataExtensionsIsEnabled(currentSelectionIndex);
        },
      );
    }
  };

  /**
   * Remove filterline from filtercontainer
   * Function can't be moved into another component. It causes state update delay
   *
   * IgnoreRemoveFunctionCall is only to be used while calling this function from inside, and toIgnore from outside.
   * @param {object} filterLineToRemove- object with filter property to be removed
   * @param {string} filterLineToRemove.filterLineId - id of dragged element
   * @param {boolean} filterLineToRemove.fieldExists - true/false
   * @param {string} filterLineToRemove.field - field name used in filters
   * @param {object} filterLineToRemove.dataExtension - DE
   * @param {object} filterLineToRemove.parent - parent object filter
   * @param {object} filterLineToRemove.grandParent - grand parent object filter
   * @param {object} filterLineToRemove.filtersBranch - branch with filters
   * @param {boolean} filterLineToRemove.ignoreRemoveFunctionCall - true/false
   * @param {boolean} filterLineToRemove.toIgnore - true/false
   * @param {string} filterLineToRemove.subQueryModalFilterLineId - filterline id of a subquery`s filters
   * @param {boolean} filterLineToRemove.isSubQuery - indicates whether the filter being removed is a subquery or not
   * @param {function} filterLineToRemove.handleFiltersSave - handles the saving of filters
   * @param {string} filterLineToRemove.filterType - Indicates type/location of filter
   * @param {array} filterLineToRemove.subQueryFilters - subQuery filters
   * @param {boolean} filterLineToRemove.nested - Indicates whether the function call is nested
   * @param {boolean} filterLineToRemove.aggregationFilterRemoveClicked - true/false passed from aggregation modal
   * @param {boolean} filterLineToRemove.removedSubQueryDE - true/false depending on whether DE in subQuery filter
   * has been removed
   * @param {string} filterLineToRemove.fromDEForRelationFilter - name for DE used in relation filter as fromDE
   * @param {boolean} filterLineToRemove.missingFieldInSubQuery - Indicates whether subQuery filter is removed,
   *  because the field was not found
   * @param {object} filterLineToRemove.aggregationCustomValue - object with aggregation value used when field in
   * filters is missing and will be removed
   * @param {boolean} filterToRemove.isRelationFilter - Indicates whether the filter is a relation filter type
   * @returns {object} - swal
   */
  handleRemoveFilterLine = async ({
    filterLineId,
    fieldExists,
    field,
    dataExtension,
    parent,
    grandParent,
    filtersBranch,
    ignoreRemoveFunctionCall,
    toIgnore,
    subQueryModalFilterLineId,
    isSubQuery,
    isRelatedRecord,
    handleFiltersSave,
    filterType,
    subQueryFilters,
    nested,
    aggregationFilterRemoveClicked,
    removedSubQueryDE,
    fromDEForRelationFilter,
    missingFieldInSubQuery,
    aggregationCustomValue,
    isRelationFilter,
    isInResultsFormula,
    parentId,
    parentFilter,
  }) => {
    // Confirmation modal message
    const confirmRemove = async () => {
      if (toIgnore) return { value: toIgnore };

      if (!removedSubQueryDE && !fieldExists && field && dataExtension &&
        !fromDEForRelationFilter && !missingFieldInSubQuery) {
        /*
         * return response when field in the filter is missing and this filter will be removed
         */

        return selectionUpdatesUtil.removedFieldInFilterResponse(field, dataExtension, isRelatedRecord);
      } if (removedSubQueryDE && dataExtension && !fromDEForRelationFilter && !missingFieldInSubQuery) {
        /*
         * return response when data extension is missing in In/ Not In results filter and
         * this filter will be removed
         */
        return selectionUpdatesUtil.removedDataExtensionInSubQueryFilterResponse(dataExtension);
      } if (fromDEForRelationFilter) {
        /*
         * return response when data extensions cannot be found in related records and
         * relation filter will be removed
         */
        return selectionUpdatesUtil.removedDataExtensionInRelationFilterResponse(
          dataExtension,
          fromDEForRelationFilter,
        );
      } if (aggregationCustomValue) {
        /*
         * return response when field used in aggregation custom value cannot be found
         * and filter used in custom value will be removed
         */
        return selectionUpdatesUtil.removedFieldInAggregationCustomValueFilterResponse(
          field,
          dataExtension,
          aggregationCustomValue.name,
        );
      } if (missingFieldInSubQuery && !isRelationFilter) {
        /*
         * return response when field used in filter in In/Not In results filters cannot be found
         * and this filter will be removed from subQuery filters
         */
        return selectionUpdatesUtil.removedFieldInSubQueryFilterResponse(field, dataExtension);
      } if (isRelationFilter) {
        /*
         * return response when field used in filter in Related Records filters cannot be found
         * and this filter will be removed from subQuery filters
         */
        return selectionUpdatesUtil.removedFieldInFiltersInRelatedRecordsResponse(field, dataExtension);
      }

      return SwalUtil.fire({
        title: 'Remove Filter',
        message: 'Are you sure you want to remove this filter?',
        options: {
          showCancelButton: true,
          confirmButtonText: 'Remove filter',
          cancelButtonText: 'Cancel',
        },
      });
    };

    // Assign the response of swal modal to this variable
    let res;

    // swal called before ? call : pass
    if (!ignoreRemoveFunctionCall) {
      res = await confirmRemove();
    }

    const { unionSelections, unionSelectionsIndex } = this.props;

    // data for selectionUpdates
    const updateData = res?.message && res?.objectType ? res : null;

    if (updateData) {
      const { selectionUpdates } = this.state;
      const { message, objectType } = updateData;

      // get the tab name of removed filter
      const { tabName } = unionSelections[unionSelectionsIndex];

      // capture the change in selection and save data in selection updates
      this.setState({
        captureSelectionChange: true,
        selectionUpdates:
          [...selectionUpdates, {
            actionType: Constants.UPDATE_SELECTION__ACTION_TYPE__REMOVE,
            message,
            objectType,
            tabName,
          }],
        showUpdateSelectionModal: true,
      });
    }

    // user confirmed to remove
    if (ignoreRemoveFunctionCall || res.value || updateData) {
      const { selectedFilters } = unionSelections[unionSelectionsIndex];

      let { operator } = selectedFilters;

      let { filters } = selectedFilters;

      const {
        advancedDeduplicationRules, advancedDedupFilterSaveIndex, dynamicCustomValuesFilters,
        aggregationFilters, showAggregationModal,
      } = this.state;

      /*
       * if aggregationCustomValue is passed, it means that there is missing field
       * in the aggregation custom value filters
       */
      const missingFieldInAggregation = !!aggregationCustomValue;

      // pass aggregationFilterRemoveClicked to aggregationFilterRemove for reassign to true
      let aggregationFilterRemove = aggregationFilterRemoveClicked;
      // If the filter is subQuery

      if (isSubQuery) {
        // Set filters property
        filters = subQueryFilters;
      }

      // Set filters' value depending on its type
      if (filterType === Constants.FILTER_TYPE__PRIO_DEDUP) {
        // Set filters' value if type is priodedup
        filters = advancedDeduplicationRules[advancedDedupFilterSaveIndex].filters;

        // set operator - this avoids errors and operator changes
        operator = advancedDeduplicationRules[advancedDedupFilterSaveIndex]?.operator || operator;
      } else if (filterType === Constants.FILTER_TYPE__CUSTOM_VALUES && !showAggregationModal) {
        // Set filters' value if type is customValues
        filters = Array.isArray(dynamicCustomValuesFilters) ?
          dynamicCustomValuesFilters :
          dynamicCustomValuesFilters.filters;

        // set operator - this avoids errors and operator changes
        operator = dynamicCustomValuesFilters?.operator || operator;
      } else if (filterType === Constants.FILTER_TYPE__CUSTOM_VALUES && showAggregationModal) {
        // Set filters' value if type is customValues and from aggregationValues

        // if aggregationCustomValue is passed, then assign it to filters
        if (aggregationCustomValue?.filters?.length) {
          filters = Array.isArray(aggregationCustomValue.filters[0]?.filters) ?
            aggregationCustomValue.filters[0].filters :
            aggregationCustomValue.filters;

          // set operator - this avoids errors and operator changes
          operator = aggregationCustomValue.filters[0]?.operator || operator;
        } else {
          // otherwise, get filters from aggregationFilters state
          filters = Array.isArray(aggregationFilters) ?
            aggregationFilters :
            aggregationFilters.filters;

          // set operator - this avoids errors and operator changes
          operator = aggregationFilters?.operator || operator;
        }

        aggregationFilterRemove = true;
      }

      /*
       * This is the filters array we need to find a matching element on:
       * works on the filters state unless a specific branch is passed
       */
      let filtersArray = filtersBranch || filters;

      if (isInResultsFormula && !filtersBranch) {
        parentFilter = Util.findFilterElement(parentId, filters);

        filtersArray = parentFilter.subQuery.formulas.filters;
      }

      // Loop through filtersArray
      for (let f = 0; f < filtersArray.length; f += 1) {
        // If the array element contains a filters array, execute this function on this branch
        if (filtersArray[f].filters) {
          this.handleRemoveFilterLine({
            filterLineId,
            fieldExists: true,
            parent: filtersArray[f],
            grandParent: parent,
            filtersBranch: filtersArray[f].filters,
            ignoreRemoveFunctionCall: true,
            subQueryModalFilterLineId,
            isSubQuery,
            handleFiltersSave,
            filterType,
            subQueryFilters,
            nested: true,
            aggregationFilterRemoveClicked: aggregationFilterRemove,
            removedSubQueryDE,
            fromDEForRelationFilter,
            missingFieldInSubQuery,
            aggregationCustomValue,
            isRelationFilter,
            isInResultsFormula,
            parentId,
            parentFilter,
          });
        } else if (filtersArray[f].id === filterLineId) {
          if (filtersArray[f].subQuery && filtersArray[f].subQuery.dataExtensionFields &&
            filtersArray[f].subQuery.dataExtensionFields.length > 0) {
            // if users deletes filterline remove fields object ids from picklist fields object ids
            this.handlePickListOptions(
              filtersArray[f]?.subQuery?.selectedDataExtension?.fields,
              false,
              filtersArray[f]?.subQuery?.selectedDataExtension,
              true,
            );
          }
          // Remove filterline element from array
          filtersArray.splice(f, 1);

          /*
           * If only one filter is left, remove it from current level and move it up one
           * level (removes current filtercontainer)
           */
          if (filtersArray.length === 1) {
            // Remaining filter, added to filters' array of filtercontainer one level higher
            const filterLineToMove = filtersArray[0];

            // All previous filters and a new one which is a remaining one after deletion
            let filtersArrayToEdit;

            if (grandParent && grandParent.filters) {
              filtersArrayToEdit = grandParent.filters;
            } else {
              filtersArrayToEdit = isInResultsFormula ? parentFilter.subQuery.formulas.filters : filters;
            }

            // Remove current filter container
            for (let p = 0; p < filtersArrayToEdit.length; p += 1) {
              if (
                filtersArrayToEdit[p] &&
                filtersArrayToEdit[p].operator &&
                filtersArrayToEdit[p].id === (parent ? parent.id : filtersArrayToEdit[p].id)
              ) {
                // if we delete last filterline from the nested filterContainer
                if (!parent && filtersArray.length === 1) {
                  Util.handleUpdateFormulaFiltersParentId({ filters });
                  filtersArray = this.recreateIds(filtersArray[0].filters, parentId, isInResultsFormula);
                  /*
                   * we need to set the state in the callback function of setState,
                   * otherwise we can't properly remove second filterContainer
                   */
                  handleFiltersSave(
                    { filters: filtersArray, operator },
                    unionSelectionsIndex,
                    aggregationFilterRemove,
                    missingFieldInSubQuery,
                    missingFieldInAggregation,
                    isInResultsFormula,
                    parentId,
                  );

                  return;
                }
                filtersArrayToEdit.splice(p, 1, filterLineToMove);
                // stop looping through grandParent.filters
                break;
              }
            }
          }
          // Stop looping through filtersArray
          break;
        }
      }

      Util.handleUpdateFormulaFiltersParentId({ filters });
      filtersArray = this.recreateIds(filtersArray, parentId, isInResultsFormula);
      /*
       * If we're dealing with a nested call to the handleRemoveFilterLine function,
       * don't save filters till we're in the root function call
       */
      if (!nested) {
        handleFiltersSave(
          { filters: filtersArray, operator },
          unionSelectionsIndex,
          aggregationFilterRemove,
          null,
          missingFieldInAggregation,
          isInResultsFormula,
          parentId,
        );
      }
    }
  };

  /**
   * Id generator for filters
   * @param {string} parentId - if of a parent
   * @param {number} id - id
   * @param {bool} isInResultsFormula - check if criteria in filterline is in results formula
   * @returns {string} - newId
   */

  createId = (parentId, id, isInResultsFormula) => {
    if (isInResultsFormula) {
      return parentId + '-' + id;
    }

    return parentId == null ? '' + id : parentId + ',' + id;
  };

  /**
   * Recreate all ids for filters
   * Needs to be called after updated filters has been saved as state
   * @param {object} filtersArray - filters
   * @param {string} parentId - id of a parent
   *  @param {bool} isInResultsFormula - check if criteria in filterline is in results formula
   * @returns {object} - filters
   */
  recreateIds = (filtersArray, parentId, isInResultsFormula) => {
    // Loop through filtersArray
    for (let f = 0; f < filtersArray.length; f += 1) {
      // Update id for this element
      // eslint-disable-next-line no-param-reassign
      filtersArray[f].id = this.createId(
        parentId,
        f,
        isInResultsFormula,
      );

      if (filtersArray[f].subQuery?.formulas?.filters?.length) {
        this.recreateIds(filtersArray[f].subQuery.formulas.filters, filtersArray[f].id, true);
      }

      /*
       * If the array element contains a filters array, execute this function on this branch
       * (executes when you join filters)
       */
      if (filtersArray[f].filters) {
        this.recreateIds(
          filtersArray[f].filters,
          filtersArray[f].id,
          isInResultsFormula,
        );
      }
    }

    return filtersArray;
  };

  /**
   * Show loading modal
   * @returns {void}
   */
  showLoadingModal = () => {
    const {
      currentSelectionId,
      loadExistingSelection,
      isSelectionRequestDone,
      isDataExtensionRequestDone,
      isFieldsRequestDone,
      isTargetExtensionRequestDone,
      isFiltersUpdatedDone,
    } = this.state;

    return currentSelectionId && loadExistingSelection ?
      !!(!isSelectionRequestDone ||
        !isDataExtensionRequestDone ||
        !isFieldsRequestDone ||
        !isTargetExtensionRequestDone ||
        !isFiltersUpdatedDone) :
      (!isDataExtensionRequestDone && isSelectionRequestDone);
  };

  /**
   * Update the Send Relationship dropdowns
   * @param {object[]} tempNewTargetDataExtensionFields - Fields in target definition
   * @param {object} newTargetDataExtensionField - Field in target definition
   * @returns {void}
   */
  manageSubscriberRelationship = (tempNewTargetDataExtensionFields, newTargetDataExtensionField) => {
    // Add option in dropdown
    this.addSubscriberOption(tempNewTargetDataExtensionFields);
    const { newTargetDataExtension } = this.state;
    /**
     * When you drop first field, the name of that field is in dropdown
     * So set the sendable name to the name of first field only if
     * Field type of that field is text, number or email and also set
     * The subscriber name
     */

    if (!(newTargetDataExtension.relationship.sendableDataExtensionField &&
      newTargetDataExtension.relationship.sendableDataExtensionField.name) &&
      (newTargetDataExtensionField.FieldType === Constants.FILTERLINE__FIELDTYPE__TEXT ||
        newTargetDataExtensionField.FieldType === Constants.FILTERLINE__FIELDTYPE__NUMBER ||
        newTargetDataExtensionField.FieldType === Constants.FILTERLINE__FIELDTYPE__EMAILADDRESS)
    ) {
      const { defaultSendRelationshipField } = this.state;

      const fieldName = newTargetDataExtensionField?.Name?.toString();

      if (this.checkDefaultSendRelationshipFieldMatch(
        { fieldName },
        defaultSendRelationshipField,
      )) {
        this.handleChangeSendableAndSubscriberField(newTargetDataExtensionField);
      } else {
        this.handleChangeSendableAndSubscriberField('none');
      }
    }
  };

  /**
   * Function called when field is dropped on a line or when a field is double clicked
   * For a target DE field
   * @param {object} e - Event
   * @param {object} targetDataExtensionFieldCustomerKey - Target DE field customer key
   * @param {object} targetDataExtensionFieldName - Target DE field name
   * @param {object} targetDataExtensionFieldFieldType - Target DE field type
   * @param {object} targetDataExtensionFieldObjectID - Target DE field object id
   * @param {object} data - Available field : name, id, customer key, field type
   * @param {boolean} targetDataExtensionFieldIsRequired - True/false if the TargetDE field is required
   * @param {boolean} mappingRequiredWithNullable - True/false if the mapping is required with nullable
   * @param {boolean} requiredNotMapped - True if a field is required but not mapped
   * @param {boolean} shorterFieldSize - True if trying to map to a field with shorter size
   * @returns {void}
   */
  handleAddFieldToTargetDE = async (
    e,
    targetDataExtensionFieldCustomerKey = '',
    targetDataExtensionFieldName = '',
    targetDataExtensionFieldFieldType = '',
    targetDataExtensionFieldObjectID = '',
    // AvailableFieldDataExtensionCustomerKey,
    data,
    targetDataExtensionFieldIsRequired = '',
    mappingRequiredWithNullable,
    requiredNotMapped,
    shorterFieldSize,
  ) => {
    const {
      selectedDataExtensions,
      newTargetDataExtensionFields,
      editNewAutoTargetDE,
      targetDataExtensionFields,
      editTargetDataExtension,
      targetDataExtension,
      prevTargetDEFields,
    } = this.state;

    if (e?.preventDefault) {
      e.preventDefault();
    }

    let availableFieldName;

    let availableFieldObjectID;

    let availableFieldFieldType;

    let availableFieldDataExtensionCustomerKey;

    let availableFieldDataExtensionAlias;

    let availableFieldIsRequired;

    let availableFieldMaxLength;

    let globalCustomValueId;
    // If data exists, that means we're dealing with a double click here

    if (data) {
      ({
        availableFieldName,
        availableFieldObjectID,
        availableFieldDataExtensionCustomerKey,
        availableFieldFieldType,
        availableFieldIsRequired,
        availableFieldMaxLength,
      } = data);
      /**
       * If required field is mapped with nullable field (mappingRequiredWithNullable) or
       * Mapping a field to a field with a shorted field size (shorterFieldSize)
       */
    } else if (mappingRequiredWithNullable || shorterFieldSize) {
      const { matchedFields } = this.state;

      let matchedField;
      // Find field in matchedFields with targetDataExtensionFieldObjectID
      const matchedFieldFiltered = matchedFields.filter(
        field => field.targetDataExtensionFieldObjectID === targetDataExtensionFieldObjectID,
      );

      if (matchedFieldFiltered && matchedFieldFiltered.length > 0) {
        // If availableFieldObjectID includes 'customValues' in ID that means we are mapping custom values
        if (matchedFieldFiltered[0] && matchedFieldFiltered[0].availableFieldObjectID &&
          matchedFieldFiltered[0].availableFieldObjectID.includes('customValues')) {
          [{ availableFieldName }] = matchedFieldFiltered;
        } else {
          // Get field from selectedDataExtensions with ObjectID equals to availableFieldObjectID
          matchedFieldFiltered.forEach((field) => {
            selectedDataExtensions.forEach((de) => {
              if (field.availableFieldDataExtensionAlias === de.deAlias) {
                // Get data extension alias and set it to availableFieldDataExtensionAlias
                availableFieldDataExtensionAlias = de.deAlias;
                de.fields.forEach((deField) => {
                  if (deField.ObjectID === (field.availableFieldObjectID.includes('+') ?
                    field.availableFieldObjectID.split('+')[0] :
                    field.availableFieldObjectID)) {
                    matchedField = deField;
                  }
                });
              }
            });
          });
        }
      }

      if (matchedField) {
        // Set necessary variables
        availableFieldIsRequired = `${matchedField.IsRequired}`;
        availableFieldName = matchedField.Name.toString();
        availableFieldDataExtensionCustomerKey = matchedField.DataExtension.CustomerKey;
        availableFieldFieldType = matchedField.FieldType;
        availableFieldMaxLength = matchedField.MaxLength;
      }
    } else if (!requiredNotMapped) {
      // The data we're getting via dataTransfer relates to Available Fields
      availableFieldFieldType = e.dataTransfer.getData('type');
      availableFieldName = e.dataTransfer.getData('fieldName');
      availableFieldDataExtensionCustomerKey = e.dataTransfer.getData('customerKey');
      availableFieldObjectID = e.dataTransfer.getData('fieldObjectID');
      availableFieldDataExtensionAlias = e.dataTransfer.getData('alias');
      availableFieldIsRequired = e.dataTransfer.getData('IsRequired');
      // Or null because tests fail
      availableFieldMaxLength = JSON.parse(e.dataTransfer.getData('MaxLength') || null);
      globalCustomValueId = e.dataTransfer.getData('globalCustomValueId');
    }

    if (!targetDataExtensionFieldName) {
      let newTargetDataExtensionField = {};

      // Custom Value
      if (availableFieldDataExtensionCustomerKey === 'null') {
        if (availableFieldFieldType === Constants.FILTERLINE__FIELDTYPE__DECIMAL) {
          // Set MaxLength and Scale if field is decimal
          newTargetDataExtensionField = { ...availableFieldMaxLength };
        } else if (availableFieldMaxLength) {
          // Set MaxLength when field is not Number or Date
          newTargetDataExtensionField.MaxLength = availableFieldMaxLength;
        }

        // generate new ID for custom value field
        const customValueID = Util.uuid();

        // give a unique ID number
        availableFieldObjectID = `${customValueID}-${availableFieldName}-customValues`;

        newTargetDataExtensionField = {
          ...newTargetDataExtensionField,
          Name: availableFieldName,
          IsPrimaryKey: false,
          IsRequired: false,
          DefaultValue: '',
          FieldType: availableFieldFieldType,
          ObjectID: availableFieldObjectID,
          globalCustomValueId,
        };
      } else {
        for (let extension = 0; extension < selectedDataExtensions.length; extension += 1) {
          for (let field = 0; field < selectedDataExtensions[extension].fields.length; field += 1) {
            if (selectedDataExtensions[extension].fields[field].ObjectID === availableFieldObjectID) {
              newTargetDataExtensionField = { ...selectedDataExtensions[extension].fields[field] };
              if (newTargetDataExtensionField.FieldType === Constants.FILTERLINE__FIELDTYPE__TEXT &&
                !newTargetDataExtensionField.MaxLength) {
                newTargetDataExtensionField.MaxLength = Constants.FIELD_TYPE__TEXT__MAX_LENGTH__DEFAULT_VALUE;
              }
            }
          }
        }
      }

      let tempNewTargetDataExtensionFields = [];

      // If we are in edit target de mode and not in ACDE mode then update target de fields
      if (editTargetDataExtension) {
        tempNewTargetDataExtensionFields = [...targetDataExtensionFields];
      } else tempNewTargetDataExtensionFields = [...newTargetDataExtensionFields];

      // eslint-disable-next-line no-param-reassign
      targetDataExtensionFieldName = availableFieldName;

      /**
       * Returns a swal with input for duplicated field name, it also checks validation of the entered new field name
       * @param {string} name - The proposed unique name
       * @param {string} title - The title for the swal modal
       * @param {string} text - The message for the swal modal
       * @returns {boolean} True or false
       */
      const invalidFieldNameSwal = async (name, title, text) => SwalUtil.fire({
        title,
        message: text,
        options: {
          confirmButtonText: 'OK',
          input: 'text',
          inputValue: name,
          allowOutsideClick: false,
          showCancelButton: true,
          cancelButtonText: 'Cancel',
          allowEscapeKey: false,

          /**
           * This function is from sweet alert itself
           * Check if name is empty or it starts with underscore or it contains only numbers
           * @param {string} newName - The new name
           * @returns {string} The message to display
           */
          inputValidator: (newName) => {
            if (!newName) {
              return 'Field name cannot be empty.';
            }
            if (Util.startsWithUnderScore(newName)) {
              return Constants.WARNING__FIELD_INVALID__STARTS_WITH_UNDERSCORE;
            }
            if (Util.containsIllegalCharacters(newName)) {
              const invalidCharacters = Util.containsIllegalCharacters(newName);

              return `Field name ${newName} cannot contain ${invalidCharacters}.`;
            }
            if (Util.containsOnlyNumbers(newName)) {
              return `Field name ${newName} cannot consist entirely of numbers.`;
            }

            /*
             * display error in edit targetDE mode
             * if this name exists in SendableDataExtensionField but it is not duplicated
             */
            if (targetDataExtension?.SendableDataExtensionField
              ?.Name?.toString()?.toLowerCase()?.trim() === newName?.toString()?.toLowerCase()?.trim() &&
              editTargetDataExtension &&
              !editNewAutoTargetDE &&
              !Util.getDuplicateField(tempNewTargetDataExtensionFields, newName)?.length) {
              // eslint-disable-next-line max-len
              return 'The field with this name has been removed and added again. This name is used in the subscriber relationship. Please rename the field or click Update Target Data Extension first, then go back to editing and add field with this name again.';
            }

            return null;
          },
        },
      });

      // Remove underscore if the field belongs to a dataview
      if (newTargetDataExtensionField && newTargetDataExtensionField.Name &&
        Util.startsWithUnderScore(newTargetDataExtensionField.Name.toString())) {
        newTargetDataExtensionField.Name =
          Util.removeUnderscoreFirstCharacter(newTargetDataExtensionField.Name.toString());
      }
      /**
       * Find the fields which have the same name
       * If we are in edit target de mode then look through target de fields
       * In other case look through new ACDE fields
       */
      let duplicateFieldByName = editTargetDataExtension && !editNewAutoTargetDE ?
        Util.getDuplicateField(targetDataExtensionFields, newTargetDataExtensionField.Name) :
        Util.getDuplicateField(newTargetDataExtensionFields, newTargetDataExtensionField.Name);

      // Values to use in duplicate/invalid name modals.
      let result = false;

      let swalResult;

      /**
       * Checks tempNewTargetDataExtensionFields to see if field name is unique
       * @param {string} name - The name to check the uniqueness with
       * @returns {boolean} True or false
       */
      const isFieldNameUnique = (name) => {
        // if the added field name exists in SendableDataExtensionField, then do not return its name
        if (editTargetDataExtension && targetDataExtension?.SendableDataExtensionField?.Name?.toString() ===
          name.toString()) {
          return false;
        }

        for (let i = 0; i < tempNewTargetDataExtensionFields.length; i += 1) {
          if (name.toString() === tempNewTargetDataExtensionFields[i].Name.toString()) {
            return false;
          }
        }

        return true;
      };

      /**
       * Returns a unique field name, by increasing a counter that's part of the name
       * @param {string} name - The field name
       * @returns {string} A unique name
       */
      const getUniqueFieldName = (name) => {
        while (!isFieldNameUnique(name)) {
          const counter = Number(name.toString()[name.length - 1]);

          if (counter) {
            // eslint-disable-next-line no-param-reassign
            name = name.toString().substring(0, name.length - 1) + '' + (counter + 1);
          } else {
            // eslint-disable-next-line no-param-reassign
            name += '2';
          }
        }

        return name;
      };

      /**
       * Loop through the fields if they have the same after swal is fired.
       * After a field drag and dropped if there is a duplicated one
       * Then fire a swal and ask for a new name
       * Otherwise just change while loop`s condition and break the loop
       */
      while (!result) {
        if (duplicateFieldByName.length > 0) {
          // eslint-disable-next-line no-await-in-loop
          swalResult = await invalidFieldNameSwal(
            getUniqueFieldName(duplicateFieldByName[0].Name.toString()),
            'Duplicate Field Name',
            'Please change the name of this field',
          );

          /*
           * if there is a value from the swal verify the field name and
           * push it or don't into newTargetDataExtensionField
           */
          if (swalResult.value) {
            duplicateFieldByName = Util.getDuplicateField(tempNewTargetDataExtensionFields, swalResult.value);

            // If we still have a field with the same name or field which starts with underscore continue
            if (duplicateFieldByName.length <= 0) {
              // Assign the new name to the field
              // eslint-disable-next-line require-atomic-updates
              newTargetDataExtensionField.Name = swalResult.value.toString();
              // eslint-disable-next-line require-atomic-updates
              targetDataExtensionFieldName = swalResult.value;
              // Assign a temporarily ObjectID to the Field so we can remove it from dropzone easier
              // eslint-disable-next-line require-atomic-updates
              newTargetDataExtensionField.ObjectID += '+' + swalResult.value;
              availableFieldObjectID += '+' + swalResult.value;
              result = true;
            }
          } else {
            result = true;

            return;
          }
        } else {
          result = true;
        }
      }

      /*
       * check if this name exists in SendableDataExtensionField before updating and added field is a new one,
       * with different ObjectID
       */
      const isAddedFieldNameInSendableDataExtensionField = targetDataExtension?.SendableDataExtensionField
        ?.Name?.toString().toLowerCase().trim() === targetDataExtensionFieldName?.toString()?.toLowerCase()?.trim() &&
        !prevTargetDEFields.find(field => field.ObjectID === availableFieldObjectID);

      // throw a message to the user that this field name exists in subscriber relationship'
      if (
        isAddedFieldNameInSendableDataExtensionField &&
        editTargetDataExtension &&
        !editNewAutoTargetDE &&
        targetDataExtension?.isSendable
      ) {
        const title = 'This name exists in subscriber relationship';
        // eslint-disable-next-line max-len
        const text = 'The field with this name has been removed and added again. This name is used in the subscriber relationship. Please rename the field or click Update Target Data Extension first, then go back to editing and add field with this name again.';

        const inputValue = targetDataExtensionFieldName;

        // indicates to show the modal with the message
        let showSwalAgain = true;

        // indicates that the next steps can be proceeded
        let goToNextStep;

        while (showSwalAgain) {
          const results = await SwalUtil.fire({
            title,
            message: text,
            options: {
              input: 'text',
              inputValue,
              allowOutsideClick: false,
              showCancelButton: true,
              cancelButtonText: 'Cancel',
              confirmButtonText: 'Rename the field',
              allowEscapeKey: false,
              inputValidator: newName => Util.getDuplicateField(
                tempNewTargetDataExtensionFields,
                newName,
              )?.length && 'There is already a field with this name.',
            },
          });

          if (results.isConfirmed) {
            const { value } = results;

            if (value && value !== '') {
              // show swal again if entered value hasn't changed
              showSwalAgain = value === targetDataExtensionFieldName;

              // go to the next step if entered value is different
              goToNextStep = value !== targetDataExtensionFieldName;

              // change target DE field name to the new one
              targetDataExtensionFieldName = value;
              // eslint-disable-next-line require-atomic-updates
              newTargetDataExtensionField.Name = value;
            } else {
              showSwalAgain = false;
            }
          } else {
            showSwalAgain = false;
          }
        }

        if (!goToNextStep) return;
      }

      if (duplicateFieldByName.length === 0) {
        tempNewTargetDataExtensionFields.push(newTargetDataExtensionField);
      }

      /**
       * If we are in edit target de mode update target de fields
       * Otherwise update new ACDE fields
       */
      if (editTargetDataExtension && !editNewAutoTargetDE) {
        this.handleSetSelectionState({ targetDataExtensionFields: tempNewTargetDataExtensionFields });
      } else {
        this.handleSetSelectionState({ newTargetDataExtensionFields: tempNewTargetDataExtensionFields });
      }

      this.manageSubscriberRelationship(tempNewTargetDataExtensionFields, newTargetDataExtensionField);

      this.dropToTargetDataExtensionField(
        availableFieldFieldType,
        availableFieldDataExtensionCustomerKey,
        availableFieldName,
        availableFieldDataExtensionAlias,
        targetDataExtensionFieldName,
        availableFieldDataExtensionCustomerKey,
        availableFieldObjectID,
        availableFieldObjectID,
        true,
        availableFieldIsRequired,
        availableFieldMaxLength,
        globalCustomValueId,
      );
      /*
       * If available field type is text and target
       * field type is email address allow mappings
       */
    } else if (availableFieldFieldType === Constants.FILTERLINE__FIELDTYPE__TEXT &&
      targetDataExtensionFieldFieldType === Constants.FILTERLINE__FIELDTYPE__EMAILADDRESS) {
      // set fieldData with required fields and properties
      const fieldData = {
        availableFieldName,
        availableFieldFieldType,
        targetDataExtensionFieldName,
        targetDataExtensionFieldFieldType,
        targetDataExtensionFieldObjectID,
      };
      // show swal for warning

      this.showFieldTypesDontMatchWarning(fieldData);

      // drop field in target de
      this.dropToTargetDataExtensionField(
        availableFieldFieldType,
        targetDataExtensionFieldCustomerKey,
        availableFieldName,
        availableFieldDataExtensionAlias,
        targetDataExtensionFieldName,
        availableFieldDataExtensionCustomerKey,
        availableFieldObjectID,
        targetDataExtensionFieldObjectID,
        true,
        availableFieldIsRequired,
        availableFieldMaxLength,
        globalCustomValueId,
      );
      // If deFieldType == text execute function with warning
    } else if (
      ((targetDataExtensionFieldFieldType === Constants.FILTERLINE__FIELDTYPE__TEXT) ||
        (targetDataExtensionFieldFieldType === Constants.FILTERLINE__FIELDTYPE__NUMBER &&
          availableFieldFieldType === Constants.FILTERLINE__FIELDTYPE__DECIMAL) ||
        (targetDataExtensionFieldFieldType === Constants.FILTERLINE__FIELDTYPE__DECIMAL &&
          availableFieldFieldType === Constants.FILTERLINE__FIELDTYPE__NUMBER)) &&
      (availableFieldFieldType !== targetDataExtensionFieldFieldType &&
        availableFieldFieldType &&
        !shorterFieldSize &&
        !mappingRequiredWithNullable
      )
    ) {
      // set fieldData with required fields and properties
      const fieldData = {
        availableFieldName,
        availableFieldFieldType,
        targetDataExtensionFieldName,
        targetDataExtensionFieldFieldType,
        targetDataExtensionFieldObjectID,
      };

      // show swal for warning
      this.showFieldTypesDontMatchWarning(fieldData);

      this.dropToTargetDataExtensionField(
        availableFieldFieldType,
        targetDataExtensionFieldCustomerKey,
        availableFieldName,
        availableFieldDataExtensionAlias,
        targetDataExtensionFieldName,
        availableFieldDataExtensionCustomerKey,
        availableFieldObjectID,
        targetDataExtensionFieldObjectID,
        true,
        availableFieldIsRequired,
        availableFieldMaxLength,
        globalCustomValueId,
      );
      // If user try to map nullable field with non nullable field or field with different length
    } else if (
      targetDataExtensionFieldFieldType !== Constants.FILTERLINE__FIELDTYPE__TEXT &&
      availableFieldFieldType !== targetDataExtensionFieldFieldType &&
      availableFieldFieldType
    ) {
      const { matchedFields } = this.state;

      const matchedFieldFiltered = matchedFields.filter(
        field => field.targetDataExtensionFieldObjectID === targetDataExtensionFieldObjectID,
      );

      if (!matchedFieldFiltered.length) {
        SwalUtil.fire({
          title: "Field Types Don't Match",
          // eslint-disable-next-line max-len
          message: `${availableFieldName} is a ${availableFieldFieldType}, ${targetDataExtensionFieldName} is a ${targetDataExtensionFieldFieldType}`,
          type: Constants.SWAL__TYPE__ERROR,
          options: {
            confirmButtonText: 'OK',
          },
        });
      }
      // If required field is not mapped throw error message
    } else if ((availableFieldIsRequired === Constants.FIELD__IS_NOT_REQUIRED &&
      targetDataExtensionFieldIsRequired === Constants.FIELD__IS_REQUIRED) || shorterFieldSize) {
      if (mappingRequiredWithNullable || shorterFieldSize) {
        SwalUtil.fire({
          title:
            mappingRequiredWithNullable ?
              'Nullable Field Is Mapped With Required Field.' :
              'Mapped Fields Have Different Length.',
          /* eslint-disable max-len */
          message:
            mappingRequiredWithNullable ?
              `You are mapping nullable field ${availableFieldName} into required field ${targetDataExtensionFieldName}. This may cause errors if there are null values in <b>${availableFieldName}</b> from Data Extension <b>${availableFieldDataExtensionAlias}</b>.` :
              `You are mapping field <b>${availableFieldName}</b> with smaller sized target field <b>${targetDataExtensionFieldName}</b>. This may cause errors.`,
          options: {
            confirmButtonText: 'OK',
          },
        });
      }

      this.dropToTargetDataExtensionField(
        availableFieldFieldType,
        targetDataExtensionFieldCustomerKey,
        availableFieldName,
        availableFieldDataExtensionAlias,
        targetDataExtensionFieldName,
        availableFieldDataExtensionCustomerKey,
        availableFieldObjectID,
        targetDataExtensionFieldObjectID,
        true,
        availableFieldIsRequired,
        availableFieldMaxLength,
        globalCustomValueId,
      );
      // If deFieldType != text pop up error, don't execute function
    } else if (requiredNotMapped) {
      SwalUtil.fire({
        title: Constants.SWAL_TITLE__FIELD_NOT_MAPPED,
        message: Constants.HTML_ERROR__FIELD_REQUIRED,
        type: Constants.SWAL__TYPE__ERROR,
        options: {
          confirmButtonText: 'OK',
        },
      });
    } else {
      this.dropToTargetDataExtensionField(
        availableFieldFieldType,
        targetDataExtensionFieldCustomerKey,
        availableFieldName,
        availableFieldDataExtensionAlias,
        targetDataExtensionFieldName,
        availableFieldDataExtensionCustomerKey,
        availableFieldObjectID,
        targetDataExtensionFieldObjectID,
        true,
        availableFieldIsRequired,
        availableFieldMaxLength,
        globalCustomValueId,
      );
    }
  };

  /**
   * User drops an available field on a target data extension field
   * @param {object} availableFieldFieldType - Field type of available field
   * @param {object} targetDataExtensionFieldCustomerKey - Target DE customer key
   * @param {string} availableFieldName - Field name
   * @param {object} availableFieldDataExtensionAlias - Alias
   * @param {string} targetDataExtensionFieldName - Target DE field name
   * @param {string} availableFieldDataExtensionCustomerKey - Field DE customer key
   * @param {string} availableFieldObjectID - Field object id
   * @param {string} targetDataExtensionFieldObjectID - Target DE field object id
   * @param {Boolean} doSetState - True/false
   * @param {boolean} availableFieldIsRequired - True if available field is required
   * @param {number} availableFieldMaxLength - The max available length
   * @param {string} globalCustomValueId - Id of shared custom value, if used
   * @returns {void}
   */
  dropToTargetDataExtensionField = (
    availableFieldFieldType,
    targetDataExtensionFieldCustomerKey,
    availableFieldName,
    availableFieldDataExtensionAlias,
    targetDataExtensionFieldName,
    availableFieldDataExtensionCustomerKey,
    availableFieldObjectID,
    targetDataExtensionFieldObjectID,
    doSetState,
    availableFieldIsRequired,
    availableFieldMaxLength,
    globalCustomValueId,
  ) => {
    let { matchedFields } = this.state;
    const {
      customValues,
      selectedDataExtensions,
      tabName,
      targetDataExtensionFields,
      globalCustomValues,
    } = this.state;
    const { unionSelections, handleSetAppState } = this.props;

    let selectionState;

    // Get DataExtension
    const selectedDataExtensionsFilter = selectedDataExtensions.filter(
      // We cannot use objectID from DataExtension as it cannot be retrieved while querying fields
      de => availableFieldDataExtensionCustomerKey &&
        de.CustomerKey?.toString() === availableFieldDataExtensionCustomerKey?.toString(),
    );

    // Get dataExtension
    if (selectedDataExtensionsFilter.length > 0 || (customValues || globalCustomValues.length)) {
      if (selectedDataExtensionsFilter && selectedDataExtensionsFilter.length > 0) {
        const dataExtension = selectedDataExtensionsFilter[0];

        if (!availableFieldDataExtensionAlias) {
          // eslint-disable-next-line no-param-reassign
          availableFieldDataExtensionAlias = dataExtension.deAlias.toString();
        }
      } else {
        // Find the custom value
        const valueToMatch = customValues.find(cV => cV.name === availableFieldName);
        // Find the target Field
        const targetDataExtensionField = targetDataExtensionFields.find(
          field => field.ObjectID === targetDataExtensionFieldObjectID,
        );

        if (valueToMatch &&
          valueToMatch.valueType === Constants.CUSTOM_VALUES__FIELD_TYPE__DYNAMIC_VALUE &&
          valueToMatch.defaultValue.defaultValue === null && targetDataExtensionField &&
          targetDataExtensionField.IsRequired
        ) {
          return SwalUtil.fire({
            message: `You are mapping nullable field ${availableFieldName} into required field ${targetDataExtensionFieldName}. This may cause errors if there are null values in custom value <b>${availableFieldName}</b>`,
            type: Constants.SWAL__TYPE__ERROR,
          });
        }
      }
      /**
       * Put fields in array that match based on name
       * FieldPlaceTracker keeps track of the fields that are already mapped on the screen
       */
      const fieldPlaceTracker = [];

      matchedFields.forEach((field) => {
        if (field.targetDataExtensionFieldObjectID === targetDataExtensionFieldObjectID) {
          fieldPlaceTracker.push(targetDataExtensionFieldName);
        }
      });

      // If field is not yet in array
      if (!fieldPlaceTracker.includes(targetDataExtensionFieldName)) {
        // Add new matchedField to matchedFields
        let newMatchedField = {};
        // Field is decimal and has MaxLength and Scale properties

        if (typeof availableFieldMaxLength === 'object' && availableFieldMaxLength !== null) {
          newMatchedField = {
            ...newMatchedField,
            ...availableFieldMaxLength,
          };
        } else {
          newMatchedField = { availableFieldMaxLength };
        }

        // for custom values availableFieldObjectID is null, so give it a unique ID
        if (availableFieldObjectID === 'null' || availableFieldObjectID === null) {
          const customValueID = Util.uuid();
          // eslint-disable-next-line no-param-reassign

          availableFieldObjectID = `${customValueID}-customValues`;
        }

        let availableFieldDataExtensionObjectId = null;

        if (selectedDataExtensionsFilter && selectedDataExtensionsFilter.length > 0) {
          const dataExtension = selectedDataExtensionsFilter[0];

          availableFieldDataExtensionObjectId = dataExtension.ObjectID.toString();
        }

        // create new matched field object and add to the matched fields array
        newMatchedField = {
          ...newMatchedField,
          availableFieldName,
          availableFieldObjectID,
          availableFieldDataExtensionCustomerKey,
          availableFieldDataExtensionObjectId,
          availableFieldDataExtensionAlias,
          targetDataExtensionFieldObjectID,
          targetDataExtensionFieldName,
          availableFieldIsRequired,
          globalCustomValueId,
        };

        matchedFields = [...matchedFields, newMatchedField];
      }

      if (unionSelections && unionSelections.length > 0) {
        /**
         * When the appendDataExtension feature is enabled
         * We re storing the selections` state in the unionSelections
         * And current tab`s matchedFields state is updated
         */
        selectionState = unionSelections.find(selection => selection.tabName === tabName);
        selectionState.matchedFields = matchedFields;
        // Set the current tab`s state as well
        this.setState({
          matchedFields,
        });
        /**
         * Since App is the highest Order Component, when something is changed Selection.js gets those
         * And assigns as state so they can be used through app
         */
        handleSetAppState({ unionSelections });
      } else {
        // AppendDe is disabled then assign component`s state
        this.setState({
          matchedFields,
        });
      }
      if (!doSetState) {
        return matchedFields;
      }
    } else {
      SwalUtil.fire({
        // eslint-disable-next-line max-len
        message: `No matching data extension for availableFieldDataExtensionCustomerKey ${availableFieldDataExtensionCustomerKey}.`,
        type: Constants.SWAL__TYPE__ERROR,
      });
    }

    return null;
  };

  /**
   * Function for adding option in dropdown
   * @param {object[]} newDeFields - fields in target definition
   * @returns {void}
   */
  addSubscriberOption = (newDeFields) => {
    const {
      subscriberFieldNames,
    } = this.state;
    /**
     * Go through fields and check if field type is text then in dropdown add option
     * Subscriber Key if isn't already there...Same for the number and email
     */

    newDeFields.forEach((field) => {
      if ((field.FieldType === Constants.FILTERLINE__FIELDTYPE__TEXT ||
        field.FieldType === Constants.FILTERLINE__FIELDTYPE__EMAILADDRESS) &&
        !subscriberFieldNames.includes(Constants.SUBSCRIBER_FIELD__SUBSCRIBER_KEY)) {
        subscriberFieldNames.push(Constants.SUBSCRIBER_FIELD__SUBSCRIBER_KEY);
      }
      if (field.FieldType === Constants.FILTERLINE__FIELDTYPE__NUMBER &&
        !subscriberFieldNames.includes(Constants.SUBSCRIBER_FIELD__SUBSCRIBER_ID)) {
        subscriberFieldNames.push(Constants.SUBSCRIBER_FIELD__SUBSCRIBER_ID);
      }
    });
    this.handleSetSelectionState({ subscriberFieldNames });
  };

  /**
   * Function for changing the sendable and subscriber field names
   * @param {object|string} field - The field as object or string
   * @returns {void}
   */
  handleChangeSendableAndSubscriberField = (field) => {
    if (!field || field === 'none') {
      this.handleSetSelectionState(prevState => ({
        newTargetDataExtension: {
          ...prevState.newTargetDataExtension,
          relationship: {
            ...prevState.newTargetDataExtension.relationship,
            sendableDataExtensionField: { name: '', type: '' },
            sendableSubscriberField: { name: '' },
          },
        },
      }));

      return;
    }

    // If the typeof field is string, parse it
    if (typeof field === 'string') {
      // eslint-disable-next-line no-param-reassign
      field = JSON.parse(field);
    }

    let subscriberFieldName = '';
    const name = field ? field.Name.toString() : '';
    const fieldType = field ? field.FieldType : null;

    if (fieldType === Constants.FILTERLINE__FIELDTYPE__TEXT ||
      fieldType === Constants.FILTERLINE__FIELDTYPE__EMAILADDRESS) {
      subscriberFieldName = Constants.SUBSCRIBER_FIELD__SUBSCRIBER_KEY;
    } else if (fieldType === Constants.FILTERLINE__FIELDTYPE__NUMBER) {
      subscriberFieldName = Constants.SUBSCRIBER_FIELD__SUBSCRIBER_ID;
    }

    this.handleSetSelectionState(prevState => ({
      newTargetDataExtension: {
        ...prevState.newTargetDataExtension,
        relationship: {
          ...prevState.newTargetDataExtension.relationship,
          sendableDataExtensionField: {
            ...prevState.newTargetDataExtension.relationship.sendableDataExtensionField,
            name,
            type: fieldType,
          },
          sendableSubscriberField: {
            ...prevState.newTargetDataExtension.relationship.sendableSubscriberField,
            name: subscriberFieldName,
          },
        },
      },
    }));
  };

  /**
   * Check if the provided field matches with the default Send Relationship settings
   * @param {*} field - Field to check
   * @param {*} defaultSettings - Default Send relationship field settings
   * @returns {Boolean} - returns true if there is a match and false otherwise
   */
  checkDefaultSendRelationshipFieldMatch = (field, defaultSettings) => {
    if (defaultSettings?.isEnabled &&
      field?.fieldName === defaultSettings?.fieldName) {
      return true;
    }

    return false;
  };

  /**
   * show swal warning with given title and message
   * @param {object} data - data containing fields to show in warning
   * @returns {void}
   */
  showFieldTypesDontMatchWarning = (data) => {
    // get required fields from object
    const {
      availableFieldName, availableFieldFieldType, targetDataExtensionFieldName,
      targetDataExtensionFieldFieldType, targetDataExtensionFieldObjectID,
    } = data;

    const { matchedFields } = this.state;

    const matchedFieldFiltered = matchedFields.filter(
      field => field.targetDataExtensionFieldObjectID === targetDataExtensionFieldObjectID,
    );

    if (!matchedFieldFiltered.length) {
      // show swal warning
      SwalUtil.fire({
        title: "Field Types Don't Match",
        // eslint-disable-next-line max-len
        message: `${availableFieldName} is a ${availableFieldFieldType}, ${targetDataExtensionFieldName} is a ${targetDataExtensionFieldFieldType}. Since a ${availableFieldFieldType} can be converted into ${targetDataExtensionFieldFieldType} this should be OK.`,
        options: {
          confirmButtonText: 'OK',
        },
      });
    }
  };

  /**
   * Checks whether any Target Data Extension fields were deleted. If so tries to replace them. At any case makes sure
   *  to update Deduplication and Sort&Limit as well to avoid errors.
   * @param {object} selection - The current selection.
   * @param {object} targetDataExtension - The Target Data Extension.
   * @returns {void}
   */
  validateTargetDataExtension = (selection, targetDataExtension) => {
    // Get ObjectIDs of all Target Data Extension fields used either in Deduplication, or Sort&Limit.
    const {
      deduplicationFieldObjectID,
      criteriaFieldObjectID,
      mode,
    } = selection.prioDeduplication;
    const sortLimitFieldObjectID = selection?.sortLimit?.orderBy?.fieldObjectID;

    // Create an array with all Target Data Extension's Object IDs.
    const targetDataExtensionIDs = targetDataExtension.fields.map(field => field.ObjectID);

    // Initialize a map to store replaced fields, in case of any.
    const replacedFields = new Map();

    // Loop through all mapped fields to check if any of them were replaced.
    if (selection?.unionSelections?.length) {
      selection.unionSelections.forEach(unionSelection => unionSelection.fields.forEach((field) => {
        // Check if ObjectID used in mapping exists in Target Data Extension.
        if (!targetDataExtensionIDs.includes(field.targetDataExtensionFieldObjectID)) {
          // In case field was not found based on Object ID, search by name.
          const newField = targetDataExtension.fields.find(tf => field.field === tf.Name);

          // If field was found based on name, add it to the map.
          if (newField) {
            replacedFields.set(field.targetDataExtensionFieldObjectID, newField.ObjectID);

            // Replace previous ObjectID with new one inside the selection.
            /* eslint-disable no-param-reassign */
            field.targetDataExtensionFieldObjectID = newField.ObjectID;
          } else field.targetDataExtensionFieldObjectID = null;
        }
      }));
    }

    // Check if Deduplication is used.
    if (selection?.usePrioDeduplication) {
      // Check if deduplication field exists in Target Data Extension.
      if (!targetDataExtensionIDs.includes(deduplicationFieldObjectID)) {
        /**
         * If deduplication field does not exist in Target, check if there is a replacement for it. If not, disable
         * deduplication.
         */
        if (replacedFields.get(deduplicationFieldObjectID)) {
          selection.prioDeduplication.deduplicationFieldObjectID = replacedFields.get(deduplicationFieldObjectID);
        } else selection.usePrioDeduplication = false;
      }

      // Check if criteria field exists in Target Data Extension.
      if (!targetDataExtensionIDs.includes(criteriaFieldObjectID)) {
        /**
         * If criteria field does not exist in Target, check if there is a replacement for it. If not, disable
         * deduplication.
         */
        if (replacedFields.get(criteriaFieldObjectID)) {
          selection.prioDeduplication.criteriaFieldObjectID = replacedFields.get(criteriaFieldObjectID);
        } else if (mode !== Constants.PRIO_DEDUP__MODE__ADVANCED__VALUE) {
          // for advanced prioDeduplication the criteriaFieldObjectID is equal to null, so don't disable it
          selection.usePrioDeduplication = false;
        }
      }
    }

    // Check if Sort&Limit is used with order by field.
    if (selection?.sortLimit?.enabled && sortLimitFieldObjectID) {
      // Check if order by field exists in Target Data Extension.
      if (!targetDataExtensionIDs.includes(sortLimitFieldObjectID)) {
        /**
         * Check if there is a replacement for it. If not, disable Sort&Limit. Make sure to reassign the fieldObjectID
         * value, otherwise when the user enables it again he can still save it with no value.
         */
        if (!replacedFields.get(sortLimitFieldObjectID)) selection.sortLimit.enabled = false;
        selection.sortLimit.orderBy.fieldObjectID = replacedFields.get(sortLimitFieldObjectID);
        /* eslint-enable no-param-reassign */
      }
    }
  };

  /**
   * copy the initial state after componentDidMount
   * to capture changes when clicking cancel
   * @returns {void}
   */
  cloneSelectionState = () => {
    setTimeout(() => {
      const { selectionUpdates } = this.state;
      // clone selection state and set state to true

      this.copiedSelectionState = JSON.parse(JSON.stringify(this.state));

      // show update selection modal if there are any changes
      if (selectionUpdates?.length) {
        this.setState({
          showUpdateSelectionModal: true,
        });
      }
    }, 500);
  };

  /**
   * sets selection name in state
   * @param {object} e - JS Event
   * @returns {void}
   */
  handleSetSelectionName = (e) => {
    this.setState({ selectionName: e.target.value });
  };

  /**
   * sets selection template in state
   * @param {object} value - Value returned by the dropdown
   * @returns {void}
   */
  handleSetSelectionTemplate = (value) => {
    this.setState({ selectionTemplate: value });
  };

  /**
   * Sets timezone to all date fields in the selection
   * @param {boolean} convertTimezone - Indicates whether convertTimezone is enabled
   * @param {string} convertToTimezone - Timezone to convert to
   * @param {string} convertFromTimezone - Timezone to convert from
   * @returns {void}
   */
  handleSetTimezoneToAllDateFields = (convertTimezone, convertToTimezone, convertFromTimezone) => {
    const { unionSelections } = this.props;

    if (unionSelections?.length) {
      unionSelections.forEach((unionSelection) => {
        const { selectedFilters, customValues, advancedDeduplicationRules = [] } = unionSelection;
        const settings = {
          convertToTimezone, convertTimezone, convertFromTimezone,
        };

        this.setState({ timezoneSettingsForAllDateFields: settings });

        // Main filters
        filtersUtil.updateTimezoneSettingsForDateFilter(selectedFilters, settings);

        // Custom values
        if (customValues?.length) {
          // Get aggregation custom values
          const aggregationCVs = customValues.filter(
            cv => cv.valueType === Constants.CUSTOM_VALUES__FIELD_TYPE__AGGREGATION_VALUE,
          );

          // Get dynamic custom values
          const dynamicCVs = customValues.filter(
            cv => cv.valueType === Constants.CUSTOM_VALUES__FIELD_TYPE__DYNAMIC_VALUE,
          );

          // Update settings in aggregation custom values
          if (aggregationCVs.length) {
            aggregationCVs.forEach((aggregationCV) => {
              const { filters: aggregationFilters } = aggregationCV.aggregationValue;

              aggregationFilters?.forEach((filter) => {
                filtersUtil.updateTimezoneSettingsForDateFilter(filter, settings);
              });
            });
          }

          // Update settings on dynamic CVs
          if (dynamicCVs.length) {
            dynamicCVs.forEach((dynamicCV) => {
              dynamicCV.criteria.forEach((criterion) => {
                const { filters } = criterion.when;

                // If filters exist
                filters.forEach((filter) => {
                  filtersUtil.updateTimezoneSettingsForDateFilter(filter, settings);
                });
              });
            });
          }
        }

        // Prior deduplication
        advancedDeduplicationRules.forEach((rule) => {
          filtersUtil.updateTimezoneSettingsForDateFilter(rule, settings);
        });
      });
    }
  };

  /**
   * Toggles the selection's mode
   * @param {string} selectionMode - new mode of the selection
   * @returns {void}
   */
  toggleMode = (selectionMode) => {
    const {
      selectionMode: currentSelectionMode, selectedDataSet, selectedDataExtensions, relations,
      selectedDEsTree,
    } = this.state;

    const selectedDataSetCopy = JSON.parse(JSON.stringify(selectedDataSet));
    const selectedDataExtensionsCopy = JSON.parse(JSON.stringify(selectedDataExtensions));

    if (selectionMode === currentSelectionMode) return;

    if (selectionMode === Constants.SELECTION_MODE__ADVANCED) {
      this.handleSetSelectionState({
        selectionMode,
        selectedDataExtensions: selectedDataSetCopy?.selectedDataExtensions || selectedDataExtensionsCopy,
        relations: selectedDataSetCopy?.relations || relations,
        modalDataExtensions: {},
        switchFromAdvancedToBasic: false,
      });
    } else {
      // Check if selectedDEs have been changed
      const hasSelectedDataExtensionsBeenChanged =
        !_.isEqual(selectedDataSetCopy?.selectedDataExtensions, selectedDataExtensionsCopy);

      if (hasSelectedDataExtensionsBeenChanged) {
        this.handleSetSelectionState({
          selectionMode,
          switchFromAdvancedToBasic: true,
          ...selectedDataExtensions.length && {
            selectedDataSet: {
              isCustomDataSet: true,
              name: 'Custom Data Set',
              selectedDataExtensions: selectedDataExtensionsCopy,
              relations,
              selectedDEsTree,
              id: `custom-id-${new Date().getTime()}`,
            },
          },
        });
      } else {
        this.handleSetSelectionState({
          selectionMode,
          switchFromAdvancedToBasic: true,
        });
      }
    }
  };

  /**
   * onChange event handler for data extensions dropdown
   * @param {object} e - event. Use e.value to get the value
   * @returns {void}
   */
  handleSetSelectedDE = async (e) => {
    const {
      dataExtensions, customValues, matchedFields, selectedFilters,
      targetCollectionObjectID,
    } = this.state;

    const selectedDE = dataExtensions.find(ds => ds.ObjectID === e.value);

    if (selectedDE) {
      const { CustomerKey, Name } = selectedDE;

      const areDataSetDEsUsedInCustomValues = customValues.length && !customValues.some(
        cV => cV.valueType === Constants.CUSTOM_VALUES__FIELD_TYPE__FIXED_VALUE ||
          (cV.valueType === Constants.CUSTOM_VALUES__FIELD_TYPE__FORMULA &&
            cV.formula === Constants.CUSTOM_VALUES__FORMULA_TYPE__FREE_FORMULA),
      );

      // check if there any matched fields from source DE
      const isMatchedFieldsFromSDE = matchedFields.some(x => x.availableFieldDataExtensionCustomerKey && x.availableFieldDataExtensionCustomerKey !== 'null');

      if (selectedFilters.filters?.length || areDataSetDEsUsedInCustomValues ||
        (targetCollectionObjectID && matchedFields.length && isMatchedFieldsFromSDE)
      ) {
        const swalMessage = 'Data Extension cannot be changed as fields of this data Extension are used as ' +
          `${selectedFilters.filters?.length ?
            'filters.' :
            'Selected Fields in the Target Data Extension.'} Please remove these fields to continue.`;

        return SwalUtil.fire({
          title: 'Change Data Extension',
          message: swalMessage,
          options: {
            confirmButtonText: 'OK',
            allowOutsideClick: false,
          },
        });
      }

      // this line calls the handleDropToSelectedDE function from SelectedExtensions.js
      await this.selectedDERef.current(CustomerKey, Name);

      const {
        selectedDataSet,
        selectedDataExtensions,
      } = this.state;

      const updatedSelectedDataSet = {
        ...selectedDataSet,
        selectedDataExtensions,
        relations: [],
        selectedDEsTree: {},
      };

      this.setState({
        selectedDataSet: updatedSelectedDataSet,
        relations: [],
        selectedDEsTree: {},
      });
    }
  };

  /**
   * Handle clear basic mode source
   * @param {sting} basicModeSourceType - basic mode source type (Data Extension or Data Set)
   * @returns {void}
   */
  handleClearBasicModeSource = async (basicModeSourceType) => {
    const {
      selectedFilters,
      matchedFields,
      customValues,
      targetCollectionObjectID,
    } = this.state;
    const {
      unionSelections,
      unionSelectionsIndex,
      handleSetAppState,
    } = this.props;

    const areDataSetDEsUsedInCustomValues = customValues.length && !customValues.some(
      cV => cV.valueType === Constants.CUSTOM_VALUES__FIELD_TYPE__FIXED_VALUE ||
        (cV.valueType === Constants.CUSTOM_VALUES__FIELD_TYPE__FORMULA &&
          cV.formula === Constants.CUSTOM_VALUES__FORMULA_TYPE__FREE_FORMULA),
    );

    // check if there any matched fields from source DE
    const isMatchedFieldsFromSDE = matchedFields.some(x => x.availableFieldDataExtensionCustomerKey && x.availableFieldDataExtensionCustomerKey !== 'null');

    if (selectedFilters.filters?.length || areDataSetDEsUsedInCustomValues ||
      (targetCollectionObjectID && matchedFields.length && isMatchedFieldsFromSDE)
    ) {
      const swalMessage = `${basicModeSourceType} cannot be removed as fields of this ${basicModeSourceType} are used as ` +
        `${selectedFilters.filters?.length ?
          'filters.' :
          'Selected Fields in the Target Data Extension.'} Please remove these fields to continue.`;

      return SwalUtil.fire({
        title: `Remove ${basicModeSourceType}`,
        message: swalMessage,
        options: {
          confirmButtonText: 'OK',
          allowOutsideClick: false,
        },
      });
    }

    const res = await SwalUtil.fire({
      title: `Remove ${basicModeSourceType}`,
      message: `Are you sure you want to remove this ${basicModeSourceType}?`,
      options: {
        confirmButtonText: 'OK',
        allowOutsideClick: false,
      },
    });

    if (!res.value) return;

    const unionSelection = unionSelections[unionSelectionsIndex];

    unionSelection.selectedDataSet = null;
    unionSelection.selectedDataExtensions = [];
    unionSelection.relations = [];

    const updatedSelectedDataSet = {
      selectedDataSet: null,
      selectedDataExtensions: [],
      relations: [],
      selectedDEsTree: {},
    };

    this.setState({
      selectedDataExtensions: [],
      selectedDataSet: updatedSelectedDataSet,
      relations: [],
      selectedDEsTree: {},
    });
    handleSetAppState({ unionSelections });
  };

  /**
   * onChange event handler for dataSets dropdown
   * @param {object} e - event. Use e.value to get the value
   * @returns {void}
   */
  handleSetSelectedDataSet = async (e) => {
    const {
      dataSets,
      selectedDataSet: currentSelectedDataSet,
      selectedFilters,
      matchedFields,
      customValues,
      targetCollectionObjectID,
    } = this.state;

    const {
      unionSelections,
      unionSelectionsIndex,
      handleSetAppState,
    } = this.props;

    const areDataSetDEsUsedInCustomValues = customValues.length && !customValues.some(
      cV => cV.valueType === Constants.CUSTOM_VALUES__FIELD_TYPE__FIXED_VALUE ||
        (cV.valueType === Constants.CUSTOM_VALUES__FIELD_TYPE__FORMULA &&
          cV.formula === Constants.CUSTOM_VALUES__FORMULA_TYPE__FREE_FORMULA),
    );

    // check if there any matched fields from source DE
    const isMatchedFieldsFromSDE = matchedFields.some(x => x.availableFieldDataExtensionCustomerKey && x.availableFieldDataExtensionCustomerKey !== 'null');

    if (selectedFilters.filters?.length || areDataSetDEsUsedInCustomValues ||
      (targetCollectionObjectID && matchedFields.length && isMatchedFieldsFromSDE)
    ) {
      const swalMessage = 'Data Set cannot be changed as fields of this data set are used as ' +
        `${selectedFilters.filters?.length ?
          'filters.' :
          'Selected Fields in the Target Data Extension.'} Please remove these fields to continue.`;

      return SwalUtil.fire({
        title: 'Change Data Set',
        message: swalMessage,
        options: {
          confirmButtonText: 'OK',
          allowOutsideClick: false,
        },
      });
    }

    if (currentSelectedDataSet?.isCustomDataSet) {
      const res = await SwalUtil.fire({
        title: 'Change Data Set',
        message: Constants.CUSTOM_DATA_SET_CHANGE__WARNING_TEXT,
        options: {
          confirmButtonText: 'Confirm',
          showCancelButton: true,
          allowOutsideClick: false,
        },
      });

      if (!res.value) return;
    }

    const selectedDataSet = dataSets.find(ds => ds.id === e.value);

    const unionSelection = unionSelections[unionSelectionsIndex];

    unionSelection.selectedDataSet = selectedDataSet;
    unionSelection.selectedDataExtensions = selectedDataSet?.selectedDataExtensions;
    unionSelection.relations = selectedDataSet?.relations;

    this.setState({
      selectedDataSet,
      selectedDataExtensions: selectedDataSet?.selectedDataExtensions,
      relations: selectedDataSet?.relations,
    }, async () => {
      const dataExtensions = selectedDataSet?.selectedDataExtensions;

      // Get picklists for each data extensions
      const picklistPromises = dataExtensions
        .map(dataExtension => this.handlePickListOptions(dataExtension.fields, true, dataExtension));

      // Get picklist values and then update the state
      Promise.allSettled(picklistPromises).then(() => {
        handleSetAppState({ unionSelections });
      });
    });
  };

  /**
   * Function that handles showing of feature advert modal with specific feature
   * @param {String} feature - feature to show
   * @returns {void}
   */
  showEssentialsUpgradeModal = (feature) => {
    this.setState(prevState => ({
      featureAdvertModal: {
        ...prevState.featureAdvertModal,
        show: true,
        feature,
      },
    }));
  };

  /**
   * Function that handles hiding of banner model
   * @returns {void}
   */
  hideBannerModal = () => {
    this.setState(() => ({
      showBanner: false,
    }));
  };

  /**
   * Function that handles hiding of feature advert modal
   * @returns {void}
   */
  cancelEssentialsUpgradeModal = () => {
    this.setState(prevState => ({
      featureAdvertModal: {
        ...prevState.featureAdvertModal,
        show: false,
        feature: '',
      },
    }));
  };

  /**
   * Get navigation bar options
   * @returns {void}
   */
  getNavBarOptions = () => {
    const { userInfo, orgInfo } = this.props;
    const engageStatus = userInfo?.engageInstallationStatus?.organisationExists;
    const searchStatus = userInfo?.searchInstallationStatus?.organisationExists;
    const stackNumber = orgInfo?.marketingCloudStackNumber;

    let search = Constants.DESELECT_SEARCH_STAGING_APP_NAME;

    let engage = Constants.DESELECT_ENGAGE_STAGING_APP_NAME;

    if (process.env.REACT_APP_ENVIRONMENT === 'production') {
      search = Constants.DESELECT_SEARCH_PRODUCTION_APP_NAME;
      engage = Constants.DESELECT_ENGAGE_PRODUCTION_APP_NAME;
    }

    if (process.env.REACT_APP_ENVIRONMENT === 'release') {
      search = Constants.DESELECT_SEARCH_RELEASE_APP_NAME;
      engage = Constants.DESELECT_ENGAGE_RELEASE_APP_NAME;
    }

    let engageLink = Constants.DESELECT_ENGAGE_APP_EXCHANGE_URL;

    let searchLink = Constants.DESELECT_SEARCH_APP_EXCHANGE_URL;

    if (engageStatus) {
      engageLink = `https://mc.${stackNumber}.exacttarget.com/cloud/#app/${engage}`;
    }

    if (searchStatus) {
      // eslint-disable-next-line max-len
      searchLink = `https://mc.${stackNumber}.exacttarget.com/cloud/#app/${search}`;
    }

    const options = [
      {
        id: 'Segment',
        name: 'Segment',
        url: '',
      },
      {
        id: 'Engage',
        name: 'Engage',
        url: engageLink,
      },
      {
        id: 'Search',
        name: 'Search',
        url: searchLink,
      },
    ];

    return options;
  };

  /**
   *
   * @param {Array} unionSelections - array of union selections
   * @returns {Array} - array of source limit data extracted from union selections
   */
  extractSourceLimitData(unionSelections) {
    return unionSelections?.map((selection) => {
      if (selection.sourceLimit) {
        const { active, value, type } = selection?.sourceLimit || {};

        return { active, value, type };
      }

      return null;
    });
  }

  render() {
    const {
      selectionNavigator,
      dataExtensions,
      allAvailableDataExtensions,
      selectedDataExtensions,
      selectedFilters,
      showRelationalModal,
      modalDataExtensions,
      movingDE,
      prevSelectedDEs,
      prevRelations,
      selectedDEsTree,
      fromFieldMissing,
      toFieldMissing,
      selectionName,
      targetDataExtensionFields,
      matchedFields,
      targetDataExtensionCustomerKey,
      fieldsMetaData,
      previewData,
      numberOfResults,
      previewDedupNumberOfRecords,
      previewStatus,
      previewError,
      previewCountTaskStatus,
      lastRanDate,
      previewQueryActivityId,
      previewDataExtensionObjectID,
      currentSelectionId,
      error,
      unionType,
      selectionType,
      relations,
      newTargetDataExtension,
      editNewAutoTargetDE,
      createNewAutoTargetDE,
      newTargetDataExtensionFields,
      subscriberFieldNames,
      prevMatchedFields,
      targetDataExtensions,
      editTargetDataExtension,
      prevTargetDEFields,
      showSaveToast,
      dataAction,
      showDataActionModal,
      showAddValuesModal,
      showSourceLimitingModal,
      customValues,
      DEBorderMouseOver,
      filterBorderMouseOver,
      dynamicCustomValuesFilters,
      editCustomValueIndex,
      switchToDedup,
      prioDeduplication,
      openSettings,
      usePrioDeduplication,
      loaderSelectedDE,
      disablePreviewBTN,
      advancedDeduplicationRules,
      previousAdvancedDeduplicationRules,
      advancedDedupFilterSaveIndex,
      pickLists,
      deletedMappedFieldsFromSFMC,
      loadingSubQueryFields,
      sortLimit,
      showSortLimitModal,
      runPreviewQueryActivityStarted,
      previewDataRetrieved,
      loadingBarIcon,
      previewTaskCompleted,
      switchedToOtherTab,
      previewDeduplicationTaskStatus,
      showScheduleSelectionModal,
      showTemplateSettingsModal,
      scheduledRun,
      isTemplate,
      selectionDescription,
      copiedFromTemplate,
      templateDescription,
      templateInstructions,
      selectionCreator,
      showAggregationModal,
      aggregationFilters,
      loadingForDataExtensions,
      loadingAllAvailableDataExtensions,
      loadingForTargetDataExtensions,
      predefinedRelations,
      predefinedRelationsMap,
      subQueryDataExtensions,
      previewTaskQuery,
      runStatus,
      captureSelectionChange,
      selectionUpdates,
      showUpdateSelectionModal,
      checkMissingFieldsInRelationsDone,
      autoFixingSelection,
      availableDEsFolderId,
      editedTargetDE,
      targetDataExtension,
      targetCollectionObjectID,
      availableDEsFolders,
      foldersSettings,
      applyTimezoneSettingsToAllDateFields,
      timezoneSettingsForAllDateFields,
      targetDEsFolderId,
      targetDEsFolders,
      clickedSave,
      isSelectionRequestDone,
      scheduleDisabled,
      dataSets,
      filterSets,
      parentDEOfDataSet,
      isArchived,
      selectionMode,
      basicModeSelectionSourceType,
      selectionTemplate,
      selectionsSchedules,
      switchFromAdvancedToBasic,
      globalCustomValues,
      timer,
      scheduledWaterfallSelections,
      featureAdvertModal,
      defaultSendRelationshipField,
      sourceLimitingEnabled,
      previewDeduplicationDEObjectID,
      showBanner,
      dataExtensionSearchField,
      basicModeStatus,
      isSelectedDEChanged,
      isSelectedFieldsTriggeredCustomValue,
      isSelectedFieldsCustomValuesModalSaved,
      isCustomValueModalTriggeredInEditMode,
      isFilterBoxExpanded,
      isFilterBoxExpandedForFirstTime,
      showFiltersImmediately,
    } = this.state;

    const {
      handleNavigator,
      unionSelections,
      unionSelectionsIndex,
      handleSetAppState,
      handleAddElementToAppArrayState,
      folders,
      folderId,
      currentSelectionName,
      backToWaterFall,
      userInfo,
      orgInfo,
      featuresInfo,
      expandedRightBar,
    } = this.props;

    // Navbar DataEx and Filters are red ones.
    if (error) {
      if (String(error?.response?.data?.actualError).includes(Constants.ERROR_SFMC_PERMISSION_FAILED)) {
        const { loadExistingSelection } = this.state;

        if (loadExistingSelection) {
          handleNavigator(Constants.NAVIGATION__OVERVIEW);
        }
      } else {
        throw error;
      }
    }

    const featureAppendDataExtensionsIsEnabled = Features.isFeatureEnabled(featuresInfo, Constants.FEATURE__APPEND_DATA_EXTENSIONS);
    const featureScheduleSelections = Features.isFeatureEnabled(featuresInfo, Constants.FEATURE__SCHEDULE_SELECTIONS);
    const featureSelectionTemplate = Features.isFeatureEnabled(featuresInfo, Constants.FEATURE__SELECTION_TEMPLATE);
    const featureDataSets = Features.isFeatureEnabled(featuresInfo, Constants.FEATURE__DATA_SETS);
    const featureFilterSets = Features.isFeatureEnabled(featuresInfo, Constants.FEATURE__FILTER_SETS);
    const featureBasicMode = Features.isFeatureEnabled(featuresInfo, Constants.SELECTION_MODE__BASIC);

    if (!currentSelectionId && !isSelectionRequestDone) {
      return <SelectionNameModal
        folders={folders}
        handleSetAppState={handleSetAppState}
        currentSelectionId={currentSelectionId}
        axiosCancelToken={this.axiosCancelToken}
        folderId={folderId}
        handleSetSelectionName={this.handleSetSelectionName}
        handleSetSelectionTemplate={this.handleSetSelectionTemplate}
        selectionName={selectionName}
        handleNavigator={handleNavigator}
        saveSelection={this.saveSelection}
        targetDataExtensions={targetDataExtensions}
        loadingForTargetDataExtensions={loadingForTargetDataExtensions}
        selectionTemplate={selectionTemplate}
        handleSetSelectionRequestDone={(id) => {
          this.handleSetSelectionState({ isSelectionRequestDone: true });
          handleNavigator(Constants.NAVIGATION__OVERVIEW);
          handleSetAppState({
            currentSelectionId: id,
            currentSelectionName: selectionName,
            navigator: Constants.NAVIGATION__SELECTION,
            globalNavigator: Constants.NAVIGATION__SELECTION,
            folderId,
            folders,
            foldersSettings,
          });
        }}
        cloneSelectionState={this.cloneSelectionState}
        backToWaterFall={backToWaterFall}
        featureAdvertModal={featureAdvertModal}
        showEssentialsUpgradeModal={this.showEssentialsUpgradeModal}
        cancelEssentialsUpgradeModal={this.cancelEssentialsUpgradeModal}
      />;
    }

    let elementToRender = null;

    if (selectionNavigator === Constants.NAVIGATION__SELECTION_CRITERIA &&
      selectionType === Constants.SELECTION__TYPE__UNION) {
      elementToRender = (
        <DataExtensionsMultipleTabsv2
          selectionNavigator={selectionNavigator}
          selectionNavigation={this.handleSelectionNavigator}
          sourceTabsHeight={this.sourceTabsHeight}
          selectedDataExtensions={selectedDataExtensions}
          setTabsScrollPositionOnClick={this.setTabsScrollPositionOnClick}
          tabsScrollPositionOnClick={this.tabsScrollPositionOnClick}
          previewQueryActivityId={previewQueryActivityId}
          numberOfResults={numberOfResults}
          previewStatus={previewStatus}
          editTargetDataExtension={editTargetDataExtension}
          unionSelections={unionSelections}
          updateDataExtensionsObject={this.updateDataExtensionsObject}
          onDataExtensionSearchFieldChange={this.handleDataExtensionSearchFieldChange}
          dataExtensionSearchField={dataExtensionSearchField}
          isArchived={isArchived}
          unionSelectionsIndex={unionSelectionsIndex}
          dataExtensions={loadingAllAvailableDataExtensions ? dataExtensions : allAvailableDataExtensions}
          availableDEs={dataExtensions}
          showRelationalModal={showRelationalModal}
          checkMissingFieldsInRelations={this.checkMissingFieldsInRelations}
          currentSelectionId={currentSelectionId}
          handleDeleteSelectedDE={this.handleDeleteSelectedDE}
          handleFiltersSave={this.handleFiltersSave}
          handleSetAppState={handleSetAppState}
          handleAddElementToAppArrayState={handleAddElementToAppArrayState}
          matchedFields={matchedFields}
          handleSetSelectionState={this.handleSetSelectionState}
          axiosCancelToken={this.axiosCancelToken.token}
          editNewAutoTargetDE={editNewAutoTargetDE}
          getDataExtensionOrDataViewFields={this.getDataExtensionOrDataViewFields}
          handleFeatureMissing={this.handleFeatureMissing}
          relations={relations}
          manageSubscriberRelationship={this.manageSubscriberRelationship}
          DEBorderMouseOver={DEBorderMouseOver}
          filterBorderMouseOver={filterBorderMouseOver}
          showDataMultipleTabs={this.showLoadingModal()}
          loaderSelectedDE={loaderSelectedDE}
          singleDEStyling={!featureAppendDataExtensionsIsEnabled}
          handlePickListOptions={this.handlePickListOptions}
          pickLists={pickLists}
          loadingSubQueryFields={loadingSubQueryFields}
          handleRemoveFilterLine={this.handleRemoveFilterLine}
          customValues={customValues}
          loadingForDataExtensions={loadingForDataExtensions}
          loadingAllAvailableDataExtensions={loadingAllAvailableDataExtensions}
          predefinedRelations={predefinedRelations}
          predefinedRelationsMap={predefinedRelationsMap}
          subQueryDataExtensions={subQueryDataExtensions}
          returnPredefinedRelationById={this.returnPredefinedRelationById}
          availableDEsFolderId={availableDEsFolderId}
          availableDEsFolders={availableDEsFolders}
          foldersSettings={foldersSettings}
          movingDE={movingDE}
          selectedDEsTree={selectedDEsTree}
          prevSelectedDEs={prevSelectedDEs}
          prevRelations={prevRelations}
          applyTimezoneSettingsToAllDateFields={applyTimezoneSettingsToAllDateFields}
          timezoneSettingsForAllDateFields={timezoneSettingsForAllDateFields}
          handleSetTimezoneToAllDateFields={this.handleSetTimezoneToAllDateFields}
          dataSets={dataSets}
          filterSets={filterSets}
          featureDataSets={featureDataSets}
          featureFilterSets={featureFilterSets}
          parentDEOfDataSet={parentDEOfDataSet}
          selectionMode={selectionMode}
          basicModeSelectionSourceType={basicModeSelectionSourceType}
          switchFromAdvancedToBasic={switchFromAdvancedToBasic}
          handleSetSelectedDataSet={this.handleSetSelectedDataSet}
          handleSetSelectedDE={this.handleSetSelectedDE}
          selectedDERef={this.selectedDERef}
          handleClearBasicModeSource={this.handleClearBasicModeSource}
          showEssentialsUpgradeModal={this.showEssentialsUpgradeModal}
          sourceLimitingEnabled={sourceLimitingEnabled}
          revertBehavioralFilterSet={this.revertBehavioralFilterSet}
          saveSelection={this.saveSelection}
          isSelectedDEChanged={isSelectedDEChanged}
          isFilterBoxExpanded={isFilterBoxExpanded}
          isFilterBoxExpandedForFirstTime={isFilterBoxExpandedForFirstTime}
          showFiltersImmediately={showFiltersImmediately}
        />
      );
    } else if (selectionNavigator === Constants.NAVIGATION__SELECTION_CRITERIA &&
      selectionType !== Constants.SELECTION__TYPE__UNION) {
      elementToRender = (
        <DataExtensions
          dataExtensions={loadingAllAvailableDataExtensions ? dataExtensions : allAvailableDataExtensions}
          availableDEs={dataExtensions}
          selectedDataExtensions={selectedDataExtensions}
          handleDeleteSelectedDE={this.handleDeleteSelectedDE}
          selectedFilters={selectedFilters}
          showRelationalModal={showRelationalModal}
          checkMissingFieldsInRelations={this.checkMissingFieldsInRelations}
          fromFieldMissing={fromFieldMissing}
          toFieldMissing={toFieldMissing}
          modalDataExtensions={modalDataExtensions}
          movingDE={movingDE}
          prevSelectedDEs={prevSelectedDEs}
          prevRelations={prevRelations}
          selectedDEsTree={selectedDEsTree}
          handleFiltersSave={this.handleFiltersSave}
          currentSelectionId={currentSelectionId}
          matchedFields={matchedFields}
          handleSetSelectionState={this.handleSetSelectionState}
          axiosCancelToken={this.axiosCancelToken.token}
          editNewAutoTargetDE={editNewAutoTargetDE}
          getDataExtensionOrDataViewFields={this.getDataExtensionOrDataViewFields}
          handleFeatureMissing={this.handleFeatureMissing}
          relations={relations}
          manageSubscriberRelationship={this.manageSubscriberRelationship}
          DEBorderMouseOver={DEBorderMouseOver}
          filterBorderMouseOver={filterBorderMouseOver}
          loaderSelectedDE={loaderSelectedDE}
          singleDEStyling={!featureAppendDataExtensionsIsEnabled}
          handlePickListOptions={this.handlePickListOptions}
          pickLists={pickLists}
          handleRemoveFilterLine={this.handleRemoveFilterLine}
          customValues={customValues}
          loadingForDataExtensions={loadingForDataExtensions}
          loadingAllAvailableDataExtensions={loadingAllAvailableDataExtensions}
          predefinedRelations={predefinedRelations}
          predefinedRelationsMap={predefinedRelationsMap}
          subQueryDataExtensions={subQueryDataExtensions}
          returnPredefinedRelationById={this.returnPredefinedRelationById}
          availableDEsFolderId={availableDEsFolderId}
          availableDEsFolders={availableDEsFolders}
          foldersSettings={foldersSettings}
          applyTimezoneSettingsToAllDateFields={applyTimezoneSettingsToAllDateFields}
          timezoneSettingsForAllDateFields={timezoneSettingsForAllDateFields}
          handleSetTimezoneToAllDateFields={this.handleSetTimezoneToAllDateFields}
          dataSets={dataSets}
          filterSets={filterSets}
          featureDataSets={featureDataSets}
          featureFilterSets={featureFilterSets}
          parentDEOfDataSet={parentDEOfDataSet}
          isArchived={isArchived}
          selectionMode={selectionMode}
          basicModeSelectionSourceType={basicModeSelectionSourceType}
          handleSetSelectedDataSet={this.handleSetSelectedDataSet}
          handleSetSelectedDE={this.handleSetSelectedDE}
          saveSelection={this.saveSelection}
          selectedDERef={this.selectedDERef}
          handleClearBasicModeSource={this.handleClearBasicModeSource}
          showEssentialsUpgradeModal={this.showEssentialsUpgradeModal}
          revertBehavioralFilterSet={this.revertBehavioralFilterSet}
          enabledScheduleSelection={scheduledRun.enabled}
          validateIfQueryCanBeRun={this.validateIfQueryCanBeRun}
          checkIncompleteFilter={this.checkIncompleteFilter}
          checkValidSchedule={this.checkValidSchedule}
          isTemplate={isTemplate}
          selectionCreator={selectionCreator}
          selectionState={this.state}
          clickedSave={clickedSave}
          unionSelections={unionSelections}
        />
      );
    } else if (selectionNavigator === Constants.NAVIGATION__TARGET_DEFINITION) {
      elementToRender = (
        <TargetDefinition
          isArchived={isArchived || false}
          previewStatus={previewStatus}
          numberOfResults={numberOfResults}
          previewQueryActivityId={previewQueryActivityId}
          setTabsScrollPositionOnClick={this.setTabsScrollPositionOnClick}
          tabsScrollPositionOnClick={this.tabsScrollPositionOnClick}
          sourceTabsHeight={this.sourceTabsHeight}
          selectionNavigator={selectionNavigator}
          selectionNavigation={this.handleSelectionNavigator}
          previousAdvancedDeduplicationRules={previousAdvancedDeduplicationRules}
          cloneAdvancedDedupRule={this.cloneAdvancedDedupRule}
          removeAdvancedDedupRule={this.removeAdvancedDedupRule}
          advancedDedupFilterSaveIndex={advancedDedupFilterSaveIndex}
          advancedDeduplicationRules={advancedDeduplicationRules}
          selectedDataExtensions={selectedDataExtensions}
          targetDataExtensions={targetDataExtensions}
          targetDataExtensionFields={targetDataExtensionFields}
          matchedFields={matchedFields}
          targetDataExtensionCustomerKey={targetDataExtensionCustomerKey}
          handleRefreshTargetDataExtensionList={this.handleRefreshTargetDataExtensionList}
          handleSetSelectionState={this.handleSetSelectionState}
          unionType={unionType}
          unionSelections={unionSelections}
          handleSetAppState={handleSetAppState}
          unionSelectionsIndex={unionSelectionsIndex}
          selectionType={selectionType}
          newTargetDataExtension={newTargetDataExtension}
          createNewAutoTargetDE={createNewAutoTargetDE}
          editNewAutoTargetDE={editNewAutoTargetDE}
          newTargetDataExtensionFields={newTargetDataExtensionFields}
          subscriberFieldNames={subscriberFieldNames}
          prevMatchedFields={prevMatchedFields}
          formatAvailableDEs={this.formatAvailableDEs}
          handleAddFieldToTargetDE={this.handleAddFieldToTargetDE}
          dropToTargetDataExtensionField={
            this.dropToTargetDataExtensionField
          }
          manageSubscriberRelationship={this.manageSubscriberRelationship}
          editTargetDataExtension={editTargetDataExtension}
          editedTargetDE={editedTargetDE}
          prevTargetDEFields={prevTargetDEFields}
          showSaveToast={showSaveToast}
          dataAction={dataAction}
          showDataActionModal={showDataActionModal}
          showAddValuesModal={showAddValuesModal}
          showSourceLimitingModal={showSourceLimitingModal}
          customValues={customValues}
          editCustomValueIndex={editCustomValueIndex}
          DEBorderMouseOver={DEBorderMouseOver}
          filterBorderMouseOver={filterBorderMouseOver}
          defaultSendRelationshipField={defaultSendRelationshipField}
          handleFiltersSave={this.handleFiltersSave}
          handleAdvancedDedupFiltersSave={this.handleAdvancedDedupFiltersSave}
          dynamicCustomValuesFilters={dynamicCustomValuesFilters}
          getDataExtensionOrDataViewFields={this.getDataExtensionOrDataViewFields}
          switchToDedup={switchToDedup}
          prioDeduplication={prioDeduplication}
          openSettings={openSettings}
          usePrioDeduplication={usePrioDeduplication}
          singleDEStyling={!featureAppendDataExtensionsIsEnabled}
          pickLists={pickLists}
          handlePickListOptions={this.handlePickListOptions}
          deletedMappedFieldsFromSFMC={deletedMappedFieldsFromSFMC}
          saveSelection={this.saveSelection}
          handleRemoveFilterLine={this.handleRemoveFilterLine}
          sortLimit={sortLimit}
          showSortLimitModal={showSortLimitModal}
          relations={relations}
          axiosCancelToken={this.axiosCancelToken.token}
          showAggregationModal={showAggregationModal}
          aggregationFilters={aggregationFilters}
          dataExtensions={loadingAllAvailableDataExtensions ? dataExtensions : allAvailableDataExtensions}
          loadingAllAvailableDataExtensions={loadingAllAvailableDataExtensions}
          loadingForTargetDataExtensions={loadingForTargetDataExtensions}
          predefinedRelations={predefinedRelations}
          targetCollectionObjectID={targetCollectionObjectID}
          applyTimezoneSettingsToAllDateFields={applyTimezoneSettingsToAllDateFields}
          timezoneSettingsForAllDateFields={timezoneSettingsForAllDateFields}
          handleSetTimezoneToAllDateFields={this.handleSetTimezoneToAllDateFields}
          targetDEsFolderId={targetDEsFolderId}
          targetDEsFolders={targetDEsFolders}
          targetDataExtension={targetDataExtension}
          addSubscriberOption={this.addSubscriberOption}
          foldersSettings={foldersSettings}
          selectionName={selectionName}
          globalCustomValues={globalCustomValues}
          showEssentialsUpgradeModal={this.showEssentialsUpgradeModal}
          sourceLimitingEnabled={sourceLimitingEnabled}
          enabledScheduleSelection={scheduledRun.enabled}
          validateIfQueryCanBeRun={this.validateIfQueryCanBeRun}
          checkMissingFieldsInRelations={this.checkMissingFieldsInRelations}
          checkIncompleteFilter={this.checkIncompleteFilter}
          checkValidSchedule={this.checkValidSchedule}
          isTemplate={isTemplate}
          selectionCreator={selectionCreator}
          selectionState={this.state}
          clickedSave={clickedSave}
          isSelectedFieldsTriggeredCustomValue={isSelectedFieldsTriggeredCustomValue}
          isSelectedFieldsCustomValuesModalSaved={isSelectedFieldsCustomValuesModalSaved}
          isCustomValueModalTriggeredInEditMode={isCustomValueModalTriggeredInEditMode}
          filterSets={filterSets}
        />
      );
    } else if (selectionNavigator === Constants.NAVIGATION__PREVIEW) {
      const { isArchived, previewTaskId } = this.state;

      const filterOutProperties = selection => Object.fromEntries(Object.entries(selection).filter(key => !Constants.STATE__NON_SELECTION_PROPERTIES.includes(key[0])));

      // remove non-effective properties from states
      const selectionStateWithoutSomeProperties = filterOutProperties(this.state);

      let copiedSelectionStateWithoutSomeProperties;

      // if copy of selection was created
      if (this.copiedSelectionState) {
        copiedSelectionStateWithoutSomeProperties = filterOutProperties(this.copiedSelectionState);
      }

      // Check if objects are identical
      const nothingHasChanged = JSON.stringify(selectionStateWithoutSomeProperties) === JSON.stringify(copiedSelectionStateWithoutSomeProperties);

      elementToRender = (
        <Preview
          isArchived={isArchived || false}
          numberOfResults={numberOfResults}
          previewDedupNumberOfRecords={previewDedupNumberOfRecords}
          fieldsMetaData={fieldsMetaData}
          previewData={previewData}
          previewStatus={previewStatus}
          previewError={previewError}
          runStatus={runStatus}
          previewCountTaskStatus={previewCountTaskStatus}
          lastRanDate={lastRanDate}
          handleSetSelectionState={this.handleSetSelectionState}
          saveSelection={this.saveSelection}
          validateIfQueryCanBeRun={this.validateIfQueryCanBeRun}
          checkMissingFieldsInRelations={this.checkMissingFieldsInRelations}
          checkValidSchedule={this.checkValidSchedule}
          checkIncompleteFilter={this.checkIncompleteFilter}
          selectionState={this.state}
          selectedDataExtensions={selectedDataExtensions}
          isTemplate={isTemplate}
          selectionNavigation={this.handleSelectionNavigator}
          currentSelectionId={currentSelectionId}
          axiosCancelToken={this.axiosCancelToken.token}
          dataAction={dataAction}
          disablePreviewBTN={disablePreviewBTN}
          usePrioDeduplication={usePrioDeduplication}
          sortLimit={sortLimit}
          runPreviewQueryActivityStarted={runPreviewQueryActivityStarted}
          previewDataRetrieved={previewDataRetrieved}
          loadingBarIcon={loadingBarIcon}
          enabledScheduleSelection={scheduledRun.enabled}
          loadPreviewTable={this.loadPreviewTable}
          previewTaskCompleted={previewTaskCompleted}
          switchedToOtherTab={switchedToOtherTab}
          previewDeduplicationTaskStatus={previewDeduplicationTaskStatus}
          previewQueryActivityId={previewQueryActivityId}
          previewDataExtensionObjectID={previewDataExtensionObjectID}
          previewDeduplicationDEObjectID={previewDeduplicationDEObjectID}
          previewTaskQuery={previewTaskQuery}
          selectionCreator={selectionCreator}
          clickedSave={clickedSave}
          userInfo={userInfo}
          nothingHasChanged={nothingHasChanged}
          taskId={previewTaskId}
          timer={timer}
          handleSelectionNavigator={this.handleSelectionNavigator}
          handleNavigator={handleNavigator}
          backToWaterFall={backToWaterFall}
        />
      );
    } else {
      elementToRender = null;
    }

    const isBasicMode = selectionMode === Constants.SELECTION_MODE__BASIC;

    const classNamesForBasicModeButton = classNames(
      'slds-tabs_default__item basic-mode-btn',
      { 'slds-is-active': isBasicMode },
    );

    const classNamesForAdvancedModeButton = classNames(
      'slds-tabs_default__item advanced-mode-btn',
      { 'slds-is-active': !isBasicMode },
    );

    return (
      <Provider store={store}>
        <div
          className={classNames('selection selection-v2-container', {
            'selection-v2-container--expanded': expandedRightBar,
          })}>
          {showBanner && orgInfo?.edition === Constants.ORGANISATION__EDITION__ESSENTIALS && (
            <Banner onclickHideBannerModal={this.hideBannerModal} />
          )}

          <SelectionNavbar
            getNavBarOptions={this.getNavBarOptions}
            selectionNavigator={selectionNavigator}
            selectionNavigation={this.handleSelectionNavigator}
            handleNavigator={handleNavigator}
            validateIfQueryCanBeRun={this.validateIfQueryCanBeRun}
            saveSelection={this.saveSelection}
            selectedDataExtensions={selectedDataExtensions}
            selectionName={selectionName}
            matchedFields={matchedFields}
            previewQueryActivityId={previewQueryActivityId}
            numberOfResults={numberOfResults}
            previewDedupNumberOfRecords={previewDedupNumberOfRecords}
            previewStatus={previewStatus}
            handleSetSelectionState={this.handleSetSelectionState}
            axiosCancelToken={this.axiosCancelToken.token}
            editNewAutoTargetDE={editNewAutoTargetDE}
            editTargetDataExtension={editTargetDataExtension}
            showSaveToast={showSaveToast}
            folders={folders}
            folderId={folderId}
            handleSetAppState={handleSetAppState}
            currentSelectionId={currentSelectionId}
            disablePreviewBTN={disablePreviewBTN}
            checkMissingFieldsInRelations={this.checkMissingFieldsInRelations}
            enabledScheduleSelection={scheduledRun.enabled}
            copiedSelectionState={this.copiedSelectionState}
            selectionState={this.state}
            checkIncompleteFilter={this.checkIncompleteFilter}
            checkValidSchedule={this.checkValidSchedule}
            runStatus={runStatus}
            captureSelectionChange={captureSelectionChange}
            handleSetSelectionName={this.handleSetSelectionName}
            isTemplate={isTemplate}
            isArchived={isArchived}
            selectionCreator={selectionCreator}
            scheduleDisabled={scheduleDisabled}
            backToWaterFall={backToWaterFall}
            clickedSave={clickedSave}
            showEssentialsUpgradeModal={this.showEssentialsUpgradeModal}
            selectionDescription={selectionDescription}
            showDataMultipleTabs={this.showLoadingModal()}
          />

          {featureBasicMode && selectionNavigator === Constants.NAVIGATION__SELECTION_CRITERIA && basicModeStatus && (
            <div className="slds-tabs_default selection-modes-bar">
              <ul className="slds-tabs_default__nav" role="tablist">
                <li
                  className={classNamesForBasicModeButton}
                  title="Basic Mode"
                  role="presentation"
                  onClick={() => this.toggleMode(Constants.SELECTION_MODE__BASIC)}
                >
                  <a
                    className="slds-tabs_default__link"
                    href="#!"
                    role="tab"
                    tabIndex={isBasicMode ? 0 : -1}
                    aria-selected={isBasicMode}
                    id="tab-basic-mode"
                  >
                    Basic Mode
                  </a>
                </li>
                <li
                  className={classNamesForAdvancedModeButton}
                  title="Advanced Mode"
                  role="presentation"
                  onClick={() => this.toggleMode(Constants.SELECTION_MODE__ADVANCED)}
                >
                  <a
                    className="slds-tabs_default__link"
                    href="#!"
                    role="tab"
                    tabIndex={isBasicMode ? -1 : 0}
                    aria-selected={!isBasicMode}
                    id="tab-advanced-mode"
                  >
                    Advanced Mode
                  </a>
                </li>
              </ul>
            </div>)}
          <LoadingModal
            closeModal={() => this.handleNavigateBack()}
            hide={!this.showLoadingModal()}
            loadingText={autoFixingSelection ? 'Selection auto-fix is in progress…' : ''}
            id="DE-loadingmodal"
          >
            {!autoFixingSelection &&
              <LoadingText
                firstPhrase="Your Selection"
                boldText={selectionName || currentSelectionName}
              />}
          </LoadingModal>
          {showScheduleSelectionModal && featureScheduleSelections && (
            <ScheduleSelectionModal
              handleSetSelectionState={this.handleSetSelectionState}
              scheduledRun={scheduledRun}
              scheduledWaterfallSelections={scheduledWaterfallSelections}
              selectionsSchedules={selectionsSchedules}
              currentSelectionId={currentSelectionId}
              currentSelectionName={currentSelectionName}
            />
          )}
          {showTemplateSettingsModal && featureSelectionTemplate && (
            <TemplateSettingsModal
              handleSetSelectionState={this.handleSetSelectionState}
              isTemplate={isTemplate}
              selectionDescription={selectionDescription}
              copiedFromTemplate={copiedFromTemplate}
              templateDescription={templateDescription}
              templateInstructions={templateInstructions}
              selectionCreator={selectionCreator}
            />
          )}
          {showUpdateSelectionModal && checkMissingFieldsInRelationsDone && (
            <UpdateSelectionModal
              selectionUpdates={selectionUpdates}
              handleSetSelectionState={this.handleSetSelectionState}
            />
          )}
          <ToastContainer
            enableMultiContainer
            containerId={Constants.NOTIFICATION__CONTAINER_ID__SELECTION}
            limit={1}
          />
          <ActiveUsers currentSelectionId={currentSelectionId} />
          <Provider store={store}>
            <FeatureAdvertContainer
              feature={featureAdvertModal?.feature}
              showFeatureModal={featureAdvertModal?.show}
              handleCancel={this.cancelEssentialsUpgradeModal}
            />
          </Provider>
          {elementToRender}
        </div>
      </Provider>
    );
  }
}

NewSelection.propTypes = {
  /**
   * HandleGlobalNavigator prop is passed from App.js and it helps to set where we are in the App.
   */
  handleGlobalNavigator: PropTypes.func.isRequired,
  /**
   * handleNavigator prop is passed from App.js and it helps to navigate between Overview and Selection
   */
  handleNavigator: PropTypes.func.isRequired,
  /**
   * On overview page if edit button is clicked App.js component`s currentSelection will be set to
   * Selected selection`s id and that will be stored in currentSelectionId state of the App component.
   * And that state will be passed to Selection.js as currentSelectionId prop
   * This prop will be passed from App.js component
   */
  currentSelectionId: PropTypes.string,
  /**
   * It clears the App component`s state when a selection is closed
   * This prop will be passed from App.js component
   */
  handleClearCurrentSelection: PropTypes.func.isRequired,
  /**
   * It sets the App component`s state
   * This prop will be passed from App.js component
   */
  handleSetAppState: PropTypes.func.isRequired,
  /**
   * It helps to add another element to App component's array state
   * This prop will be passed from App.js component
   */
  handleAddElementToAppArrayState: PropTypes.func.isRequired,
  /**
   * @property {object} unionSelections.relations => it keeps the relation between selected data extensions
   * @property {object} unionSelections.collections => it keeps the selected data extensions
   * @property {object} unionSelections.fields
   *  => it keeps the selected fields for target data extensions (matchedFields)
   * @property {object} unionSelections.filters => it keeps the filters of a Selection
   * It will be passed from App.js
   */
  unionSelections: PropTypes.instanceOf(Array),
  /**
   * This prop keeps the unionSelectionsIndex of Union Selection
   * This prop will be passed from App.js component if the appendDataExtension feature is enabled
   */
  unionSelectionsIndex: PropTypes.number,
  /**
   * This props keeps the tabName of a Union Selection
   * This prop will be passed from App.js component if the appendDataExtension feature is enabled
   */
  tabName: PropTypes.string,
  folders: PropTypes.instanceOf(Array).isRequired,
  folderId: PropTypes.string.isRequired,
  /**
   * Edited selection name
   * This prop will be passed from App.js component
   */
  currentSelectionName: PropTypes.string,
  /**
   * the waterFall we want to go back to, null if we do not
   */
  backToWaterFall: PropTypes.object,
  /**
   * User info from cookie
   */
  userInfo: PropTypes.object,
  /**
   * Org info from cookie
   */
  orgInfo: PropTypes.object,
  /**
   * Features info from cookie
   */
  featuresInfo: PropTypes.object,
  /**
   * expandedRightBar prop is passed from App.js and it helps to expand the right bar
   */
  expandedRightBar: PropTypes.bool,
};

NewSelection.defaultProps = {
  /**
   * Indicates default folderId
   */
  folderId: '',
};

export default connect(mapStateToProps(['userInfo', 'orgInfo', 'featuresInfo']), null, null, { pure: false })(NewSelection);

export const NewSelectionStates = tabName => new NewSelection({ tabName });
