import Constants from '../../constants/constants';

const selectionUpdatesUtil = {
  /**
   * Returns message and object type when the field in the filter is missing
   * @param {string} fieldName - field name
   * @param {string} dataExtensionName - data extension name
   * @param {string} isRelatedRecord - Tells if it concerns related record or not
   * @returns {object} - message and object type required to view results in selection updates modal
   */
  removedFieldInFilterResponse: (fieldName, dataExtensionName, isRelatedRecord) => {
    let message = `Field <strong>${fieldName}</strong> in Data Extension
    <strong>${dataExtensionName}</strong> cannot be found.`;

    const objectType = Constants.UPDATE_SELECTION__OBJECT_TYPE__FILTER;

    if (isRelatedRecord) {
      message = 'The Data extension that is used in related records field is not found.';
    }

    // return object with data for Update Selection modal
    return { message, objectType };
  },

  /**
   * Returns message and object type when data extension is missing in In/ Not In results filter
   * @param {string} dataExtensionName - data extension name
   * @returns {object} - message and object type required to view results in selection updates modal
   */
  removedDataExtensionInSubQueryFilterResponse: (dataExtensionName) => {
    const message = `Data Extension <strong>${dataExtensionName}</strong>
    used in (Not) In Results filters cannot be found.`;

    const objectType = Constants.UPDATE_SELECTION__OBJECT_TYPE__FILTER;

    // return object with data for Update Selection modal
    return { message, objectType };
  },

  /**
   * Returns message and object type when data extensions cannot be found in related records
   * @param {string} toDataExtensionName - name of toDataExtension in predefined relation
   * @param {string} fromDataExtensionName -name of fromDataExtension in predefined relation
   * @returns {object} - message and object type required to view results in selection updates modal
   */
  removedDataExtensionInRelationFilterResponse: (toDataExtensionName, fromDataExtensionName) => {
    const message = `Data Sources <strong>${fromDataExtensionName}</strong> or <strong>${toDataExtensionName}
    </strong> used in relation filter cannot be found in related records.`;

    const objectType = Constants.UPDATE_SELECTION__OBJECT_TYPE__RELATION_FILTER;

    // return object with data for Update Selection modal
    return { message, objectType };
  },

  /**
   * Returns message and object type when field used in filters in aggregation custom value cannot be found
   * @param {string} fieldName - field name
   * @param {string} dataExtensionName - data extension name
   * @param {string} aggregationCustomValueName - aggregation custom value name
   * @returns {object} - message and object type required to view results in selection updates modal
   */
  removedFieldInAggregationCustomValueFilterResponse: (fieldName, dataExtensionName, aggregationCustomValueName) => {
    const message = `Field <strong>${fieldName}</strong> in Data Extension <strong>${dataExtensionName}
    </strong> used in custom value <strong>${aggregationCustomValueName}</strong> cannot be found.`;

    const objectType = Constants.UPDATE_SELECTION__OBJECT_TYPE__FILTER_IN_CUSTOM_VALUE;

    // return object with data for Update Selection modal
    return { message, objectType };
  },

  /**
   * Returns message and object type when field used in filter in In/Not In results filters cannot be found
   * @param {string} fieldName - field name
   * @param {string} dataExtensionName - data extension name
   * @returns {object} - message and object type required to view results in selection updates modal
   */
  removedFieldInSubQueryFilterResponse: (fieldName, dataExtensionName) => {
    const message = `Field <strong>${fieldName}</strong> in Data Extension <strong>${dataExtensionName}
    </strong> used in filter in (Not) In Results filters cannot be found.`;

    const objectType = Constants.UPDATE_SELECTION__OBJECT_TYPE__FILTER_IN_SUBQUERY;

    // return object with data for Update Selection modal
    return { message, objectType };
  },

  /**
   * Returns message and object type when field used in filter in Related Records filters cannot be found
   * @param {string} fieldName - field name
   * @param {string} dataExtensionName - data extension name
   * @returns {object} - message and object type required to view results in selection updates modal
   */
  removedFieldInFiltersInRelatedRecordsResponse: (fieldName, dataExtensionName) => {
    const message = `Field <strong>${fieldName}</strong> in Data Extension <strong>${dataExtensionName}
      </strong> used in filter in Related Records filters cannot be found.`;

    const objectType = Constants.UPDATE_SELECTION__OBJECT_TYPE__FILTER_IN_RELATION_FILTER;

    // return object with data for Update Selection modal
    return { message, objectType };
  },

  /**
   * Returns message and object type when data extension or field used in custom value has been removed
   * @param {string} cvName - The name of the custom value
   * @param {string} deName - The name of the Data Extension
   *  @param {string} DEOrField - specifies what has been removed - Data Extension or Field
   * @returns {object} - message and object type required to view results in selection updates modal
   */
  removedFieldOrDEInCustomValueResponse: (cvName, deName, DEOrField) => {
    const message = `Custom value <strong>${cvName}</strong> uses a
        ${DEOrField === Constants.FIELD_OR_DATA_EXTENSION__DATA_EXTENSION ?
    'data extension ' :
    'field in data extension '}
        <strong>${deName}</strong> that no longer exists.`;

    const objectType = Constants.UPDATE_SELECTION__OBJECT_TYPE__CUSTOM_VALUE;

    // return object with data for Update Selection modal
    return { message, objectType };
  },

  /**
   * Returns message and object type when data extension or field used in custom value has been removed
   * @param {object} updateRelationData - object with fields that have been updated in relation filters
   * @param {string} fromDataExtensionName - The name of fromDE in predefined relation
   * @param {string} toDataExtensionName - The name of toDE in predefined relation
   * @returns {object} - message and object type required to view results in selection updates modal
   */
  updatedFieldOrIdInRelationFilterResponse: (updateRelationData, fromDataExtensionName, toDataExtensionName) => {
    // set object type - relation filter
    const objectType = Constants.UPDATE_SELECTION__OBJECT_TYPE__RELATION_FILTER;

    // show message when relation between two DEs has changed
    // eslint-disable-next-line max-len
    const message = `An administrator has changed the relation between <strong>${fromDataExtensionName}</strong> - <strong>${toDataExtensionName}</strong> in the Admin Panel. Therefore, the relation between these data extensions/data views has been updated.`;

    // return object with data for Update Selection modal
    return { message, objectType };
  },

};

export default selectionUpdatesUtil;
