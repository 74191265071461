import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Dropdown } from 'semantic-ui-react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import mapStateToProps from '../../../mapStateToProps';
import AdminBody from '../../shared/AdminBody/AdminBody';
import AdminHeader from '../../shared/AdminHeader/AdminHeader';
import Button from '../../../components/shared/Button/Button';
import Constants from '../../../constants/constants';
import ModalTemplate from '../../../components/shared/ModalTemplate/ModalTemplate';
import DataExtensionsAPI from '../../../api/data-extensions';
import GlobalCustomValuesAPI from '../../../api/global-custom-values';
import './styles.scss';
import FixedValueModal from '../../Selection/TargetDefinition/CustomValues/FixedValueModal/FixedValueModal';
import TransformDateModal from '../../Selection/TargetDefinition/CustomValues/TransformDateModal/TransformDateModal';
import TimestampModal from '../../Selection/TargetDefinition/CustomValues/TimestampModal/TimestampModal';
import DateDifferenceModal from '../../Selection/TargetDefinition/CustomValues/DateDifferenceModal/DateDifferenceModal';
import RowNumberModal from '../../Selection/TargetDefinition/CustomValues/RowNumberModal/RowNumberModal';
import DefaultValues from './defaultValues';
import FreeFormulaModal from '../../Selection/TargetDefinition/CustomValues/FreeFormulaModal/FreeFormulaModal';
import DropdownOptions from '../../../constants/dropdownOptions';
import FreeFormulaUtil from '../../Selection/TargetDefinition/CustomValues/FreeFormulaModal/freeFormulaUtil';
import AggregationModal from '../../Selection/TargetDefinition/CustomValues/AggregationModal/AggregationModal';
import filtersUtil from '../../../utils/filters/filtersUtil';
import FilterSetsPanel from '../FilterSets/FilterSetsPanel/FilterSetsPanel';
import Features from '../../../features';
import AdminPanelUtil from '../adminPanelUtil';
import DynamicValueModal from '../../Selection/TargetDefinition/CustomValues/DynamicValueModal/DynamicValueModal';
import Util from '../../../util';
import Alert from '../../../components/shared/Alert/Alert';
import CustomValuesUtil from '../../Selection/TargetDefinition/CustomValues/customValuesUtil';
import LoadingModal from '../../../components/shared/LoadingModal/LoadingModal';
import SwalUtil from '../../../utils/swal/swalUtil';
import GlobalCustomValuesUtil from './globalCustomValuesUtil';
import { usePicklists } from '../../../utils/hooks/picklists';
import { usePredefinedRelations } from '../../../utils/hooks/predefinedRelations';
import { useSelectedDataExtensions } from '../../../utils/hooks/selectedDataExtensions';
import { useAvailableDataExtensions } from '../../../utils/hooks/availableDataExtensions';
import DynamicValueUtil from '../../Selection/TargetDefinition/CustomValues/DynamicValueModal/dynamicValueUtil';
import HooksUtil from '../../../utils/hooks/hooksUtil';

const GlobalCustomValue = ({
  customValue,
  openPanel,
  globalCustomValuesNames,
  featuresInfo,
}) => {
  const featurePredefinedRelationsIsEnabled = Features.isFeatureEnabled(
    featuresInfo,
    Constants.FEATURE__PREDEFINED_RELATIONS,
  );
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [type, setType] = useState(customValue?.type);
  const [formulaType, setFormulaType] = useState(customValue?.value?.formula);
  const [description, setDescription] = useState(customValue?.description);
  const [modalValue, setModalValue] = useState(customValue?.value);
  const [valueName, setValueName] = useState(customValue?.name);
  const [availableDataExtensions] = useAvailableDataExtensions(() => {});
  const [availableDataExtensionsWithExclusionSkipped] = useAvailableDataExtensions(setLoading, true);
  const [value, setValue] = useState({
    ...customValue?.value,
    selectedDataExtensions: customValue?.selectedDataExtensions,
  });
  const [selectedDataExtensions, setSelectedDataExtensions] = useSelectedDataExtensions(customValue, (valueObject) => {
    setValue(val => ({ ...val, ...valueObject }));
    setModalValue(modalVal => ({ ...modalVal, ...valueObject }));
  });

  /** State used for Custom Values with filter modals */
  const [filtersModal, setFiltersModal] = useState(false);
  const [pickLists] = usePicklists([]);

  /** Aggregation Value related state */
  const [mouseBorder, setMouseBorder] = useState(false);
  const [predefinedRelations] = usePredefinedRelations([]);

  /** Dynamic Custom Value related state */
  const [dynamicValueState, setDynamicValueState] = useState({
    dynamicCustomValuesFilters: {},
    dynamicCustomValuesFilterID: 0,
  });

  /** Free Formulas related state */
  const [freeFormulaState, setFreeFormulaState] = useState({
    isSyntaxValid: false,
    syntaxError: null,
    isValidating: false,
    isFreeFormulaValidated: false,
  });

  useEffect(() => {
    /**
     * Check if some field(s) are missing and show error if it's the case
     * @param {Array} dataExtensions - Data Extensions to check for missing fields
     * @param {Object} fieldsObjectIds - Object Ids of fields used in Custom Value
     * @return {void}
     */
    const checkMissingFields = async (dataExtensions, fieldsObjectIds) => {
      const dataExtensionsWithFields = await DataExtensionsAPI.getManyDataExtensionsWithFields(
        dataExtensions,
        axios.CancelToken.source().token,
      );
      const missingFields = fieldsObjectIds?.some(fieldsObjectId => !dataExtensionsWithFields?.find(
        de => de?.fields?.find(field => field?.ObjectID === fieldsObjectId),
      ));

      if (missingFields) {
        await SwalUtil.fire({
          type: Constants.SWAL__TYPE__ERROR,
          title: 'Deleted Field used in Shared Custom Value',
          message: `The Custom Value you're attempting to use includes Field(s) that are no longer available.
           Please update the Custom Value accordingly.`,
          options: {
            confirmButtonText: 'OK',
            allowOutsideClick: false,
          },
        });

        setShowModal(true);
      }
    };

    /**
     * Check if some DE is missing and show error if it's the case
     * @param {Array} dataExtensions - Available Data Extensions
     * @param {Object} customValue - Custom value object
     *  (field name, value and error message to show)
     * @return {void}
     */
    const checkMissingDE = (dataExtensions, customValue) => {
      let lookupParams = null;
      const type = customValue?.type;
      const formula = customValue?.value?.formula;

      switch (type) {
        case Constants.CUSTOM_VALUES__FIELD_TYPE__FORMULA:
          switch (formula) {
            case Constants.CUSTOM_VALUES__FORMULA_TYPE__TRANSFORM:
              lookupParams = {
                fieldName: 'ObjectID',
                dataExtensionLookupCriteria: [customValue?.value?.dataExtensionObjectId],
                fieldsObjectIds: [customValue?.value?.fieldObjectId],
                errorMessage: 'Selected Data Extension not found',
              };
              break;
            case Constants.CUSTOM_VALUES__FORMULA_TYPE__DATE_DIFFERENCE:
              lookupParams = {
                fieldName: 'ObjectID',
                dataExtensionLookupCriteria: [...new Set([customValue?.value?.field1?.dataExtensionObjectId,
                  customValue?.value?.field2?.dataExtensionObjectId])].filter(id => id !== null),
                fieldsObjectIds: [...new Set([customValue?.value?.field1?.fieldObjectId,
                  customValue?.value?.field2?.fieldObjectId])].filter(id => id !== null),
                errorMessage: 'Selected Data Extension(s) not found',
              };
              break;
            case Constants.CUSTOM_VALUES__FORMULA_TYPE__ROW_NUMBER:
              lookupParams = {
                fieldName: 'deAlias',
                dataExtensionLookupCriteria: [customValue?.value?.orderBy?.collectionAlias],
                fieldsObjectIds: [customValue?.value?.orderBy?.fieldObjectID],
                errorMessage: 'Selected Data Extension not found',
              };
              break;
          }

          break;
        case Constants.CUSTOM_VALUES__FIELD_TYPE__AGGREGATION_VALUE:
          lookupParams = {
            fieldName: 'deAlias',
            dataExtensionLookupCriteria: [customValue?.value?.relations?.toCollectionAlias],
            fieldsObjectIds: [customValue?.value?.relations?.toFieldObjectId],
            errorMessage: 'Selected Data Extension not found',
          };
          break;
        default:
          break;
      }

      const dataExtensionsFound = dataExtensions?.filter(
        de => lookupParams?.dataExtensionLookupCriteria?.includes(String(de?.[lookupParams?.fieldName])),
      );

      if (lookupParams && (!dataExtensionsFound ||
        dataExtensionsFound?.length !== lookupParams?.dataExtensionLookupCriteria?.length)) {
        HooksUtil.showMissingDEsError(null, lookupParams?.errorMessage);
      } else {
        checkMissingFields(dataExtensionsFound, lookupParams?.fieldsObjectIds);
      }
    };

    if(customValue?._id && availableDataExtensionsWithExclusionSkipped?.length && !loading) {
      checkMissingDE(availableDataExtensionsWithExclusionSkipped, customValue);
    }
  }, [availableDataExtensionsWithExclusionSkipped, loading, customValue]);

  // Changes size of filters modal for Dynamic Custom Values
  const modalClass = classNames(
    'slds-modal_large',
    { 'criteria-modal': filtersModal && type === Constants.CUSTOM_VALUES__FIELD_TYPE__DYNAMIC_VALUE },
    { 'free-formula-modal': type === Constants.CUSTOM_VALUES__FIELD_TYPE__FORMULA },
  );

  /**
   * renders header text based on different
   * custom value option selected
   * @return {String} text of header
   */
  const renderHeader = () => {
    if (type === Constants.CUSTOM_VALUES__FIELD_TYPE__FORMULA &&
        formulaType === Constants.CUSTOM_VALUES__FORMULA_TYPE__TRANSFORM) {
      return Constants.CUSTOM_VALUES__HEADER__TRANSFORM_DATE;
    }
    if (type === Constants.CUSTOM_VALUES__FIELD_TYPE__FORMULA &&
        formulaType === Constants.CUSTOM_VALUES__FORMULA_TYPE__TIMESTAMP) {
      return Constants.CUSTOM_VALUES__HEADER__TIMESTAMP;
    }
    if (type === Constants.CUSTOM_VALUES__FIELD_TYPE__FORMULA &&
      formulaType === Constants.CUSTOM_VALUES__FORMULA_TYPE__DATE_DIFFERENCE) {
      return Constants.CUSTOM_VALUES__HEADER__DATE_DIFFERENCE;
    }
    if (type === Constants.CUSTOM_VALUES__FIELD_TYPE__FORMULA &&
      formulaType === Constants.CUSTOM_VALUES__FORMULA_TYPE__FREE_FORMULA) {
      return Constants.CUSTOM_VALUES__HEADER__FREE_FORMULA;
    }
    if (type === Constants.CUSTOM_VALUES__FIELD_TYPE__FORMULA &&
      formulaType === Constants.CUSTOM_VALUES__FORMULA_TYPE__ROW_NUMBER) {
      return Constants.CUSTOM_VALUES__HEADER__ROW_NUMBER;
    }
    if (type === Constants.CUSTOM_VALUES__FIELD_TYPE__FIXED_VALUE) {
      return Constants.CUSTOM_VALUES__HEADER__FIXED_VALUE;
    }
    if (type === Constants.CUSTOM_VALUES__FIELD_TYPE__DYNAMIC_VALUE) {
      return Constants.CUSTOM_VALUES__HEADER__DYNAMIC_VALUE;
    }
    if (type === Constants.CUSTOM_VALUES__FIELD_TYPE__AGGREGATION_VALUE) {
      return Constants.CUSTOM_VALUES__HEADER__AGGREGATION;
    }

    return 'Shared Custom Value';
  };

  /**
   * If not already fetched, fetches fields of Selected Data Extensions, and updates state.
   * @param {Array} newSelectedDataExtensions - Data Extension ObjectIDs selected from the dropdown.
   * @returns {void}
   */
  const handleChangeSelectedDataExtensions = async (newSelectedDataExtensions) => {
    if (type === Constants.CUSTOM_VALUES__FIELD_TYPE__DYNAMIC_VALUE) {
      // Make sure the Data Extension being removed is not used in the criteria
      if (selectedDataExtensions?.some((de) => {
        // Check if DE is being removed
        const foundDE = newSelectedDataExtensions.find(objectId => de.ObjectID === objectId);

        // If DE was removed, check if it s used
        if (!foundDE && DynamicValueUtil.containsDataExtension(de, modalValue)) return true;

        return false;
      // If at least one filter uses the criteria, do not remove DE
      })) return;
    }

    const dataExtensions = availableDataExtensions
      .filter(dataExtension => newSelectedDataExtensions.includes(dataExtension.ObjectID))
      .map(dataExtension => ({ ...dataExtension, deAlias: dataExtension.Name }));

    const dataExtensionsWithFields = await DataExtensionsAPI.getManyDataExtensionsWithFields(
      dataExtensions,
      axios.CancelToken.source().token,
    );

    setSelectedDataExtensions(dataExtensionsWithFields);
  };

  const axiosCancelToken = axios.CancelToken.source()?.token;

  /**
   * Redirects user back to Shared Custom Values panel
   * @returns {void}
   */
  const handleCancel = () => openPanel(Constants.ADMIN_PANEL__MENU__GLOBAL_CUSTOM_VALUES__PANEL);

  /**
   * Make sure all necessary info are filled in, in order to define a Shared Custom Value.
   * @returns {boolean} - True if all info are there, false otherwise.
   */
  const modalDisabled = () => !type || (type === Constants.CUSTOM_VALUES__FIELD_TYPE__FORMULA && !formulaType);

  /**
   * For the Custom Value type selected, checks if Save should be possible.
   * @returns {void}
   */
  const shouldDisableSaveModal = () => {
    switch (type) {
      case Constants.CUSTOM_VALUES__FIELD_TYPE__DYNAMIC_VALUE:
        return !selectedDataExtensions?.length || !modalValue?.criteria?.length;

      case Constants.CUSTOM_VALUES__FIELD_TYPE__FORMULA:
        switch (formulaType) {
          case Constants.CUSTOM_VALUES__FORMULA_TYPE__TRANSFORM:
            return !modalValue?.fieldObjectId;
          case Constants.CUSTOM_VALUES__FORMULA_TYPE__FREE_FORMULA:
            return !freeFormulaState.isFreeFormulaValidated;
          case Constants.CUSTOM_VALUES__FORMULA_TYPE__DATE_DIFFERENCE:
            return (
              (!modalValue?.field1?.fieldObjectId && modalValue?.field1?.format === 'field') ||
              (!modalValue?.field2?.fieldObjectId && modalValue?.field2?.format === 'field')
            );
          case Constants.CUSTOM_VALUES__FORMULA_TYPE__ROW_NUMBER:
            return !modalValue?.orderBy?.fieldObjectID;
        }
    }

    return false;
  };

  /**
   * Check whether the name of the Shared Custom Value exists in the existing Shared Custom Values.
   * @returns {boolean} True if name exists, false otherwise
   */
  const doesNameExist = () => (
    (
      (Array.isArray(globalCustomValuesNames) ?
        globalCustomValuesNames.filter(name => name === valueName) :
        []).length -
       Number(valueName === customValue?.name || 0)
    ) > 0
  );

  /**
   * Check whether the Shared Custom Value save button should be disabled.
   * @returns {boolean} True if name is unique, false otherwise
   */
  const shouldDisableSave = () => {
    return !valueName?.length || doesNameExist() || modalDisabled() ||
    !value?.fieldType || shouldDisableSaveModal();
  };

  /**
   * Handles updates inside the modal
   * @param {Object} updatedData - Object containing the updated data.
   * @returns {void}
   */
  const handleChangeValue = (updatedData) => {
    switch (type) {
      case Constants.CUSTOM_VALUES__FIELD_TYPE__FIXED_VALUE:
        setModalValue(updatedData.fixedValueData);

        break;

      case Constants.CUSTOM_VALUES__FIELD_TYPE__DYNAMIC_VALUE:
        if (updatedData.hasOwnProperty('criteriaModal')) setFiltersModal(updatedData.criteriaModal);
        if (updatedData.hasOwnProperty('dynamicCustomValuesFilterID')) {
          setDynamicValueState(previousState => ({
            ...previousState,
            dynamicCustomValuesFilterID: updatedData.dynamicCustomValuesFilterID,
          }));
        }
        if (updatedData.hasOwnProperty('dynamicCustomValuesFilters')) {
          setDynamicValueState(previousState => ({
            ...previousState,
            dynamicCustomValuesFilters: updatedData?.dynamicCustomValuesFilters,
          }));
        }
        if (updatedData.dynamicValueData) setModalValue(updatedData.dynamicValueData);

        break;

      case Constants.CUSTOM_VALUES__FIELD_TYPE__FORMULA:
        switch (formulaType) {
          case Constants.CUSTOM_VALUES__FORMULA_TYPE__TIMESTAMP:
            break;

          case Constants.CUSTOM_VALUES__FORMULA_TYPE__TRANSFORM:
            setModalValue(previousState => ({ ...previousState, ...updatedData.transformDateData }));

            break;

          case Constants.CUSTOM_VALUES__FORMULA_TYPE__DATE_DIFFERENCE:
            setModalValue(updatedData.dateDifferenceData);

            break;

          case Constants.CUSTOM_VALUES__FORMULA_TYPE__ROW_NUMBER:
            setModalValue({
              ...updatedData.rowNumberData,
              formula: formulaType,
            });

            break;

          case Constants.CUSTOM_VALUES__FORMULA_TYPE__FREE_FORMULA:
            if (updatedData?.freeFormulaData) setModalValue(updatedData.freeFormulaData);
            if (updatedData?.hasOwnProperty('isFreeFormulaValidated')) {
              setFreeFormulaState(previousState => ({
                ...previousState,
                isFreeFormulaValidated: updatedData.isFreeFormulaValidated,
              }));
            }

            break;
        }

        break;

      case Constants.CUSTOM_VALUES__FIELD_TYPE__AGGREGATION_VALUE:
        if (updatedData?.aggregationFilters) {
          setModalValue(previousState => ({ ...previousState, filters: updatedData?.aggregationFilters }));
        }
        if (updatedData?.hasOwnProperty('showAggregationModal')) setShowModal(updatedData.showAggregationModal);
        if (updatedData?.hasOwnProperty('filterBorderMouseOver')) setMouseBorder(updatedData.filterBorderMouseOver);
        if (updatedData?.aggregationValues) {
          setModalValue({ ...updatedData.aggregationValues, fieldType: Constants.FILTERLINE__FIELDTYPE__NUMBER });
        }
        if (updatedData?.hasOwnProperty('filtersModal')) setFiltersModal(updatedData.filtersModal);

        break;
    }
  };

  /**
   * Handles check syntax button of Free Formulas
   * @returns {void}
   */
  const handleCheckSyntax = async () => {
    setFreeFormulaState(previousState => ({ ...previousState, isValidating: true }));

    const { isSyntaxValid, syntaxError } = await FreeFormulaUtil.checkSyntax(
      modalValue,
      selectedDataExtensions,
      GlobalCustomValuesUtil.buildFakeRelations(selectedDataExtensions),
      axiosCancelToken,
    );

    setFreeFormulaState({
      isValidating: false,
      isFreeFormulaValidated: true,
      isSyntaxValid,
      syntaxError,
    });
  };

  /**
   * Handles updates in filter's state of Dynamic Custom Values sub-modal
   * @param {Object} updatedData - Object containing the updated data.
   * @returns {void}
   */
  const handleSetDynamicValueFilterState = (updatedData) => {
    const newValue = JSON.parse(JSON.stringify(
      modalValue || DefaultValues[Constants.CUSTOM_VALUES__FIELD_TYPE__DYNAMIC_VALUE],
    ));

    if (updatedData?.dynamicCustomValuesFilterID == null) {
      newValue.criteria.push({
        when: updatedData?.dynamicValuesFilters,
        then: Util.getDefaultValueByFieldType(modalValue?.fieldType),
      });
    } else {
      newValue.criteria[dynamicValueState.dynamicCustomValuesFilterID].when =
        updatedData?.dynamicValuesFilters;
    }
    setModalValue(newValue);
  };

  /**
   * Uses selected Custom Value type and (optionally) formula type to render the right modal.
   * @returns {object} The HTML for the selected options
   */
  const renderModal = () => {
    const featureDataViews = Features.isFeatureEnabled(featuresInfo, Constants.FEATURE__DATA_VIEWS);
    const getDataExtensionOrDataViewFieldsWrapper = (selectedCollection, isComparedField = false) => {
      return AdminPanelUtil.getDataExtensionOrDataViewFields(selectedCollection, featureDataViews, isComparedField);
    };

    switch (type) {
      case Constants.CUSTOM_VALUES__FIELD_TYPE__FIXED_VALUE:
        if (!modalValue?.fieldType) setModalValue(DefaultValues[Constants.CUSTOM_VALUES__FIELD_TYPE__FIXED_VALUE]);

        return (
          <FixedValueModal
            fixedValueData={modalValue}
            handleSetCustomValuesState={updatedData => handleChangeValue(updatedData)}
          />
        );
      case Constants.CUSTOM_VALUES__FIELD_TYPE__DYNAMIC_VALUE:
        if (!modalValue?.fieldType) setModalValue(DefaultValues[Constants.CUSTOM_VALUES__FIELD_TYPE__DYNAMIC_VALUE]);

        return (
          <DynamicValueModal
            dynamicValueData={modalValue}
            handleSetCustomValuesState={updatedData => handleChangeValue(updatedData)}
            handleSetSelectionState={updatedData => handleChangeValue(updatedData)}
            getDataExtensionOrDataViewFields={getDataExtensionOrDataViewFieldsWrapper}
            selectedDataExtensions={selectedDataExtensions}
            pickLists={pickLists}
            handleSetDynamicValueFilterState={updatedData => handleSetDynamicValueFilterState(updatedData)}
            getSelectedDEFields={() => selectedDataExtensions[0].fields}
            dynamicCustomValuesFilters={dynamicValueState.dynamicCustomValuesFilters}
            dynamicCustomValuesFilterID={dynamicValueState.dynamicCustomValuesFilterID}
            criteriaModal={filtersModal}
            closeCriteriaModal={() => setFiltersModal(false)}
            handleFiltersSave={filters => setDynamicValueState(previousState => ({
              ...previousState,
              dynamicCustomValuesFilters: filters,
            }))}
            handleRemoveFilterLine={filters => setDynamicValueState(previousState => ({
              ...previousState,
              dynamicCustomValuesFilters: {
                ...filtersUtil.removeFilterLine(previousState.dynamicCustomValuesFilters, filters),
                aggregationFilterRemoveClicked: true,
              },
            }))}
          />
        );
      case Constants.CUSTOM_VALUES__FIELD_TYPE__FORMULA:
        switch (formulaType) {
          case Constants.CUSTOM_VALUES__FORMULA_TYPE__TIMESTAMP:
            if (!modalValue) setModalValue(DefaultValues[Constants.CUSTOM_VALUES__FORMULA_TYPE__TIMESTAMP]);

            return (
                <TimestampModal
                  timestampData={modalValue}
                  handleConvertTimezoneSwitch={() => setModalValue(previousState => ({
                    ...previousState,
                    fieldType: Constants.FILTERLINE__FIELDTYPE__DATE,
                    convert: !previousState?.convert,
                    formula: formulaType,
                  }))}
                  handleSetCustomValuesState={updatedData => setModalValue({
                    ...updatedData.timestampData,
                    formula: formulaType,
                  })}
                />
            );
          case Constants.CUSTOM_VALUES__FORMULA_TYPE__TRANSFORM:
            return (
              <TransformDateModal
                transformDateData={modalValue || DefaultValues[Constants.CUSTOM_VALUES__FORMULA_TYPE__TRANSFORM]}
                selectedDataExtensions={availableDataExtensions}
                availableDataExtensionsWithExclusionSkipped={availableDataExtensionsWithExclusionSkipped}
                handleSetCustomValuesState={updatedData => handleChangeValue(updatedData)}
                getDataExtensionOrDataViewFields={getDataExtensionOrDataViewFieldsWrapper}
              />
            );
          case Constants.CUSTOM_VALUES__FORMULA_TYPE__DATE_DIFFERENCE:
            return (
              <DateDifferenceModal
                dateDifferenceData={modalValue || DefaultValues[Constants.CUSTOM_VALUES__FORMULA_TYPE__DATE_DIFFERENCE]}
                selectedDataExtensions={availableDataExtensions}
                availableDataExtensionsWithExclusionSkipped={availableDataExtensionsWithExclusionSkipped}
                handleSetCustomValuesState={updatedData => handleChangeValue(updatedData)}
                getDataExtensionOrDataViewFields={getDataExtensionOrDataViewFieldsWrapper}
              />
            );
          case Constants.CUSTOM_VALUES__FORMULA_TYPE__ROW_NUMBER:
            return (
              <RowNumberModal
                selectedDataExtensions={availableDataExtensions}
                availableDataExtensionsWithExclusionSkipped={availableDataExtensionsWithExclusionSkipped}
                handleSetCustomValuesState={updatedData => handleChangeValue(updatedData)}
                rowNumberData={modalValue || DefaultValues[Constants.CUSTOM_VALUES__FORMULA_TYPE__ROW_NUMBER]}
                getDataExtensionOrDataViewFields={getDataExtensionOrDataViewFieldsWrapper}
              />
            );
          case Constants.CUSTOM_VALUES__FORMULA_TYPE__FREE_FORMULA:
            return (
              <FreeFormulaModal
                freeFormulaData={modalValue || DefaultValues[Constants.CUSTOM_VALUES__FORMULA_TYPE__FREE_FORMULA]}
                handleSetCustomValuesState={updatedData => handleChangeValue(updatedData)}
                selectedDataExtensions={selectedDataExtensions}
                handleCheckSyntax={handleCheckSyntax}
                isSyntaxValid={freeFormulaState.isSyntaxValid}
                syntaxError={freeFormulaState.syntaxError}
                isValidating={freeFormulaState.isValidating}
                isFreeFormulaValidated={freeFormulaState.isFreeFormulaValidated}
              />
            );
        }

        break;

      case Constants.CUSTOM_VALUES__FIELD_TYPE__AGGREGATION_VALUE:
        return (
          <AggregationModal
            dataExtensions={availableDataExtensions}
            selectedDataExtensions={availableDataExtensions}
            availableDataExtensionsWithExclusionSkipped={availableDataExtensionsWithExclusionSkipped}
            handleSetSelectionState={updatedData => handleChangeValue(updatedData)}
            aggregationValues={modalValue || DefaultValues[Constants.CUSTOM_VALUES__FIELD_TYPE__AGGREGATION_VALUE]}
            isNewValue={!modalValue && predefinedRelations?.length}
            aggregationFilters={modalValue?.filters}
            handleSetCustomValuesState={updatedData => handleChangeValue(updatedData)}
            pickLists={pickLists}
            handlePickListOptions={FilterSetsPanel.handlePickListOptions}
            getAllRelationsForSelectedDE={() => predefinedRelations}
            getSelectedRelation={GlobalCustomValuesUtil.getSelectedRelation}
            getDataExtensionOrDataViewFields={getDataExtensionOrDataViewFieldsWrapper}
            filtersModal={filtersModal}
            handleFiltersSave={(filters) => {
              setModalValue(previousState => ({ ...previousState, filters }));
            }}
            handleRemoveFilterLine={filters => setModalValue(previousState => ({
              ...previousState,
              filters: {
                ...filtersUtil.removeFilterLine(previousState.filters, filters),
                aggregationFilterRemoveClicked: true,
              },
            }))}
            filterBorderMouseOver={mouseBorder}
            featurePredefinedRelationsIsEnabled={featurePredefinedRelationsIsEnabled}
          />
        );

      default:
        break;
    }

    return null;
  };

  /**
   * Handles save of the Shared Custom Value, if successful redirects to Panel.
   * @returns {void}
   */
  const handleSave = async () => {
    setSaving(true);

    if (customValue?._id) {
      const confirmed = await SwalUtil.fire({
        title: 'Warning',
        type: Constants.SWAL__TYPE__WARNING,
        messageHTML: 'Please note that any changes made to this Shared Custom Value can impact the Selections it is ' +
          'being used in. Are you sure you want to continue?',
        options: {
          showConfirmationButton: true,
          buttonsStyling: false,
          allowOutsideClick: false,
          showCancelButton: true,
        },
      });

      if (!confirmed.value) {
        setSaving(false);

        return;
      }
    }

    const {
      selectedDataExtensions,
      ...sanitizedValue
    } = value;

    try {
      if (customValue?._id) {
        await GlobalCustomValuesAPI.updateGlobalCustomValue(
          customValue?._id,
          {
            name: valueName,
            value: sanitizedValue,
            description,
            type,
            selectedDataExtensions: GlobalCustomValuesUtil.getSelectedDataExtensionsForCustomValue({
              type,
              formulaType,
              value,
              selectedDataExtensions,
              availableDataExtensions,
            }),
          },
          axiosCancelToken,
        );
      } else {
        await GlobalCustomValuesAPI.createGlobalCustomValue(
          {
            name: valueName,
            value: sanitizedValue,
            description,
            type,
            selectedDataExtensions: GlobalCustomValuesUtil.getSelectedDataExtensionsForCustomValue({
              type,
              formulaType,
              value,
              selectedDataExtensions,
              availableDataExtensions,
            }),
          },
          axiosCancelToken,
        );
      }
      openPanel(Constants.ADMIN_PANEL__MENU__GLOBAL_CUSTOM_VALUES__PANEL);
    } catch (error) {
      setSaving(false);

      if (!axios.isCancel(error)) {
        SwalUtil.fire({
          type: 'error',
          title: '<div class="error-title">Something went wrong</div>',
          messageHTML: `<p class="width_swal">${error?.actualError || error?.message || error}</p>`,
          options: {
            showCancelButton: false,
            confirmButtonText: 'OK',
            footer: '<div></div>',
            buttonsStyling: false,
            allowOutsideClick: false,
          },
        });
      }
    }
  };

  /**
   * Handles modal's save button when clicked.
   * @returns {void}
   */
  const handleModalSave = () => {
    const valueToBeSaved = modalValue || DefaultValues[formulaType] || DefaultValues[type];

    const isValid = CustomValuesUtil.validate(
      valueToBeSaved,
      type,
      formulaType,
      freeFormulaState.isSyntaxValid,
      GlobalCustomValuesUtil.getSelectedRelation(predefinedRelations, valueToBeSaved),
    );

    if (isValid) {
      setShowModal(false);
      setValue({ ...valueToBeSaved, selectedDataExtensions });
    }
  };

  /**
   * Builds HTML used in the Admin Body component.
   * @returns {object} HTML to be rendered in the body.
   */
  const htmlBody = () => (
    <div className="global-custom-values-body">
      <span className="name-label">Name</span>
      <input
        id="global-custom-value-name"
        name="value"
        type="text"
        min="1"
        max="2550"
        className="slds-input"
        aria-label="Name"
        value={valueName}
        onChange={e => setValueName(e.target.value)}
      />
      {doesNameExist() &&
        <Alert
          id="invalid email"
          title="Shared Custom Value with this name already exists."
          className="invalid-email name-warning"
        />}
      <span className="name-label">Description</span>
      <textarea
        id="global-custom-value-description"
        name="value"
        minLength="0"
        maxLength="2550"
        className="slds-input"
        aria-label="Description"
        value={description}
        onChange={e => setDescription(e.target.value)}
      />
      <span className="name-label">Type of Custom Value</span>
      <Dropdown
        id="global-custom-value-dropdown-type"
        className="global-custom-value-dropdown searchable-dropdown"
        search
        selection
        placeholder="Choose type of Custom Value you want to add"
        value={type}
        onChange={(e, data) => { setType(data?.value); setModalValue(); setValue(); }}
        options={DropdownOptions.CUSTOM_VALUE__OPTIONS}
      />
      {type === Constants.CUSTOM_VALUES__FIELD_TYPE__FORMULA &&
        <div>
          <span className="name-label">Type of formula</span>
          <Dropdown
            id="global-custom-value-dropdown-formula-type"
            className="global-custom-value-dropdown searchable-dropdown"
            search
            selection
            placeholder="Choose type of formula you want to add"
            value={formulaType}
            onChange={(e, data) => { setFormulaType(data?.value); setModalValue(null); setValue(); }}
            options={DropdownOptions.FORMULA__OPTIONS}
          />
        </div>}
      <Button
        className="name-label"
        buttonLook={Constants.BUTTON__TYPE__BRAND}
        label="Cancel"
        onClick={() => setShowModal(true)}
        disabled={modalDisabled()}
      >
        {customValue?._id ? 'Edit Value' : 'Define Value'}
      </Button>
      {showModal ?
        <ModalTemplate
          id="add-values-modal"
          headerId="header-text"
          headerTitle={renderHeader()}
          contentClassName="slds-p-around_medium"
          contentId="global-value-modal"
          className={modalClass}
          handleCancel={() => {
            setShowModal(false);
            setModalValue(value);
            setSelectedDataExtensions(value?.selectedDataExtensions);
          }}
          handleSave={handleModalSave}
          saveButtonDisabled={shouldDisableSaveModal()}
          saveButtonId="global-custom-value-modal-btn"
        >
          {(type === Constants.CUSTOM_VALUES__FIELD_TYPE__DYNAMIC_VALUE ||
          (
            type === Constants.CUSTOM_VALUES__FIELD_TYPE__FORMULA &&
            formulaType === Constants.CUSTOM_VALUES__FORMULA_TYPE__FREE_FORMULA
          )) &&
            <>
              <span className="selected-de-label">Selected Data Sources</span>
              <Dropdown
                search
                multiple
                selection
                fluid
                value={selectedDataExtensions?.map(selectedDataExtension => selectedDataExtension?.ObjectID)}
                className="global-custom-value-dropdown searchable-dropdown in-modal-dropdown selected-data-extensions"
                placeholder="Choose Data Sources"
                // added selectedDataExtensions to the list options in case there is any restricted DE
                options={availableDataExtensions
                  ?.concat(selectedDataExtensions)?.map(availableDE => ({
                    text: availableDE?.Name,
                    value: availableDE?.ObjectID,
                  })) || []}
                onChange={(e, data) => handleChangeSelectedDataExtensions(data?.value)}
              />
            </>}
          {renderModal()}
        </ModalTemplate> :
        null}
    </div>
  );

  // render loading text based on panel type
  if (loading) {
    return (
      <LoadingModal
        closeModal={() => openPanel(Constants.ADMIN_PANEL__MENU__GLOBAL_CUSTOM_VALUES__PANEL)}
        loadingText={customValue?._id ? 'Shared Custom Value is loading...' : 'Loading...'}
        openPanel={openPanel}
        id="loadingmodal-wrapper"
      />
    );
  }

  return (
    <>
      {/* Admin header */}
      <AdminHeader
        title={customValue ? 'Edit Shared Custom Value' : 'New Shared Custom Value'}
        buttonLabel="New Shared Custom Value"
        iconLink="/assets/icons/standard-sprite/svg/symbols.svg#picklist_type"
      />
      <AdminBody
        title="Define a Shared Custom Value"
        info={`As an admin, you can create a Shared Custom Value which will then automatically be available to be
        used inside any selection.`}
        handleSave={handleSave}
        handleCancel={handleCancel}
        htmlBody={htmlBody()}
        saving={saving}
        tooltip={Constants.TOOLTIP_TYPE__GLOBAL_CUSTOM_VALUE__INFO}
        disabled={shouldDisableSave()}
        saveButtonId="global-custom-value-save"
      />
    </>
  );
};

GlobalCustomValue.propTypes = {
  /**
   * Array containing names of existing Shared Custom Values
   */
  globalCustomValuesNames: PropTypes.instanceOf(Array),
  /**
   * Custom value we are editing, if we are on edit mode
   */
  customValue: PropTypes.object,
  /**
   * Function to open a certain panel
   */
  openPanel: PropTypes.func.isRequired,
  /**
   * Features info from cookie
   */
  featuresInfo: PropTypes.object,
};

export default connect(mapStateToProps(['featuresInfo']), null, null, { pure: false })(GlobalCustomValue);
