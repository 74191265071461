/* eslint-disable max-len */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { debounce } from 'lodash';
import {
  InputIcon, Input, ButtonGroup,
} from '@salesforce/design-system-react';
import './styles.scss';
import classNames from 'classnames';
import { connect } from 'react-redux';

import mapStateToProps from '../../mapStateToProps';
import Features from '../../features';
import Constants from '../../constants/constants';
import DataViews from '../../constants/dataViews';
import Spinner from '../shared_v2/Spinner/Spinner';
import Button from '../shared/Button/Button';
import Util from '../../util';
import AvailableDECard from '../../components/Selection/DataExtensions/AvailableDECard/AvailableDECard';
import GuidanceTip from '../shared_v2/GuidanceTip/GuidanceTip';
import NewAutoCreatedTargetDE from '../Selection/TargetDefinition/NewAutoCreatedTargetDE/NewAutoCreatedTargetDE';
import FolderDataExtensions from '../Selection/DataExtensions/AvailableExtensions/FolderDataExtensions/FolderDataExtensions';
import DataExtensionsAPI from '../../api/data-extensions';
import filterSetsUtil from '../../utils/filterSets/filterSets';
import timeUtil from '../../utils/time/timeUtil';

class AvailableDataExtensions extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      availableDEsForFolder: [],
      availableDEsFolderId: props.availableDEsFolderId || null,
      showAvailableDEsFoldersModal: false,
      showDEsForFolder: false,
      selectedDE: {},
      foldersPath: [],
      isAvailableDEsTabSelected: true,
      isLoadingSearch: false,
      searchedDEs: [],
      searchMode: false,
      showAvailableFields: true,
      showDataSets: false,
      useNewDesign: true,
      showDataViews: false,
    };
    // this.axiosCancelToken = axios.CancelToken.source();
    this.debouncedSearchDataExtensions = debounce(this.fetchDataExtensions, 300);
    // Define a variable to hold the cancel token source
    this.axiosCancelSource = axios.CancelToken.source();
  }

  /**
   * Filter data views by search value
   * @param {String} searchValue - search value
   * @returns {Array} filtered data views
   */
  getFilteredDataViews = (searchValue) => {
    const { featuresInfo } = this.props;
    const featureDataViews = Features.isFeatureEnabled(featuresInfo, Constants.FEATURE__DATA_VIEWS);

    if (!featureDataViews) return [];

    const filteredDataViews = DataViews.filter((dataView) => {
      return dataView.Name.toLowerCase().includes(searchValue.toLowerCase());
    });

    return filteredDataViews;
  };

  /**
   * Fetch data extensions from salesforce
   * @param {string} searchValue - search value
   * @returns {void}
   */

  fetchDataExtensions = async (searchValue, cancelToken) => {
    this.setState({ isLoadingSearch: true });
    const { updateDataExtensionsObject, dataExtensionSearchField } = this.props;

    try {
      const retrievedDEs = await DataExtensionsAPI.searchDataExtensions(searchValue, cancelToken);

      this.setState({ isLoadingSearch: false, searchedDEs: [...retrievedDEs] });
      updateDataExtensionsObject([...retrievedDEs], dataExtensionSearchField);
    } catch (error) {
      this.setState({ isLoadingSearch: false });
      if (!axios.isCancel(error)) {
        filterSetsUtil.throwSwal({
          typeOfSwal: 'error',
          titleOfSwal: 'Error',
          message: error,
        });
      }
    }
  };

  /**
   * Search for specific data extension
   * @param {object} e - event
   * @returns {void}
   */
  handleFilterDataExtensionSearchField = async (e) => {
    const searchValue = e.target.value;
    const { onDataExtensionSearchFieldChange, updateDataExtensionsObject } = this.props;

    if (searchValue === '') {
      this.setState({
        selectedDE: {},
        isLoadingSearch: false,
        searchMode: false,
      });
      onDataExtensionSearchFieldChange('');
      updateDataExtensionsObject([], searchValue);
    } else {
      this.setState({
        selectedDE: {},
        isLoadingSearch: true,
        searchMode: true,
      });
      onDataExtensionSearchFieldChange(searchValue);
      // Cancel the previous request
      this.axiosCancelSource.cancel('Request canceled');

      // Create a new cancel token source
      this.axiosCancelSource = axios.CancelToken.source();
      // Call the debounced function
      this.debouncedSearchDataExtensions(searchValue, this.axiosCancelSource.token);
    }
  };

  /**
   * Opens Data extensions modal for a certain folder
   * @param {number} folderId - id of the selected folder
   * @param {array} foldersPath - path to the selected folder
   * @returns {void}
   */
  openAvailableDEsForCertainFolderModal = (folderId, foldersPath) => {
    const { dataExtensions } = this.props;

    // find data extensions by CategoryID for the given folderId
    const availableDEsForFolder = dataExtensions.filter(de => de.CategoryID === folderId);

    this.setState({
      availableDEsForFolder, showDEsForFolder: true, availableDEsFolderId: folderId, foldersPath,
    });
  };

  /**
   * General function to handle the state of the AvailableExtensions component
   * @param {object} newState - State to be set
   * @returns {void}
   */
  handleSetAvailableExtensionsState = (newState) => {
    this.setState(newState);
  };

  displaySearchText = (filteredExtensionsLength, filteredDataSetsLenght) => {
    const { isAvailableDEsTabSelected, showDataSets, showDataViews } = this.state;
    const { userInfo } = this.props;

    const USERLOCALE = timeUtil.getUserLocale(userInfo);

    let text = 'Available ';

    if (isAvailableDEsTabSelected) {
      text += `Data Extensions  (${Util.formatNumber(filteredExtensionsLength, 0, USERLOCALE)})`;

      return text;
    }

    if (showDataSets) {
      text += `Data Sets  (${Util.formatNumber(filteredDataSetsLenght, 0, USERLOCALE)})`;

      return text;
    }

    if (showDataViews) {
      text += `Data Views  (${Util.formatNumber(DataViews.length, 0, USERLOCALE)})`;

      return text;
    }
  };

  render() {
    const {
      dataExtensions,
      DEBorderMouseOver,
      filterBorderMouseOver,
      handleSetSelectionState,
      customValues,
      loadingForDataExtensions,
      loadingAllAvailableDataExtensions,
      availableDEsFolders,
      foldersSettings,
      dataSets,
      selectedDataExtensions,
      dataExtensionSearchField,
      isFilterBoxExpanded,
      isFilterBoxExpandedForFirstTime,
      showFiltersImmediately,
    } = this.props;

    const {
      showDEsForFolder,
      showAvailableDEsFoldersModal,
      availableDEsForFolder,
      selectedDE,
      availableDEsFolderId,
      foldersPath,
      isAvailableDEsTabSelected,
      isLoadingSearch,
      showAvailableFields,
      showDataSets,
      searchedDEs,
      useNewDesign,
      searchMode,
      showDataViews,
    } = this.state;

    let filteredExtensions;

    let filteredDataSets = [];

    let filterDataSetsArray = [];

    let filteredDataViews = [];

    // Get available features

    const { featuresInfo } = this.props;
    const featureDataSets = Features.isFeatureEnabled(featuresInfo, Constants.FEATURE__DATA_SETS);
    const featureDataViews = Features.isFeatureEnabled(featuresInfo, Constants.FEATURE__DATA_VIEWS);

    // If there is a selected DE set it as the filteredExtensions
    if (selectedDE.ObjectID) {
      filteredExtensions = [selectedDE];
    } else {
      if (isAvailableDEsTabSelected) {
        // Filtered results of available data extension by Name
        if (searchMode) {
          filteredExtensions = searchedDEs;
        } else {
          filteredExtensions = dataExtensions;
        }
      } else {
        if (dataSets?.length) {
          /*
           * if there are selected DEs and data sets are not empty
           * find those data sets where the selected DEs are parent DEs
           */
          if (selectedDataExtensions?.length) {
            selectedDataExtensions.forEach((de) => {
              dataSets.forEach((dataSet) => {
                // parent DE is the first DE in the selected DEs data set array
                if (dataSet.selectedDataExtensions[0].ObjectID === de.ObjectID &&
                  dataSet.name?.toString().toLowerCase().includes(dataExtensionSearchField?.toString().toLowerCase())) {
                  filterDataSetsArray.push(dataSet);
                }
              });
            });
          }
        }
      }
    }

    /**
     * General function to display certain case of the data sets
     * @returns {object} HTML for the Data Sets tab
     */
    const displayDataSets = () => {
      if (loadingAllAvailableDataExtensions) {
        return (
          <div className="available-extension-v2_loader">
            <div className="demo-only">
              <Spinner size={Constants.SPINNER__SIZE__MEDIUM} assistiveText="Loading" />
            </div>
          </div>);
      }

      if (dataSets?.length === 0) {
        return <span className="data-sets-message">
          You do not have any predefined Data Sets. You can set them up from the admin panel.
               </span>;
      }

      if (filteredDataSets.length === 0 && dataExtensionSearchField) {
        return null;
      }

      return filteredDataSets?.map((dataSet, i) => (
        <AvailableDECard
          // eslint-disable-next-line react/no-array-index-key
          key={i}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...dataSet}
          useNewDesign={useNewDesign}
          Name={dataSet.name.toString()}
          DEBorderMouseOver={DEBorderMouseOver}
          filterBorderMouseOver={filterBorderMouseOver}
          handleSetSelectionState={handleSetSelectionState}
          customValues={customValues}
          draggingDataSet
          dataSets={dataSets}
        />
      ));
    };

    /**
     * General function to display the data views
     * @returns {object} HTML for the Data Views tab
     */
    const displayDataViews = () => {
      if (filteredDataViews.length === 0 && dataExtensionSearchField) {
        return null;
      }

      return filteredDataViews?.map((dataView, i) => (
        <AvailableDECard
          // eslint-disable-next-line react/no-array-index-key
          key={i}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...dataView}
          useNewDesign={useNewDesign}
          Name={dataView.Name.toString()}
          DEBorderMouseOver={DEBorderMouseOver}
          filterBorderMouseOver={filterBorderMouseOver}
          handleSetSelectionState={handleSetSelectionState}
        />
      ));
    };

    if (!isAvailableDEsTabSelected && showDataSets) {
      // filter all data sets based on the search input
      filteredDataSets = dataSets?.filter(dataSet => dataSet.name.toString().toLowerCase().includes(
        dataExtensionSearchField?.toString().toLowerCase(),
      ));

      // remove duplicate
      filteredDataSets = Util.removeDuplicatesFromArray(filteredDataSets);
      // and sort them by name
      filteredDataSets = Util.sortArrayOfObjects(filteredDataSets, 'name');

      if (filterDataSetsArray?.length) {
        // get the data sets that don't have any of the selected DEs as the parent DEs
        let disabledDataSets = filteredDataSets.reduce((arr, currentValue) => {
          const found = filterDataSetsArray.find(dS => dS.id === currentValue.id);

          if (!found) {
            const disableDataSet = { ...currentValue, disabled: true };

            arr.push(disableDataSet);
          }

          return arr;
        }, []);

        // remove duplicate
        filterDataSetsArray = Util.removeDuplicatesFromArray(filterDataSetsArray);
        // and sort them by name
        filterDataSetsArray = Util.sortArrayOfObjects(filterDataSetsArray, 'name');

        // remove duplicate
        disabledDataSets = Util.removeDuplicatesFromArray(disabledDataSets);
        // and sort them by name
        disabledDataSets = Util.sortArrayOfObjects(disabledDataSets, 'name');

        /*
         * create a new array of the data sets with the data sets that can be dragged on the top
         * and with the data sets that cannot be dragged on the bottom of the array
         */
        filteredDataSets = [...filterDataSetsArray, ...disabledDataSets];
      } else if (selectedDataExtensions?.length) {
        filteredDataSets = filteredDataSets.map((dataSet) => {
          return { ...dataSet, disabled: true };
        });
      }
    }

    if (showDataViews) {
      filteredDataViews = this.getFilteredDataViews(dataExtensionSearchField);
    }

    const displaySearchPlaceholder = () => {
      if (isAvailableDEsTabSelected) {
        return 'Search Data Extensions';
      }

      if (showDataSets) {
        return 'Search Data Sets';
      }

      return 'Search Data Views';
    };

    return (
      <div className={classNames(
        'available-extension-v2',
        { 'hide-available-extension-v2': isFilterBoxExpanded && !showFiltersImmediately },
        { 'hide-available-extension-v2-immediately': showFiltersImmediately },
      )}>
        <div className={classNames(
          'stickyAE',
          { 'stickyAE-disappear-animation': isFilterBoxExpanded && !showFiltersImmediately },
          { 'stickyAE-appear-animation': !isFilterBoxExpanded && isFilterBoxExpandedForFirstTime },
          { 'stickyAE-disappear-animation-immediately': showFiltersImmediately },
        )}>
          {/* TODO: use the flag from backend instead of dataExtensions.length */}
          {/* {
            dataExtensions && dataExtensions.length > 20 && !searchMode && (
              <p className="section-notification">
                Loaded 20 data extensions. Use the search bar to find more.
              </p>
            )
          } */}
          <div className="button-group-tde">
              <ButtonGroup id="button-group-more-icon" className="selected-data-extensions-button-group">
                <Button
                  onClick={() => {
                    this.setState({
                      isAvailableDEsTabSelected: true,
                      showAvailableFields: true,
                      showDataSets: false,
                      showDataViews: false,
                    });
                  }}
                  className="button-group-tde-text de-button"
                  variant={showAvailableFields ? 'brand' : 'neutral'}
                  label="Data Extensions"
                />

                <Button
                  onClick={() => {
                    this.setState({
                      isAvailableDEsTabSelected: false,
                      showAvailableFields: false,
                      showDataSets: false,
                      showDataViews: true,
                    });
                  }}
                  className="button-group-tde-text dv-button"
                  variant={showDataViews ? 'brand' : 'neutral'}
                  label="Data Views"
                  disabled={!featureDataViews}
                  tooltip={featureDataViews ?
                    null :
                    {
                      type: Constants.TOOLTIP_TYPE__UNAVAILABLE_FEATURE,
                      align: Constants.SLDS_TOOLTIP_POSITION__BOTTOM_RIGHT,
                      id: 'dataview-tab-tooltip',
                    }}
                />

                <Button
                  onClick={() => {
                    this.setState({
                      isAvailableDEsTabSelected: false,
                      showAvailableFields: false,
                      showDataSets: true,
                      showDataViews: false,
                    });
                  }}
                  className="button-group-tde-text data-set-button"
                  variant={showDataSets ? 'brand' : 'neutral'}
                  label="Data Sets"
                  disabled={!featureDataSets}
                  tooltip={featureDataSets ?
                    null :
                    {
                      type: Constants.TOOLTIP_TYPE__UNAVAILABLE_FEATURE,
                      align: Constants.SLDS_TOOLTIP_POSITION__BOTTOM_RIGHT,
                      id: 'datasets-tab-tooltip',
                    }}
                  />
              </ButtonGroup>
          </div>

          <h4 className="section-title">
            {this.displaySearchText(filteredExtensions?.length, filteredDataSets?.length)}
            <span />
            <GuidanceTip tipId="available-data-sources-tip" toolTipPosition="right" />
          </h4>
          <div className="main-search-container">
          <Input
            iconLeft={
              <InputIcon
                assistiveText={{
                  icon: 'Search',
                }}
                name="search"
                category="utility"
              />
            }
            className="available-extension-v2_search des-search-position-v2"
            style={{ display: 'none' }}
            placeholder={displaySearchPlaceholder()}
            onChange={e => this.handleFilterDataExtensionSearchField(e)}
            value={selectedDE.Name || dataExtensionSearchField}
            id="unique-id-1"
          />
          {isAvailableDEsTabSelected && (
              <span
              title="Filter Data Extensions by Folders"
              className={classNames(
                'filter-icon-v2',
                loadingForDataExtensions || !isAvailableDEsTabSelected ?
                  'disabled-available-folders-filter-icon-v2' :
                  'cursor-pointer',
              )}
              onClick={() => loadingForDataExtensions ?
                null :
                this.setState({ showAvailableDEsFoldersModal: true })}
                >
                <svg
                  className={`slds-icon slds-icon-text-default slds-icon_small ${loadingForDataExtensions ?
                    'icon-disabled' :
                    ''}`}
                  aria-hidden="true"
                  title="Find a Data Extension by browsing the folder structure"
                  >
                  <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#open_folder" />
                </svg>
              </span>
          )}
          </div>
          {/* bottom section */}

          <div className="des-make-space-for-scroll">

            <div
              id="available-collections"
              className="available-extension-v2_available_collections_new"
              style={{ pointerEvents: filterBorderMouseOver ? 'none' : '' }}
              onDragOver={e => e.preventDefault()}
              onDrop={() => handleSetSelectionState({ setFilterBorderMouseOver: false, setDEBorderMouseOver: false })}
            >
              {(isAvailableDEsTabSelected && filteredExtensions.length > 0 &&
                !loadingForDataExtensions && !isLoadingSearch ||
                isAvailableDEsTabSelected && searchedDEs.length > 0 && loadingForDataExtensions) ?
                (
                  filteredExtensions.map((collection, i) => (
                    <AvailableDECard
                      // eslint-disable-next-line react/no-array-index-key
                      key={i}
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...collection}
                      useNewDesign={useNewDesign}
                      CustomerKey={collection.CustomerKey.toString()}
                      Name={collection.Name.toString()}
                      DEBorderMouseOver={DEBorderMouseOver}
                      filterBorderMouseOver={filterBorderMouseOver}
                      handleSetSelectionState={handleSetSelectionState}
                      customValues={customValues}
                    />
                  ))
                ) :
                null}

              {showDataSets ? displayDataSets() : null}
              {showDataViews ? displayDataViews() : null}

              {isAvailableDEsTabSelected && ((filteredExtensions?.length === 0 && !dataExtensionSearchField) ||
                loadingForDataExtensions && !dataExtensionSearchField || isLoadingSearch) ?
                (
                  <div className="available-extension-v2_loader">
                    <div className="demo-only">
                      <Spinner size={Constants.SPINNER__SIZE__MEDIUM} assistiveText="Loading" />
                    </div>
                  </div>
                ) :
                null}
            </div>
          </div>
        </div>
        <div>
          {showAvailableDEsFoldersModal && (
            <NewAutoCreatedTargetDE
              handleSetSelectionState={handleSetSelectionState}
              isAvailableDEsFoldersModal
              openAvailableDEsForCertainFolderModal={this.openAvailableDEsForCertainFolderModal}
              handleSetAvailableExtensionsState={this.handleSetAvailableExtensionsState}
              availableDEFolderId={availableDEsFolderId}
              availableDEsFolders={availableDEsFolders}
              foldersSettings={foldersSettings}
            />
          )}
          {showDEsForFolder && (
            <FolderDataExtensions
              dataExtensions={availableDEsForFolder}
              handleSetAvailableExtensionsState={this.handleSetAvailableExtensionsState}
              availableDEsFolderId={availableDEsFolderId}
              handleSetSelectionState={handleSetSelectionState}
              foldersPath={foldersPath}
            />
          )}
        </div>
      </div>
    );
  }
}

AvailableDataExtensions.propTypes = {
  /**
   * It keeps the data extensions after they are retrieved from SFMC
   * if dataViews feature is enabled, it will also contain dataViews as well
   */
  dataExtensions: PropTypes.instanceOf(Object).isRequired,
  /**
   * It helps to delete a selected data extension
   * it will be passed from Selection.js
   */
  handleDeleteSelectedDE: PropTypes.func.isRequired,
  /**
   * Keeps track whether Available DE are dragged
   */
  DEBorderMouseOver: PropTypes.bool.isRequired,
  /**
   * Keeps track whether Available Fields are dragged
   */
  filterBorderMouseOver: PropTypes.bool.isRequired,
  /**
   * Edit state of selection
   */
  handleSetSelectionState: PropTypes.func.isRequired,
  /**
   * It keeps custom values data
   * It will be passed from Selection.js
   */
  customValues: PropTypes.instanceOf(Array).isRequired,
  /**
   * Responsible for the loading status of data extensions
   */
  loadingForDataExtensions: PropTypes.bool.isRequired,
  /**
   * Responsible for the loading status of all available data extensions
   */
  loadingAllAvailableDataExtensions: PropTypes.bool.isRequired,
  /**
   * Selected id of the available folder
   */
  availableDEsFolderId: PropTypes.number,
  /**
   * Stored availableDEs folders in selection state (Stored in order to prevent making unnecessary api calls)
   */
  availableDEsFolders: PropTypes.instanceOf(Array),
  /**
   * Keeps folder filtering information
   */
  foldersSettings: PropTypes.instanceOf(Object),
  /**
   * Array of the data sets created in the Admin panel
   */
  dataSets: PropTypes.instanceOf(Array).isRequired,
  /**
   * Selected Data Extension in selection
   */
  selectedDataExtensions: PropTypes.instanceOf(Array).isRequired,
  /**
   * Determines if the feature flag for the data sets is true or false
   */
  featureDataSets: PropTypes.bool,
  /**
   * It toggles a feature advert modal on with specific feature
   */
  showEssentialsUpgradeModal: PropTypes.func.isRequired,
  /**
   * Update data extensions with search object
   */
  updateDataExtensionsObject: PropTypes.func,
  /**
   * User info from cookie
   */
  userInfo: PropTypes.object,
  /**
   * Features info from cookie
   */
  featuresInfo: PropTypes.object,
};

export default connect(mapStateToProps(['userInfo', 'featuresInfo']), null, null, { pure: false })(AvailableDataExtensions);
