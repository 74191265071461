import React, { useState } from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

import Constants from '../../../constants/constants';
import Button from '../Button/Button';
import timeUtil from '../../../utils/time/timeUtil';

const Tooltip = ({
  type, nubbinPosition, customClassName, tooltipText,
}) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const getStyle = () => {
    switch (nubbinPosition) {
      case Constants.NUBBIN_POSITION__TOP_LEFT:
        return 'slds-nubbin_top-left wp-break-spaces';
      case Constants.NUBBIN_POSITION__TOP_RIGHT:
        return 'slds-nubbin_top-right wp-break-spaces';
      case Constants.NUBBIN_POSITION__BOTTOM_RIGHT:
        return 'slds-nubbin_bottom-right wp-break-spaces';
      case Constants.NUBBIN_POSITION__BOTTOM_LEFT:
        return 'slds-nubbin_bottom-left wp-break-spaces';
      default:
        return null;
    }
  };

  const getText = () => {
    switch (type) {
      case Constants.TOOLTIP_TYPE__UNAVAILABLE_FEATURE:
        return (
          <>
            Please&nbsp;
            <a
              href="https://deselect.com/contact"
              target="_blank"
              rel="noopener noreferrer"
              tabIndex="0"
              className="disabled-feature-link"
            >
              contact DESelect
            </a>
            &nbsp;for more information about using this feature.
          </>
        );
      case Constants.TOOLTIP_TYPE__RELATION_MODAL:
        return `Fields not compatible with the field selected on the left are
            disabled in this dropdown.`;
      case Constants.TOOLTIP_TYPE__DISABLED_AUTO_REFRESH:
        return 'Picklist auto refresh is disabled because server-to-server authentication is not set up.';
      case Constants.TOOLTIP_TYPE__UTC:
        return `Time set here is in UTC. You are currently in UTC ${timeUtil.formatUserUTC()}.`;
      case Constants.TOOLTIP_TYPE__DELETE_SELECTION:
        return `When you Run a selection, DESelect creates one or more query activities
            and writes the results to your target data extension.
            You can delete these items as well if you are not using them in an automation or journey.`;
      case Constants.TOOLTIP_TYPE__RECOMMENDED_FILTER_MODE:
        return 'It is recommended to use Include as this results in faster loading of the data extensions.';
      case Constants.TOOLTIP_TYPE__RELATIVE_DATE_FILTER_START_NOW:
        return 'Takes into account the current date and time and the date and time of the field.';
      case Constants.TOOLTIP_TYPE__RELATIVE_DATE_FILTER_START_TODAY:
        return 'Takes into account the current date (not the time) and the date of the field (not the time).';
      case Constants.TOOLTIP_TYPE__TEMPLATE_SELECTION:
        return `When a selection is marked as a template, only the person who created
            it or admins can modify it.`;
      case Constants.TOOLTIP_TYPE__CONVERT_FROM_TIMEZONE:
        return 'SFMC servers use the Central Standard Time (CST) Timezone.';
      case Constants.FEATURE__DATA_SETS:
        return 'As an admin, you can create data sets by defining relations between multiple data extensions.';
      case Constants.FEATURE__FILTER_SETS:
        return 'As an admin, you can create filter sets by predefining a combination of filters.';
      case Constants.TOOLTIP_TYPE__PREDEFINED_DATA_EXTENSIONS:
        return 'Only data extensions for which predefined relations are defined are available here';
      case Constants.TOOLTIP_TYPE__S2S_PACKAGE_NOT_INSTALLED:
        return Constants.S2S_NOT_INSTALLED;
      case Constants.TOOLTIP_TYPE__UPDATE_S2S_PACKAGE:
        return <span dangerouslySetInnerHTML={{ __html: Constants.UPDATE_S2S_PACKAGE }} />;
      case Constants.TOOLTIP_TYPE__PREDEFINED_FILTER_SET_SOURCE:
        return 'You can choose to create a Filter Set using any available Data Set or an individual Data Extension.';
      case Constants.TOOLTIP_TYPE__TEMPORARY_DATA_EXTENSION_RETENTION_POLICY:
        return 'DESelect uses temporary data extensions when running previews. ' +
        'You can set a retention policy for these Temporary Data Extensions created by DESelect.';
      case Constants.TOOLTIP_TYPE__NEW_DATA_EXTENSION_RETENTION_POLICY:
        return 'You can set a retention policy for the New Data Extensions created by you.';
      case Constants.TOOLTIP_TYPE__GLOBAL_CUSTOM_VALUE__INFO:
        return 'Please note that only the Fixed Value and Timestamp Custom Values will be visible inside ' +
        'all Selections. All other Custom Values will only be shown when the corresponding Data Extension is added ' +
        'in the selection.';
      case Constants.FEATURE__GLOBAL_CUSTOM_VALUES:
        return 'As an admin, you can create predefined custom values from the Admin Panel.';
      case Constants.TOOLTIP_TYPE__CHANGE_LOCATION_TO_SHARED_FOLDER:
        return 'Changing the type of a Data Extension from a Child Business Unit is currently not supported.';
      default:
        return tooltipText;
    }
  };

  return (
    <div
      className="tooltip-container"
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
    >
      <Button
        buttonIcon
        aria-describedby="help"
        aria-disabled="true"
      >
        <svg className="slds-button__icon" aria-hidden="true">
          <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#info" />
        </svg>
        <span className="slds-assistive-text">Help</span>
      </Button>
      {showTooltip && (
        <div
          className={`slds-popover slds-popover_tooltip ${getStyle()} ${customClassName}`}
          role="tooltip"
          id="tooltip-content"
        >
          <div className="slds-popover__body">

            {getText()}
          </div>
        </div>
      )}
    </div>
  );
};

Tooltip.propTypes = {
  /**
   * Determines the position of the nubbin and
   * the orientation of the tooltip container
   */
  nubbinPosition: PropTypes.string.isRequired,
  /**
   * Determines the type of the tooltip
   */
  type: PropTypes.string,
  /**
   * Determines the custom className to make Tooltip better positioned
   */
  customClassName: PropTypes.string,
  /**
   * The text to show if the tooltip type is not passed
   */
  tooltipText: PropTypes.oneOfType([
    PropTypes.instanceOf(Object),
    PropTypes.string,
  ]),
};

export default Tooltip;
