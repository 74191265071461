import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react';
import _ from 'lodash';
import pickDeep from 'deepdash/pickDeep';
import classNames from 'classnames';
import './styles.scss';
import { connect } from 'react-redux';

import mapStateToProps from '../../../../mapStateToProps';
import AvailableExtensions from '../AvailableExtensions/AvailableExtensions';
import SelectedExtensions from '../SelectedExtensions/SelectedExtensions';
import Filters from '../../Filters/Filters';
import RelationModal from '../RelationalModal/RelationModal';
import Constants from '../../../../constants/constants';
import Util from '../../../../util';
import Features from '../../../../features';
import RadioButton from '../../../shared/RadioButton/RadioButton';
import WarningAlert from '../../../shared/WarningAlert/WarningAlert';
import Button from '../../../shared/Button/Button';

/*
 * we tried PureComponent and the issue we got is
 * alias of a selectedDataExtension isn't change until triggered again
 */
class DataExtensions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectionSourceType: Constants.BASIC_MODE_SOURCE_TYPE__DATA_SETS,
      basicModeSelectionSourceDataSet: null,
      basicModeSelectionSourceDE: null,
    };
    this.filtersRef = React.createRef();
  }

  componentDidMount() {
    const {
      selectedDataExtensions,
      selectedDataSet,
      selectionMode,
      basicModeSelectionSourceType,
    } = this.props;

    if(basicModeSelectionSourceType) {
      this.setState({ selectionSourceType: basicModeSelectionSourceType });
    }

    if(selectionMode === Constants.SELECTION_MODE__BASIC &&
      basicModeSelectionSourceType === Constants.BASIC_MODE_SOURCE_TYPE__DATA_EXTENSIONS &&
      selectedDataExtensions?.[0]?.ObjectID) {
      this.setState({ basicModeSelectionSourceDE: selectedDataExtensions[0].ObjectID });
    }

    if(selectionMode === Constants.SELECTION_MODE__BASIC &&
      basicModeSelectionSourceType === Constants.BASIC_MODE_SOURCE_TYPE__DATA_SETS &&
      selectedDataSet?.id) {
      this.setState({ basicModeSelectionSourceDataSet: selectedDataSet.id });
    }
  }

  componentDidUpdate() {
    const {
      handleSetSelectionState,
      selectedDataSet,
      dataSets,
      selectedDataExtensions,
      selectedFilters,
      matchedFields,
      relations,
      selectionMode,
      basicModeSelectionSourceType,
      switchFromAdvancedToBasic,
    } = this.props;

    if (selectedDataSet && !selectedDataSet.isCustomDataSet &&
      dataSets?.length && selectionMode === Constants.SELECTION_MODE__BASIC) {
      // Check if data extensions in datasets are used for selection filters
      const isDEUsedInFilters = selectedDataExtensions?.map(
        de => de.ObjectID,
      )?.some(deId => selectedFilters?.filters?.some(filter => filter.deId === deId));

      // Check if data extensions in datasets are used for target de matching fields
      const isDEUsedInMatchedFields = selectedDataExtensions?.map(
        de => de.CustomerKey,
      )
        ?.some(deCustomerKey => matchedFields?.some(
          matchedField => matchedField.availableFieldDataExtensionCustomerKey === deCustomerKey,
        ));

      let updatedSelectedDataSet = dataSets.find(ds => ds.id === selectedDataSet.id);
      const comparisonFields = [
        'name',
        'CustomerKey',
        'deAlias',
        'CreatedDate',
        'ModifiedDate',
      ];
      const isDataSetUpdated = !_.isEqual(
        pickDeep(
          { ...updatedSelectedDataSet },
          comparisonFields,
        ),
        pickDeep(
          { ...selectedDataSet },
          comparisonFields,
        ),
      );

      if (updatedSelectedDataSet && isDataSetUpdated) {
        if (!switchFromAdvancedToBasic && (isDEUsedInFilters || isDEUsedInMatchedFields)) {
          const customDataSet = {
            ...selectedDataSet, isCustomDataSet: true, name: 'Custom Data Set', id: Util.uuid(),
          };

          handleSetSelectionState({ selectedDataSet: customDataSet });
        } else {
          /**
           * Update relations if data extensions in datasets are used for relations is
           * removed from dataset
           */
          const deToBeRemoved = Util.getObjectDifference(
            selectedDataExtensions,
            updatedSelectedDataSet.selectedDataExtensions,
          );

          const updatedRelations = relations.filter(relation => !deToBeRemoved
            .some(de => de.CustomerKey === relation
              .fromCollection.CustomerKey ||
              de.CustomerKey === relation.toCollection.CustomerKey));

          handleSetSelectionState({
            selectedDataSet: updatedSelectedDataSet,
            selectedDataExtensions: updatedSelectedDataSet.selectedDataExtensions,
            relations: deToBeRemoved ? updatedRelations : relations,
          });
        }
      } else if (selectedDataSet?.selectedDataExtensions?.length && !updatedSelectedDataSet &&
        basicModeSelectionSourceType === Constants.BASIC_MODE_SOURCE_TYPE__DATA_SETS) {
        /*
         * This means dataSet couldn't be found in the list of dataSets (it's been deleted),
         * therefore set selectedDataSet as Custom Data Set
         */
        updatedSelectedDataSet = {
          ...selectedDataSet,
          name: 'Custom Data Set',
          isCustomDataSet: true,
          id: Util.uuid(),
        };

        handleSetSelectionState({ selectedDataSet: updatedSelectedDataSet });
      }
    }
  }

  /**
   * Event handler for when form elements' values are changed
   * @param {object} data - onChange event object
   * @returns {void}
   */
  handleBasicModeSelectionDropdownChange = (data) => {
    const { selectionSourceType } = this.state;

    if (selectionSourceType === Constants.BASIC_MODE_SOURCE_TYPE__DATA_SETS) {
      this.setState({ basicModeSelectionSourceDataSet: data?.value });
    } else {
      this.setState({ basicModeSelectionSourceDE: data?.value });
    }
  };

  /**
   * Handle select basic mode selection source
   * @returns {void}
   */
  handleSelectBasicModeSource = () => {
    const { handleSetSelectedDataSet, handleSetSelectedDE } = this.props;
    const { selectionSourceType, basicModeSelectionSourceDE, basicModeSelectionSourceDataSet } = this.state;

    if (selectionSourceType === Constants.BASIC_MODE_SOURCE_TYPE__DATA_SETS) {
      handleSetSelectedDataSet({ value: basicModeSelectionSourceDataSet });
    } else {
      handleSetSelectedDE({ value: basicModeSelectionSourceDE });
    }
  };

  /**
   * Event handler for when form elements' values are changed
   * @param {object} e - onChange event object
   * @returns {void}
   */
  setSelectionSourceType = (e) => {
    const { value } = e.target;
    const { handleSetSelectionState } = this.props;

    handleSetSelectionState({ basicModeSelectionSourceType: value });
    this.setState({ selectionSourceType: value });
  };

  render() {
    const {
      dataExtensions,
      handleDeleteSelectedDE,
      selectedDataExtensions,
      showRelationalModal,
      checkMissingFieldsInRelations,
      fromFieldMissing,
      toFieldMissing,
      modalDataExtensions,
      handleFiltersSave,
      selectedFilters,
      matchedFields,
      handleSetSelectionState,
      relations,
      getDataExtensionOrDataViewFields,
      handleFeatureMissing,
      manageSubscriberRelationship,
      DEBorderMouseOver,
      filterBorderMouseOver,
      loaderSelectedDE,
      singleDEStyling,
      handlePickListOptions,
      pickLists,
      loadingSubQueryFields,
      handleRemoveFilterLine,
      unionSelections,
      unionSelectionsIndex,
      handleSetAppState,
      customValues,
      loadingForDataExtensions,
      loadingAllAvailableDataExtensions,
      predefinedRelations,
      predefinedRelationsMap,
      subQueryDataExtensions,
      returnPredefinedRelationById,
      availableDEsFolderId,
      availableDEsFolders,
      foldersSettings,
      movingDE,
      prevSelectedDEs,
      prevRelations,
      selectedDEsTree,
      applyTimezoneSettingsToAllDateFields,
      handleSetTimezoneToAllDateFields,
      timezoneSettingsForAllDateFields,
      dataSets,
      filterSets,
      featureDataSets,
      featureFilterSets,
      parentDEOfDataSet,
      isArchived,
      selectionMode,
      handleClearBasicModeSource,
      selectedDataSet,
      selectedDERef,
      showEssentialsUpgradeModal,
      revertBehavioralFilterSet,
      updateDataExtensionsObject,
      dataExtensionSearchField,
      onDataExtensionSearchFieldChange,
      featuresInfo,
    } = this.props;
    const {
      selectionSourceType,
      basicModeSelectionSourceDataSet,
      basicModeSelectionSourceDE,
    } = this.state;

    const featureBasicMode = Features.isFeatureEnabled(featuresInfo, Constants.SELECTION_MODE__BASIC);

    const dataSetListToRender = [
      ...(dataSets || []),
      ...(selectedDataSet?.isCustomDataSet ? [selectedDataSet] : []),
    ];

    /*
     * Format the data extensions for the Data Sets dropdown
     * Dataviews are filtered out because it doesn't make sense for them to be in this dropdown
     */
    const formattedDataSets = Util.removeDuplicatesFromArray(dataSetListToRender)
      .map(ds => ({
        value: ds.id,
        title: ds.name,
        text: ds.name,
        key: ds.id,
      }));

    /*
     * Format the data extensions for the DE dropdown
     * Dataviews are filtered out because it doesn't make sense for them to be in this dropdown
     */
    const formattedDataExtensions = dataExtensions.map((ds, i) => ({
      value: ds.ObjectID,
      text: ds.Name && typeof ds.Name === 'string' ? ds.Name : '',
      key: 'dropdownDataExtensions-' + i,
    }));

    const basicModeSourceOptions = selectionSourceType === Constants.BASIC_MODE_SOURCE_TYPE__DATA_SETS ?
      formattedDataSets :
      formattedDataExtensions;

    const basicModeSelectedSourceValue = selectionSourceType === Constants.BASIC_MODE_SOURCE_TYPE__DATA_SETS ?
      (selectedDataSet?.id || basicModeSelectionSourceDataSet) :
      (selectedDataExtensions?.[0]?.ObjectID || basicModeSelectionSourceDE);

    // Boolean that helps to know if edit is allowed or not
    const editIsDisabled = basicModeSelectedSourceValue && (selectedDataExtensions?.length ||
      loadingForDataExtensions) || loadingForDataExtensions || loaderSelectedDE;

    return (
      <div className={`${singleDEStyling ? 'data-extension-single' : 'data-extension'}`} id="selection-body">

        {isArchived && <WarningAlert text={Constants.WARNING_TEXT__ARCHIVED_SELECTION_READ_ONLY} />}

        {selectionMode === Constants.SELECTION_MODE__BASIC && (
          <div className="data-sets-dropdown-container">
            <div className="slds-show_inline-block">
              <div className="selectionSourceTypeRadioButton">
                <RadioButton
                  className="slds-show_inline-block"
                  id={Constants.BASIC_MODE_SOURCE_TYPE__DATA_EXTENSIONS}
                  name={Constants.BASIC_MODE_SOURCE_TYPE__DATA_EXTENSIONS}
                  label="Data Extension"
                  value={Constants.BASIC_MODE_SOURCE_TYPE__DATA_EXTENSIONS}
                  disabled={editIsDisabled}
                  onChange={this.setSelectionSourceType}
                  checked={selectionSourceType === Constants.BASIC_MODE_SOURCE_TYPE__DATA_EXTENSIONS}
              />
              </div>
              <div className="selectionSourceTypeRadioButton">
                <RadioButton
                    id={Constants.BASIC_MODE_SOURCE_TYPE__DATA_SETS}
                    name={Constants.BASIC_MODE_SOURCE_TYPE__DATA_SETS}
                    label="Data Set"
                    value={Constants.BASIC_MODE_SOURCE_TYPE__DATA_SETS}
                    disabled={editIsDisabled}
                    onChange={this.setSelectionSourceType}
                    checked={selectionSourceType === Constants.BASIC_MODE_SOURCE_TYPE__DATA_SETS}
                  />
              </div>
            </div>

            <div className="display-flex">
              <Dropdown
                id="basic-mode-dropdown"
                selection
                className="target-data-extension-dropdown searchable-dropdown"
                search
                placeholder={loadingForDataExtensions ? 'loading ...' : `Choose ${selectionSourceType}`}
                value={basicModeSelectedSourceValue}
                options={basicModeSourceOptions}
                onChange={(e, data) => this.handleBasicModeSelectionDropdownChange(data)}
                disabled={editIsDisabled}
              />
               {basicModeSelectedSourceValue && selectedDataExtensions.length > 0 ?
                 (
                  <Button
                    id="clear-selected-basic-mode-btn"
                    type="button"
                    className="slds-button slds-button_neutral ml-4px"
                    onClick={() => handleClearBasicModeSource(selectionSourceType)}
                  >
                    Clear
                  </Button>
                 ) :
                <Button
                id="select-basic-mode-btn"
                type="button"
                className="slds-button slds-button_neutral ml-4px"
                onClick={this.handleSelectBasicModeSource}
                disabled={!basicModeSelectedSourceValue || loadingForDataExtensions || loaderSelectedDE}
               >
                {loadingForDataExtensions || loaderSelectedDE ?
                  (
                  <svg
                  aria-hidden="true"
                  className="slds-button__icon fa-spin"
                >
                  <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#refresh" />
                  </svg>
                  ) :
                  'Select'}
                </Button>}
            </div>

          </div>
        )}
          <div className={classNames(
            'data-extension_wrapper',
            // hide but do not remove by if condition these components since we use some of their functions
            {
              'display-none': featureBasicMode && selectionMode !== Constants.SELECTION_MODE__ADVANCED,
            },
          )}>
            <RelationModal
              showRelationalModal={showRelationalModal}
              checkMissingFieldsInRelations={checkMissingFieldsInRelations}
              fromFieldMissing={fromFieldMissing}
              toFieldMissing={toFieldMissing}
              modalDataExtensions={modalDataExtensions}
              relations={relations}
              handleSetSelectionState={handleSetSelectionState}
              selectedDataExtensions={selectedDataExtensions}
              handlePickListOptions={handlePickListOptions}
              predefinedRelationsMap={predefinedRelationsMap}
              predefinedRelations={predefinedRelations}
              movingDE={movingDE}
              prevSelectedDEs={prevSelectedDEs}
              prevRelations={prevRelations}
              selectedDataSet={selectedDataSet}
              selectionMode={selectionMode}
            />
            <AvailableExtensions
              dataExtensions={dataExtensions}
              updateDataExtensionsObject={updateDataExtensionsObject}
              onDataExtensionSearchFieldChange={onDataExtensionSearchFieldChange}
              dataExtensionSearchField={dataExtensionSearchField}
              handleDeleteSelectedDE={handleDeleteSelectedDE}
              handleSetSelectionState={handleSetSelectionState}
              DEBorderMouseOver={DEBorderMouseOver}
              filterBorderMouseOver={filterBorderMouseOver}
              customValues={customValues}
              loadingForDataExtensions={loadingForDataExtensions}
              loadingAllAvailableDataExtensions={loadingAllAvailableDataExtensions}
              availableDEsFolderId={availableDEsFolderId}
              availableDEsFolders={availableDEsFolders}
              foldersSettings={foldersSettings}
              handleSetDataExtensionsState={this.handleSetDataExtensionsState}
              dataSets={dataSets}
              selectedDataExtensions={selectedDataExtensions}
              featureDataSets={featureDataSets}
              showEssentialsUpgradeModal={showEssentialsUpgradeModal}
            />
            <SelectedExtensions
              selectedDataExtensions={selectedDataExtensions}
              handleDeleteSelectedDE={handleDeleteSelectedDE}
              matchedFields={matchedFields}
              filtersRef={this.filtersRef}
              dataExtensions={dataExtensions}
              modalDataExtensions={modalDataExtensions}
              relations={relations}
              getDataExtensionOrDataViewFields={getDataExtensionOrDataViewFields}
              handleSetSelectionState={handleSetSelectionState}
              DEBorderMouseOver={DEBorderMouseOver}
              filterBorderMouseOver={filterBorderMouseOver}
              loaderSelectedDE={loaderSelectedDE}
              customValues={customValues}
              predefinedRelations={predefinedRelations}
              predefinedRelationsMap={predefinedRelationsMap}
              selectedDEsTree={selectedDEsTree}
              dataSets={dataSets}
              parentDEOfDataSet={parentDEOfDataSet}
              handlePickListOptions={handlePickListOptions}
              handleSetAppState={handleSetAppState}
              unionSelections={unionSelections}
              selectedDERef={selectedDERef}
              filterSets={filterSets}
            />
          </div>

        <Filters
          hideCollectionAlias={false}
          showInResultsOption
          dataExtensions={dataExtensions}
          selectedDataExtensions={selectedDataExtensions}
          selectedFilters={selectedFilters}
          filtersRef={this.filtersRef}
          handleFiltersSave={handleFiltersSave}
          getDataExtensionOrDataViewFields={getDataExtensionOrDataViewFields}
          handleFeatureMissing={handleFeatureMissing}
          manageSubscriberRelationship={manageSubscriberRelationship}
          handleSetSelectionState={handleSetSelectionState}
          DEBorderMouseOver={DEBorderMouseOver}
          filterBorderMouseOver={filterBorderMouseOver}
          handlePickListOptions={handlePickListOptions}
          pickLists={pickLists}
          dataSets={dataSets}
          filterSets={filterSets}
          isFromSelection
          showFilterSets={featureFilterSets}
          loadingForDataExtensions={loadingForDataExtensions}
          loadingSubQueryFields={loadingSubQueryFields}
          handleRemoveFilterLine={handleRemoveFilterLine}
          unionSelections={unionSelections}
          unionSelectionsIndex={unionSelectionsIndex}
          handleSetAppState={handleSetAppState}
          inSelectionCriteria
          predefinedRelations={predefinedRelations}
          subQueryDataExtensions={subQueryDataExtensions}
          returnPredefinedRelationById={returnPredefinedRelationById}
          applyTimezoneSettingsToAllDateFields={applyTimezoneSettingsToAllDateFields}
          timezoneSettingsForAllDateFields={timezoneSettingsForAllDateFields}
          handleSetTimezoneToAllDateFields={handleSetTimezoneToAllDateFields}
          isRegularFilter
          isBasicMode={selectionMode === Constants.SELECTION_MODE__BASIC}
          showEssentialsUpgradeModal={showEssentialsUpgradeModal}
          revertBehavioralFilterSet={revertBehavioralFilterSet}
        />
      </div>
    );
  }
}

DataExtensions.propTypes = {
  /**
   * It keeps the data extensions after they are retrieved from SFMC
   * if dataViews feature is enabled, it will also contain dataViews as well
   */
  dataExtensions: PropTypes.instanceOf(Object).isRequired,
  /**
   * It helps to delete a selected data extension
   * it will be passed from Selection.js
   */
  handleDeleteSelectedDE: PropTypes.func.isRequired,
  /**
   * It keeps the selected data extensions for Selection.js
   * selected data extensions are stored as collections in database
   * It will be passed from Selection.js
   */
  selectedDataExtensions: PropTypes.instanceOf(Array).isRequired,
  /**
   * It determines if the relationalModal will be shown or not
   */
  showRelationalModal: PropTypes.bool.isRequired,
  /**
   * It keeps the data extensions which will be shown in the Relation Modal
   */
  modalDataExtensions: PropTypes.instanceOf(Object).isRequired,
  /**
   * It helps to save the selected filters for the selection
   * it will be passed from Selection.js
   */
  handleFiltersSave: PropTypes.func.isRequired,
  /**
   * It keeps the selected filters for a Selection
   * selectedFilters are stored as filters in database
   * It will be passed from Selection.js
   */
  selectedFilters: PropTypes.instanceOf(Object),
  /**
   * It keeps the matchedFields for a target data extension of the Selection
   * It will be passed from Selection.js
   */
  matchedFields: PropTypes.instanceOf(Array),
  /**
   * It helps to set the Selection`s state
   * It will be passed from Selection.js
   */
  handleSetSelectionState: PropTypes.func.isRequired,
  /**
   * It keeps the relation between selected data extensions
   * It will be passed from Selection.js
   */
  relations: PropTypes.instanceOf(Array),
  /**
   * It helps to retrieve fields of a data extension or data view from SFMC
   * It will be passed from Selection.js
   */
  getDataExtensionOrDataViewFields: PropTypes.func.isRequired,
  /**
   * It helps to throw a warning message if a feature is disabled and used in the Selection
   * It will be passed from Selection.js
   */
  handleFeatureMissing: PropTypes.func.isRequired,
  /**
   * It helps to manage subscriber relationship while creating a data extension
   * It will be passed from Selection.js
   */
  manageSubscriberRelationship: PropTypes.func.isRequired,
  /**
   * Keeps track whether Available DE are dragged
   */
  DEBorderMouseOver: PropTypes.bool.isRequired,
  /**
   * Keeps track whether Available Fields are dragged
   */
  filterBorderMouseOver: PropTypes.bool.isRequired,
  /**
   * Responsible for showing/hiding loader
   */
  loaderSelectedDE: PropTypes.bool.isRequired,
  /**
   * Responsible for DE style (1 or 2+)
   */
  singleDEStyling: PropTypes.bool,
  /**
   * Responsible for adding/deleting fields Object IDs when searching picklist for the options
   * it will be passed from Selection.js
   */
  handlePickListOptions: PropTypes.func.isRequired,
  /**
   * Keeps searched picklist
   * It will be passed from Selection.js
   */
  pickLists: PropTypes.instanceOf(Array).isRequired,
  /**
   * Indicates whether the selected fromField has been deleted
   */
  fromFieldMissing: PropTypes.bool.isRequired,
  /**
   * Indicates whether the selected toField has been deleted
   */
  toFieldMissing: PropTypes.bool.isRequired,
  /**
   * Checks if there are any deleted fields in relations
   */
  checkMissingFieldsInRelations: PropTypes.func,
  /*
   * Stores the loading state of filterlines with subQueries
   */
  loadingSubQueryFields: PropTypes.instanceOf(Object),
  /**
   * It Removes a given filterLine
   * It is passed from Selection.js
   */
  handleRemoveFilterLine: PropTypes.func,
  /**
   * It keeps the states of a Selection`s if appendDataExtension feature is enabled
   */
  unionSelections: PropTypes.instanceOf(Array),
  /**
   * This prop keeps the unionSelectionsIndex of Union Selection
   * This prop will be passed from App.js component if the appendDataExtension feature is enabled
   */
  unionSelectionsIndex: PropTypes.number,
  /**
   * it sets the App component`s state
   * This prop will be passed from App.js component through Selection.js
   */
  handleSetAppState: PropTypes.func,
  /**
   * It keeps custom values data
   * It will be passed from Selection.js
   */
  customValues: PropTypes.instanceOf(Array).isRequired,
  /**
   * Responsible for the loading status of data extensions
   */
  loadingForDataExtensions: PropTypes.bool.isRequired,
  /**
   * Responsible for the loading status of all available data extensions
   */
  loadingAllAvailableDataExtensions: PropTypes.bool.isRequired,
  /**
   * An array containing relations that were defined in the admin panel
   */
  predefinedRelations: PropTypes.instanceOf(Array).isRequired,
  /**
   * An object that stores the object ids of found predefined relations in a selection
   */
  predefinedRelationsMap: PropTypes.instanceOf(Object).isRequired,
  /**
   * An array containing data extensions used in InResults and relations filters
   */
  subQueryDataExtensions: PropTypes.instanceOf(Array).isRequired,
  /**
   * It returns predefined relation object for relation filter
   * it is passed from Selection.js
   */
  returnPredefinedRelationById: PropTypes.func.isRequired,
  /**
   * Selected id of the available folder
   */
  availableDEsFolderId: PropTypes.number,
  /**
   * Stored availableDEs folders in selection state (Stored in order to prevent making unnecessary api calls)
   */
  availableDEsFolders: PropTypes.instanceOf(Array),
  /**
   * Keeps folder filtering information
   */
  foldersSettings: PropTypes.instanceOf(Object),
  /**
   * Indicates whether a DE is being moved
   */
  movingDE: PropTypes.bool.isRequired,
  /**
   * Copied selectedDEs before addition and removal of DEs from a subcollection
   * when moving DEs
   */
  prevSelectedDEs: PropTypes.instanceOf(Array).isRequired,
  /**
   * Copied selectedDEs before addition and removal of DEs from a subcollection
   * when moving DEs
   */
  prevRelations: PropTypes.instanceOf(Array).isRequired,
  /**
   * An object with child DE's id as key and parent DE's id as value.
   * Used to prevent the dragging of DE's unto their descendants
   */
  selectedDEsTree: PropTypes.instanceOf(Object),
  /**
   * Indicates whether timezone settings are applied to all date filters
   */
  applyTimezoneSettingsToAllDateFields: PropTypes.bool,
  /**
   * Handles the setting of timezone settings to all date filters
   */
  handleSetTimezoneToAllDateFields: PropTypes.func,
  /**
   * An object containing timezone details
   */
  timezoneSettingsForAllDateFields: PropTypes.instanceOf(Object),
  /**
   * An array containing data sets
   */
  dataSets: PropTypes.instanceOf(Array),
  /**
   * An array containing filter sets
   */
  filterSets: PropTypes.instanceOf(Array),
  /**
   * Determines if the feature flag for the data sets is true or false
   */
  featureDataSets: PropTypes.bool,
  /**
   * Determines if the feature flag for the filter sets is true or false
   */
  featureFilterSets: PropTypes.bool,
  /**
   * Parent Data Extension of the data set that is being dragged
   */
  parentDEOfDataSet: PropTypes.string,
  /*
   * Tells whether the selection is archived or not
   */
  isArchived: PropTypes.bool,
  /**
   * Mode of the selection
   */
  selectionMode: PropTypes.string,
  /**
   * Basic mode selection source type
   */
  basicModeSelectionSourceType: PropTypes.string,
  /**
   * Handles the setting of selected dataSet
   */
  handleSetSelectedDataSet: PropTypes.func,
  /**
   * handle remove basic mode source
   */
  handleClearBasicModeSource: PropTypes.func,
  /**
   * Handles the setting of selected DE
   */
  handleSetSelectedDE: PropTypes.func,
  /**
   * Ref from the selection container
   */
  selectedDERef: PropTypes.instanceOf(Object),
  /**
   * Selected dataSet
   */
  selectedDataSet: PropTypes.instanceOf(Object),
  /**
   * Check if mode was switched from advanced to basic
   */
  switchFromAdvancedToBasic: PropTypes.bool,
  /**
   * It toggles a feature advert modal on with specific feature
   */
  showEssentialsUpgradeModal: PropTypes.func,
  /**
   * Revert behavioral filter set
   */
  revertBehavioralFilterSet: PropTypes.func,
  /**
   * Update data extensions with search object
   */
  updateDataExtensionsObject: PropTypes.func,
  /**
   * Features info from cookie
   */
  featuresInfo: PropTypes.object,
};

DataExtensions.defaultProps = {
  /**
   * Indicates default value for fromFieldMissing
   */
  fromFieldMissing: false,
  /**
   * Indicates default value for toFieldMissing
   */
  toFieldMissing: false,

};

export default connect(mapStateToProps(['featuresInfo']), null, null, { pure: false })(DataExtensions);
